import { Link, Tabs } from "@common/components";
import { ProjectTabNameEnum } from "@user/enums";
import { Row, Col } from "antd";
import RoutePaths from "config/routePaths";
import { Project } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const renderProjectsList = (projects: Project[]): JSX.Element => {
  return (
    <Row gutter={[24, 12]}>
      {projects?.map((project) => {
        return (
          <Col key={project.id}>
            <Link to={`${RoutePaths.Projects}/${project.id}`}>
              {project.name}
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};

type PropTypes = { projects: Project[]; currentProjects: Project[] };

const ExpandedProjectsList: FC<PropTypes> = ({ projects, currentProjects }) => {
  const { t } = useTranslation("user.ExpandedProjectsList");

  const tabsItems = [
    {
      label: <div className={styles.tabName}>{t("current")}</div>,
      key: ProjectTabNameEnum.Current,
      children: currentProjects.length ? (
        renderProjectsList(currentProjects)
      ) : (
        <span className={styles.noProjectsText}>{t("noCurrentProjects")}</span>
      ),
    },
    {
      label: <div className={styles.tabName}>{t("all")}</div>,
      key: ProjectTabNameEnum.All,
      children: renderProjectsList(projects),
    },
  ];

  return <Tabs items={tabsItems} />;
};

export default ExpandedProjectsList;
