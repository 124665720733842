import { Select } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import { EmployeesTasksTableColumnType } from "@project/types";
import TasksFilterForm from "@task/components/TasksFilterForm";
import { DefaultOptionType } from "antd/lib/select";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";

type PropTypes = {
  userData: EmployeesTasksTableColumnType;
  setUserList: Dispatch<
    SetStateAction<EmployeesTasksTableColumnType[] | undefined>
  >;
};

const SelectTasksCell: FC<PropTypes> = ({ userData, setUserList }) => {
  const { t } = useTranslation("project.SelectTasksCell");
  const { setModalProps, hideModal } = useModal();
  const { projectId } = useParams();

  const currentUserTasks: DefaultOptionType[] = userData.tasks.map((task) => {
    return { value: task.id, label: task.name };
  });

  const onClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: (
        <TasksFilterForm
          projectId={projectId as string}
          key={userData.key}
          currentUserData={userData}
          setUserList={setUserList}
        />
      ),
      open: true,
      className: styles.modalFilter,
    });
  };

  return (
    <Select
      removeIcon={false}
      onClick={onClick}
      placeholder={t("selectTasks")}
      className={styles.tasksSelect}
      mode="multiple"
      value={currentUserTasks}
      allowClear={false}
      notFoundContent={false}
    />
  );
};

export default SelectTasksCell;
