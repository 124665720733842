import { RouteProps } from "@common/types";
import RoutePaths from "config/routePaths";
import { FC } from "react";
import { Navigate } from "react-router-dom";

const NoAuthRoute: FC<RouteProps> = ({ Page, pageProps, user }) => {
  if (user) {
    return <Navigate to={RoutePaths.Profile} />;
  }

  return <Page {...pageProps} />;
};

export default NoAuthRoute;
