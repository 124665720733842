import { Select } from "@common/components";
import { SetBusinessUnitsPropTypes } from "@task/types";
import { TaskInfo, useGetBusinessUnitListQuery } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  taskData: TaskInfo;
  setBusinessUnits: ({
    taskId,
    businessUnits,
  }: SetBusinessUnitsPropTypes) => void;
};

const BusinessUnitsCell: FC<PropTypes> = ({ taskData, setBusinessUnits }) => {
  const { t } = useTranslation("task.BusinessUnitsCell");

  const businessUnitsQuery = useGetBusinessUnitListQuery();

  const selectedBusinessUnits = taskData.businessUnit?.map((businessUnit) => {
    return { value: businessUnit, label: businessUnit };
  });

  const businessUnitOptions = businessUnitsQuery.data?.getBusinessUnitList?.map(
    (businessUnit) => {
      return { value: businessUnit, label: businessUnit };
    },
  );

  const onSelect = (optionValue: string) => {
    const selectedBusinessUnit =
      businessUnitsQuery.data?.getBusinessUnitList?.find((businessUnit) => {
        return optionValue === businessUnit;
      });
    if (selectedBusinessUnit) {
      setBusinessUnits({
        taskId: taskData.id,
        businessUnits: [...(taskData.businessUnit || []), selectedBusinessUnit],
      });
    }
  };

  const onDeselect = (optionValue: string) => {
    const filteredBusinessUnits = taskData.businessUnit?.filter(
      (businessUnit) => {
        return optionValue !== businessUnit;
      },
    );

    setBusinessUnits({
      taskId: taskData.id,
      businessUnits: filteredBusinessUnits || [],
    });
  };

  return (
    <Select
      showSearch
      onSelect={onSelect}
      onDeselect={onDeselect}
      loading={businessUnitsQuery.loading}
      className={styles.trainingsSelect}
      options={businessUnitOptions}
      placeholder={t("pleaseSelectBusinessUnits")}
      mode="multiple"
      value={selectedBusinessUnits}
    />
  );
};

export default BusinessUnitsCell;
