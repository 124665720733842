export default {
  DoesNotExist: {
    hintForUser: "Sorry, the page you visited does not exist.",
    toProfile: "Back to Profile",
    toSignIn: "Back to Sign In",
  },
  SideBarMenu: {
    profile: "My profile",
    users: "Users",
    projects: "Projects",
    trainings: "Training",
    myTrainingMatrix: "My training matrix",
    tasks: "Tasks",
    dashboard: "Dashboard",
    help: "Help",
    supperAdmin: "Super Admin",
    admin: "Admin",
    management: "Management",
    projectTeam: "Project team",
    roleMenu: "{{role}} menu",
  },
  BackButton: {
    back: "Back",
  },
  MainLayout: {
    profile: "TrainTrack",
    users: "Users",
    projects: "Projects",
    myProjects: "My projects",
    training: "Training",
    myTrainingMatrix: "My training matrix",
    tasks: "Tasks",
    dashboard: "Dashboard",
    help: "Help",
    notifications: "Notifications",
  },
  JoyrideTooltip: { skip: "Skip", back: "Back", next: "Next" },
  StatusCell: {
    done: "Done",
    required: "Required",
    expired: "Expired",
    archived: "Archived",
  },
};
