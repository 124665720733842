import { List } from "antd";
import { ListItemProps } from "antd/lib/list";
import { FC } from "react";

const ListItemANTD = List.Item;

const ListItem: FC<ListItemProps> = (props: ListItemProps) => {
  return <ListItemANTD {...props} />;
};

export default ListItem;
