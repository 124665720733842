import { Select } from "@common/components";
import { MyTrainingMatrixFilterType } from "@training/types";
import { Col, Row } from "antd";
import { Need, Status } from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  setFilterParams: Dispatch<SetStateAction<MyTrainingMatrixFilterType>>;
  filterParams: MyTrainingMatrixFilterType;
};

const MyTrainingMatrixFilterForm: FC<PropTypes> = ({
  setFilterParams,
  filterParams,
}) => {
  const { t } = useTranslation("training.MyTrainingMatrixFilterForm");

  const statusFilterOptions = [
    { value: Status.Done, label: t("done") },
    { value: Status.Expired, label: t("expired") },
    { value: Status.Required, label: t("required") },
    { value: Status.Archived, label: t("archived") },
  ];

  const needFilterOptions = [
    { value: Need.JobDepended, label: t("jobDepended") },
    { value: Need.Mandatory, label: t("mandatory") },
    { value: Need.Custom, label: t("custom") },
  ];

  const filters = [
    {
      nameLocale: "statuses",
      options: statusFilterOptions,
      filterName: "statuses",
      defaultValues: filterParams.statuses,
      placeholderLocale: "statusesSelectPlaceholder",
    },
    {
      nameLocale: "needs",
      options: needFilterOptions,
      filterName: "needs",
      defaultValues: filterParams?.needs,
      placeholderLocale: "needsSelectPlaceholder",
    },
  ];
  return (
    <Row gutter={[0, 12]}>
      <Col span={24} className={styles.title}>
        {t("filters")}
      </Col>
      {filters.map((filter) => {
        const onChange = (value: string) => {
          setFilterParams((filterParams) => {
            return { ...filterParams, [filter.filterName]: value };
          });
        };

        return (
          <Col span={24} key={filter.filterName}>
            <Row>
              <Col span={24}>{t(filter.nameLocale)}</Col>
              <Col span={24}>
                <Select
                  className={styles.select}
                  onChange={onChange}
                  placeholder={t(filter.placeholderLocale)}
                  defaultValue={filter.defaultValues}
                  options={filter.options}
                  mode="multiple"
                />
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default MyTrainingMatrixFilterForm;
