export default {
  ManageTasks: {
    searchPlaceholder: "Search by task, training...",
    edit: "Edit",
    createNewTask: "Create a new task",
    exportExcel: "Export Excel",
  },
  TasksTable: {
    tasksName: "Tasks",
    trainingsRequired: "Training required",
    businessUnits: "Business units",
  },
  EditTasksTable: {
    tasksName: "Tasks",
    trainingsRequired: "Training required",
    save: "Save",
    changesSaved: "Changes were saved",
    popconfirmText: "Are you sure you want to delete task?",
    yes: "Yes",
    no: "No",
    businessUnits: "Business units",
  },
  CreateTaskForm: {
    businessUnits: "Business units",
    tasksName: "Tasks Name",
    trainings: "Training",
    submit: "Submit",
    selectTrainingPlaceholder: "Please select trainings",
    selectBusinessUnitPlaceholder: "Please select business units",
    taskCreated: "Task created",
    createTask: "Create task",
  },
  TrainingRequiredCell: {
    pleaseSelectTraining: "Please select training",
  },
  BusinessUnitsCell: {
    pleaseSelectBusinessUnits: "Please select business units",
  },
  TasksFilterForm: {
    submit: "Submit",
    cleanAll: "Clean all",
  },
  SearchWithFilter: {
    searchPlaceholder: "Search by task name...",
    reset: "Reset",
  },
  SelectedTasksList: {
    selectedTasks: "Selected tasks",
  },
};
