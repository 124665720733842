import { ControlledInput, ControlledSelect, Spin } from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, message, Row } from "antd";
import {
  useCreateTaskMutation,
  useGetBusinessUnitListQuery,
  useListTrainingWithoutPaginationQuery,
} from "graphqlTypes";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CreateTaskFormType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Task,
  "components",
  "CreateTaskForm",
);

const CreateTaskForm: FC = () => {
  const { t } = useTranslation("task.CreateTaskForm");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateTaskFormType>({
    resolver: yupResolver(schema),
  });
  const { hideModal } = useModal();
  const [createTask, createTaskMutationStatus] = useCreateTaskMutation({
    refetchQueries: ["listTask"],
  });
  const trainingListQuery = useListTrainingWithoutPaginationQuery();
  const businessUnitQuery = useGetBusinessUnitListQuery();

  const trainingSelectOptions =
    trainingListQuery.data?.listTrainingWithoutPagination?.map((training) => {
      return { value: training.id, label: training.name };
    });

  const businessUnitSelectOption =
    businessUnitQuery.data?.getBusinessUnitList?.map((businessUnit) => {
      return { value: businessUnit, label: businessUnit };
    });

  const onSubmitClick = (data: CreateTaskFormType) => {
    createTask({
      variables: {
        businessUnit: data.businessUnits,
        name: data.taskName,
        trainingIds: data.trainings,
      },
      onCompleted: (data) => {
        hideModal();
        reset();
        logsServices.logInformation({
          message: `Task (id=${data.createTask?.id}) was created.`,
          location: ["onSubmitClick", "createTask", "onCompleted"],
        });
        message.success(t("taskCreated"));
      },
      refetchQueries: [
        "listTask",
        "getUserInfo",
        "getProfileInfo",
        "listTaskWithoutPagination",
        "listTaskInfoWithoutPagination",
        "getLeaderboardInfo",
        "getProjectInfo",
        "getProjectTeamInfo",
        "getGapAnalysisByDate",
      ],
    });
  };

  const isLoading = loadingService.checkLoading([
    businessUnitQuery.loading,
    trainingListQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Form>
        <Row align="middle" gutter={[0, 24]}>
          <Col span={24} className={styles.title}>
            {t("createTask")}
          </Col>
          <Col span={8}>{t("tasksName")}:</Col>
          <Col span={16}>
            <ControlledInput
              useControllerProps={{
                name: "taskName",
                control,
              }}
              inputProps={{
                "data-testid": "task-name",
                status: errors.taskName && "error",
              }}
            />
          </Col>
          <Col span={8}>{t("trainings")}:</Col>
          <Col span={16}>
            <ControlledSelect
              useControllerProps={{
                name: "trainings",
                control,
              }}
              selectProps={{
                "data-testid": "training-select",
                popupClassName: styles.selectPopup,
                showSearch: true,
                status: errors.trainings && "error",
                options: trainingSelectOptions,
                placeholder: t("selectTrainingPlaceholder"),
                mode: "multiple",
                className: styles.select,
              }}
            />
          </Col>
          <Col span={8}>{t("businessUnits")}:</Col>
          <Col span={16}>
            <ControlledSelect
              useControllerProps={{
                name: "businessUnits",
                control,
              }}
              selectProps={{
                "data-testid": "business-unit-select",
                popupClassName: styles.selectPopup,
                showSearch: true,
                status: errors.trainings && "error",
                options: businessUnitSelectOption,
                placeholder: t("selectBusinessUnitPlaceholder"),
                mode: "multiple",
                className: styles.select,
              }}
            />
          </Col>
          <Col span={24} className={styles.submitButton}>
            <Button
              data-testid="submit"
              type="primary"
              loading={createTaskMutationStatus.loading}
              onClick={handleSubmit(onSubmitClick)}
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateTaskForm;
