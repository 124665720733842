import {
  DatePicker,
  DownloadButton,
  Input,
  Spin,
  Table,
} from "@common/components";
import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import sortConverterServices from "@common/services/sortConverterServices";
import GapAnalysisPieChart from "@project/components/GapAnalysisPieChart";
import { EmployeeTableSortersType } from "@project/types";
import { Col, Row, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import config from "config";
import { format } from "date-fns";
import {
  ProjectTeamInfo,
  useGetGapAnalysisByDateQuery,
  useGetProjectInfoQuery,
  useGetProjectTeamInfoQuery,
  useListGapAnalysisDateQuery,
} from "graphqlTypes";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

import { getColumns } from "./columns";
import styles from "./styles.module.scss";

import { CONFIG } from "../../config";

const logsServices = new LogsServices(
  ModuleNameEnum.Project,
  "components",
  "EmployeesTable",
);

const EmployeesTable: FC = () => {
  const { t } = useTranslation("project.EmployeesTable");
  const { projectId } = useParams();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearchText = useDebounce(searchText);
  const chartRef = useRef<HTMLDivElement>(null);
  const [date, setDate] = useState<Date | null>();
  const [isPrintMode, setIsPrintMode] = useState(false);
  const [sorters, setSorters] = useState<EmployeeTableSortersType>();
  const projectInfoQuery = useGetProjectInfoQuery({
    variables: {
      id: projectId as string,
    },
  });

  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;

  const getProjectTeamQuery = useGetProjectTeamInfoQuery({
    variables: {
      mandatoryTrainingSort: sorters?.mandatoryTrainingStatus,
      jobDependentTrainingSort: sorters?.jobDependentStatus,
      searchString: debouncedSearchText || "",
      projectId: projectId as string,
      limit: config.TABLE_LIMIT,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
    },
  });
  const listGapAnalysisDateQuery = useListGapAnalysisDateQuery({
    variables: {
      projectId: projectId as string,
    },
  });
  const getGapAnalysisByDateQuery = useGetGapAnalysisByDateQuery({
    variables: {
      date: format(
        date || CONFIG.PROJECT_STATUS_GAP_ANALYSIS_DEFAULT_DATE,
        config.REVERSED_DATE_WITH_DASHES,
      ),
      projectId: projectId as string,
    },
    onCompleted: (data) => {
      if (data.getGapAnalysisByDate?.date) {
        setDate(new Date(data.getGapAnalysisByDate.date));
      }
    },
  });

  useEffect(() => {
    const sorters = getSearchParam(SearchParamsEnum.FilterParams);
    if (sorters) {
      setSorters(JSON.parse(sorters));
    }
  }, []);

  useEffect(() => {
    addSearchParams({
      [SearchParamsEnum.FilterParams]: JSON.stringify(sorters || {}),
    });
  }, [sorters]);

  const gapAnalysisByDateData =
    getGapAnalysisByDateQuery.data?.getGapAnalysisByDate;
  const allowedDateList =
    listGapAnalysisDateQuery.data?.listGapAnalysisDate?.map((date) => {
      return new Date(date);
    });
  const projectTeamUsers = getProjectTeamQuery.data?.getProjectTeamInfo?.data;
  const total = getProjectTeamQuery.data?.getProjectTeamInfo?.total;
  const projectName = projectInfoQuery.data?.getProjectInfo?.name;
  const tableData = projectTeamUsers?.map((projectTeamUser) => {
    return {
      key: projectTeamUser.graphUser.id,
      ...projectTeamUser,
    };
  });
  const columns = getColumns({ sorters });

  const onDatePickerChange = (date: Date | null) => {
    setDate(date);
  };

  const onChange: TableProps<ProjectTeamInfo>["onChange"] = (
    _pagination,
    _filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<ProjectTeamInfo>;

    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setSorters(sorterFields);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });

    logsServices.logVerbose({
      location: ["onSearchChange"],
      message: `Project team search: ${searchText}`,
    });
  };

  const onPageChange = (page: number) => {
    addSearchParams({
      [SearchParamsEnum.Page]: page.toString(),
    });
  };

  const isLoading = loadingService.checkLoading([
    listGapAnalysisDateQuery.loading,
    getGapAnalysisByDateQuery.loading,
    getProjectTeamQuery.loading,
    projectInfoQuery.loading,
  ]);

  const onBeforeGetPrintContent = () => {
    setIsPrintMode(true);
    // React to print is waiting until project name will be displayed.
    return Promise.resolve();
  };

  const onAfterPrint = () => {
    setIsPrintMode(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Input
            value={searchText || ""}
            onChange={onSearchChange}
            placeholder={t("searchPlaceholder")}
          />
        </Col>
        <Col span={24}>
          <Table
            onChange={onChange}
            loading={getProjectTeamQuery.loading}
            columns={columns}
            dataSource={tableData}
            pagination={{
              current: currentPage,
              onChange: onPageChange,
              pageSize: config.TABLE_LIMIT,
              total,
              showSizeChanger: false,
            }}
          />
        </Col>
        <Col span={24}>
          <div ref={chartRef} className={styles.gapAnalysisTableContainer}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Row align="middle" gutter={[24, 0]}>
                  <Col>
                    <Row className={styles.title} gutter={[12, 0]}>
                      <Col>{t("projectStatusChart")}</Col>
                      <Col>
                        <ReactToPrint
                          trigger={DownloadButton}
                          onBeforeGetContent={onBeforeGetPrintContent}
                          onAfterPrint={onAfterPrint}
                          content={() => {
                            return chartRef.current;
                          }}
                        />
                      </Col>
                      <Col>
                        <DatePicker
                          clearIcon={false}
                          disabledDate={(date) => {
                            return !allowedDateList?.some((allowedDate) => {
                              const formattedAllowedDate = format(
                                allowedDate,
                                config.REVERSED_DATE_WITH_DASHES,
                              );
                              const formattedDate = format(
                                date,
                                config.REVERSED_DATE_WITH_DASHES,
                              );
                              return formattedAllowedDate === formattedDate;
                            });
                          }}
                          value={date}
                          onChange={onDatePickerChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {isPrintMode && (
                <Col span={24} className={styles.title}>
                  {projectName}
                </Col>
              )}
              <Col span={24} ref={chartRef}>
                <GapAnalysisPieChart
                  gapAnalysisUserInfoList={gapAnalysisByDateData?.info || []}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default EmployeesTable;
