import { BellOutlined } from "@ant-design/icons";
import { Link } from "@common/components";
import wsNotificationsServices from "@notification/services/wsNotificationsServices";
import { WsCountType, WsNotificationType } from "@notification/types";
import { Badge, message } from "antd";
import classNames from "classnames";
import RoutePaths from "config/routePaths";
import { NotificationType } from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IMessageEvent } from "websocket";

import styles from "./styles.module.scss";

const localeNamesByNotificationType = {
  [NotificationType.Certificate]: "addNewCertificate",
  [NotificationType.Diploma]: "addedDiploma",
  [NotificationType.Request]: "requestTraining",
  [NotificationType.Require]: "requiresTraining",
  [NotificationType.CertificateForAnother]: "certificateForAnother",
  [NotificationType.RequestForAnother]: "requestForAnother",
};

const NotificationIconWithCount: FC = () => {
  const { t } = useTranslation("notification.NotificationIconWithCount");
  const [unreadCount, setUnreadCount] = useState(0);
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const isSelected = pathname === RoutePaths.Notifications.split("/")[1];

  const eventFunction: {
    [key: string]: <T extends WsCountType & WsNotificationType>(
      notificationData: T,
    ) => void;
  } = {
    count: (notificationData: WsCountType) => {
      setUnreadCount(Number(notificationData.unreadCount));
    },
    notification: (notificationData: WsNotificationType) => {
      const userName = notificationData.sender.displayName;
      const localeName =
        localeNamesByNotificationType[
          notificationData.notification.type as NotificationType
        ];
      apolloProvider.apolloApiClient.refetchQueries({
        include: ["listMyNotifications"],
      });
      message.info(t(localeName, { userName }));
    },
  };

  const onNotificationMessage = useCallback(
    (webSocketMessage: IMessageEvent) => {
      const notificationData = JSON.parse(webSocketMessage.data.toString());
      eventFunction[notificationData.eventType](notificationData.event);
    },
    [],
  );

  useEffect(() => {
    wsNotificationsServices.subscribeOnNotifications(onNotificationMessage);
    return () => {
      wsNotificationsServices.unsubscribeOnNotifications(onNotificationMessage);
    };
  }, []);

  const iconClassName = classNames(styles.badgeIcon, {
    [styles.selected]: isSelected,
  });

  return (
    <Link to={RoutePaths.Notifications}>
      <Badge
        status="success"
        count={unreadCount || undefined}
        size="small"
        showZero
      >
        <BellOutlined className={iconClassName} />
      </Badge>
    </Link>
  );
};

export default NotificationIconWithCount;
