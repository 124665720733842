import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_APPLICATION_INSIGHT_CONNECTING_STRING,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
