import { Select } from "@common/components";
import { SetTrainingPropTypes } from "@task/types";
import { TaskInfo, useListTrainingWithoutPaginationQuery } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  taskData: TaskInfo;
  setTrainings: ({ taskId, trainings }: SetTrainingPropTypes) => void;
};

const TrainingRequiredCell: FC<PropTypes> = ({ taskData, setTrainings }) => {
  const { t } = useTranslation("task.TrainingRequiredCell");

  const trainingListQuery = useListTrainingWithoutPaginationQuery();

  const selectedTrainings = taskData.trainings.map((options) => {
    return { value: options.id, label: options.name };
  });

  const trainingSelectOptions =
    trainingListQuery.data?.listTrainingWithoutPagination?.map((training) => {
      return { value: training.id, label: training.name };
    });

  const onSelect = (trainingId: string) => {
    const selectedTraining =
      trainingListQuery.data?.listTrainingWithoutPagination?.find(
        (training) => {
          return trainingId === training.id;
        },
      );

    if (selectedTraining) {
      setTrainings({
        taskId: taskData.id,
        trainings: [...taskData.trainings, selectedTraining],
      });
    }
  };

  const onDeselect = (trainingId: string) => {
    const filteredTrainings = taskData.trainings.filter((training) => {
      return training.id !== trainingId;
    });

    setTrainings({
      taskId: taskData.id,
      trainings: filteredTrainings,
    });
  };

  return (
    <Select
      showSearch
      onSelect={onSelect}
      onDeselect={onDeselect}
      loading={trainingListQuery.loading}
      className={styles.trainingsSelect}
      options={trainingSelectOptions}
      placeholder={t("pleaseSelectTraining")}
      mode="multiple"
      value={selectedTrainings}
    />
  );
};

export default TrainingRequiredCell;
