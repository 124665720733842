import { Link } from "@common/components";
import { Col, Row } from "antd";
import RoutePaths from "config/routePaths";
import { LeaderboardProjectTeamInfo, ProjectInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PropTypes = {
  employees: LeaderboardProjectTeamInfo[];
  project: ProjectInfo;
};

const ReactiveProjectInfoWithLink: FC<PropTypes> = ({ employees, project }) => {
  const { t } = useTranslation("dashboard.ReactiveProjectInfoWithLink");

  const missingTrainingsCount = employees.reduce((totalCount, employee) => {
    return totalCount + employee.certificatesValue;
  }, 0);

  return (
    <Row>
      <Col span={24}>
        {t("trainingsMissing", {
          missingTrainingsCount,
        })}
      </Col>
      {employees.map((employee) => {
        return (
          <Col span={24} key={employee.graphUser?.id}>
            <Row gutter={[12, 12]}>
              <Col>
                <Link
                  to={`${RoutePaths.Projects}/${project.id}/${employee.graphUser?.id}`}
                >
                  {employee.graphUser?.displayName}
                </Link>
                :
              </Col>
              <Col>{employee.certificatesValue}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default ReactiveProjectInfoWithLink;
