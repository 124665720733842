import { AxiosResponse } from "axios";
import { encode } from "base64-arraybuffer";

class FileConverterServices {
  convertToBase64String = (
    response: AxiosResponse<ArrayBuffer, unknown>,
  ): string => {
    if (!response?.data) {
      return "";
    }

    const encodedStringData = encode(response.data);

    return `data:${response.headers["content-type"]};base64,${encodedStringData}`;
  };
}

const fileConverterServices = new FileConverterServices();

export default fileConverterServices;
