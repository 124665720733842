import AuthRoute from "@auth/components/AuthRoute";
import CommonRoute from "@auth/components/CommonRoute";
import NoAuthRoute from "@auth/components/NoAuthRoute";
import MainLayout from "@common/components/MainLayout";
import { Layout } from "@common/enums";
import { AuthType, Route as RouteType } from "@common/types";
import UserProvider from "providers/UserProvider";
import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

type PropTypes = {
  routes: RouteType[];
};

const routesByAuthType = {
  [AuthType.Auth]: AuthRoute,
  [AuthType.NoAuth]: NoAuthRoute,
  [AuthType.Common]: CommonRoute,
};

const layout = {
  [Layout.MainLayout]: <MainLayout />,
  [Layout.NoLayout]: undefined,
};

const AuthProvider: FC<PropTypes> = ({ routes }) => {
  const layoutNames = Object.keys(layout) as Layout[];
  const routesData = layoutNames.map((layoutName) => {
    const routesWithCurrentLayout = routes.filter((route) => {
      return route.layout === layoutName;
    });

    const routesComponents = routesWithCurrentLayout.map((route) => {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <UserProvider
              RouteByAuthTypeComponent={routesByAuthType[route.type]}
              route={route}
            />
          }
        />
      );
    });

    return (
      <Route key={layoutName} element={layout[layoutName]}>
        {routesComponents}
      </Route>
    );
  });

  return (
    <BrowserRouter>
      <Routes>{routesData}</Routes>
    </BrowserRouter>
  );
};

export default AuthProvider;
