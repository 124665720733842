import { Col, Row } from "antd";
import { LeaderboardProjectTeamInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PropTypes = {
  employees: LeaderboardProjectTeamInfo[];
};

const ActiveProjectInfo: FC<PropTypes> = ({ employees }) => {
  const { t } = useTranslation("dashboard.ActiveProjectInfo");

  const doneTrainingsCount = employees.reduce((totalCount, employee) => {
    return totalCount + employee.certificatesValue;
  }, 0);

  return (
    <Row>
      <Col span={24}>
        {t("trainingsDone", {
          doneTrainingsCount,
        })}
      </Col>
      {employees.map((employee) => {
        return (
          <Col span={24} key={employee.graphUser?.id}>
            <Row gutter={[12, 12]}>
              <Col>{employee.graphUser?.displayName}:</Col>
              <Col>{employee.certificatesValue}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default ActiveProjectInfo;
