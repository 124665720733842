import { gql } from "@apollo/client";
import { USER_INFO_FRAGMENT } from "@user/graphql/fragments/userInfoFragment";

export const GET_PROFILE_INFO_QUERY = gql`
  ${USER_INFO_FRAGMENT}
  query getProfileInfo {
    getProfileInfo {
      ...UserInfoNode
    }
  }
`;
