import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useSearchParams } from "@common/hooks/useSearchParams";
import LogsServices from "@common/services/logsServices";
import QuestionsList from "@help/components/QuestionsList";
import { Col, Input, Row } from "antd";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Help,
  "components",
  "ManageQuestionList",
);

const ManageQuestionList: FC = () => {
  const { t } = useTranslation("help.ManageQuestionList");
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearchText = useDebounce(searchText);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
    });

    logsServices.logVerbose({
      location: ["onSearchChange"],
      message: `FAQ search: ${searchText}`,
    });
  };

  return (
    <Row gutter={[0, 24]} justify="center">
      <Col span={23}>
        <Input
          onChange={onSearchChange}
          value={searchText || ""}
          placeholder={t("searchPlaceholder")}
        />
      </Col>
      <Col span={23}>
        <Row gutter={[0, 12]} justify="center">
          <Col span={24} className={styles.title}>
            <b>{t("commonQuestions")}</b>
          </Col>
          <Col span={23}>
            <QuestionsList searchText={debouncedSearchText || ""} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ManageQuestionList;
