import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "@common/components";
import CurrentTrainingUsersCount from "@training/components/CurrentTrainingUsersCount";
import UpdateTrainingForm from "@training/components/UpdateTrainingForm";
import { ModalProps } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import config from "config";
import { format } from "date-fns/esm";
import { TrainingWithTasks } from "graphqlTypes";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

import styles from "./styles.module.scss";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.TrainingsTable",
);

type PropTypes = {
  deleteTraining: (id: number) => void;
  setModalProps: Dispatch<SetStateAction<ModalProps>>;
};

export const getColumns = ({
  deleteTraining,
  setModalProps,
}: PropTypes): ColumnsType<TrainingWithTasks> => {
  return [
    {
      title: (
        <span data-testid="training-table-training-title-column">
          {t("trainingName")}
        </span>
      ),
      dataIndex: "trainingName",
      key: "trainingName",
      sorter: true,
      render: (_, trainingWithTasks) => {
        return (
          <span data-cy="training-table-training-title">
            {trainingWithTasks.training.name}
          </span>
        );
      },
    },
    {
      title: t("positions"),
      dataIndex: "positions",
      key: "positions",
      ellipsis: true,
      render: (_, trainingWithTasks) => {
        return trainingWithTasks.training.mandatoryPositions.join(", ");
      },
    },
    {
      title: t("tasks"),
      dataIndex: "tasks",
      key: "tasks",
      ellipsis: true,
      render: (_, trainingWithTasks) => {
        const tasksNames = trainingWithTasks.tasks.map((task) => {
          return task.name;
        });

        return tasksNames.join(", ");
      },
    },
    {
      title: (
        <span data-testid="training-table-creation-date-column">
          {t("creationDate")}
        </span>
      ),
      dataIndex: "creationDate",
      key: "creationDate",
      sorter: true,
      render: (_, trainingWithTasks) => {
        return (
          <span data-cy="training-table-creation-date">
            {format(
              new Date(trainingWithTasks.training.createdAt || 0),
              config.DATE_WITH_DOTS,
            )}
          </span>
        );
      },
    },
    {
      key: "usersCount",
      title: t("users"),
      dataIndex: "usersCount",
      render: (_, trainingWithTasks) => {
        return (
          <CurrentTrainingUsersCount training={trainingWithTasks.training} />
        );
      },
    },
    {
      key: "edit",
      render: (_, trainingWithTasks) => {
        const onEdit = () => {
          setModalProps({
            closable: false,
            footer: false,
            open: true,
            children: (
              <UpdateTrainingForm
                training={trainingWithTasks.training}
                key={trainingWithTasks.training.id}
              />
            ),
          });
        };
        return <Button type="link" onClick={onEdit} icon={<EditOutlined />} />;
      },
    },
    {
      key: "delete",
      render: (_, trainingWithTasks) => {
        const onDelete = () => {
          deleteTraining(trainingWithTasks.training.id);
        };

        return (
          <Popconfirm
            okText={t("yes")}
            cancelText={t("no")}
            title={t("deleteWarning")}
            onConfirm={onDelete}
          >
            <DeleteOutlined className={styles.deleteIcon} />
          </Popconfirm>
        );
      },
    },
  ];
};
