import { ModalContext } from "@common/contexts/ModalContext";
import { ModalProps } from "antd";
import { SetStateAction, useContext } from "react";

export const useModal = () => {
  const modalContext = useContext(ModalContext);

  const hideModal = () => {
    document.body.style.overflow = "initial";
    document.documentElement.style.overflow = "initial";
    modalContext.setModalProps({ open: false, children: null });
  };

  const setModalProps = (props: SetStateAction<ModalProps>) => {
    document.body.style.overflow = "hidden";
    // TODO scroll lock in case: iphone scroll bug
    // document.documentElement.style.overflow = "hidden";

    modalContext.setModalProps(props);
  };

  return { hideModal, setModalProps };
};
