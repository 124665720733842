import { FilterFilled } from "@ant-design/icons";
import { Link } from "@common/components";
import { UserTableFiltersType } from "@user/types";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import config from "config";
import RoutePaths from "config/routePaths";
import { AccountStatus, Role, UserInfo } from "graphqlTypes";
import i18n from "providers/i18n";

import EditCellByRole from "./EditCellByRole";
import styles from "./styles.module.scss";

const t: (key: string) => string = i18n.getFixedT(null, "user.UsersTable");

const classNameByUserStatus = {
  [AccountStatus.Active]: styles.activeStatus,
  [AccountStatus.Proactive]: styles.activeStatus,
  [AccountStatus.Reactive]: styles.reactiveStatus,
  [AccountStatus.Inactive]: styles.inactiveStatus,
};

type PropTypes = {
  businessUnits: string[];
  currentUserRole: Role;
  filterParams: UserTableFiltersType | undefined;
};

export const getColumns = ({
  businessUnits,
  currentUserRole,
  filterParams,
}: PropTypes): ColumnsType<UserInfo> => {
  const translatedRole = {
    [Role.SuperAdmin]: t("superAdmin"),
    [Role.Admin]: t("admin"),
    [Role.Manager]: t("management"),
    [Role.User]: t("projectTeam"),
  };

  const translatedTrainingStatus = {
    [AccountStatus.Active]: t("active"),
    [AccountStatus.Proactive]: t("proactive"),
    [AccountStatus.Reactive]: t("reactive"),
    [AccountStatus.Inactive]: t("inactive"),
  };

  const roleFilterOptions = [
    {
      text: t("superAdmin"),
      value: Role.SuperAdmin,
    },
    {
      text: t("admin"),
      value: Role.Admin,
    },
    {
      text: t("management"),
      value: Role.Manager,
    },
    {
      text: t("projectTeam"),
      value: Role.User,
    },
  ];

  const trainingStatusFilterOptions = [
    {
      text: t("active"),
      value: AccountStatus.Active,
    },
    {
      text: t("proactive"),
      value: AccountStatus.Proactive,
    },
    {
      text: t("reactive"),
      value: AccountStatus.Reactive,
    },
    {
      text: t("inactive"),
      value: AccountStatus.Inactive,
    },
  ];

  const businessUnitFilterOptions = businessUnits.map((businessUnit) => {
    return { text: businessUnit, value: businessUnit };
  });

  return [
    {
      title: t("fullName"),
      dataIndex: "user",
      key: "user",
      render: (_, userData) => {
        return (
          <Link
            data-cy="users-table-name"
            to={`${RoutePaths.Users}/${userData.graphProfile.id}`}
          >
            {userData.graphProfile.displayName}
          </Link>
        );
      },
    },
    {
      title: t("role"),
      dataIndex: "role",
      key: "role",
      filters: roleFilterOptions,
      filteredValue: filterParams?.roleFilter,
      filterIcon: () => {
        return <FilterFilled data-testid="users-table-role-filter" />;
      },
      render: (_, userData) => {
        return (
          <span data-cy="users-table-role">
            {translatedRole[userData.user?.role || config.DEFAULT_ROLE]}
          </span>
        );
      },
    },
    {
      title: t("position"),
      dataIndex: "position",
      key: "position",
      render: (_, userData) => {
        return userData.graphProfile.jobTitle;
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: t("currentProjects"),
      dataIndex: "currentProjects",
      key: "currentProjects",
      ellipsis: true,
      render: (_, userData) => {
        const projectNames = userData.currentProjects?.map((project) => {
          return project.name;
        });
        return projectNames?.join(", ");
      },
    },
    {
      title: t("businessUnit"),
      dataIndex: "businessUnit",
      key: "businessUnit",
      filters: businessUnitFilterOptions,
      filteredValue: filterParams?.businessUnitFilter,
      filterIcon: () => {
        return <FilterFilled data-testid="users-table-business-unit-filter" />;
      },
      render: (_, userData) => {
        return (
          <span data-cy="users-table-business-unit">
            {userData.graphProfile.officeLocation}
          </span>
        );
      },
    },
    {
      title: t("profileTrainingStatus"),
      dataIndex: "profileTrainingStatus",
      key: "profileTrainingStatus",
      filters: trainingStatusFilterOptions,
      filteredValue: filterParams?.profileTrainingStatusFilter,
      filterIcon: () => {
        return <FilterFilled data-testid="users-table-status-filter" />;
      },
      render: (_, userData) => {
        return userData.accountStatus ? (
          <span
            data-cy="users-table-status"
            className={classNameByUserStatus[userData.accountStatus]}
          >
            {translatedTrainingStatus[userData.accountStatus]}
          </span>
        ) : null;
      },
    },
    {
      dataIndex: "deactivated",
      key: "deactivated",
      title: t("userStatus"),
      render: (_, userData) => {
        return userData.user?.active ? null : (
          <span className={styles.deactivated}>{t("deactivated")}</span>
        );
      },
    },
    {
      dataIndex: "edit",
      key: "edit",
      render: (_, userData) => {
        return (
          <span data-cy="users-table-edit">
            <EditCellByRole
              userData={userData}
              currentUserRole={currentUserRole}
            />
          </span>
        );
      },
    },
  ];
};
