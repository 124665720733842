import { Link } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import UserNameSection from "@project/components/UserNameSection";
import { ColumnsType } from "antd/lib/table/interface";
import { AccountStatus, Need, SuggestedCandidateInfo } from "graphqlTypes";
import i18n from "providers/i18n";

import styles from "./styles.module.scss";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "project.SuggestedCandidatesTable",
);

const classNameByUserStatus = {
  [AccountStatus.Active]: styles.activeStatus,
  [AccountStatus.Proactive]: styles.activeStatus,
  [AccountStatus.Reactive]: styles.reactiveStatus,
  [AccountStatus.Inactive]: styles.inactiveStatus,
};

export const getColumns = (): ColumnsType<SuggestedCandidateInfo> => {
  const translatedTrainingStatus = {
    [AccountStatus.Active]: t("active"),
    [AccountStatus.Proactive]: t("proactive"),
    [AccountStatus.Reactive]: t("reactive"),
    [AccountStatus.Inactive]: t("inactive"),
  };

  return [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (_, candidateInfo) => {
        return <UserNameSection graphUser={candidateInfo.graphUser} />;
      },
    },
    {
      title: t("position"),
      dataIndex: "position",
      key: "position",
      render: (_, candidateInfo) => {
        return candidateInfo.position;
      },
    },
    {
      title: t("mandatoryTrainingStatus"),
      dataIndex: "mandatoryTrainingStatus",
      key: "mandatoryTrainingStatus",
      sorter: true,
      render: (_, candidateInfo) => {
        const status = candidateInfo.mandatoryTrainingStatus;

        return status ? (
          <Link
            to={`./${candidateInfo.graphUser.id}?${SearchParamsEnum.Need}=${Need.Mandatory}`}
            className={classNameByUserStatus[status]}
          >
            {translatedTrainingStatus[status]}
          </Link>
        ) : null;
      },
    },
    {
      title: t("jobDependentStatus"),
      dataIndex: "jobDependentStatus",
      key: "jobDependentStatus",
      sorter: true,
      render: (_, candidateInfo) => {
        const status = candidateInfo.jobDependedTrainingStatus;

        return status ? (
          <Link
            to={`./${candidateInfo.graphUser.id}/?${SearchParamsEnum.Need}=${Need.JobDepended}`}
            className={classNameByUserStatus[status]}
          >
            {translatedTrainingStatus[status]}
          </Link>
        ) : null;
      },
    },
  ];
};
