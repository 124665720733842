import { SearchParamsEnum } from "@common/enums";
import { useSearchParams as useSearchParamsRRD } from "react-router-dom";

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsRRD();

  const allParams = Object.fromEntries(searchParams);

  const getAllSearchParams = () => {
    return allParams;
  };

  const addSearchParams = (params: { [key: string]: string }) => {
    setSearchParams({
      ...allParams,
      ...params,
    });
  };

  const getSearchParam = (paramName: SearchParamsEnum) => {
    return searchParams.get(paramName);
  };

  return {
    getAllSearchParams,
    addSearchParams,
    getSearchParam,
    setSearchParams,
  };
};
