import { LeftOutlined } from "@ant-design/icons";
import Button from "@common/components/Button";
import { useHistory } from "@common/hooks/useHistory";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const BackButton: FC = () => {
  const { navigateToLastRoute } = useHistory();
  const { t } = useTranslation("common.BackButton");

  const onBackClick = () => {
    navigateToLastRoute();
  };

  return (
    <Button onClick={onBackClick} type="link" icon={<LeftOutlined />}>
      {t("back")}
    </Button>
  );
};

export default BackButton;
