import { FileOutlined } from "@ant-design/icons";
import RequestExistedCertificateForm from "@training/components/RequestExistedCertificateForm";
import RequestTrainingCell from "@training/components/RequestTrainingCell";
import { ModalProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import features from "config/features";
import { Need, Status, CertificateInfo, UserInfo } from "graphqlTypes";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

import styles from "./styles.module.scss";

const positiveStatuses = [Status.Archived, Status.Done];

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.UserCertificatesTable",
);

type PropTypes = {
  setModalProps: Dispatch<SetStateAction<ModalProps>>;
  hideModal: () => void;
  userData: UserInfo | undefined;
  needFilters: string[];
};

export const getColumns = ({
  setModalProps,
  hideModal,
  userData,
  needFilters,
}: PropTypes): ColumnsType<CertificateInfo> => {
  const statusLocale = {
    [Status.Done]: t("done"),
    [Status.Expired]: t("expired"),
    [Status.Required]: t("required"),
    [Status.Archived]: t("archived"),
  };

  const needLocales = {
    [Need.JobDepended]: t("jobDepended"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const needFilterOptions = [
    { value: Need.JobDepended, text: t("jobDepended") },
    { value: Need.Mandatory, text: t("mandatory") },
  ];

  return [
    {
      key: "trainingName",
      title: t("trainingName"),
      sorter: (trainingA: CertificateInfo, trainingB: CertificateInfo) => {
        const nameA = trainingA.name.toUpperCase();
        const nameB = trainingB.name.toUpperCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      },
      ellipsis: true,
      render: (_, certificate) => {
        return certificate.name;
      },
    },
    {
      key: "trainingNeed",
      title: t("trainingNeed"),
      filters: needFilterOptions,
      defaultFilteredValue: needFilters,
      onFilter(value, record) {
        return record.need === value;
      },
      render: (_, certificate) => {
        return needLocales[certificate.need];
      },
    },
    {
      key: "uploadDownload",
      render: (_, certificate) => {
        const isPositiveStatus = positiveStatuses.some((status) => {
          return status === certificate.status;
        });

        return isPositiveStatus && certificate.certificateLink ? (
          <a
            target="_blank"
            href={certificate.certificateLink}
            rel="noreferrer"
          >
            <FileOutlined />
          </a>
        ) : (
          <span className={styles.overdueCertificate}>
            {statusLocale[certificate.status]}
          </span>
        );
      },
    },
    ...(features.request
      ? [
          {
            key: "request",
            render: (_: undefined, certificate: CertificateInfo) => {
              const isPositiveStatus = positiveStatuses.some((status) => {
                return status === certificate.status;
              });

              const onRequestClick = () => {
                setModalProps({
                  footer: false,
                  onCancel: hideModal,
                  children: (
                    <RequestExistedCertificateForm
                      key={certificate.trainingId}
                      userData={userData}
                      certificate={certificate}
                    />
                  ),
                  open: true,
                });
              };

              return isPositiveStatus ? null : (
                <RequestTrainingCell
                  certificate={certificate}
                  onRequestClick={onRequestClick}
                />
              );
            },
          },
        ]
      : []),
  ];
};
