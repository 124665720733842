import ActiveProjectInfo from "@dashboard/components/ActiveProjectInfo";
import ActiveProjectInfoWithLink from "@dashboard/components/ActiveProjectInfoWithLink";
import ReactiveProjectInfo from "@dashboard/components/ReactiveProjectInfo";
import ReactiveProjectInfoWithLink from "@dashboard/components/ReactiveProjectInfoWithLink";
import {
  LeaderboardAwaitingCommencementInfo,
  LeaderboardInfo,
  ProjectStatus,
  Role,
} from "graphqlTypes";
import { FC } from "react";

import styles from "./styles.module.scss";

type PropTypes = {
  userRole: Role;
  leaderData: LeaderboardInfo | LeaderboardAwaitingCommencementInfo;
};

const DashboardPopup: FC<PropTypes> = ({ userRole, leaderData }) => {
  const popoverWithLinks = {
    [ProjectStatus.Active]: (
      <ActiveProjectInfoWithLink
        project={leaderData.projectInfo}
        employees={leaderData.projectTeamInfo}
      />
    ),
    [ProjectStatus.Inactive]: undefined,
    [ProjectStatus.Reactive]: (
      <ReactiveProjectInfoWithLink
        project={leaderData.projectInfo}
        employees={leaderData.projectTeamInfo}
      />
    ),
  };

  const popoverWithoutLinks = {
    [ProjectStatus.Active]: (
      <ActiveProjectInfo employees={leaderData.projectTeamInfo} />
    ),
    [ProjectStatus.Inactive]: undefined,
    [ProjectStatus.Reactive]: (
      <ReactiveProjectInfo employees={leaderData.projectTeamInfo} />
    ),
  };

  const popoverByStatusAndByRole = {
    [Role.SuperAdmin]: popoverWithLinks,
    [Role.Admin]: popoverWithLinks,
    [Role.Manager]: popoverWithLinks,
    [Role.User]: popoverWithoutLinks,
  };

  return (
    <div className={styles.statusPopover}>
      {popoverByStatusAndByRole[userRole][leaderData.status]}
    </div>
  );
};

export default DashboardPopup;
