import {
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
  ControlledTextArea,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, message, Row } from "antd";
import config from "config";
import {
  Role,
  Training,
  Type,
  useGetPositionListQuery,
  useUpdateTrainingMutation,
} from "graphqlTypes";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CreateTrainingType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Training,
  "components",
  "UpdateTrainingForm",
);

type PropTypes = {
  training: Training;
};

const UpdateTrainingForm: FC<PropTypes> = ({ training }) => {
  const { t } = useTranslation("training.UpdateTrainingForm");
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm<CreateTrainingType>({
    resolver: yupResolver(schema),
    defaultValues: {
      nationalUnitOfCompetency: training.unitOfCompetency || undefined,
      description: training.description || "",
      mandatoryNeed: training.mandatoryPositions as Role[],
      recurring: Number(training.recurring) || undefined,
      trainingType: training.type as Type,
    },
  });
  const { hideModal } = useModal();
  const [updateTraining, updateTrainingMutationStatus] =
    useUpdateTrainingMutation();
  const positionListQuery = useGetPositionListQuery();

  const isRecurring = getValues("isRecurring");

  const onClose = () => {
    hideModal();
    reset();
  };

  const onFormSubmit = (data: CreateTrainingType) => {
    updateTraining({
      variables: {
        id: training.id,
        name: data.trainingName,
        unitOfCompetency: data.nationalUnitOfCompetency?.toString(),
        mandatoryPositions: data.mandatoryNeed || [],
        type: data.trainingType,
        recurring: isRecurring ? data.recurring : undefined,
        description: data.description,
      },
      refetchQueries: [
        "listTask",
        "getUserInfo",
        "getProfileInfo",
        "getLeaderboardInfo",
        "getProjectInfo",
        "listTraining",
        "getTrainingMatrix",
        "listTrainingWithoutPagination",
        "getProjectTeamInfo",
        "getGapAnalysisByDate",
      ],
      onCompleted: (data) => {
        message.success(t("trainingUpdated"));
        logsServices.logInformation({
          message: `Training (id=${data.updateTraining?.id}) was updated.`,
          location: ["onFormSubmit", "onCompleted"],
        });
        hideModal();
      },
    });
  };

  watch("isRecurring");

  const trainingTypeOptions = [
    { value: Type.External, label: t("external") },
    { value: Type.InHouse, label: t("inHouse") },
    { value: Type.Rto, label: t("RTO") },
  ];

  const positionOptions = positionListQuery.data?.getPositionList?.map(
    (positionName: string) => {
      return { value: positionName, label: positionName };
    },
  );

  return (
    <Form>
      <Row align="middle" justify="start" gutter={[12, 24]}>
        <Col span={24}>
          <h2>{t("editTraining")}</h2>
        </Col>
        <Col span={9}>{t("trainingName")}:</Col>
        <Col span={14}>
          <ControlledInput
            useControllerProps={{
              name: "trainingName",
              control,
              defaultValue: training.name,
            }}
            inputProps={{ status: errors.trainingName && "error" }}
          />
        </Col>
        <Col span={9}>{t("nationalUnitOfCompetency")}:</Col>
        <Col span={14}>
          <ControlledInput
            useControllerProps={{
              name: "nationalUnitOfCompetency",
              control,
            }}
            inputProps={{
              className: styles.inputNumber,
              status: errors.nationalUnitOfCompetency && "error",
            }}
          />
        </Col>
        <Col span={9}>{t("mandatoryNeed")}:</Col>
        <Col span={14}>
          <ControlledSelect
            useControllerProps={{
              name: "mandatoryNeed",
              control,
            }}
            selectProps={{
              showSearch: true,
              className: styles.select,
              loading: positionListQuery.loading,
              options: positionOptions,
              status: errors.mandatoryNeed && "error",
              placeholder: t("mandatoryNeedPlaceholder"),
              mode: "multiple",
            }}
          />
        </Col>
        <Col span={9}>{t("trainingType")}:</Col>
        <Col span={14}>
          <ControlledSelect
            useControllerProps={{
              name: "trainingType",
              control,
            }}
            selectProps={{
              allowClear: true,
              popupClassName: styles.selectPopup,
              options: trainingTypeOptions,
              className: styles.select,
              status: errors.trainingType && "error",
              placeholder: t("trainingType"),
            }}
          />
        </Col>
        <Col span={9}>{t("recurring")}:</Col>
        <Col span={14}>
          <Row align="middle" justify="space-between">
            <Col>
              <ControlledCheckbox
                useControllerProps={{
                  name: "isRecurring",
                  control,
                  defaultValue: !!training.recurring,
                }}
              />
            </Col>
            {isRecurring ? (
              <Col>
                <ControlledInput
                  useControllerProps={{
                    name: "recurring",
                    control,
                  }}
                  inputProps={{
                    type: "number",
                    placeholder: t("enterFrequency"),
                    status: errors.recurring && "error",
                    suffix: t("month"),
                  }}
                />
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col span={24}>{t("description")}:</Col>
        <Col span={24}>
          <ControlledTextArea
            useControllerProps={{
              name: "description",
              control,
            }}
            textAreaProps={{
              maxLength: config.LONG_DESCRIPTION_LENGTH,
              status: errors.description && "error",
            }}
          />
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button
                disabled={updateTrainingMutationStatus.loading}
                onClick={onClose}
              >
                {t("close")}
              </Button>
            </Col>
            <Col>
              <Button
                loading={updateTrainingMutationStatus.loading}
                type="primary"
                onClick={handleSubmit(onFormSubmit)}
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateTrainingForm;
