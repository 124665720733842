import { Tooltip } from "@common/components";
import { GapAnalysisTableColumnType } from "@project/types";
import { Col, Row, Space } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { GapAnalysisUserInfo } from "graphqlTypes";
import i18n from "providers/i18n";

import CertificateCell from "./CertificateCell";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "project.GapAnalysisTable",
);

type PropTypes = {
  usersInfo: GapAnalysisUserInfo[];
};
export const getColumns = ({
  usersInfo,
}: PropTypes): ColumnsType<GapAnalysisTableColumnType> => {
  const usersColumns: ColumnsType<GapAnalysisTableColumnType> = usersInfo.map(
    (userInfo) => {
      return {
        dataIndex: userInfo.user?.id,
        title: userInfo.graphUser?.displayName,
        key: userInfo.user?.graphId,
        render: (_, gapTrainingInfo: GapAnalysisTableColumnType) => {
          const userGapTraining = userInfo.taskTrainingInfoList.find(
            (userGapTrainingInfo) => {
              return gapTrainingInfo.key === userGapTrainingInfo.training.id;
            },
          );

          return userGapTraining ? (
            <CertificateCell userGapTraining={userGapTraining} />
          ) : null;
        },
      };
    },
  );

  return [
    {
      dataIndex: "trainingName",
      title: t("trainingName"),
      key: "trainingName",
      render: (_: string, rowData: GapAnalysisTableColumnType) => {
        const {
          gapTrainingInfo: { training },
        } = rowData;
        const description = training.description ? (
          <Row>{`${t("description")}:  ${training.description}`}</Row>
        ) : null;

        return <Tooltip title={description}>{training.name}</Tooltip>;
      },
    },
    ...usersColumns,
  ];
};
