import { Layout } from "@common/enums";
import { ModuleType, AuthType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import Profile from "./pages/Profile";

const user: ModuleType = {
  routes: [
    {
      path: RoutePaths.Profile,
      Page: Profile,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      type: AuthType.Auth,
      layout: Layout.MainLayout,
    },
  ],
  contextProviders: [],
};

export default user;
