import { Link } from "@common/components";
import { ScreenContext } from "@common/contexts/ScreenContext";
import { Row, Col } from "antd";
import RoutePaths from "config/routePaths";
import { AccountStatus, UserInfo } from "graphqlTypes";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const classNameByUserStatus = {
  [AccountStatus.Active]: styles.activeStatus,
  [AccountStatus.Proactive]: styles.activeStatus,
  [AccountStatus.Reactive]: styles.reactiveStatus,
  [AccountStatus.Inactive]: styles.inactiveStatus,
};

type PropTypes = {
  profileData: UserInfo | null | undefined;
};

const HeaderUserData: FC<PropTypes> = ({ profileData }) => {
  const { t } = useTranslation("profile.HeaderUserData");
  const { isPhoneScreenSize } = useContext(ScreenContext);
  const translatedAccountStatus = {
    [AccountStatus.Active]: t("active"),
    [AccountStatus.Proactive]: t("proactive"),
    [AccountStatus.Reactive]: t("reactive"),
    [AccountStatus.Inactive]: t("inactive"),
  };

  return (
    <Row gutter={[0, 6]}>
      <Col className={styles.userName} span={24}>
        {profileData?.graphProfile?.displayName}
      </Col>
      <Col span={24} className={styles.userPosition}>
        {profileData?.graphProfile?.jobTitle}
      </Col>
      <Col span={24}>
        <a
          className={styles.email}
          href={`mailto:${profileData?.graphProfile?.mail}`}
        >
          {isPhoneScreenSize ? t("sendMail") : profileData?.graphProfile?.mail}
        </a>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row gutter={[12, 24]}>
              <Col>{t("businessUnit")}:</Col>
              <Col>{profileData?.graphProfile?.officeLocation}</Col>
            </Row>
          </Col>
          {profileData?.manager?.displayName && (
            <Col span={24}>
              <Row gutter={[12, 24]}>
                <Col>{t("directManager")}:</Col>
                <Col>
                  <Link to={`${RoutePaths.Users}/${profileData?.manager.id}`}>
                    {profileData?.manager.displayName}
                  </Link>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={[12, 24]}>
              <Col>{t("profileStatus")}:</Col>
              <Col>
                {profileData?.accountStatus && (
                  <span
                    className={
                      classNameByUserStatus[profileData?.accountStatus]
                    }
                  >
                    {translatedAccountStatus[profileData?.accountStatus]}
                  </span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderUserData;
