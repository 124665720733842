import { UserOutlined } from "@ant-design/icons";
import { Image } from "@common/components";
import { ScreenContext } from "@common/contexts/ScreenContext";
import fileConverterServices from "@common/services/fileConverterServices";
import photoServices from "@common/services/photoServices";
import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";

const UserProfileAvatar: FC = () => {
  const { userId } = useParams();
  const [image, setImage] = useState<string>();
  const { isPhoneScreenSize } = useContext(ScreenContext);

  useEffect(() => {
    (async () => {
      const response = await photoServices.getProfilePhoto(userId as string);
      const imageSrc = fileConverterServices.convertToBase64String(response);
      setImage(imageSrc);
    })();
  }, [userId]);

  return image ? (
    <Image
      className={isPhoneScreenSize ? styles.mobileAvatar : styles.avatar}
      preview={false}
      src={image}
      alt="avatar"
    />
  ) : (
    <div
      className={
        isPhoneScreenSize
          ? styles.defaultMobileUserImageContainer
          : styles.defaultUserImageContainer
      }
    >
      <UserOutlined className={styles.defaultUserIcon} />
    </div>
  );
};
export default UserProfileAvatar;
