import { Button, Spin, Table } from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import LogsServices from "@common/services/logsServices";
import sortConverterServices from "@common/services/sortConverterServices";
import { TaskListSorterType } from "@task/types";
import { Col, Form, message, Row, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import {
  TaskInfo,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from "graphqlTypes";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getColumns } from "./columns";

const logsServices = new LogsServices(
  ModuleNameEnum.Task,
  "components",
  "EditTasksTable",
);

type PropTypes = {
  setIsEditTable: Dispatch<SetStateAction<boolean>>;
  taskList: TaskInfo[];
  totalPageCount: number;
  setSorters: Dispatch<SetStateAction<TaskListSorterType>>;
  loading: boolean;
  onPaginationChange: (page: number) => void;
  currentPage: number;
  sorters: TaskListSorterType;
  businessUnits: string[];
  setEditedTaskList: Dispatch<SetStateAction<TaskInfo[]>>;
  editedTaskList: TaskInfo[];
};

const EditTasksTable: FC<PropTypes> = ({
  setIsEditTable,
  taskList,
  totalPageCount,
  setSorters,
  loading,
  sorters,
  onPaginationChange,
  currentPage,
  businessUnits,
  setEditedTaskList,
  editedTaskList,
}) => {
  const { t } = useTranslation("task.EditTasksTable");
  const [isError, setIsError] = useState(false);
  const [updateTask, updateTaskStatus] = useUpdateTaskMutation();
  const [deleteTask, deleteTaskMutationStatus] = useDeleteTaskMutation();
  const currentTasks = editedTaskList.filter((task) => {
    return taskList.some((initialTask) => {
      return initialTask.id === task.id;
    });
  });

  useEffect(() => {
    setEditedTaskList((tasks) => {
      const mergedTasks = taskList.reduce((mergedTasks, initialTask) => {
        const isTaskExist = mergedTasks.some((task) => {
          return task.id === initialTask.id;
        });
        if (isTaskExist) {
          return mergedTasks;
        }
        return [...mergedTasks, initialTask];
      }, tasks);
      return mergedTasks;
    });
  }, []);

  const onDeleteTask = (id: string) => {
    deleteTask({
      variables: {
        id,
      },
      onCompleted: (data) => {
        logsServices.logInformation({
          message: `Task (id=${data.deleteTask?.id}) was delete.`,
          location: ["onDeleteTask", "deleteTask", "onCompleted"],
        });
        const filteredTasksList = editedTaskList.filter((task) => {
          return task.id !== id;
        });
        setEditedTaskList(filteredTasksList);
      },
      refetchQueries: [
        "listTask",
        "getUserInfo",
        "getProfileInfo",
        "listTaskWithoutPagination",
        "listTaskInfoWithoutPagination",
        "getLeaderboardInfo",
        "getProjectInfo",
        "getProjectTeamInfo",
        "getGapAnalysisByDate",
      ],
    });
  };

  const columns = getColumns({
    setTaskList: setEditedTaskList,
    setIsError,
    sorters,
    onDeleteTask,
    businessUnits,
  });

  const onSaveClick = () => {
    const updateTasks = editedTaskList?.map((task) => {
      const trainingIds = task.trainings.map((training) => {
        return training.id;
      });

      return {
        id: task.id,
        businessUnit: task.businessUnit || [],
        name: task.name,
        trainingIds,
      };
    });

    updateTask({
      variables: { updateTasks: updateTasks || [] },
      onCompleted: (data) => {
        const taskIds = data.updateTask?.data?.map((task) => {
          return task.id;
        });
        setIsEditTable(false);
        setEditedTaskList([]);
        logsServices.logInformation({
          message: `Tasks (ids=${taskIds?.join(", ")}) was updated.`,
          location: ["onSaveClick", "updateTask", "onCompleted"],
        });
        message.success(t("changesSaved"));
      },
      refetchQueries: [
        "listTask",
        "getUserInfo",
        "getProfileInfo",
        "listTaskWithoutPagination",
        "listTaskInfoWithoutPagination",
        "getLeaderboardInfo",
        "getProjectInfo",
        "getProjectTeamInfo",
        "getGapAnalysisByDate",
      ],
    });
  };

  const onChange: TableProps<TaskInfo>["onChange"] = (
    _pagination,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<TaskInfo>;

    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};
    setSorters({
      ...sorterFields,
      businessUnitFilter: (filters.businessUnits || []) as string[],
    });
  };

  return (
    <Form>
      <Spin spinning={deleteTaskMutationStatus.loading}>
        <Row gutter={[12, 24]}>
          <Col span={24}>
            <Button
              disabled={isError}
              type="primary"
              loading={updateTaskStatus.loading}
              onClick={onSaveClick}
            >
              {t("save")}
            </Button>
          </Col>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              onChange={onChange}
              dataSource={currentTasks}
              pagination={{
                onChange: onPaginationChange,
                total: totalPageCount,
                current: currentPage,
                showSizeChanger: false,
              }}
            />
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default EditTasksTable;
