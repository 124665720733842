import {
  ControlledTextArea,
  Button,
  Spin,
  ControlledSelect,
} from "@common/components";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, message, Row } from "antd";
import config from "config";
import {
  useCreateTrainingRequestMutation,
  useGetUserInfoQuery,
  useListTrainingWithoutPaginationQuery,
} from "graphqlTypes";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequestTrainingFormType, schema } from "./schema";
import styles from "./styles.module.scss";

type PropTypes = {
  userId: string;
};

const RequestTrainingForm: FC<PropTypes> = ({ userId }) => {
  const { hideModal } = useModal();
  const { t } = useTranslation("training.RequestTrainingForm");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<RequestTrainingFormType>({ resolver: yupResolver(schema) });
  const userInfoQuery = useGetUserInfoQuery({
    variables: {
      id: userId,
    },
  });
  const trainingListQuery = useListTrainingWithoutPaginationQuery();

  watch("trainingValue");

  const trainingValue = getValues("trainingValue");
  const customTrainingOption = trainingValue?.length
    ? [{ value: trainingValue, label: trainingValue }]
    : [];

  const userData = userInfoQuery.data?.getUserInfo;
  const trainingSelectOptions =
    trainingListQuery.data?.listTrainingWithoutPagination?.map((training) => {
      return {
        value: training.id,
        label: training.name,
      };
    });

  const [requestTrainingMutation, requestTrainingMutationStatus] =
    useCreateTrainingRequestMutation();

  const onSubmit = (data: RequestTrainingFormType) => {
    requestTrainingMutation({
      variables: {
        trainingId: Number(data.trainingValue),
        trainingName: data.trainingValue,
        userId,
        comment: data.description,
      },
      onCompleted: () => {
        message.success(t("trainingRequested"));
        reset();
        hideModal();
      },
      refetchQueries: [
        "listTrainingWithRequest",
        "getProfileInfo",
        "getUserInfo",
        "getTrainingMatrix",
      ],
    });
  };

  const isLoading = loadingService.checkLoading([
    userInfoQuery.loading,
    trainingListQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Form>
        <Row gutter={[0, 24]}>
          <Col span={24} className={styles.title}>
            {t("requestTraining")}
          </Col>
          <Col span={8}>{t("userName")}:</Col>
          <Col span={16}>{userData?.graphProfile?.displayName}</Col>
          <Col span={8}>{t("trainingName")}:</Col>
          <Col span={16}>
            <ControlledSelect
              useControllerProps={{ control, name: "trainingValue" }}
              selectProps={{
                "data-testid": "select",
                value: trainingValue,
                showSearch: true,
                popupClassName: styles.selectPopup,
                className: styles.selectTrainingName,
                status: errors.trainingValue && "error",
                options: [
                  ...customTrainingOption,
                  ...(trainingSelectOptions || []),
                ],
                onSearch: (value) => {
                  setValue("trainingValue", value);
                },
                filterOption: (input, option) => {
                  return (option?.label?.toString() || "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                },
              }}
            />
          </Col>
          <Col span={8}>{t("position")}:</Col>
          <Col span={16}>{userData?.graphProfile?.jobTitle}</Col>
          <Col span={24}>
            <ControlledTextArea
              useControllerProps={{ control, name: "description" }}
              textAreaProps={{
                "data-testid": "description",
                placeholder: t("descriptionPlaceholder"),
                status: errors.description && "error",
                maxLength: config.LONG_DESCRIPTION_LENGTH,
              }}
            />
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Button
                  data-testid="send"
                  type="primary"
                  loading={requestTrainingMutationStatus.loading}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("send")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default RequestTrainingForm;
