import { ReactQuill } from "@common/components";
import { ElementMetadataType } from "@common/types";
import { useController, UseControllerProps } from "react-hook-form";
import { ReactQuillProps } from "react-quill";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "video"],
    ["clean"],
  ],
};

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  reactQuillProps?: ReactQuillProps & ElementMetadataType;
};

const ControlledReactQuill = <T extends object>({
  reactQuillProps,
  useControllerProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, onBlur, value },
  } = useController(useControllerProps);

  return (
    <ReactQuill
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      theme="snow"
      modules={modules}
      {...reactQuillProps}
    />
  );
};

export default ControlledReactQuill;
