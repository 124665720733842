import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useModal } from "@common/hooks/useModal";
import { useSearchParams } from "@common/hooks/useSearchParams";
import CreateTrainingForm from "@training/components/CreateTrainingForm";
import TrainingsTable from "@training/components/TrainingsTable";
import { Row, Col } from "antd";
import config from "config";
import { useGetUserQuery } from "graphqlTypes";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

const ManegeTrainings: FC = () => {
  const { t } = useTranslation("training.ManegeTrainings");
  const { setModalProps, hideModal } = useModal();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearchText = useDebounce(searchText);
  const userQuery = useGetUserQuery();
  const [exportExcel, setExportExcel] = useState<{
    exportUrl: string;
    disabled: boolean;
  }>({ exportUrl: "", disabled: true });
  const onCreateNewTrainingButtonClick = () => {
    setModalProps({
      footer: false,
      closable: false,
      onCancel: hideModal,
      children: <CreateTrainingForm />,
      open: true,
    });
  };

  const onSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });
  };

  const isExcelExport =
    userQuery.data?.getUser?.role &&
    config.ROLES_WITH_EXCEL_EXPORT.includes(userQuery.data.getUser.role);

  return (
    <Row gutter={[0, 24]} justify="center">
      <Col span={23}>
        <Input
          data-testid="search-training"
          value={searchText || ""}
          onChange={onSearchText}
          placeholder={t("searchPlaceholder")}
        />
      </Col>
      <Col span={23}>
        <Row gutter={[24, 0]} align="middle">
          <Col>
            <Button
              data-testid="create-training-button"
              icon={<PlusOutlined />}
              onClick={onCreateNewTrainingButtonClick}
              type="primary"
            >
              {t("createNewTrainingButton")}
            </Button>
          </Col>
          {isExcelExport && (
            <Col>
              <Button
                disabled={exportExcel.disabled}
                href={exportExcel.exportUrl}
                rel="noreferrer"
                target="_blank"
                icon={<FileExcelOutlined />}
                type="dashed"
              >
                {t("exportExcel")}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={23}>
        <TrainingsTable
          searchText={debouncedSearchText || ""}
          setExportExcel={setExportExcel}
        />
      </Col>
    </Row>
  );
};

export default ManegeTrainings;
