import { Table } from "@common/components";
import sortConverterServices from "@common/services/sortConverterServices";
import { TaskListSorterType } from "@task/types";
import { TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { TaskInfo } from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";

import { getColumns } from "./columns";

type PropTypes = {
  taskList: TaskInfo[];
  totalPageCount: number;
  setSorters: Dispatch<SetStateAction<TaskListSorterType>>;
  loading: boolean;
  onPaginationChange: (page: number) => void;
  currentPage: number;
  sorters: TaskListSorterType;
  businessUnits: string[];
};

const TasksTable: FC<PropTypes> = ({
  totalPageCount,
  setSorters,
  taskList,
  loading,
  onPaginationChange,
  currentPage,
  sorters,
  businessUnits,
}) => {
  const columns = getColumns({ sorters, businessUnits });

  const onChange: TableProps<TaskInfo>["onChange"] = (
    _pagination,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<TaskInfo>;

    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setSorters({
      ...sorterFields,
      businessUnitFilter: (filters.businessUnits || []) as string[],
    });
  };

  return (
    <Table
      onChange={onChange}
      columns={columns}
      dataSource={taskList}
      loading={loading}
      expandable={{
        expandedRowRender: (task) => {
          return task.businessUnit?.join(", ");
        },
        rowExpandable: (task) => {
          return !!task.businessUnit?.length;
        },
      }}
      pagination={{
        onChange: onPaginationChange,
        total: totalPageCount,
        current: currentPage,
        showSizeChanger: false,
      }}
    />
  );
};

export default TasksTable;
