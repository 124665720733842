import { UploadedFileType } from "@common/types";
import { UploadFile } from "antd/lib/upload/interface";
import config from "config";

class FileServices {
  checkFileSizeValid = (file: UploadFile, fileSize = config.FILE_SIZE) => {
    if (file.size) {
      const isSizeValid = file.size / 1024 ** 2 <= fileSize;
      return isSizeValid;
    }

    return false;
  };

  checkFileTypeValid = (file: UploadFile, validFileTypes: string[]) => {
    if (file.type) {
      const fileExtension = file.type.split("/")[1];
      const isFileTypeValid = validFileTypes.some(
        (type) => fileExtension === type,
      );
      return isFileTypeValid;
    }
    return false;
  };

  validateAttachmentFile = (
    uploadedFile: UploadedFileType,
    allowedFileTypes: string[],
  ): boolean => {
    const isError = uploadedFile.fileList?.some((file) => {
      const isFileSizeValid = this.checkFileSizeValid(file);

      if (!isFileSizeValid) {
        return true;
      }

      const isFileTypeValid = this.checkFileTypeValid(file, allowedFileTypes);

      return !isFileTypeValid;
    });
    return !!isError;
  };

  validateFileBeforeUpload = (
    file: UploadFile,
    allowedFileTypes: string[],
    {
      sizeErrorCallback,
      typeErrorCallback,
    }: {
      sizeErrorCallback: () => void;
      typeErrorCallback: () => void;
    },
  ): boolean => {
    const isFileSizeValid = this.checkFileSizeValid(file);

    if (!isFileSizeValid) {
      sizeErrorCallback();
      return true;
    }

    const isFiletypeValid = this.checkFileTypeValid(file, allowedFileTypes);

    if (!isFiletypeValid) {
      typeErrorCallback();
      return true;
    }

    return false;
  };
}

const fileServices = new FileServices();

export default fileServices;
