import { FilterFilled } from "@ant-design/icons";
import StatusCell from "@common/components/StatusCell";
import { AssignedUsersTrainingInfoFilterType } from "@training/types";
import { ColumnsType } from "antd/lib/table";
import config from "config";
import { format } from "date-fns";
import { ExportExpireFilter, Status, UserExportCsvInfo } from "graphqlTypes";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

import ExpiryColumnFilter from "./ExpiryColumnFilter";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.AssignedUsersTrainingInfoTable",
);

type PropTypes = {
  businessUnits: string[];
  positions: string[];
  setFilterParams: Dispatch<
    SetStateAction<AssignedUsersTrainingInfoFilterType | undefined>
  >;
  filterParams: AssignedUsersTrainingInfoFilterType;
};

export const getColumns = ({
  businessUnits,
  positions,
  setFilterParams,
  filterParams,
}: PropTypes): ColumnsType<UserExportCsvInfo> => {
  const businessUnitsFilterOptions = businessUnits.map((businessUnit) => {
    return { text: businessUnit, value: businessUnit };
  });

  const positionsFilterOptions = positions.map((position) => {
    return { text: position, value: position };
  });

  const statusFilterOptions = [
    { value: Status.Done, text: t("done") },
    { value: Status.Expired, text: t("expired") },
    { value: Status.Required, text: t("required") },
  ];

  return [
    {
      key: "fullName",
      dataIndex: "fullName",
      title: (
        <span data-testid="assigned-users-on-training-full-name-column">
          {t("fullName")}
        </span>
      ),
      sorter: true,
      render: (_, userData) => {
        return (
          <span data-cy="assigned-users-on-training-full-name">
            {userData.fullName}
          </span>
        );
      },
    },
    {
      key: "position",
      dataIndex: "position",
      title: t("position"),
      filters: positionsFilterOptions,
      filteredValue: filterParams.positionFilter,
      filterIcon: () => {
        return (
          <FilterFilled data-testid="assigned-users-on-training-position-filter" />
        );
      },
      render: (_, userData) => {
        return (
          <span data-cy="assigned-users-on-training-position">
            {userData.position}
          </span>
        );
      },
    },
    {
      key: "businessUnit",
      dataIndex: "businessUnit",
      title: t("businessUnit"),
      filteredValue: filterParams.businessUnitFilter,
      filters: businessUnitsFilterOptions,
      filterIcon: () => {
        return (
          <FilterFilled data-testid="assigned-users-on-training-business-unit-filter" />
        );
      },
      render: (_, userData) => {
        return (
          <span data-cy="assigned-users-on-training-business-unit">
            {userData.businessUnit}
          </span>
        );
      },
    },
    {
      key: "competed",
      dataIndex: "competed",
      title: t("competed"),
      sorter: true,
      render: (_, userData) => {
        return (
          userData.competed &&
          format(new Date(userData.competed), config.DATE_WITH_DOTS)
        );
      },
    },
    {
      key: "expiry",
      dataIndex: "expiry",
      title: t("expiry"),
      sorter: true,
      filteredValue: [],
      filtered:
        filterParams.expiryFilter &&
        filterParams.expiryFilter?.[1] !== ExportExpireFilter.Days0,
      filterDropdown: () => {
        return <ExpiryColumnFilter setFilterParams={setFilterParams} />;
      },
      render: (_, userData) => {
        return (
          userData.expiry &&
          format(new Date(userData.expiry), config.DATE_WITH_DOTS)
        );
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: t("status"),
      filteredValue: filterParams.statusFilter,
      filters: statusFilterOptions,
      filterIcon: () => {
        return (
          <FilterFilled data-testid="assigned-users-on-training-status-filter" />
        );
      },
      render: (_, userData) => {
        return (
          <span data-cy="assigned-users-on-training-status">
            <StatusCell status={userData.status} />
          </span>
        );
      },
    },
  ];
};
