import { Button, Spin } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import AssignedUsersTrainingInfo from "@training/components/AssignedUsersTrainingInfo";
import { Training, useCountAssignedUsersOnTrainingQuery } from "graphqlTypes";
import { FC } from "react";

import styles from "./styles.module.scss";

type PropTypes = {
  training: Training;
};

const CurrentTrainingUsersCount: FC<PropTypes> = ({ training }) => {
  const { setModalProps, hideModal } = useModal();

  const openModalWithUsersCertificates = () => {
    setModalProps({
      open: true,
      footer: false,
      onCancel: hideModal,
      className: styles.modalWindow,
      children: (
        <AssignedUsersTrainingInfo key={training.id} training={training} />
      ),
    });
  };

  const usersCountQuery = useCountAssignedUsersOnTrainingQuery({
    variables: {
      id: training.id,
    },
  });

  const usersCount = usersCountQuery.data?.countAssignedUsersOnTraining;

  return (
    <Spin spinning={usersCountQuery.loading}>
      <Button
        data-cy="assigned-users-count-button"
        onClick={openModalWithUsersCertificates}
        type="link"
      >
        {usersCount}
      </Button>
    </Spin>
  );
};

export default CurrentTrainingUsersCount;
