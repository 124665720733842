import {
  UserOutlined,
  TeamOutlined,
  ProfileOutlined,
  ReadOutlined,
  AreaChartOutlined,
  QuestionOutlined,
  ScheduleOutlined,
  TableOutlined,
} from "@ant-design/icons";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";
import i18n from "providers/i18n";

const t: (key: string) => string = i18n.getFixedT(null, "common.SideBarMenu");

class SidebarServices {
  private generateMenuItems = () => ({
    profile: {
      icon: <UserOutlined />,
      title: t("profile"),
      path: RoutePaths.Profile,
    },
    users: {
      icon: <TeamOutlined data-testid="users" />,
      title: t("users"),
      path: RoutePaths.Users,
    },
    projects: {
      icon: <ProfileOutlined data-testid="projects" />,
      title: t("projects"),
      path: RoutePaths.Projects,
    },
    trainings: {
      icon: <ReadOutlined data-testid="trainings" />,
      title: t("trainings"),
      path: RoutePaths.Training,
    },
    myTrainingMatrix: {
      icon: <TableOutlined />,
      title: t("myTrainingMatrix"),
      path: RoutePaths.MyTrainingMatrix,
    },
    tasks: {
      icon: <ScheduleOutlined data-testid="tasks" />,
      title: t("tasks"),
      path: RoutePaths.Tasks,
    },
    dashboard: {
      icon: <AreaChartOutlined />,
      title: t("dashboard"),
      path: RoutePaths.Dashboard,
    },
    help: {
      icon: <QuestionOutlined />,
      title: t("help"),
      path: RoutePaths.Help,
    },
  });

  private getMenuByRoles = () => {
    const menuItems = this.generateMenuItems();
    const commonMenu = [
      menuItems.profile,
      menuItems.projects,
      menuItems.myTrainingMatrix,
      menuItems.dashboard,
      menuItems.help,
    ];

    return {
      [Role.SuperAdmin]: {
        commonMenu,
        specialMenu: [menuItems.users, menuItems.trainings, menuItems.tasks],
      },
      [Role.Admin]: {
        commonMenu,
        specialMenu: [menuItems.users, menuItems.trainings, menuItems.tasks],
      },
      [Role.Manager]: {
        commonMenu,
        specialMenu: [menuItems.users],
      },
      [Role.User]: {
        commonMenu,
        specialMenu: [],
      },
    };
  };

  getMenuByRole = (role: Role) => {
    const menuByRoles = this.getMenuByRoles();
    return menuByRoles[role];
  };
}

const sidebarServices = new SidebarServices();

export default sidebarServices;
