import { UserOutlined } from "@ant-design/icons";
import { Pie, PieConfig } from "@ant-design/plots";
import { Popover } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import chartServices from "@project/services/chartServices";
import { ChartDataType } from "@project/types";
import { Row, Col, PopoverProps } from "antd";
import {
  AccountStatus,
  GapAnalysisUserInfo,
  ProjectStatus,
  Role,
  useGetUserQuery,
} from "graphqlTypes";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { UserCertificatesModal } from "../UserCertificatesModal";

const rolesWithUserCertificatesAccess = [
  Role.Admin,
  Role.SuperAdmin,
  Role.Manager,
];

type PropTypes = {
  gapAnalysisUserInfoList: GapAnalysisUserInfo[];
};

const GapAnalysisPieChart: FC<PropTypes> = ({ gapAnalysisUserInfoList }) => {
  const { t } = useTranslation("project.GapAnalysisPieChart");
  const [popoverProps, setPopoverProps] = useState<PopoverProps>();
  const { setModalProps, hideModal } = useModal();
  const userQuery = useGetUserQuery();

  const userRole = userQuery.data?.getUser?.role;
  const gapAnalysisUserInfoByStatus = chartServices.separateUsersByStatus(
    gapAnalysisUserInfoList,
  );

  const data: ChartDataType[] = [
    {
      title: t("reactive"),
      value: gapAnalysisUserInfoByStatus.reactiveUsersData.length,
      status: ProjectStatus.Reactive,
      data: gapAnalysisUserInfoByStatus.reactiveUsersData,
    },
    {
      title: t("active"),
      value: gapAnalysisUserInfoByStatus.activeUsersData.length,
      status: ProjectStatus.Active,
      data: gapAnalysisUserInfoByStatus.activeUsersData,
    },
  ].filter((el) => el.data.length > 0);

  const isLinkAvailable = rolesWithUserCertificatesAccess.some((role) => {
    return role === userRole;
  });

  const hidePopover = () => {
    setPopoverProps({
      open: false,
    });
  };

  const config: PieConfig = {
    data,
    angleField: "value",
    colorField: "status",
    tooltip: false,
    legend: {
      color: {
        itemMarker: "circle",
        itemMarkerFill: (datum: any) => () => {
          // TODO trouble if color is variable
          return datum.label === ProjectStatus.Active ? "#9CE5C0" : "#FEAEAE";
        },
        layout: {
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
        itemLabelFontSize: 16,
      },
    },
    label: {
      autoRotate: false,
      text: "value",
      opacity: 1,
      style: {
        fontSize: 14,
        fontWeight: "bold",
      },
      render: (title: string) => {
        return (
          <div className={styles.label}>
            <UserOutlined />
            {title}
          </div>
        );
      },
    },
    animate: false,
    height: 600,
    style: {
      fill: (item: any) => {
        // TODO trouble if color is variable

        return item.status === ProjectStatus.Active ? "#9CE5C0" : "#FEAEAE";
      },
    },
    onReady: (plot) => {
      plot.chart.on("interval:pointerover", (evt: any) => {
        const { status, data, title } = evt.data.data;

        const preparedData = data
          .filter(
            (user: GapAnalysisUserInfo) =>
              user.profileStatus === AccountStatus.Active ||
              user.profileStatus === AccountStatus.Reactive,
          )
          .map((user: GapAnalysisUserInfo) => {
            const names: string[] = [];
            const taskTrainingInfoList = user?.taskTrainingInfoList?.filter(
              (task) => {
                if (names.includes(task.training.name)) {
                  return false;
                }

                names.push(task.training.name);

                return true;
              },
            );

            return { ...user, taskTrainingInfoList };
          });

        setPopoverProps({
          open: true,
          placement: status === ProjectStatus.Active ? "right" : "left",
          content: (
            <Row onMouseLeave={hidePopover} className={styles.popover}>
              <Col span={24}>{title}</Col>
              {preparedData.map((userData: GapAnalysisUserInfo) => {
                const certificatesCount =
                  chartServices.getCertificatesCountByStatus({
                    userData,
                    fragmentStatus: status,
                  });

                return (
                  <Col key={userData.user?.id} span={24}>
                    <Row gutter={[12, 0]}>
                      <Col>
                        {isLinkAvailable ? (
                          <div
                            className={styles.a}
                            onClick={() => {
                              setModalProps({
                                footer: false,
                                onCancel: hideModal,
                                open: true,
                                children: (
                                  <UserCertificatesModal
                                    gapAnalysisUserInfo={userData}
                                  />
                                ),
                              });
                            }}
                          >
                            {userData.graphUser?.displayName}
                          </div>
                        ) : (
                          userData.graphUser?.displayName
                        )}
                      </Col>
                      <Col>-</Col>
                      <Col>{certificatesCount}</Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          ),
        });
      });
    },
  };

  return (
    <Row justify="center" onMouseLeave={hidePopover}>
      <Col>
        <Popover
          placement={popoverProps?.placement}
          open={popoverProps?.open}
          content={popoverProps?.content}
        >
          <Pie {...config} />
        </Popover>
      </Col>
    </Row>
  );
};

export default GapAnalysisPieChart;
