import tokenServices from "@auth/services/tokenServices";
import config from "config";
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket";

class WebSocketClientServices {
  constructor(url: string) {
    this.url = url;
    this.runWebSocketConnection({
      time: config.WEB_SOCKET_RECONNECT_MILLISECONDS,
    });
  }

  private url = "";

  private webSocketClient = {};

  private onMessageCallbacks: ((message: IMessageEvent) => void)[] = [];

  private setWebSocketClient = (webSocketClient: W3CWebSocket) => {
    this.webSocketClient = webSocketClient;
  };

  private setOnMessageCallbacks = (
    onMessageCallbacks: ((message: IMessageEvent) => void)[],
  ) => {
    this.onMessageCallbacks = onMessageCallbacks;
  };

  private runWebSocketConnection = async ({
    time,
    webSocketClient,
  }: {
    webSocketClient?: W3CWebSocket;
    time: number;
  }) => {
    webSocketClient?.close();

    const accessToken = await tokenServices.getToken();

    const newWebSocketClient = new W3CWebSocket(
      this.url,
      accessToken || undefined,
    );

    newWebSocketClient.onmessage = (message) => {
      this.onMessageCallbacks.forEach((callback) => {
        callback(message);
      });
    };

    newWebSocketClient.onopen = () => {
      this.setWebSocketClient(newWebSocketClient);

      setTimeout(() => {
        this.runWebSocketConnection({
          webSocketClient: newWebSocketClient,
          time,
        });
      }, time);
    };
  };

  subscribeOnNotifications = (
    onMessageCallback: (message: IMessageEvent) => void,
  ) => {
    this.setOnMessageCallbacks([...this.onMessageCallbacks, onMessageCallback]);
  };

  unsubscribeOnNotifications = (
    onMessageCallback: (message: IMessageEvent) => void,
  ) => {
    const filteredCallbacks = this.onMessageCallbacks.filter((callback) => {
      return callback !== onMessageCallback;
    });

    this.setOnMessageCallbacks(filteredCallbacks);
  };
}

export default WebSocketClientServices;
