import { DatePickerProps } from "@common/types";
import generatePicker from "antd/es/date-picker/generatePicker";
import config from "config";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import "antd/es/date-picker/style";
import { FC } from "react";

const DatePickerWithDateFns = generatePicker<Date>(dateFnsGenerateConfig);

const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
  const { format = config.DATE_WITH_DOTS, ...restProps } = props;

  return <DatePickerWithDateFns format={format} {...restProps} />;
};

export default DatePicker;
