import { Table } from "@common/components";
import config from "config";
import { GapAnalysisUserInfo } from "graphqlTypes";
import { FC } from "react";

import { getColumns } from "./columns";
import styles from "./styles.module.scss";

interface IProps {
  gapAnalysisUserInfo: GapAnalysisUserInfo;
}

export const UserCertificatesModal: FC<IProps> = ({ gapAnalysisUserInfo }) => {
  const userData = gapAnalysisUserInfo;

  const trainings = userData?.taskTrainingInfoList?.map((tableData) => {
    return { ...tableData, key: tableData.training.name };
  });

  const columns = getColumns();

  return (
    <>
      {userData?.graphUser?.displayName && (
        <span className={styles.userName}>
          {userData?.graphUser?.displayName}
        </span>
      )}
      <Table
        columns={columns}
        dataSource={trainings}
        pagination={{
          pageSize: config.TABLE_LIMIT,
          total: trainings?.length,
          showSizeChanger: false,
        }}
      />
    </>
  );
};
