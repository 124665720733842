import { Spin, Table } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import config from "config";
import { useGetUserInfoQuery } from "graphqlTypes";
import { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { getColumns } from "./columns";
import styles from "./styles.module.scss";

const UserCertificatesTable: FC = () => {
  const { userId } = useParams();
  const userInfoQuery = useGetUserInfoQuery({
    variables: { id: Number(userId) || userId },
  });
  const { setModalProps, hideModal } = useModal();
  const [searchParams] = useSearchParams();

  const needFilter = searchParams.get(SearchParamsEnum.Need);

  const userData = userInfoQuery.data?.getUserInfo;

  const trainings = userData?.trainings?.map((tableData) => {
    return { ...tableData, key: tableData.name };
  });
  const columns = getColumns({
    setModalProps,
    hideModal,
    userData: userData || undefined,
    needFilters: needFilter ? [needFilter] : [],
  });

  return (
    <Spin spinning={userInfoQuery.loading}>
      {userData?.graphProfile?.displayName && (
        <span className={styles.userName}>
          {userData.graphProfile.displayName}
        </span>
      )}
      <Table
        dataSource={trainings}
        columns={columns}
        pagination={{
          pageSize: config.TABLE_LIMIT,
          total: trainings?.length,
          showSizeChanger: false,
        }}
      />
    </Spin>
  );
};

export default UserCertificatesTable;
