import config from "config";
import * as yup from "yup";

export type RequestExistedCertificateFormType = {
  description: string;
};

export const schema: yup.SchemaOf<RequestExistedCertificateFormType> =
  yup.object({
    description: yup.string().max(config.SHORT_DESCRIPTION_LENGTH).required(),
  });
