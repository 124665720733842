import { BackButton, Button, Spin, Tabs } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import EmployeesTable from "@project/components/EmployeesTable";
import GapAnalysis from "@project/components/GapAnalysis";
import RequestTrainingByProjectForm from "@training/components/RequestTrainingByProjectForm";
import { Col, Row } from "antd";
import { useGetProjectInfoQuery } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";

enum UserProjectTabs {
  ProjectTeam = "ProjectTeam",
  ProjectTeamAnalysis = "Analytics",
}

const ManageProjectUser: FC = () => {
  const { t } = useTranslation("project.ManageProjectUser");
  const { projectId } = useParams();
  const projectInfoQuery = useGetProjectInfoQuery({
    variables: {
      id: projectId as string,
    },
  });

  const projectName = projectInfoQuery.data?.getProjectInfo?.name;

  const tabsItems = [
    {
      label: t("projectTeam"),
      key: UserProjectTabs.ProjectTeam,
      children: <EmployeesTable />,
    },
    {
      label: t("projectTeamAnalysis"),
      key: UserProjectTabs.ProjectTeamAnalysis,
      children: <GapAnalysis />,
    },
  ];

  const { setModalProps, hideModal } = useModal();

  const onRequestTrainingClick = () => {
    setModalProps({
      onCancel: hideModal,
      children: (
        <RequestTrainingByProjectForm
          projectId={projectId as string}
          projectName={projectName || ""}
        />
      ),
      footer: false,
      open: true,
    });
  };

  return (
    <Spin spinning={projectInfoQuery.loading}>
      <Row gutter={[0, 24]} justify="center">
        <Col span={23}>
          <BackButton />
        </Col>
        <Col span={23}>
          <Row align="middle" gutter={[24, 0]}>
            <Col className={styles.projectName}>{projectName}</Col>
            <Col>
              <Button type="primary" onClick={onRequestTrainingClick}>
                {t("requestTraining")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          <Tabs
            className={styles.tabName}
            defaultActiveKey={UserProjectTabs.ProjectTeam}
            items={tabsItems}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default ManageProjectUser;
