import Input from "@common/components/Input";
import { ElementMetadataType } from "@common/types";
import { InputProps } from "antd";
import { useController, UseControllerProps } from "react-hook-form";

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  inputProps?: InputProps & ElementMetadataType;
};

const ControlledInput = <T extends object>({
  useControllerProps,
  inputProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, onBlur, name, value },
  } = useController(useControllerProps);

  return (
    <Input
      {...inputProps}
      onChange={(text) => {
        onChange(text);
        inputProps?.onChange && inputProps?.onChange(text);
      }}
      onBlur={onBlur}
      value={value}
      name={name}
    />
  );
};

export default ControlledInput;
