import { FilterFilled } from "@ant-design/icons";
import sortConverterServices from "@common/services/sortConverterServices";
import { TaskListSorterType } from "@task/types";
import { Table } from "antd";
import { ColumnFilterItem, ColumnsType } from "antd/lib/table/interface";
import { TaskInfo } from "graphqlTypes";
import i18n from "providers/i18n";

const t: (key: string) => string = i18n.getFixedT(null, "task.TasksTable");

type PropTypes = {
  sorters: TaskListSorterType;
  businessUnits: string[];
};

export const getColumns = ({
  sorters,
  businessUnits,
}: PropTypes): ColumnsType<TaskInfo> => {
  const businessUnitOptions: ColumnFilterItem[] = businessUnits.map(
    (businessUnit) => {
      return { value: businessUnit, text: businessUnit };
    },
  );

  return [
    {
      dataIndex: "tasksName",
      title: <span data-testid="tasks-name-column">{t("tasksName")}</span>,
      key: "tasksName",
      sorter: true,
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        sorters.tasksName,
      ),
      render: (_, taskInfo) => {
        return <span data-cy="tasks-name">{taskInfo.name}</span>;
      },
    },
    {
      dataIndex: "trainingsRequired",
      title: t("trainingsRequired"),
      key: "trainingsRequired",
      ellipsis: true,
      render: (_, taskInfo) => {
        const trainingNames = taskInfo.trainings.map((training) => {
          return training.name;
        });
        return trainingNames.join(", ");
      },
    },
    Table.EXPAND_COLUMN,
    {
      dataIndex: "businessUnits",
      title: t("businessUnits"),
      key: "businessUnits",
      filters: businessUnitOptions,
      filteredValue: sorters.businessUnitFilter,
      ellipsis: true,
      filterIcon: () => {
        return <FilterFilled data-testid="tasks-business-unit-filter" />;
      },
      render: (_, taskInfo) => {
        return (
          <span data-cy="tasks-business-unit">
            {taskInfo.businessUnit?.join(", ")}
          </span>
        );
      },
    },
  ];
};
