import { Layout } from "@common/enums";
import { CheckboxProps, ModalProps } from "antd";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
  RangePickerProps as RangePickerPropsANTD,
} from "antd/lib/date-picker/generatePicker";
import { UploadFile } from "antd/lib/upload/interface";
import config from "config";
import RoutePaths from "config/routePaths";
import enAULocale from "date-fns/locale/en-AU";
import enUSLocale from "date-fns/locale/en-US";
import { Role, User } from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export type LowLevelLocales = {
  [key: string]: { [key: string]: string };
};

export type Locale = {
  [key: string]: LowLevelLocales;
};

export type PropsWithChild = { children: JSX.Element };

export enum AuthType {
  Auth = "Auth",
  NoAuth = "NoAuth",
  Common = "Common",
}

export type RouteProps = {
  pageProps?: object;
  Page: FC;
  roles: Role[];
  user?: User | null;
};

export type Route = {
  layout: Layout;
  path: string;
  Page: FC;
  type: AuthType;
  roles: Role[];
  props?: object;
};

export type ModuleType = {
  routes: Route[];
  contextProviders: FC<{
    children: JSX.Element | JSX.Element[];
  }>[];
};

export type Icon = JSX.Element;

export type UserContextValue = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
};

export type ModalContextValueType = {
  setModalProps: React.Dispatch<React.SetStateAction<ModalProps>>;
};

export type DatePickerProps =
  | PickerBaseProps<Date>
  | PickerDateProps<Date>
  | PickerTimeProps<Date>;

export type RangePickerProps = RangePickerPropsANTD<Date>;

export const DateFnsLocales = {
  EnUS: enUSLocale,
  EnAU: enAULocale,
};

export type FilterOptionType = {
  title: string;
  id: string;
  checkboxProps?: CheckboxProps | undefined;
};

export type MenuItemType = {
  icon: JSX.Element;
  title: string;
  path: RoutePaths;
};

export type HistoryContextValueType = {
  routes: string[];
  setRoutes: Dispatch<SetStateAction<string[]>>;
};

export type UploadedFileType = { file?: UploadFile; fileList?: UploadFile[] };

export type WebSocketContextValueType = { wobSocketClient: W3CWebSocket };

export type ImgResolutionType =
  | typeof config.IMG_RESOLUTION_HIGHT
  | typeof config.IMG_RESOLUTION_MIDDLE
  | typeof config.IMG_RESOLUTION_LOW;

export type LogPropsType = {
  message: string;
  location?: string[];
};

export type ScreenContextValueType = { isPhoneScreenSize: boolean };

export type ValidateEnvType = {
  [key: string]: (environment: string | undefined) => string | undefined;
};

export type ElementMetadataType = {
  "data-testid"?: string;
};
