import { Table } from "@common/components";
import { GapAnalysisTableColumnType } from "@project/types";
import { GapAnalysisUserInfo, GapTrainingInfo, Status } from "graphqlTypes";
import { FC } from "react";

import { getColumns } from "./columns";

type PropTypes = { gapAnalysisUserInfoList: GapAnalysisUserInfo[] };

const GapAnalysisTable: FC<PropTypes> = ({ gapAnalysisUserInfoList }) => {
  const columns = getColumns({
    usersInfo: gapAnalysisUserInfoList,
  });

  const gapTrainingInfoList: GapTrainingInfo[] = gapAnalysisUserInfoList.reduce(
    (gapTrainingInfoList: GapTrainingInfo[], gapTrainingInfo) => {
      return [...gapTrainingInfoList, ...gapTrainingInfo.taskTrainingInfoList];
    },
    [],
  );

  const trainingTableDataList: GapAnalysisTableColumnType[] =
    gapTrainingInfoList.reduce(
      (
        trainingTableDataList: GapAnalysisTableColumnType[],
        gapTrainingInfo,
      ) => {
        const isExisted = trainingTableDataList.some((trainingTableData) => {
          return trainingTableData.key === gapTrainingInfo.training.id;
        });
        if (!isExisted) {
          const trainingTableData: GapAnalysisTableColumnType = {
            key: gapTrainingInfo.training.id,
            gapTrainingInfo,
          };
          return [...trainingTableDataList, trainingTableData];
        }
        return trainingTableDataList;
      },
      [],
    );

  const filteredTrainingTableDataList = trainingTableDataList.sort(
    (trainingTableData) => {
      return trainingTableData.gapTrainingInfo.status === Status.Done ? 1 : -1;
    },
  );

  return (
    <Table
      dataSource={filteredTrainingTableDataList}
      columns={columns}
      pagination={false}
      sticky
    />
  );
};

export default GapAnalysisTable;
