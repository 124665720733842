import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "@common/components";
import sortConverterServices from "@common/services/sortConverterServices";
import {
  SetBusinessUnitsPropTypes,
  SetNamePropTypes,
  SetTrainingPropTypes,
  TaskListSorterType,
} from "@task/types";
import { ColumnFilterItem, ColumnsType } from "antd/lib/table/interface";
import { TaskInfo } from "graphqlTypes";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

import BusinessUnitsCell from "./BusinessUnitsCell/index";
import TaskNameCell from "./TaskNameCell";
import TrainingRequiredCell from "./TrainingRequiredCell";

const t: (key: string) => string = i18n.getFixedT(null, "task.EditTasksTable");

type PropTypes = {
  setTaskList: Dispatch<SetStateAction<TaskInfo[]>>;
  setIsError: Dispatch<SetStateAction<boolean>>;
  sorters: TaskListSorterType;
  onDeleteTask: (id: string) => void;
  businessUnits: string[];
};

export const getColumns = ({
  setTaskList,
  setIsError,
  sorters,
  onDeleteTask,
  businessUnits,
}: PropTypes): ColumnsType<TaskInfo> => {
  const businessUnitOptions: ColumnFilterItem[] = businessUnits.map(
    (businessUnit) => {
      return { value: businessUnit, text: businessUnit };
    },
  );

  const setBusinessUnits = ({
    taskId,
    businessUnits,
  }: SetBusinessUnitsPropTypes) => {
    setTaskList((taskList) => {
      const updatedTaskList = taskList?.map((task) => {
        if (task.id === taskId) {
          return { ...task, businessUnit: businessUnits };
        }
        return task;
      });
      return updatedTaskList;
    });
  };

  const setTrainings = ({ taskId, trainings }: SetTrainingPropTypes) => {
    setTaskList((taskList) => {
      const updatedTaskList = taskList?.map((task) => {
        if (task.id === taskId) {
          return { ...task, trainings };
        }
        return task;
      });
      return updatedTaskList;
    });
  };

  const setName = ({ name, taskId }: SetNamePropTypes) => {
    setTaskList((taskList) => {
      const updatedTaskList = taskList?.map((task) => {
        if (task.id === taskId) {
          return { ...task, name };
        }
        return task;
      });
      return updatedTaskList;
    });
  };

  return [
    {
      dataIndex: "tasksName",
      title: t("tasksName"),
      key: "tasksName",
      sorter: true,
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        sorters.tasksName,
      ),
      render: (_, taskData) => {
        return (
          <TaskNameCell
            taskData={taskData}
            setName={setName}
            setIsError={setIsError}
          />
        );
      },
    },
    {
      width: "30%",
      dataIndex: "trainingsRequired",
      title: t("trainingsRequired"),
      key: "trainingsRequired",
      render: (_, taskData) => {
        return (
          <TrainingRequiredCell
            taskData={taskData}
            setTrainings={setTrainings}
          />
        );
      },
    },
    {
      width: "30%",
      dataIndex: "businessUnits",
      title: t("businessUnits"),
      key: "businessUnits",
      filteredValue: sorters.businessUnitFilter,
      filters: businessUnitOptions,
      render: (_, taskData) => {
        return (
          <BusinessUnitsCell
            taskData={taskData}
            setBusinessUnits={setBusinessUnits}
          />
        );
      },
    },
    {
      dataIndex: "delete",
      key: "delete",
      render: (_, taskData) => {
        const onClick = () => {
          onDeleteTask(taskData.id);
        };
        return (
          <Popconfirm
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={onClick}
            title={t("popconfirmText")}
          >
            <Button danger type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];
};
