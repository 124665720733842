import config from "config";
import * as yup from "yup";

export type RequestTrainingFormType = {
  trainingValue: string;
  description: string;
};

export const schema: yup.SchemaOf<RequestTrainingFormType> = yup.object({
  trainingValue: yup.string().required(),
  description: yup.string().max(config.SHORT_DESCRIPTION_LENGTH).required(),
});
