import { TextArea } from "@common/components";
import { ElementMetadataType } from "@common/types";
import { TextAreaProps } from "antd/lib/input";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

type PropTypes<T extends FieldValues> = {
  useControllerProps: UseControllerProps<T>;
  textAreaProps?: TextAreaProps & ElementMetadataType;
};

const ControlledTextArea = <T extends FieldValues>({
  useControllerProps,
  textAreaProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, onBlur, name, value },
  } = useController(useControllerProps);

  return (
    <TextArea
      onChange={(text) => {
        onChange(text);
        textAreaProps?.onChange && textAreaProps?.onChange(text);
      }}
      onBlur={onBlur}
      value={value}
      name={name}
      {...textAreaProps}
    />
  );
};

export default ControlledTextArea;
