export default {
  GapAnalysis: {
    gapAnalysis: "Gap analysis",
    requireAll: "Require all",
    trainingsRequested: "Training requested",
    exportExcel: "Export Excel",
  },
  EmployeesTasksTable: {
    name: "Name",
    position: "Position",
    tasks: "Tasks",
    save: "Save",
    decline: "Decline",
    edit: "Edit",
    selectAll: "Select all",
    runGapAnalysis: "Run gap analysis",
    usersTasksSaved: "User tasks saved",
  },
  TaskAssignmentTable: {
    name: "Name",
    position: "Position",
    tasks: "Tasks",
    save: "Save",
    decline: "Decline",
    edit: "Edit",
    selectAll: "Select all",
    runGapAnalysis: "Run gap analysis",
    usersTasksSaved: "User tasks saved",
  },
  SelectTasksCell: { selectTasks: "Select tasks" },
  EmployeesTable: {
    name: "Name",
    position: "Position",
    mandatoryTrainingStatus: "Mandatory training status",
    jobDependentStatus: "Job dependent status",
    active: "Active",
    reactive: "Reactive",
    proactive: "Proactive",
    inactive: "Inactive",
    searchPlaceholder: "Search by full name...",
    projectStatusChart: "Project status chart",
  },
  SuggestedCandidatesTable: {
    name: "Name",
    position: "Position",
    mandatoryTrainingStatus: "Mandatory training status",
    jobDependentStatus: "Job dependent status",
    active: "Active",
    reactive: "Reactive",
    proactive: "Proactive",
    inactive: "Inactive",
    searchPlaceholder: "Search by full name...",
  },
  RequestTrainingByProjectForm: {
    requestTraining: "Request a training",
    employee: "Employee",
    position: "Position",
    project: "Project",
    trainingNeed: "Training need",
    selectTraining: "Select Training",
    descriptionPlaceholder: "Leave your preferences (date, time, etc)",
    submit: "Submit",
    jobDependent: "Job dependent",
  },
  ManageProjects: {
    searchPlaceholder: "Search by project name, manager...",
    projects: "Project",
    exportExcel: "Export Excel",
  },
  ManageMyProjects: {
    searchPlaceholder: "Search by project name, manager...",
    projects: "Project",
    exportExcel: "Export Excel",
  },
  ProjectsTable: {
    projectName: "Project name",
    projectManager: "Project manager",
    businessUnit: "Business unit",
    projectStage: "Project stage",
    startDate: "Start date",
    endDate: "End date",
    requestTraining: "Request training",
    percentOfTrainingsComplete: "HSE courses complete",
  },
  MyProjectsTableUser: {
    projectName: "Project name",
    projectManager: "Project manager",
    businessUnit: "Business unit",
    projectStage: "Project stage",
    startDate: "Start date",
    endDate: "End date",
  },
  ProjectsTableUser: {
    projectName: "Project name",
    projectManager: "Project manager",
    businessUnit: "Business unit",
    projectStage: "Project stage",
    startDate: "Start date",
    endDate: "End date",
  },
  MyProjectsTableManagement: {
    projectName: "Project name",
    businessUnit: "Business unit",
    projectStage: "Project stage",
    startDate: "Start date",
    endDate: "End date",
  },
  ManageProject: {
    summary: "Summary",
    gapAnalysis: "Gap analysis",
    taskAssignment: "Task assignment",
    suggestedCandidates: "Other suggested candidates",
    ofHSETrainingComplete: "of HSE Courses Complete",
  },
  Candidates: {
    filter: "Filter",
    selectFilterHint: "Please select filters",
    searchPlaceholder: "Search by full name...",
  },
  ManageProjectManagement: {
    searchPlaceholder: "Search by full name...",
  },
  ManageProjectUser: {
    projectTeam: "Project Team",
    projectTeamAnalysis: "Project Team Analysis",
    projectName: "Project name",
    requestTraining: "Request training",
  },
  GapAnalysisTable: {
    trainingName: "Training",
    taskName: "Task",
    description: "Description",
  },
  CertificateCell: {
    expired: "Expired",
    required: "Required",
    active: "Active",
    archived: "Archived",
  },
  GapAnalysisPieChart: {
    active: "Active",
    reactive: "Reactive",
  },
  ProjectTeamCandidates: {
    searchPlaceholder: "Search by full name...",
  },
  CandidateFilterForm: {
    submit: "Submit",
  },
  ProjectCard: {
    project: "Project",
    businessUnit: "Business unit",
    projectStage: "Project stage",
    startDate: "Start date",
    endDate: "End date",
    projectManager: "Project manager",
    HSETrainingComplete: "HSE Courses Complete",
  },
  ProjectsFilterForm: {
    filters: "Filters",
    businessUnits: "Business units",
    businessUnitsSelectPlaceholder: "Select business units",
    stages: "Stages",
    stagesSelectPlaceholder: "Select project stages",
  },
  ManageAdminProjects: {
    myProjects: "My projects",
    projects: "All projects",
  },
};
