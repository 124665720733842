import { FormOutlined } from "@ant-design/icons";
import { Button, Spin, Table } from "@common/components";
import { EmployeesTasksTableColumnType } from "@project/types";
import { Col, message, Row } from "antd";
import config from "config";
import {
  ProjectTeamGapAnalysisInfoInput,
  useGetProjectTeamInfoQuery,
  useUpdateGapAnalysisPresetMutation,
} from "graphqlTypes";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getColumns } from "./columns";

const TaskAssignmentTable: FC = () => {
  const { t } = useTranslation("project.TaskAssignmentTable");
  const { projectId } = useParams();
  const [userList, setUserList] = useState<
    EmployeesTasksTableColumnType[] | undefined
  >();
  const [initialUserList, setInitialUserList] = useState<
    EmployeesTasksTableColumnType[] | undefined
  >();
  const [isEditing, setIsEditing] = useState(false);
  const [updateGapAnalysisPreset, updateGapAnalysisPresetStatus] =
    useUpdateGapAnalysisPresetMutation();
  const projectTeamInfoQuery = useGetProjectTeamInfoQuery({
    variables: {
      searchString: "",
      projectId: projectId as string,
      limit: config.TABLE_LIMIT,
      offset: 0,
    },
    onCompleted: (data) => {
      const userList: EmployeesTasksTableColumnType[] | undefined =
        data.getProjectTeamInfo?.data?.map((userData) => {
          return {
            graphProfile: userData.graphUser,
            user: userData.user,
            tasks: userData.tasks,
            key: userData.graphUser.id,
          };
        });
      setUserList(userList);
      setInitialUserList(userList);
    },
  });

  const columns = getColumns({
    setUserList,
    isEditing,
  });

  const onSaveUserTasksData = () => {
    const projectTeamInfo: ProjectTeamGapAnalysisInfoInput[] | undefined =
      userList?.map((userData) => {
        const taskIdList = userData.tasks.map((task) => {
          return task.id;
        });
        return { userId: userData.user.id, taskIdList };
      });
    updateGapAnalysisPreset({
      variables: {
        projectId: projectId as string,
        projectTeamInfo: projectTeamInfo || [],
      },
      onCompleted: () => {
        setInitialUserList(userList);
        setIsEditing(false);
        projectTeamInfoQuery.refetch();
        message.success(t("usersTasksSaved"));
      },
      refetchQueries: [
        "listGapAnalysisDate",
        "getProfileInfo",
        "getUserInfo",
        "getGapAnalysisByDate",
        "listUser",
        "getProjectTeamInfo",
        "getTrainingMatrix",
      ],
    });
  };

  const onEditClick = () => {
    setIsEditing(true);
  };

  const onDeclineClick = () => {
    setUserList(initialUserList);
    setIsEditing(false);
  };

  return (
    <Spin spinning={updateGapAnalysisPresetStatus.loading}>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col span={24}>
          <Row justify="end" gutter={[12, 12]}>
            {isEditing ? (
              <>
                <Col>
                  <Button
                    data-testid="save-tasks"
                    onClick={onSaveUserTasksData}
                    type="primary"
                  >
                    {t("save")}
                  </Button>
                </Col>
                <Col>
                  <Button danger onClick={onDeclineClick}>
                    {t("decline")}
                  </Button>
                </Col>
              </>
            ) : (
              <Col>
                <Button
                  data-testid="edit-button"
                  icon={<FormOutlined />}
                  onClick={onEditClick}
                  type="link"
                >
                  {t("edit")}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Table
            loading={projectTeamInfoQuery.loading}
            dataSource={userList}
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default TaskAssignmentTable;
