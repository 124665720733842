import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  ControlledInput,
  ControlledReactQuill,
  ControlledSelect,
  ControlledUpload,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import fileServices from "@common/services/fileServices";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import AttachmentDownload from "@help/components/AttachmentDownload";
import {
  AddQuestionFormType,
  schema,
} from "@help/components/CreateQuestionForm/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Form, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import config from "config";
import { Attachment, Faq, Role, useUpdateFaqMutation } from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Help,
  "components",
  "EditQuestionForm",
);

type PropTypes = {
  question: Faq;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditQuestionForm: FC<PropTypes> = ({ question, setIsEditMode }) => {
  const { t } = useTranslation("help.EditQuestionForm");
  const [oldAttachments, setOldAttachment] = useState<Attachment[]>(
    question.attachments,
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<AddQuestionFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      questionTitle: question.title,
      roles: question.roles,
      description: question.description,
    },
  });
  const [updateFaq, updateFaqMutationStatus] = useUpdateFaqMutation({
    client: apolloProvider.apolloApiClient,
  });
  const [updateFaqWithFiles, updateFaqWithFilesMutationStatus] =
    useUpdateFaqMutation({
      client: apolloProvider.apolloUploadClient,
    });

  const files = getValues("attachment.fileList");

  const roles = [
    { value: Role.Admin, label: t("admin") },
    { value: Role.Manager, label: t("management") },
    { value: Role.User, label: t("projectTeam") },
  ];

  const onSaveClick = (data: AddQuestionFormType) => {
    if (data.attachment?.fileList?.length) {
      return updateFaqWithFiles({
        variables: {
          id: question.id,
          title: data.questionTitle,
          roles: data.roles,
          description: data.description,
          oldAttachments: oldAttachments || [],
          newAttachments: data.attachment.fileList.map((file) => {
            return file.originFileObj;
          }),
        },
        onCompleted: (data) => {
          message.success(t("FAQEdited"));
          logsServices.logInformation({
            message: `FAQ (id=${data.updateFAQ?.id}) was updated.`,
            location: ["onSaveClick", "updateFaqWithFiles", "onCompleted"],
          });
          apolloProvider.apolloApiClient.refetchQueries({
            include: ["listFAQ", "getFAQ"],
          });
          setIsEditMode(false);
        },
      });
    }
    updateFaq({
      variables: {
        id: question.id,
        title: data.questionTitle,
        roles: data.roles,
        description: data.description,
        oldAttachments: oldAttachments || [],
        newAttachments: [],
      },
      onCompleted: (data) => {
        message.success(t("FAQEdited"));
        logsServices.logInformation({
          message: `FAQ (id=${data.updateFAQ?.id}) was updated.`,
          location: ["onSaveClick", "updateFaq", "onCompleted"],
        });
        apolloProvider.apolloApiClient.refetchQueries({
          include: ["listFAQ", "getFAQ"],
        });
        setIsEditMode(false);
      },
    });
  };

  const validateFileBeforeUpload = (file: UploadFile) => {
    return fileServices.validateFileBeforeUpload(file, config.HELP_FILE_TYPES, {
      sizeErrorCallback: () => {
        message.error(
          t("fileSizeError", {
            fileSize: config.FILE_SIZE,
          }),
        );
      },
      typeErrorCallback: () => {
        message.error(
          t("fileTypeError", {
            fileTypes: config.HELP_FILE_TYPES.join(", "),
          }),
        );
      },
    });
  };

  const onOldAttachmentDelete = (attachmentUrl: string) => {
    const filletedOldAttachments = oldAttachments?.filter((attachment) => {
      return attachmentUrl !== attachment.url;
    });
    setOldAttachment(filletedOldAttachments);
  };

  const onCancelClick = () => {
    setIsEditMode(false);
  };

  const isMutationLoading = loadingService.checkLoading([
    updateFaqWithFilesMutationStatus.loading,
    updateFaqMutationStatus.loading,
  ]);

  return (
    <Form>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={24}>
          <Row justify="center" gutter={[0, 12]}>
            <Col span={24}>{t("questionTitle")}</Col>
            <Col span={24}>
              <ControlledInput
                useControllerProps={{
                  name: "questionTitle",
                  control,
                }}
                inputProps={{
                  "data-testid": "title",
                  status: errors.questionTitle && "error",
                  placeholder: t("titlePlaceholder"),
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[0, 12]}>
            <Col span={24}>{t("selectRole")}</Col>
            <Col span={24}>
              <ControlledSelect
                useControllerProps={{
                  name: "roles",
                  control,
                }}
                selectProps={{
                  status: errors.roles && "error",
                  options: roles,
                  className: styles.roleSelect,
                  mode: "multiple",
                  placeholder: "selectRole",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[0, 12]}>
            <Col span={24}>
              {t("description")}
              {errors.description && (
                <Col className={styles.error}>{errors.description.message}</Col>
              )}
            </Col>
            <Col span={24}>
              <ControlledReactQuill
                useControllerProps={{
                  name: "description",
                  control,
                }}
                reactQuillProps={{
                  theme: "snow",
                  placeholder: t("descriptionPlaceholder"),
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle" gutter={[24, 12]}>
            <Col>{t("uploadAttachment")}</Col>
            <Col>
              <Row gutter={[24, 24]}>
                {oldAttachments?.map((attachment) => {
                  return (
                    <Col span={24} key={attachment.url}>
                      <Row align="middle">
                        <Col>
                          <AttachmentDownload attachment={attachment} />
                        </Col>
                        <Col>
                          <Button
                            onClick={() => {
                              onOldAttachmentDelete(attachment.url);
                            }}
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col>
              <ControlledUpload
                useControllerProps={{
                  name: "attachment",
                  control,
                }}
                uploadProps={{
                  "data-testid": "upload",
                  beforeUpload: validateFileBeforeUpload,
                  fileList: files,
                  children: (
                    <Button icon={<PlusOutlined />} type="link">
                      {t("upload")}
                    </Button>
                  ),
                }}
              />
            </Col>
            <Col span={24}>
              {errors.attachment && (
                <span className={styles.fileError}>{t("fileError")}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button onClick={onCancelClick} disabled={isMutationLoading}>
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={handleSubmit(onSaveClick)}
                loading={isMutationLoading}
                data-testid="submit"
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EditQuestionForm;
