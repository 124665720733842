import auth from "./auth";
import common from "./common";
import dashboard from "./dashboard";
import help from "./help";
import notification from "./notification";
import profile from "./profile";
import project from "./project";
import task from "./task";
import training from "./training";
import user from "./user";

export default {
  training,
  project,
  task,
  common,
  user,
  profile,
  auth,
  help,
  dashboard,
  notification,
};
