import { Layout } from "@common/enums";
import { ModuleType, AuthType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import UserProfile from "./pages/Userprofile";
import Users from "./pages/Users";

const user: ModuleType = {
  routes: [
    {
      layout: Layout.MainLayout,
      path: RoutePaths.Users,
      Page: Users,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager],
      type: AuthType.Auth,
    },
    {
      layout: Layout.MainLayout,
      path: RoutePaths.UserProfile,
      Page: UserProfile,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      type: AuthType.Auth,
    },
  ],
  contextProviders: [],
};

export default user;
