import tokenServices from "@auth/services/tokenServices";
import LogsServices from "@common/services/logsServices";
import axios from "axios";

const logsServices = new LogsServices("providers", "axios");
const microsoftGraphApi = axios.create({
  baseURL: process.env.REACT_APP_MICROSOFT_GRAPH_API_URL,
});

microsoftGraphApi.interceptors.request.use(async (config) => {
  const accessToken = await tokenServices.getToken();

  const configWithToken = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return configWithToken;
});

microsoftGraphApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    logsServices.logError({
      message: error.toString(),
      location: ["interceptor"],
    });
  },
);

export default microsoftGraphApi;
