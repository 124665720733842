export default {
  ManageQuestionList: {
    searchPlaceholder: "Search by specific topics...",
    commonQuestions: "Common questions",
  },

  ManageEditableQuestionList: {
    searchPlaceholder: "Search by specific topics....",
    createNewQuestion: "Create new question",
    commonQuestions: "Common questions",
  },
  CreateQuestionForm: {
    fileError: "File is not valid.",
    questionTitle: "Question title",
    titlePlaceholder: "Type a question title",
    selectRole: "Select roles",
    description: "Description",
    descriptionPlaceholder: "Fill in necessary info",
    uploadAttachment: "Upload attachment:",
    upload: "Upload",
    close: "Close",
    submit: "Submit",
    fileSizeError: "File size should be less than {{fileSize}}MB.",
    fileTypeError: "Available file types are {{fileTypes}}.",
    admin: "Admin",
    management: "Management",
    projectTeam: "Project team",
    FAQcreated: "FAQ created",
    descriptionRequired: "*description is a required field",
    descriptionMostThan:
      "*description must be at most {{maxLength}} characters",
    createQuestion: "Create question",
  },
  DisplayEditableQuestion: {
    edit: "Edit",
    delete: "Delete",
    attachments: "Attachments",
    download: "Download",
    deleteConfirmation: "Are you sure you want to delete FAQ?",
    yes: "Yes",
    no: "No",
  },
  EditQuestionForm: {
    fileError: "File is not valid.",
    questionTitle: "Question title",
    titlePlaceholder: "Type a question title",
    selectRole: "Select roles",
    description: "Description",
    descriptionPlaceholder: "Fill in necessary info",
    uploadAttachment: "Upload attachment:",
    upload: "Upload",
    attachments: "Attachments",
    save: "Save",
    cancel: "Cancel",
    fileSizeError: "File size should be less than {{fileSize}}MB.",
    fileTypeError: "Available file types are {{fileTypes}}.",
    admin: "Admin",
    management: "Management",
    projectTeam: "Project team",
    FAQEdited: "FAQ edited",
  },
  DisplayQuestion: {
    attachments: "Attachments",
    download: "Download",
  },
};
