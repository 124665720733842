import config from "config";
import i18n from "i18next";
import locales from "locales";

i18n.init({
  fallbackLng: config.LANGUAGE,
  resources: locales,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it does escape per default to prevent xss!
  },
});

export default i18n;
