import { PlusOutlined } from "@ant-design/icons";
import { Button } from "@common/components";
import { Col, Row } from "antd";
import config from "config";
import { format } from "date-fns";
import { CertificateInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PropTypes = {
  certificate: CertificateInfo;
  onRequestClick: () => void;
};

const RequestTrainingCell: FC<PropTypes> = ({
  certificate,
  onRequestClick,
}) => {
  const { t } = useTranslation("training.RequestTrainingCell");

  return certificate.request ? (
    <Row>
      <Col span={24}>{certificate.request.createdBy?.displayName}</Col>
      <Col span={24}>
        {format(new Date(certificate.request.createdAt), config.DATE_WITH_DOTS)}
      </Col>
    </Row>
  ) : (
    <Button
      data-cy="request-existed-certificate"
      type="link"
      icon={<PlusOutlined />}
      onClick={onRequestClick}
    >
      {t("request")}
    </Button>
  );
};

export default RequestTrainingCell;
