import DatePicker from "@common/components/DatePicker";
import { DatePickerProps, ElementMetadataType } from "@common/types";
import { useController, UseControllerProps } from "react-hook-form";

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  datePickerProps?: DatePickerProps & ElementMetadataType;
};

const ControlledDatePicker = <T extends object>({
  useControllerProps,
  datePickerProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, onBlur, value },
  } = useController(useControllerProps);

  return (
    <DatePicker
      onChange={(date, stringDate) => {
        // date picker can be undefined when it is cleaned.
        // eslint-disable-next-line strict-null-checks/all
        onChange(date, stringDate);
        datePickerProps?.onChange &&
          datePickerProps?.onChange(date, stringDate);
      }}
      value={value}
      onBlur={onBlur}
      {...datePickerProps}
    />
  );
};

export default ControlledDatePicker;
