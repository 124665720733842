class LoadingServices {
  checkLoading = (loadings: boolean[]): boolean => {
    return loadings.some((loading) => {
      return loading;
    });
  };
}

const loadingService = new LoadingServices();

export default loadingService;
