import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Input } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useModal } from "@common/hooks/useModal";
import { useSearchParams } from "@common/hooks/useSearchParams";
import CandidateFilterForm from "@project/components/CandidateFilterForm";
import SuggestedCandidatesTable from "@project/components/SuggestedCandidatesTable";
import { Row, Col, Button } from "antd";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const Candidates: FC = () => {
  const { t } = useTranslation("project.Candidates");
  const [filterParams, setFilterParams] = useState<string[]>([]);
  const { hideModal, setModalProps } = useModal();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearchText = useDebounce(searchText);

  const onFilterClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      open: true,
      children: <CandidateFilterForm setFilterParams={setFilterParams} />,
    });
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });
  };

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Input onChange={onSearchChange} placeholder={t("searchPlaceholder")} />
      </Col>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            {!filterParams.length && (
              <div className={styles.filterHint}>
                <ExclamationCircleOutlined /> {t("selectFilterHint")}
              </div>
            )}
          </Col>
          <Col>
            <Button onClick={onFilterClick} type="link">
              {t("filter")}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {!!filterParams.length && (
          <SuggestedCandidatesTable
            searchText={debouncedSearchText || ""}
            taskIdList={filterParams}
          />
        )}
      </Col>
    </Row>
  );
};

export default Candidates;
