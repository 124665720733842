import { Row, Col } from "antd";
import { CertificateInfo, UserInfo } from "graphqlTypes";
import { FC } from "react";

type PropTypes = {
  TrainingCard: FC<{
    certificate: CertificateInfo;
    userData: UserInfo | undefined;
  }>;
  certificates: CertificateInfo[];
  userData: UserInfo | undefined;
};

const TrainingsCardList: FC<PropTypes> = ({
  certificates,
  TrainingCard,
  userData,
}) => {
  return (
    <Row gutter={[0, 24]}>
      {certificates.map((certificate) => {
        return (
          <Col
            span={24}
            key={`${certificate.trainingId}${certificate.completedAt}`}
          >
            <TrainingCard userData={userData} certificate={certificate} />
          </Col>
        );
      })}
    </Row>
  );
};

export default TrainingsCardList;
