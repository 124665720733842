import { ModuleNameEnum } from "@common/enums";
import LogsServices from "@common/services/logsServices";
import { Alert } from "antd";
import { FC } from "react";
import { ErrorBoundary as ErrorBoundaryREB } from "react-error-boundary";

const logsServices = new LogsServices(
  ModuleNameEnum.Common,
  "components",
  "ErrorBoundary",
);

type ErrorBoundaryPropTypes = {
  children: JSX.Element;
};

type FallbackComponentPropTypes = { error: Error };

const FallbackComponent: FC<FallbackComponentPropTypes> = ({
  error,
}: FallbackComponentPropTypes) => {
  return (
    <Alert
      type="error"
      message={`name: ${error.name}; error: ${error.message}; stack: ${error.stack}`}
    />
  );
};

const ErrorBoundary: FC<ErrorBoundaryPropTypes> = ({
  children,
}: ErrorBoundaryPropTypes) => {
  const onError = (error: Error) => {
    logsServices.logCritical({
      message: `name: ${error.name}, error: ${error.message}, stack: ${error.stack}`,
      location: ["onError"],
    });
  };

  return (
    <ErrorBoundaryREB fallbackRender={FallbackComponent} onError={onError}>
      {children}
    </ErrorBoundaryREB>
  );
};

export default ErrorBoundary;
