import { Button, Select, Spin, Switch } from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import LogsServices from "@common/services/logsServices";
import { Row, Col, message } from "antd";
import config from "config";
import {
  AccountStatus,
  Role,
  UserInfo,
  useUpdateUserMutation,
  useGetProfileInfoQuery,
} from "graphqlTypes";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.User,
  "components",
  "EditUser",
);

type PropTypes = {
  userData: UserInfo;
};

const EditUser: FC<PropTypes> = ({ userData }) => {
  const { t } = useTranslation("user.EditUser");
  const { hideModal } = useModal();
  const [userRole, setUserRole] = useState<Role>(
    userData.user?.role || config.DEFAULT_ROLE,
  );
  const [isUserActive, setIsUserActive] = useState<boolean>(
    !!userData.user?.active,
  );
  const [updateUser, updateUserMutationStatus] = useUpdateUserMutation();
  const currentUserQuery = useGetProfileInfoQuery();

  const currentUserRole =
    currentUserQuery.data?.getProfileInfo?.user?.role || config.DEFAULT_ROLE;

  const isLoading = updateUserMutationStatus.loading;

  const selectOptionsByRole = {
    [Role.SuperAdmin]: [
      { label: t("admin"), value: Role.Admin },
      { label: t("management"), value: Role.Manager },
      { label: t("projectTeam"), value: Role.User },
    ],
    [Role.Admin]: [
      { label: t("management"), value: Role.Manager },
      { label: t("projectTeam"), value: Role.User },
    ],
    [Role.Manager]: [],
    [Role.User]: [],
  };

  const selectOptions = selectOptionsByRole[currentUserRole];

  const onCancelClick = () => {
    setUserRole(userData.user?.role || config.DEFAULT_ROLE);
    setIsUserActive(!!userData.user?.active);
    hideModal();
  };

  const onSaveClick = () => {
    if (userData.user) {
      updateUser({
        variables: {
          id: userData.user.id,
          active: isUserActive,
          role: userRole,
        },
        onCompleted: () => {
          message.success(t("userUpdated"));
          logsServices.logInformation({
            message: `User (id=${userData.user?.id}) was updated.`,
            location: ["onSaveClick", "updateUser", "onCompleted"],
          });
          hideModal();
        },
      });
    }
  };

  const onDeactivateSwitchChange = () => {
    setIsUserActive(!isUserActive);
  };

  return (
    <Spin spinning={currentUserQuery.loading}>
      <Row justify="space-between" align="middle" gutter={[0, 24]}>
        <Col span={8}>{t("fullName")}:</Col>
        <Col span={15}>{userData.graphProfile.displayName}</Col>
        <Col span={8}>{t("position")}:</Col>
        <Col span={15}>{userData.graphProfile.jobTitle}</Col>
        <Col span={8}>{t("currentProjects")}:</Col>
        <Col span={15}>
          {userData?.currentProjects
            ?.map((project) => {
              return project?.name;
            })
            ?.join(", ") || t("noCurrentProjects")}
        </Col>
        <Col span={8}>{t("businessUnit")}:</Col>
        <Col span={15}>{userData.graphProfile.officeLocation}</Col>
        <Col span={8}>{t("profileTrainingStatus")}:</Col>
        <Col
          span={14}
          className={
            userData.accountStatus === AccountStatus.Reactive
              ? styles.reactiveStatus
              : styles.activeStatus
          }
        >
          {userData.accountStatus}
        </Col>
        <Col span={8}>{t("role")}:</Col>
        <Col span={15}>
          <Select
            data-testid="select-role"
            popupClassName={styles.selectPopup}
            className={styles.select}
            onChange={setUserRole}
            placeholder={t("selectRole")}
            options={selectOptions}
            value={userRole}
          />
        </Col>
        <Col span={8}>{t("deactivateUser")}:</Col>
        <Col span={15} className={styles.deactivateSwitcherContainer}>
          <Switch
            data-testid="deactivate"
            checked={!isUserActive}
            onChange={onDeactivateSwitchChange}
          />
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button disabled={isLoading} onClick={onCancelClick}>
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button
                data-testid="submit"
                loading={isLoading}
                type="primary"
                onClick={onSaveClick}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default EditUser;
