import { ApolloClient, InMemoryCache } from "@apollo/client";

import link from "./link";

const apolloApiClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: link.apiLink,
  connectToDevTools: true,
});

const apolloUploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: link.uploadLink,
});

export default { apolloApiClient, apolloUploadClient };
