import { gql } from "@apollo/client";
import { EDUCATION_FRAGMENT } from "@profile/graphql/fragments/educationFragment";
import { GRAPH_USER_FRAGMENT } from "@profile/graphql/fragments/graphUserFragment";
import { USER_FRAGMENT } from "@profile/graphql/fragments/userFragment";
import { PROJECT_FRAGMENT } from "@project/graphql/fragments/projectFragment";
import { CERTIFICATE_INFO_FRAGMENT } from "@training/graphql/fragments/certificateInfoFragment";
import { TRAINING_FRAGMENT } from "@training/graphql/fragments/trainingFragment";

export const USER_INFO_FRAGMENT = gql`
  ${USER_FRAGMENT}
  ${GRAPH_USER_FRAGMENT}
  ${TRAINING_FRAGMENT}
  ${PROJECT_FRAGMENT}
  ${EDUCATION_FRAGMENT}
  ${CERTIFICATE_INFO_FRAGMENT}
  fragment UserInfoNode on UserInfo {
    user {
      ...UserNode
    }
    graphProfile {
      ...GraphUserNode
    }
    manager {
      ...GraphUserNode
    }
    projects {
      ...ProjectNode
    }
    currentProjects {
      ...ProjectNode
    }
    education {
      ...EducationNode
    }
    trainings {
      ...CertificateInfoNode
    }
    customCertificates {
      ...CertificateInfoNode
    }
    accountStatus
  }
`;
