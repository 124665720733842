import { ScreenContextValueType } from "@common/types";
import config from "config";
import { createContext, FC, useEffect, useState } from "react";

const contextValue: ScreenContextValueType = {
  isPhoneScreenSize: false,
};

const ScreenContext = createContext(contextValue);

type PropTypes = { children: JSX.Element | JSX.Element[] };

const ScreenContextProvider: FC<PropTypes> = ({ children }) => {
  const [isPhoneScreenSize, setIsPhoneScreenSize] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsPhoneScreenSize(window.innerWidth <= config.PHONE_SCREEN_SIZE);
    });
    setIsPhoneScreenSize(window.innerWidth <= config.PHONE_SCREEN_SIZE);
  }, []);

  return (
    <ScreenContext.Provider
      value={{
        isPhoneScreenSize,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export { ScreenContext, ScreenContextProvider };
