export default {
  UploadEducationForm: {
    universityName: "University name",
    fieldOfStudy: "Field of study",
    year: "Year",
    diploma: "Diploma",
    upload: "Upload",
    save: "Save",
    fileSizeError: "File size should be less than {{fileSize}}MB.",
    fileTypeError: "Available file types are {{fileTypes}}.",
    diplomaUploaded: "Diploma uploaded",
    uploadDiploma: "Upload diploma",
    userName: "User name",
  },
  ProfileEdit: {
    education: "Education",
    addDiploma: "Add qualification",
    listOfTrainings: "List of training",
    request: "Request",
    upload: "Upload",
    overdue: "Overdue",
    active: "Active",
    help: "Help",
    userDataStep:
      "Please, check your data. If data is not correct, please, contact your direct manager.",
    educationStep: "Please, click here to upload your diploma.",
    requestCertificateStep:
      "Here you can request training approval. For more details click on the link:",
    uploadCertificateStep:
      "Please click here to upload your certificates! For more details click on the link:",
  },
  UserProfileEdit: {
    education: "Education",
    addDiploma: "Add qualification",
    listOfTrainings: "List of training",
    request: "Request",
    upload: "Upload",
    overdue: "Overdue",
    active: "Active",
    help: "Help",
    userDataStep:
      "Please, check your data. If data is not correct, please, contact your direct manager.",
    educationStep: "Please, click here to upload your diploma.",
    requestCertificateStep:
      "Here you can request training approval. For more details click on the link:",
    uploadCertificateStep:
      "Please click here to upload your certificates! For more details click on the link:",
  },
  HeaderUserData: {
    businessUnit: "Business unit",
    directManager: "Direct manager",
    profileStatus: "Profile status",
    active: "Active",
    reactive: "Reactive",
    proactive: "Proactive",
    inactive: "Inactive",
    sendMail: "Send mail",
  },
  DiplomaListEdit: {
    deleteWarning: "Are you sure you want to delete diploma?",
    yes: "Yes",
    no: "No",
    university: "University",
    diplomaDeleted: "Diploma was deleted",
  },
  DiplomaList: {
    university: "University",
  },
};
