import { EditOutlined } from "@ant-design/icons";
import { Button } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import EditUser from "@user/components/EditUser";
import { Role, UserInfo } from "graphqlTypes";
import { FC } from "react";

const editableRolesByRole = {
  [Role.SuperAdmin]: [Role.Admin, Role.Manager, Role.User],
  [Role.Admin]: [Role.Manager, Role.User],
  [Role.Manager]: [],
  [Role.User]: [],
};

type PropTypes = { userData: UserInfo; currentUserRole: Role };

const EditCellByRole: FC<PropTypes> = ({ userData, currentUserRole }) => {
  const { setModalProps } = useModal();

  const editableRoles = editableRolesByRole[currentUserRole];

  const isEditButton = editableRoles.some((role) => {
    return userData.user?.role === role;
  });

  const onEditClick = () => {
    setModalProps({
      footer: false,
      closable: false,
      children: <EditUser userData={userData} />,
      open: true,
    });
  };

  return isEditButton ? (
    <Button type="link" onClick={onEditClick} icon={<EditOutlined />} />
  ) : null;
};

export default EditCellByRole;
