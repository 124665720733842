class TextServices {
  clearHtmlText = (text: string) => {
    return text
      .replace(/<!--[\s\S]*?--!?>/g, "")
      .replace(/<\/?[a-z][^>]*(>|$)/gi, "");
  };
}

const textServices = new TextServices();

export default textServices;
