import { gql } from "@apollo/client";
import { USER_INFO_FRAGMENT } from "@user/graphql/fragments/userInfoFragment";

export const GET_USER_INFO_QUERY = gql`
  ${USER_INFO_FRAGMENT}
  query getUserInfo($id: UserID!) {
    getUserInfo(id: $id) {
      ...UserInfoNode
    }
  }
`;
