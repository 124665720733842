import { BackButton, Spin, Tabs } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useSearchParams } from "@common/hooks/useSearchParams";
import Candidates from "@project/components/Candidates";
import EmployeesTable from "@project/components/EmployeesTable";
import GapAnalysis from "@project/components/GapAnalysis";
import EmployeesTasksTable from "@project/components/TaskAssignmentTable";
import { ProjectTab } from "@project/enum";
import { Col, Row } from "antd";
import { useGetProjectInfoQuery } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";

const ManageProject: FC = () => {
  const { t } = useTranslation("project.ManageProject");
  const { setSearchParams, getSearchParam } = useSearchParams();
  const { projectId } = useParams();
  const projectInfoQuery = useGetProjectInfoQuery({
    variables: {
      id: projectId as string,
    },
  });

  const projectName = projectInfoQuery.data?.getProjectInfo?.name;

  const paramsTabName = getSearchParam(SearchParamsEnum.Tab);

  const tabsItems = [
    {
      label: t("summary"),
      key: ProjectTab.ProjectTeam,
      children: <EmployeesTable />,
    },
    {
      label: <span data-testid="task-assign-tab">{t("taskAssignment")}</span>,
      key: ProjectTab.TaskAssignment,
      children: <EmployeesTasksTable />,
    },
    {
      label: <span data-testid="gap-analysis">{t("gapAnalysis")}</span>,
      key: ProjectTab.GapAnalytics,
      children: <GapAnalysis />,
    },
    {
      label: t("suggestedCandidates"),
      key: ProjectTab.SuggestedCandidates,
      children: <Candidates />,
    },
  ];

  const onTabChange = (tabName: string) => {
    setSearchParams({ tab: tabName || ProjectTab.ProjectTeam });
  };

  return (
    <Spin spinning={projectInfoQuery.loading}>
      <Row gutter={[0, 24]} justify="center">
        <Col span={23}>
          <BackButton />
        </Col>
        <Col span={23} className={styles.projectName}>
          <Row className={styles.rowCenter}>
            <Col>{projectName}</Col>
            <Col>
              <div className={styles.HSETrainingComplete}>{`${
                projectInfoQuery.data?.getProjectInfo?.percent || 0
              }% ${t("ofHSETrainingComplete")}`}</div>
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          <Tabs
            className={styles.tabName}
            onChange={onTabChange}
            activeKey={paramsTabName || ProjectTab.ProjectTeam}
            items={tabsItems}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default ManageProject;
