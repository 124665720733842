import { Table } from "@common/components";
import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import sortConverterServices from "@common/services/sortConverterServices";
import trainingExpandServices from "@training/services/trainingExpandServices";
import { TrainingListFilterSorterType } from "@training/types";
import { TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import config from "config";
import {
  Need,
  TrainingWithTasks,
  useDeleteTrainingMutation,
  useListTrainingQuery,
} from "graphqlTypes";
import { FC, useEffect, useState } from "react";

import { getColumns } from "./columns";

const logsServices = new LogsServices(
  ModuleNameEnum.Training,
  "components",
  "TrainingsTable",
);

type PropTypes = {
  searchText: string;
  setExportExcel?: (data: { exportUrl: string; disabled: boolean }) => void;
};

const TrainingsTable: FC<PropTypes> = ({ searchText, setExportExcel }) => {
  const { getSearchParam, addSearchParams } = useSearchParams();
  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;
  const { setModalProps } = useModal();

  const [filterSorter, setFilterSorter] =
    useState<TrainingListFilterSorterType>({
      needsFilter: [],
    });
  const [deleteTrainingMutation, deleteTrainingMutationStatus] =
    useDeleteTrainingMutation();
  const listTrainingsQuery = useListTrainingQuery({
    variables: {
      search: searchText,
      limit: config.TABLE_LIMIT,
      offset: (currentPage - 1) * config.TABLE_LIMIT,

      titleSort: filterSorter.trainingName,
      creationDateSort: filterSorter.creationDate,
    },
  });
  const trainingsWithTasksList =
    listTrainingsQuery.data?.listTraining?.trainingWithTask?.data?.map(
      (trainingWithTask) => {
        return { ...trainingWithTask, key: trainingWithTask.training.id };
      },
    );

  const pageTotal =
    listTrainingsQuery.data?.listTraining?.trainingWithTask?.total;

  useEffect(() => {
    if (setExportExcel) {
      const disabled =
        !listTrainingsQuery?.data?.listTraining?.trainingWithTask?.data?.length;
      const exportUrl =
        disabled || !listTrainingsQuery?.data?.listTraining?.url
          ? ""
          : (listTrainingsQuery.data.listTraining.url as string);

      setExportExcel({
        disabled,
        exportUrl,
      });
    }
  }, [listTrainingsQuery.data]);

  const deleteTraining = (id: number) => {
    deleteTrainingMutation({
      variables: {
        id,
      },
      onCompleted: (data) => {
        logsServices.logInformation({
          message: `Training (id=${data?.deleteTraining?.id}) was deleted.`,
          location: ["TrainingsTable", "deleteTraining", "onCompleted"],
        });
      },
      refetchQueries: ["listTraining"],
    });
  };

  const columns = getColumns({ deleteTraining, setModalProps });

  const onTableChange: TableProps<TrainingWithTasks>["onChange"] = (
    _,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<TrainingWithTasks>;

    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setFilterSorter({
      needsFilter: filters.need as Need[],
      ...sorterFields,
    });
  };

  const isLoading = loadingService.checkLoading([
    listTrainingsQuery.loading,
    deleteTrainingMutationStatus.loading,
  ]);

  const onPaginationChange = (page: number) => {
    addSearchParams({
      page: page.toString(),
    });
  };

  return (
    <Table
      onChange={onTableChange}
      loading={isLoading}
      columns={columns}
      dataSource={trainingsWithTasksList}
      expandable={{
        rowExpandable: trainingExpandServices.checkIsRowExpandable,
        expandedRowRender: (trainingWithTasks: TrainingWithTasks) => {
          const renderedElement = trainingExpandServices.renderExpandableRow({
            trainingWithTasks,
          });

          return renderedElement;
        },
      }}
      pagination={{
        total: pageTotal,
        pageSize: config.TABLE_LIMIT,
        current: currentPage,
        onChange: onPaginationChange,
        showSizeChanger: false,
      }}
    />
  );
};

export default TrainingsTable;
