import { UserOutlined } from "@ant-design/icons";
import { Link, Image } from "@common/components";
import fileConverterServices from "@common/services/fileConverterServices";
import photoServices from "@common/services/photoServices";
import { Col, Row } from "antd";
import config from "config";
import RoutePaths from "config/routePaths";
import { MicrosoftGraphUser } from "graphqlTypes";
import { FC, useEffect, useState } from "react";

import styles from "./styles.module.scss";

type PropTypes = {
  graphUser: MicrosoftGraphUser;
};

const UserNameSection: FC<PropTypes> = ({ graphUser }) => {
  const [image, setImage] = useState<string>();

  useEffect(() => {
    (async () => {
      if (graphUser.id) {
        const response = await photoServices.getProfilePhoto(
          graphUser.id,
          config.IMG_RESOLUTION_LOW,
        );
        const imageSrc = fileConverterServices.convertToBase64String(response);
        setImage(imageSrc);
      }
    })();
  }, []);

  return (
    <Row gutter={[10, 10]} align="middle">
      <Col>
        {image ? (
          <Image className={styles.avatar} preview={false} src={image} />
        ) : (
          <UserOutlined className={styles.defaultAvatarIcon} />
        )}
      </Col>
      <Col>
        <Link to={`${RoutePaths.Users}/${graphUser?.id}`}>
          {graphUser.displayName}
        </Link>
      </Col>
    </Row>
  );
};

export default UserNameSection;
