import { FilterOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input } from "@common/components";
import { Col, Divider, Row, Space, Spin } from "antd";
import { useGetBusinessUnitListQuery } from "graphqlTypes";
import i18n from "providers/i18n";
import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const renderDropdown = ({
  resetSelectedItems,
  menu,
}: {
  menu: ReactNode;
  resetSelectedItems: () => void;
}) => {
  const buttonName = i18n.t("reset", { ns: "task.SearchWithFilter" });

  return (
    <div className={styles.dropdownContent}>
      {menu}
      <Divider style={{ margin: 0 }} />
      <Space style={{ padding: 8 }}>
        <Button
          className={styles.resetButton}
          onClick={resetSelectedItems}
          type="primary"
        >
          {buttonName}
        </Button>
      </Space>
    </div>
  );
};

type PropTypes = {
  businessUnitFilter: string[] | undefined;
  setTaskSearch: Dispatch<SetStateAction<string | undefined>>;
  setBusinessUnitFilter: Dispatch<SetStateAction<string[] | undefined>>;
};

const SearchWithFilter: FC<PropTypes> = ({
  businessUnitFilter,
  setTaskSearch,
  setBusinessUnitFilter,
}) => {
  const { t } = useTranslation("task.SearchWithFilter");
  const [isBusinessUnitsFilterOpen, setIsBusinessUnitsFilterOpen] =
    useState(false);
  const businessUnitsQuery = useGetBusinessUnitListQuery();

  const businessUnitsFilterOptions =
    businessUnitsQuery.data?.getBusinessUnitList?.map((businessUnit) => {
      return { key: businessUnit, label: businessUnit };
    });

  const filterClassName = businessUnitFilter?.length
    ? undefined
    : styles.disabledFilter;

  const openFilterDropdown = () => {
    setIsBusinessUnitsFilterOpen(true);
  };

  const closeFilterDropdown = () => {
    setIsBusinessUnitsFilterOpen(false);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTaskSearch(e.currentTarget.value);
  };

  const onBusinessUnitsFilterChange = ({
    selectedKeys,
  }: {
    selectedKeys: string[];
  }) => {
    setBusinessUnitFilter(selectedKeys);
  };

  const resetSelectedItems = () => {
    setBusinessUnitFilter([]);
  };
  return (
    <Spin spinning={businessUnitsQuery.loading}>
      <Row gutter={[0, 12]} wrap={false} justify="space-between">
        <Col flex="auto">
          <Input
            placeholder={t("searchPlaceholder")}
            className={styles.search}
            onChange={onSearchChange}
          />
        </Col>
        <Col
          onMouseLeave={closeFilterDropdown}
          onMouseDown={openFilterDropdown}
        >
          <Dropdown
            menu={{
              multiple: true,
              selectable: true,
              items: businessUnitsFilterOptions,
              selectedKeys: businessUnitFilter,
              onSelect: onBusinessUnitsFilterChange,
              onDeselect: onBusinessUnitsFilterChange,
            }}
            open={isBusinessUnitsFilterOpen}
            placement="topRight"
            arrow
            dropdownRender={(menu) => {
              return renderDropdown({ menu, resetSelectedItems });
            }}
          >
            <Button
              className={filterClassName}
              type="link"
              icon={<FilterOutlined />}
            />
          </Dropdown>
        </Col>
      </Row>
    </Spin>
  );
};

export default SearchWithFilter;
