import { UserOutlined } from "@ant-design/icons";
import { Image, Link } from "@common/components";
import fileConverterServices from "@common/services/fileConverterServices";
import photoServices from "@common/services/photoServices";
import { DateFnsLocales } from "@common/types";
import notificationsServices from "@notification/services/notificationsServices";
import { Col, Row } from "antd";
import config from "config";
import RoutePaths from "config/routePaths";
import { formatDistance } from "date-fns";
import { NotificationInfo } from "graphqlTypes";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  notificationData: NotificationInfo;
};

const Notification: FC<PropTypes> = ({ notificationData }) => {
  const { t } = useTranslation("notification.Notification");
  const [image, setImage] = useState("");

  useEffect(() => {
    (async () => {
      if (notificationData.sender?.id) {
        const imageData = await photoServices.getProfilePhoto(
          notificationData.sender.id,
          config.IMG_RESOLUTION_LOW,
        );
        const imageBase64String =
          fileConverterServices.convertToBase64String(imageData);
        setImage(imageBase64String);
      }
    })();
  }, []);

  const notificationLocaleData =
    notificationsServices.getNotificationDataByType(notificationData);

  const notificationText = t(
    notificationLocaleData.localeName,
    notificationLocaleData.localeParams as { [key: string]: string },
  );

  const creationDate = new Date(notificationData.notification.createdAt);

  return (
    <div className={styles.notificationContainer}>
      <Row
        align="top"
        justify="space-between"
        gutter={[24, 0]}
        className={styles.notification}
        wrap={false}
      >
        <Col flex="80px">
          <Link
            className={styles.userImage}
            to={`${RoutePaths.Users}/${notificationData.recipient.id}`}
          >
            {image ? (
              <Image className={styles.image} preview={false} src={image} />
            ) : (
              <UserOutlined className={styles.userIcon} />
            )}
          </Link>
        </Col>
        <Col flex="auto">
          <Row gutter={[0, 12]}>
            <Col span={24} className={styles.title}>
              {notificationText}
            </Col>
            <Col className={styles.description} span={24}>
              {notificationData.notification.description}
            </Col>
          </Row>
        </Col>
        <Col flex="120px" className={styles.time}>
          {formatDistance(new Date(), creationDate, {
            locale: DateFnsLocales.EnAU,
          })}
        </Col>
      </Row>
    </div>
  );
};

export default Notification;
