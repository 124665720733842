import { PlusOutlined } from "@ant-design/icons";
import { Button, Joyride, Link, Spin } from "@common/components";
import { ScreenContext } from "@common/contexts/ScreenContext";
import { useModal } from "@common/hooks/useModal";
import DiplomaListEdit from "@profile/components/DiplomaListEdit";
import ProfileHeader from "@profile/components/ProfileHeader";
import UploadEducationForm from "@profile/components/UploadEducationForm";
import EditTrainingCardOverdue from "@training/components/EditTrainingCardOverdue";
import EditTrainingsTableOverdue from "@training/components/EditTrainingsTableOverdue";
import RequestTrainingForm from "@training/components/RequestTrainingForm";
import TrainingCardActiveWithFileLink from "@training/components/TrainingCardActiveWithFileLink";
import TrainingsCardList from "@training/components/TrainingsCardList";
import TrainingsTableActiveWithFileLink from "@training/components/TrainingsTableActiveWithFileLink";
import UploadCertificateForm from "@training/components/UploadCertificateForm";
import { Col, Row } from "antd";
import features from "config/features";
import RoutePaths from "config/routePaths";
import { Status, useGetProfileInfoQuery } from "graphqlTypes";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Step } from "react-joyride";

import styles from "./styles.module.scss";

const doneStatuses = [Status.Done, Status.Archived];
const pendingStatuses = [Status.Expired, Status.Required];

const ProfileEdit: FC = () => {
  const { setModalProps, hideModal } = useModal();
  const { t } = useTranslation("profile.ProfileEdit");
  const profileInfoQuery = useGetProfileInfoQuery();
  const { isPhoneScreenSize } = useContext(ScreenContext);
  const steps: Step[] = [
    {
      target: ".userDataStep",
      content: t("userDataStep"),
      disableBeacon: true,
    },
    {
      target: ".educationStep",
      content: t("educationStep"),
      disableBeacon: true,
    },
    {
      target: ".requestCertificateStep",
      content: (
        <span>
          {t("requestCertificateStep")}
          <Link className={styles.helpLink} to={RoutePaths.Help}>
            {t("help")}
          </Link>
        </span>
      ),
      disableBeacon: true,
    },
    {
      target: ".uploadCertificateStep",
      content: (
        <span>
          {t("uploadCertificateStep")}
          <Link className={styles.helpLink} to={RoutePaths.Help}>
            {t("help")}
          </Link>
        </span>
      ),
      disableBeacon: true,
    },
  ];

  const profileData = profileInfoQuery.data?.getProfileInfo;

  const doneTrainings = profileData?.trainings?.filter((training) => {
    return doneStatuses.some((status) => {
      return status === training.status;
    });
  });

  const doneTrainingsWithCustom = [
    ...(doneTrainings || []),
    ...(profileData?.customCertificates || []),
  ];

  const pendingTrainings = profileData?.trainings?.filter((training) => {
    return pendingStatuses.some((status) => {
      return status === training.status;
    });
  });

  const onUploadCertificateClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: <UploadCertificateForm userData={profileData || undefined} />,
      open: true,
    });
  };

  const onAddEducationClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: <UploadEducationForm userData={profileData || undefined} />,
      open: true,
    });
  };

  const onRequestCertificateClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: profileData?.graphProfile && (
        <RequestTrainingForm userId={profileData.user?.id} />
      ),
      open: true,
    });
  };

  const runJoyrider = profileData?.user?.showedPrompts || false;

  return (
    <Spin
      spinning={profileInfoQuery.loading}
      size="large"
      className={styles.spinner}
    >
      <Joyride run={runJoyrider} steps={steps} continuous showSkipButton />
      <Row justify="center" gutter={[0, 16]}>
        <Col className="userDataStep" span={23}>
          <ProfileHeader profileData={profileData} />
        </Col>
        <Col span={23}>
          <Row>
            <Col span={24}>
              <Row align="middle">
                <Col className={styles.titleText}>{t("education")}:</Col>
                <Col>
                  <Button
                    data-testid="add-diploma"
                    className="educationStep"
                    onClick={onAddEducationClick}
                    type="link"
                    icon={<PlusOutlined />}
                  >
                    {t("addDiploma")}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <DiplomaListEdit education={profileData?.education || []} />
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          <Row align="middle">
            <Col className={styles.titleText}>{t("listOfTrainings")}:</Col>
            {features.request && !isPhoneScreenSize && (
              <Col>
                <Button
                  data-testid="request-training"
                  onClick={onRequestCertificateClick}
                  type="link"
                  icon={<PlusOutlined />}
                  className="requestCertificateStep"
                >
                  {t("request")}
                </Button>
              </Col>
            )}
            <Col>
              <Button
                data-testid="upload-training"
                className="uploadCertificateStep"
                onClick={onUploadCertificateClick}
                type="link"
                icon={<PlusOutlined />}
              >
                {t("upload")}
              </Button>
            </Col>
          </Row>
        </Col>
        {pendingTrainings?.length ? (
          <>
            <Col span={23} className={styles.overdue}>
              {t("overdue")}:
            </Col>
            <Col span={23}>
              {isPhoneScreenSize ? (
                <TrainingsCardList
                  TrainingCard={EditTrainingCardOverdue}
                  certificates={pendingTrainings || []}
                  userData={profileData || undefined}
                />
              ) : (
                <EditTrainingsTableOverdue
                  userData={profileData || undefined}
                  certificates={pendingTrainings || []}
                />
              )}
            </Col>
          </>
        ) : null}
        <Col span={23} className={styles.done}>
          {t("active")}:
        </Col>
        <Col span={23}>
          {isPhoneScreenSize ? (
            <TrainingsCardList
              TrainingCard={TrainingCardActiveWithFileLink}
              certificates={doneTrainingsWithCustom}
              userData={profileData || undefined}
            />
          ) : (
            <TrainingsTableActiveWithFileLink
              userId={profileData?.user?.id}
              certificates={doneTrainingsWithCustom}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default ProfileEdit;
