import { FilterFilled } from "@ant-design/icons";
import sortConverterServices from "@common/services/sortConverterServices";
import LeaderboardStatusCell from "@dashboard/components/LeaderboardStatusCell";
import { LeaderboardFilterOptionsType } from "@dashboard/types";
import { ColumnFilterItem, ColumnsType } from "antd/lib/table/interface";
import config from "config";
import { format } from "date-fns";
import { LeaderboardInfo, ProjectStatus } from "graphqlTypes";
import i18n from "providers/i18n";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "dashboard.LeadersTable",
);

type PropTypes = {
  businessUnitsFilterOptions: ColumnFilterItem[];
  filterParams: LeaderboardFilterOptionsType | undefined;
};

export const getColumns = ({
  businessUnitsFilterOptions,
  filterParams,
}: PropTypes): ColumnsType<LeaderboardInfo> => {
  const statusFilterOptions: ColumnFilterItem[] = [
    {
      value: ProjectStatus.Active,
      text: t("active"),
    },
    {
      value: ProjectStatus.Reactive,
      text: t("reactive"),
    },
    {
      value: ProjectStatus.Inactive,
      text: t("inactive"),
    },
  ];

  return [
    {
      title: <span data-testid="dashboard-rating-column">{t("rating")}</span>,
      dataIndex: "rating",
      key: "rating",
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams?.rating,
      ),
      sorter: true,
      render: (_, leaderData) => {
        return <span data-cy="dashboard-rating">{leaderData.rating}</span>;
      },
    },
    {
      title: (
        <span data-testid="dashboard-project-title-column">
          {t("projectTitle")}
        </span>
      ),
      dataIndex: "projectTitle",
      key: "projectTitle",
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams?.projectTitle,
      ),
      sorter: true,
      render: (_, leaderData) => {
        return (
          <span data-cy="dashboard-training-title">
            {leaderData.projectInfo.name}
          </span>
        );
      },
    },
    {
      title: t("businessUnit"),
      dataIndex: "businessUnit",
      key: "businessUnit",
      filteredValue: filterParams?.businessUnitFilter,
      filters: businessUnitsFilterOptions,
      filterIcon: () => {
        return <FilterFilled data-testid="dashboard-business-unit-filter" />;
      },
      render: (_, leaderData) => {
        return (
          <span data-cy="dashboard-business-unit">
            {leaderData.projectInfo.businessUnit}
          </span>
        );
      },
    },
    {
      title: t("projectManager"),
      dataIndex: "projectManager",
      key: "projectManager",
      render: (_, leaderData) => {
        const managerNames = leaderData.projectInfo.managerList.map(
          (manager) => {
            return manager.left || manager.right?.displayName;
          },
        );
        return managerNames.join(", ");
      },
    },
    {
      title: (
        <span data-testid="dashboard-end-date-column">{t("endDate")}</span>
      ),
      dataIndex: "endDate",
      key: "endDate",
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams?.endDate,
      ),
      sorter: true,
      render: (_, leaderData) => {
        return (
          <span data-cy="dashboard-end-date">
            {format(
              new Date(leaderData.projectInfo.endDate || 0),
              config.DATE_WITH_DOTS,
            )}
          </span>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      filteredValue: filterParams?.statusFilter,
      filters: statusFilterOptions,
      filterIcon: () => {
        return <FilterFilled data-testid="dashboard-status-filter" />;
      },
      render: (_, leaderData) => {
        return (
          <span data-cy="dashboard-status">
            <LeaderboardStatusCell leaderData={leaderData} />
          </span>
        );
      },
    },
  ];
};
