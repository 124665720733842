import { Input } from "@common/components";
import { SetNamePropTypes } from "@task/types";
import { TaskInfo } from "graphqlTypes";
import { FC, ChangeEvent, useState, Dispatch, SetStateAction } from "react";

import { schema } from "./schema";

type PropTypes = {
  taskData: TaskInfo;
  setName: ({ name, taskId }: SetNamePropTypes) => void;
  setIsError: Dispatch<SetStateAction<boolean>>;
};

const TaskNameCell: FC<PropTypes> = ({ taskData, setName, setIsError }) => {
  const [isNameValid, setIsNameValid] = useState(true);
  const onNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const taskName = event.target.value;
    const isNameValid = await schema.isValid({ taskName });
    setIsError(!isNameValid);
    setIsNameValid(isNameValid);
    setName({ taskId: taskData.id, name: taskName });
  };

  return (
    <Input
      defaultValue={taskData.name}
      onChange={onNameChange}
      status={!isNameValid ? "error" : undefined}
    />
  );
};

export default TaskNameCell;
