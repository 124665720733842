import { BackButton } from "@common/components";
import UserCertificatesTable from "@training/components/UserCertificatesTable";
import { Row, Col } from "antd";
import { FC } from "react";

const UserCertificatesManagement: FC = () => {
  return (
    <Row gutter={[0, 24]} justify="center">
      <Col span={23}>
        <BackButton />
      </Col>
      <Col span={23}>
        <UserCertificatesTable />
      </Col>
    </Row>
  );
};

export default UserCertificatesManagement;
