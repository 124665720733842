import { Icon } from "@common/types";
import { Row, Col } from "antd";
import classNames from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

type PropTypes = {
  title: string;
  path: string;
  icon: Icon;
  isSelected: boolean;
};

const SideBarMenuItem: FC<PropTypes> = ({ path, title, icon, isSelected }) => {
  const titleClassName = classNames(styles.title, {
    [styles.selectedItem]: isSelected,
  });

  return (
    <Link to={path} className={styles.container}>
      <Row gutter={[12, 0]} className={titleClassName}>
        <Col>{icon}</Col>
        <Col>{title}</Col>
      </Row>
    </Link>
  );
};

export default SideBarMenuItem;
