import { UploadOutlined } from "@ant-design/icons";
import {
  ControlledInput,
  ControlledInputNumber,
  ControlledUpload,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import fileServices from "@common/services/fileServices";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, message, Row } from "antd";
import { UploadFile } from "antd/lib/upload";
import config from "config";
import { useAddEducationInfoMutation, UserInfo } from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddEducationFormType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Profile,
  "components",
  "UploadEducationForm",
);

type PropTypes = {
  userData: UserInfo | undefined;
};

const UploadEducationForm: FC<PropTypes> = ({ userData }) => {
  const { hideModal } = useModal();
  const { t } = useTranslation("profile.UploadEducationForm");
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm<AddEducationFormType>({ resolver: yupResolver(schema) });

  const files = getValues("fileData.fileList");

  const [uploadEducationMutation, uploadEducationMutationStatus] =
    useAddEducationInfoMutation({
      client: apolloProvider.apolloUploadClient,
    });

  const onSubmit = (data: AddEducationFormType) => {
    const diplomaFile = data.fileData.fileList?.[0];
    uploadEducationMutation({
      variables: {
        userId: userData?.user?.id,
        universityName: data.universityName,
        fieldOfStudy: data.study,
        year: data.year,
        diploma: diplomaFile?.originFileObj,
      },
      onCompleted: (data) => {
        message.success(t("diplomaUploaded"));
        reset();
        logsServices.logInformation({
          location: ["onSubmit", "uploadEducationMutation", "onCompleted"],
          message: `Diploma (id=${data.addEducationInfo?.id}) was uploaded.`,
        });
        apolloProvider.apolloApiClient.refetchQueries({
          include: ["getProfileInfo", "getUserInfo"],
        });
        hideModal();
      },
    });
  };

  const validateFileBeforeUpload = (file: UploadFile) => {
    return fileServices.validateFileBeforeUpload(
      file,
      config.EDUCATION_FILE_TYPES,
      {
        sizeErrorCallback: () => {
          message.error(
            t("fileSizeError", {
              fileSize: config.FILE_SIZE,
            }),
          );
        },
        typeErrorCallback: () => {
          message.error(
            t("fileTypeError", {
              fileTypes: config.EDUCATION_FILE_TYPES.join(", "),
            }),
          );
        },
      },
    );
  };

  return (
    <Form>
      <Row gutter={[0, 24]} align="middle">
        <Col span={24} className={styles.title}>
          {t("uploadDiploma")}
        </Col>
        <Col span={8}>{t("userName")}:</Col>
        <Col span={16}>{userData?.graphProfile?.displayName}</Col>
        <Col span={8}>{t("universityName")}:</Col>
        <Col span={16}>
          <ControlledInput
            useControllerProps={{
              control,
              name: "universityName",
            }}
            inputProps={{
              "data-testid": "university-name",
              status: errors.universityName && "error",
            }}
          />
        </Col>
        <Col span={8}>{t("fieldOfStudy")}:</Col>
        <Col span={16}>
          <ControlledInput
            useControllerProps={{ control, name: "study" }}
            inputProps={{
              "data-testid": "study",
              status: errors.study && "error",
            }}
          />
        </Col>
        <Col span={8}>{t("year")}:</Col>
        <Col span={16}>
          <ControlledInputNumber
            useControllerProps={{ control, name: "year" }}
            inputNumberProps={{
              "data-testid": "year",
              className: styles.yearInput,
              min: config.MIN_YEAR,
              max: config.MAX_YEAR,
              status: errors.year && "error",
            }}
          />
        </Col>
        <Col span={8}>{t("diploma")}:</Col>
        <Col span={16}>
          <ControlledUpload
            useControllerProps={{ control, name: "fileData" }}
            uploadProps={{
              "data-testid": "upload",
              multiple: false,
              beforeUpload: validateFileBeforeUpload,
              maxCount: 1,
              fileList: files,
              children: (
                <Button danger={!!errors.fileData} icon={<UploadOutlined />}>
                  {t("upload")}
                </Button>
              ),
            }}
          />
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                loading={uploadEducationMutationStatus.loading}
                onClick={handleSubmit(onSubmit)}
                data-testid="submit"
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UploadEducationForm;
