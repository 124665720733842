import { Spin } from "@common/components";
import ManageEditableQuestionList from "@help/components/ManageEditableQuestionList";
import ManageQuestionList from "@help/components/ManageQuestionList";
import config from "config";
import { Role, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";

const manageQuestionListByRole = {
  [Role.SuperAdmin]: ManageEditableQuestionList,
  [Role.Admin]: ManageEditableQuestionList,
  [Role.Manager]: ManageQuestionList,
  [Role.User]: ManageQuestionList,
};

const Help: FC = () => {
  const userQuery = useGetUserQuery();
  const role = userQuery.data?.getUser?.role;

  const ManageQuestions = manageQuestionListByRole[role || config.DEFAULT_ROLE];

  return (
    <Spin spinning={userQuery.loading}>
      <ManageQuestions />
    </Spin>
  );
};

export default Help;
