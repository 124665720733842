import { DeleteOutlined, FileOutlined } from "@ant-design/icons";
import { Card, Popconfirm } from "@common/components";
import StatusCell from "@common/components/StatusCell";
import loadingService from "@common/services/loadingServices/index";
import { Row, Col } from "antd";
import config from "config";
import { format } from "date-fns";
import {
  Need,
  CertificateInfo,
  useDeleteCustomCertificateMutation,
  useDeleteTrainingCertificateMutation,
} from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  certificate: CertificateInfo;
  userId?: string;
};

const TrainingCardActiveWithFileLink: FC<PropTypes> = ({
  certificate,
  userId,
}) => {
  const { t } = useTranslation("training.TrainingCardActiveWithFileLink");

  const [deleteTrainingCertificate, deleteCertificateMutationStatus] =
    useDeleteTrainingCertificateMutation();
  const [deleteCustomCertificate, deleteCustomCertificateMutationStatus] =
    useDeleteCustomCertificateMutation();

  const deleteCertificate = () => {
    const variables = {
      userId,
      certificateId: certificate.certificateId,
    };
    const refetchQueries = [
      "getProfileInfo",
      "getTrainingMatrix",
      "getUserInfo",
    ];

    if (certificate.need === Need.Custom) {
      return deleteCustomCertificate({
        variables,
        refetchQueries,
      });
    }
    deleteTrainingCertificate({
      variables,
      refetchQueries,
    });
  };

  const translatedNeed = {
    [Need.JobDepended]: t("done"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const isLoading = loadingService.checkLoading([
    deleteCustomCertificateMutationStatus.loading,
    deleteCertificateMutationStatus.loading,
  ]);

  return (
    <Card loading={isLoading}>
      <Row justify="space-between">
        <Col span={20}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("training")}:</Col>
                <Col>{certificate.name}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("completed")}:</Col>
                <Col>
                  {certificate.completedAt
                    ? format(
                        new Date(certificate.completedAt),
                        config.DATE_WITH_DOTS,
                      )
                    : null}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("expiry")}:</Col>
                <Col>
                  {certificate.dueTo
                    ? format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)
                    : null}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("need")}:</Col>
                <Col>{translatedNeed[certificate.need]}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("status")}:</Col>
                <Col>
                  <StatusCell status={certificate.status} />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("viewRecords")}:</Col>
                {certificate.certificateLink && (
                  <Col>
                    <a
                      target="_blank"
                      href={certificate.certificateLink}
                      rel="noreferrer"
                    >
                      <FileOutlined />
                    </a>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Popconfirm
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={deleteCertificate}
            title={t("deleteConfirmation")}
          >
            <DeleteOutlined className={styles.deleteIcon} />
          </Popconfirm>
        </Col>
      </Row>
    </Card>
  );
};

export default TrainingCardActiveWithFileLink;
