import { Status } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const colorByStatus = {
  [Status.Archived]: styles.archivedStatus,
  [Status.Done]: styles.positiveStatus,
  [Status.Expired]: styles.negativeStatus,
  [Status.Required]: styles.negativeStatus,
};

type PropTypes = {
  status: Status;
};

const StatusCell: FC<PropTypes> = ({ status }) => {
  const { t } = useTranslation("common.StatusCell");

  const translatedStatus = {
    [Status.Done]: t("done"),
    [Status.Expired]: t("expired"),
    [Status.Required]: t("required"),
    [Status.Archived]: t("archived"),
  };

  return (
    <span className={colorByStatus[status]}>{translatedStatus[status]}</span>
  );
};

export default StatusCell;
