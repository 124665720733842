import {
  EditOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Input } from "@common/components";
import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useModal } from "@common/hooks/useModal";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices/index";
import LogsServices from "@common/services/logsServices";
import CreateTaskForm from "@task/components/CreateTaskForm";
import EditTasksTable from "@task/components/EditTasksTable";
import TasksTable from "@task/components/TasksTable";
import { TaskListSorterType } from "@task/types";
import { Col, Row } from "antd";
import config from "config";
import {
  TaskInfo,
  useGetBusinessUnitListQuery,
  useGetUserQuery,
  useListTaskQuery,
} from "graphqlTypes";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

const logsServices = new LogsServices(
  ModuleNameEnum.Task,
  "components",
  "ManageTasks",
);

const ManageTasks: FC = () => {
  const [isEditTable, setIsEditTable] = useState(false);
  const { t } = useTranslation("task.ManageTasks");
  const { setModalProps, hideModal } = useModal();
  const { getSearchParam, addSearchParams } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const [sorters, setSorters] = useState<TaskListSorterType>({});
  const debouncedSearchText = useDebounce(searchText);
  const businessUnitsQuery = useGetBusinessUnitListQuery();
  const userQuery = useGetUserQuery();
  const [editedTaskList, setEditedTaskList] = useState<TaskInfo[]>([]);

  const businessUnits = businessUnitsQuery.data?.getBusinessUnitList;

  const isExcelExport =
    userQuery.data?.getUser?.role &&
    config.ROLES_WITH_EXCEL_EXPORT.includes(userQuery.data.getUser.role);

  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;

  const listTasksQuery = useListTaskQuery({
    variables: {
      search: debouncedSearchText || "",
      limit: config.TABLE_LIMIT,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
      titleSort: sorters.tasksName,
      businessUnitFilter: sorters.businessUnitFilter || [],
    },
  });

  const taskList = listTasksQuery.data?.listTask?.taskInfo?.data?.map(
    (task) => {
      return { ...task, key: task.id };
    },
  );
  const totalPageCount = listTasksQuery.data?.listTask?.taskInfo?.total;

  const tableLoading = loadingService.checkLoading([
    listTasksQuery.loading,
    businessUnitsQuery.loading,
  ]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });

    logsServices.logVerbose({
      location: ["onSearchChange"],
      message: `Tasks search: ${searchText}`,
    });
  };

  const onCreateTaskClick = () => {
    setModalProps({
      onCancel: hideModal,
      open: true,
      footer: false,
      children: <CreateTaskForm />,
    });
  };

  const onEditClick = () => {
    setIsEditTable(true);
  };

  const onPaginationChange = (page: number) => {
    addSearchParams({ page: page.toString() });
  };

  return (
    <Row gutter={[0, 24]} justify="space-around">
      <Col span={23}>
        <Input
          data-testid="search-task"
          value={searchText || ""}
          onChange={onSearchChange}
          placeholder={t("searchPlaceholder")}
        />
      </Col>
      <Col span={23}>
        {isEditTable ? (
          <EditTasksTable
            businessUnits={businessUnits || []}
            key={taskList?.[0]?.id}
            onPaginationChange={onPaginationChange}
            currentPage={currentPage}
            setIsEditTable={setIsEditTable}
            loading={tableLoading}
            totalPageCount={totalPageCount || 1}
            taskList={taskList || []}
            setSorters={setSorters}
            sorters={sorters}
            setEditedTaskList={setEditedTaskList}
            editedTaskList={editedTaskList}
          />
        ) : (
          <Row align="middle" gutter={[12, 24]}>
            <Col>
              <Button
                data-testid="create-task"
                icon={<PlusOutlined />}
                type="primary"
                onClick={onCreateTaskClick}
              >
                {t("createNewTask")}
              </Button>
            </Col>
            <Col>
              <Button icon={<EditOutlined />} type="link" onClick={onEditClick}>
                {t("edit")}
              </Button>
            </Col>
            <Col>
              {isExcelExport && (
                <Button
                  disabled={
                    !listTasksQuery.data?.listTask?.taskInfo?.data?.length
                  }
                  href={listTasksQuery.data?.listTask?.exportUrl}
                  rel="noreferrer"
                  target="_blank"
                  icon={<FileExcelOutlined />}
                  type="dashed"
                >
                  {t("exportExcel")}
                </Button>
              )}
            </Col>
            <Col span={24}>
              <TasksTable
                businessUnits={businessUnits || []}
                sorters={sorters}
                onPaginationChange={onPaginationChange}
                currentPage={currentPage}
                loading={tableLoading}
                totalPageCount={totalPageCount || 1}
                taskList={taskList || []}
                setSorters={setSorters}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ManageTasks;
