import { Table } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices";
import sortConverterServices from "@common/services/sortConverterServices";
import { MyTrainingMatrixFilterType } from "@training/types";
import { TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import {
  CertificateInfo,
  Need,
  Status,
  TrainingMatrixInfo,
  useDeleteCustomCertificateMutation,
  useDeleteTrainingCertificateMutation,
  UserInfo,
} from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";

import { getColumns } from "./columns";

type PropsTypes = {
  trainingMatrixInfoList: TrainingMatrixInfo[];
  totalPageCount?: number;
  currentPage: number;
  setFilterParams: Dispatch<SetStateAction<MyTrainingMatrixFilterType>>;
  onPageChange: (page: number) => void;
  userData?: UserInfo;
  filterParams: MyTrainingMatrixFilterType;
};

const MyTrainingMatrixTable: FC<PropsTypes> = ({
  trainingMatrixInfoList,
  totalPageCount,
  currentPage,
  setFilterParams,
  onPageChange,
  userData,
  filterParams,
}: PropsTypes) => {
  const { setModalProps, hideModal } = useModal();
  const [deleteTrainingCertificate, deleteCertificateMutationStatus] =
    useDeleteTrainingCertificateMutation();
  const [deleteCustomCertificate, deleteCustomCertificateMutationStatus] =
    useDeleteCustomCertificateMutation();

  const deleteCertificate = (certificate: CertificateInfo) => {
    const variables = {
      userId: userData?.user?.id,
      certificateId: certificate.certificateId,
    };
    const refetchQueries = [
      "getProfileInfo",
      "getTrainingMatrix",
      "getUserInfo",
    ];

    if (certificate.need === Need.Custom) {
      return deleteCustomCertificate({
        variables,
        refetchQueries,
      });
    }
    deleteTrainingCertificate({
      variables,
      refetchQueries,
    });
  };

  const trainingList = trainingMatrixInfoList.map((trainingInfo) => {
    return {
      key: `${trainingInfo.certificate.certificateId}:${trainingInfo.certificate.trainingId}`,
      ...trainingInfo,
    };
  });

  const columns = getColumns({
    hideModal,
    setModalProps,
    userData,
    deleteCertificate,
    filterParams,
  });

  const onTableChange: TableProps<TrainingMatrixInfo>["onChange"] = (
    _pagination,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<TrainingMatrixInfo>;
    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setFilterParams({
      ...sorterFields,
      needs: (filters.need as Need[]) || [],
      statuses: (filters.status as Status[]) || [],
    });
  };

  const isLoading = loadingService.checkLoading([
    deleteCertificateMutationStatus.loading,
    deleteCustomCertificateMutationStatus.loading,
  ]);

  return (
    <Table
      data-testid="my-training-matrix-table"
      loading={isLoading}
      onChange={onTableChange}
      columns={columns}
      dataSource={trainingList}
      pagination={{
        current: currentPage,
        total: totalPageCount,
        onChange: onPageChange,
        showSizeChanger: false,
      }}
    />
  );
};

export default MyTrainingMatrixTable;
