import { Spin } from "@common/components";
import SideBarFooter from "@common/components/MainLayout/SideBarFooter";
import SideBarHeader from "@common/components/MainLayout/SideBarHeader";
import SideBarMenu from "@common/components/MainLayout/SideBarMenu";
import { Col, Row } from "antd";
import config from "config";
import { useGetProfileInfoQuery } from "graphqlTypes";
import { FC } from "react";

const SideBar: FC = () => {
  const getUserProfileQuery = useGetProfileInfoQuery();
  const profileData = getUserProfileQuery.data?.getProfileInfo;

  return (
    <Spin spinning={getUserProfileQuery.loading}>
      <Row gutter={[0, 26]} align="middle" justify="center">
        {profileData?.graphProfile?.displayName && (
          <Col span={18}>
            <SideBarHeader userName={profileData.graphProfile.displayName} />
          </Col>
        )}
        <Col span={18}>
          <SideBarMenu
            userRole={profileData?.user?.role || config.DEFAULT_ROLE}
          />
        </Col>
        <Col span={18}>
          <SideBarFooter />
        </Col>
      </Row>
    </Spin>
  );
};

export default SideBar;
