import { Locale, LowLevelLocales } from "@common/types";

import en from "./en";

const mapLocales = (locale: Locale) =>
  Object.keys(locale).reduce((locales: LowLevelLocales, localeKey) => {
    const lowLevelLocale = locale[localeKey];
    const lowLevelFlatLocales = Object.keys(lowLevelLocale).reduce(
      (lowLevelFlatLocales: LowLevelLocales, lowLevelLocaleKey) => ({
        ...lowLevelFlatLocales,
        [`${localeKey}.${lowLevelLocaleKey}`]:
          lowLevelLocale[lowLevelLocaleKey],
      }),
      {},
    );
    return { ...locales, ...lowLevelFlatLocales };
  }, {});

const locales = {
  en: mapLocales(en),
};

export default locales;
