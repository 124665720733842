import fileServices from "@common/services/fileServices";
import { UploadedFileType } from "@common/types";
import { UploadFile } from "antd";
import config from "config";
import * as yup from "yup";

export type AddCertificateFormType = {
  stringifiedTraining: string;
  completedDate: Date;
  expirationDate?: Date;
  fileData: UploadedFileType;
};

export const schema: yup.SchemaOf<AddCertificateFormType> = yup.object({
  stringifiedTraining: yup.string().required(),
  completedDate: yup.date().required(),
  expirationDate: yup.date().min(yup.ref("completedDate")),
  fileData: yup
    .object({
      file: yup.mixed<UploadFile>(),
      fileList: yup.array<UploadFile>().max(1),
    })
    .test("fileError", "File Error", (attachment) => {
      if ((attachment.fileList?.length || 0) < 1) {
        return false;
      }

      const isError = fileServices.validateAttachmentFile(
        attachment,
        config.HELP_FILE_TYPES,
      );
      return !isError;
    })
    .required(),
});
