import { UserOutlined } from "@ant-design/icons";
import { Image, Tooltip } from "@common/components";
import { ProfilePhotoContext } from "@common/contexts/ProfileImageContext";
import NotificationIconWithCount from "@notification/components/NotificationIconWithCount";
import { Row, Col } from "antd";
import buildcorpLogoWhite from "assets/images/BuildcorpLogoWhite.svg";
import { FC, useContext } from "react";

import styles from "./styles.module.scss";

type PropTypes = {
  userName: string;
};

const SideBarHeader: FC<PropTypes> = ({ userName }) => {
  const { profileImage } = useContext(ProfilePhotoContext);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Image
          className={styles.logo}
          preview={false}
          src={buildcorpLogoWhite}
        />
      </Col>
      <Col span={24}>
        <Row
          justify="space-between"
          align="middle"
          className={styles.container}
          wrap={false}
          gutter={[12, 0]}
        >
          <Col>
            {profileImage ? (
              <Image
                preview={false}
                className={styles.avatar}
                src={profileImage}
              />
            ) : (
              <UserOutlined className={styles.defaultUserIcon} />
            )}
          </Col>
          <Col className={styles.name}>
            <Tooltip title={userName}>{userName}</Tooltip>
          </Col>
          <Col>
            <NotificationIconWithCount />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SideBarHeader;
