import { ExportOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import RoutePaths from "config/routePaths";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const SideBarFooter: FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const onExitClick = async () => {
    await instance.logoutPopup();
    navigate(RoutePaths.Profile);
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <Button
        onClick={onExitClick}
        className={styles.logoutButton}
        type="link"
        icon={<ExportOutlined />}
      />
    </div>
  );
};

export default SideBarFooter;
