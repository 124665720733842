import { TableProps, Table as TableANTD } from "antd";
import { ColumnsType } from "antd/lib/table/interface";

const Table = <T extends object>(props: TableProps<T>): JSX.Element => {
  const { columns, ...restProps } = props;
  const centredColumns: ColumnsType<T> | undefined = columns?.map((column) => {
    if (Object.keys(column).length) {
      return { ...column, align: "center" };
    }
    return column;
  });
  return <TableANTD bordered columns={centredColumns} {...restProps} />;
};

export default Table;
