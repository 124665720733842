import HeaderUserData from "@profile/components/HeaderUserData";
import UserProfileAvatar from "@profile/components/UserProfileAvatar";
import { Col, Row } from "antd";
import { UserInfo } from "graphqlTypes";
import { FC } from "react";

type PropTypes = {
  profileData: UserInfo | null | undefined;
};

const UserProfileHeader: FC<PropTypes> = ({ profileData }) => {
  return (
    <Row align="middle" wrap={false} justify="start" gutter={[24, 24]}>
      <Col>
        <UserProfileAvatar />
      </Col>
      <Col>
        <HeaderUserData profileData={profileData} />
      </Col>
    </Row>
  );
};

export default UserProfileHeader;
