import { ModuleType, Route } from "@common/types";
import { FC } from "react";

import auth from "./auth";
import common from "./common";
import dashboard from "./dashboard";
import help from "./help";
import notification from "./notification";
import profile from "./profile";
import project from "./project";
import task from "./task";
import training from "./training";
import user from "./user";

const modules: ModuleType[] = [
  auth,
  profile,
  common,
  user,
  project,
  training,
  task,
  help,
  dashboard,
  notification,
];

const modulesData = modules.reduce(
  (
    modulesData: {
      routes: Route[];
      contextProviders: FC<{ children: JSX.Element | JSX.Element[] }>[];
    },
    module,
  ) => {
    return {
      contextProviders: [
        ...modulesData.contextProviders,
        ...module.contextProviders,
      ],
      routes: [...modulesData.routes, ...module.routes],
    };
  },
  { routes: [], contextProviders: [] },
);

export default modulesData;
