import InputNumber from "@common/components/InputNumber";
import { ElementMetadataType } from "@common/types";
import { InputNumberProps } from "antd";
import { useController, UseControllerProps } from "react-hook-form";

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  inputNumberProps?: InputNumberProps & ElementMetadataType;
};

const ControlledInputNumber = <T extends object>({
  useControllerProps,
  inputNumberProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, onBlur, name, value },
  } = useController(useControllerProps);

  return (
    <InputNumber
      {...inputNumberProps}
      onBlur={onBlur}
      value={value}
      name={name}
      onChange={(changedValue) => {
        // value could be undefined
        // eslint-disable-next-line strict-null-checks/all
        onChange(changedValue || undefined);
        inputNumberProps?.onChange && inputNumberProps?.onChange(changedValue);
      }}
    />
  );
};

export default ControlledInputNumber;
