import { Button, FilterItemsForm, Spin } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import { FilterOptionType } from "@common/types";
import { Col, Row } from "antd";
import { useListTrainingWithoutPaginationQuery } from "graphqlTypes";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  setFilterParams: Dispatch<SetStateAction<string[]>>;
};

const CandidateFilterForm: FC<PropTypes> = ({ setFilterParams }) => {
  const { t } = useTranslation("project.CandidateFilterForm");
  const { hideModal } = useModal();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const tasklistQuery = useListTrainingWithoutPaginationQuery();

  const listTask = tasklistQuery.data?.listTrainingWithoutPagination;

  const options: FilterOptionType[] | undefined = listTask?.map((task) => {
    return { title: task.name, id: task.id };
  });

  const onSubmitClick = () => {
    setFilterParams(selectedOptions);
    hideModal();
  };

  return (
    <Spin spinning={tasklistQuery.loading}>
      <Row gutter={[0, 24]} justify="end">
        <Col span={24} className={styles.trainingList}>
          <FilterItemsForm
            selectedOptions={selectedOptions}
            items={options}
            setSelectedOptions={setSelectedOptions}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={onSubmitClick}>
            {t("submit")}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default CandidateFilterForm;
