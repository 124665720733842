import { gql } from "@apollo/client";
import { GRAPH_USER_FRAGMENT } from "@profile/graphql/fragments/graphUserFragment";

export const TRAINING_REQUEST_INFO_FRAGMENT = gql`
  ${GRAPH_USER_FRAGMENT}
  fragment TrainingRequestInfoNode on TrainingRequestInfo {
    id
    trainingName
    trainingId
    user {
      ...GraphUserNode
    }
    createdBy {
      ...GraphUserNode
    }
    comment
    createdAt
    updatedAt
  }
`;
