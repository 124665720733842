import { Row, Col } from "antd";
import { TrainingWithTasks, Type } from "graphqlTypes";
import i18n from "providers/i18n";

import styles from "./styles.module.scss";

const ns = "training.TrainingsTable";

class TrainingExpandServices {
  renderExpandableRow = ({
    trainingWithTasks,
  }: {
    trainingWithTasks: TrainingWithTasks;
  }): JSX.Element[] => {
    const trainingTypeLocale = {
      [Type.External]: i18n.t("external", { ns }),
      [Type.InHouse]: i18n.t("inHouse", { ns }),
      [Type.Rto]: i18n.t("RTO", { ns }),
    };

    const trainingManagers =
      trainingWithTasks.training.mandatoryPositions.join(", ");
    const tasksNames = trainingWithTasks.tasks.map((task) => {
      return task.name;
    });

    const trainingDataRows = [
      { locale: "positions", value: trainingManagers },
      {
        locale: "nationalUnitOfCompetency",
        value: trainingWithTasks.training.unitOfCompetency,
      },
      {
        locale: "trainingType",
        value: trainingWithTasks.training.type
          ? trainingTypeLocale[trainingWithTasks.training.type]
          : null,
      },
      {
        locale: "recurring",
        value: trainingWithTasks.training.recurring
          ? `${trainingWithTasks.training.recurring} ${i18n.t("months", {
              ns,
            })}`
          : null,
      },
      { locale: "description", value: trainingWithTasks.training.description },
      { locale: "tasks", value: tasksNames.join(", ") },
    ];

    const filteredRows = trainingDataRows.filter((rowData) => {
      return rowData.value;
    });

    return filteredRows.map((rowData) => {
      return (
        <Row wrap={false} key={rowData.locale} gutter={[6, 0]}>
          <Col className={styles.expandRowName}>
            {i18n.t(rowData.locale, { ns }).toString()}:
          </Col>
          <Col className={styles.expandRowData}>{rowData.value}</Col>
        </Row>
      );
    });
  };

  checkIsRowExpandable = (trainingWithTasks: TrainingWithTasks) => {
    const fieldsData = [
      trainingWithTasks.training.mandatoryPositions.length,
      trainingWithTasks.training.unitOfCompetency,
      trainingWithTasks.training.type,
      trainingWithTasks.training.recurring,
      trainingWithTasks.training.description,
      trainingWithTasks.tasks.length,
    ];

    return fieldsData.some((data) => {
      return !!data;
    });
  };
}

const trainingExpandServices = new TrainingExpandServices();

export default trainingExpandServices;
