import { Table } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useSearchParams } from "@common/hooks/useSearchParams";
import ExpandedProjectsList from "@user/components/ExpandedProjectsList";
import { UserTableFiltersType } from "@user/types";
import { TableProps } from "antd";
import config from "config";
import {
  AccountStatus,
  Role,
  useGetDepartmentListQuery,
  useGetProfileInfoQuery,
  useListUserQuery,
  UserInfo,
} from "graphqlTypes";
import { FC, useEffect, useState } from "react";

import { getColumns } from "./columns";

const renderExpandedRow = (userData: UserInfo): JSX.Element => {
  return (
    <ExpandedProjectsList
      projects={userData.projects || []}
      currentProjects={userData.currentProjects || []}
    />
  );
};

type PropTypes = {
  searchText: string;
};

const UsersTable: FC<PropTypes> = ({ searchText }) => {
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchFilterParams = getSearchParam(SearchParamsEnum.FilterParams);
  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;
  const [filterParams, setFilterParams] = useState<UserTableFiltersType>(
    searchFilterParams && JSON.parse(searchFilterParams),
  );
  const userListQuery = useListUserQuery({
    variables: {
      search: searchText,
      limit: config.TABLE_LIMIT,
      roleFilter: filterParams?.roleFilter || undefined,
      businessUnitFilter: filterParams?.businessUnitFilter || undefined,
      profileTrainingStatusFilter:
        filterParams?.profileTrainingStatusFilter || undefined,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
    },
  });
  const businessUnitsQuery = useGetDepartmentListQuery();
  const currentUserQuery = useGetProfileInfoQuery();

  const currentUserRole =
    currentUserQuery.data?.getProfileInfo?.user?.role || config.DEFAULT_ROLE;

  const businessUnits = businessUnitsQuery.data?.getDepartmentList;

  const usersList = userListQuery.data?.listUser?.data?.map((userData) => {
    return { ...userData, key: userData.user?.id };
  });
  const totalPageCount = userListQuery.data?.listUser?.total;

  const columns = getColumns({
    currentUserRole,
    businessUnits: businessUnits || [],
    filterParams,
  });

  useEffect(() => {
    const searchFilterParams = getSearchParam(SearchParamsEnum.FilterParams);
    if (searchFilterParams) {
      addSearchParams(JSON.parse(searchFilterParams));
    }
  }, []);

  useEffect(() => {
    const stringifiedFilters = JSON.stringify(filterParams || {});
    addSearchParams({
      [SearchParamsEnum.FilterParams]: stringifiedFilters,
    });
  }, [filterParams]);

  const onPageChange = (page: number) => {
    addSearchParams({
      [SearchParamsEnum.Page]: page.toString(),
    });
  };

  const onTableChange: TableProps<UserInfo>["onChange"] = (
    _pagination,
    filters,
  ) => {
    setFilterParams({
      roleFilter: filters.role as Role[],
      businessUnitFilter: filters.businessUnit as string[],
      profileTrainingStatusFilter:
        filters.profileTrainingStatus as AccountStatus[],
    });
  };

  return (
    <Table
      onChange={onTableChange}
      loading={userListQuery.loading}
      columns={columns}
      expandable={{
        rowExpandable: (userData: UserInfo) => {
          return !!userData.projects?.length;
        },
        expandedRowRender: renderExpandedRow,
      }}
      dataSource={usersList}
      pagination={{
        total: totalPageCount,
        pageSize: config.TABLE_LIMIT,
        current: currentPage,
        onChange: onPageChange,
        showSizeChanger: false,
      }}
    />
  );
};

export default UsersTable;
