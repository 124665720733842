import { Table } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useSearchParams } from "@common/hooks/useSearchParams";
import config from "config";
import { useGetSuggestedCandidatesQuery } from "graphqlTypes";
import { FC } from "react";
import { useParams } from "react-router-dom";

import { getColumns } from "./columns";

type PropTypes = {
  searchText: string;
  taskIdList: string[];
};

const SuggestedCandidatesTable: FC<PropTypes> = ({
  searchText,
  taskIdList,
}) => {
  const columns = getColumns();
  const { projectId } = useParams();
  const { getSearchParam, addSearchParams } = useSearchParams();
  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;
  const debouncedSearch = useDebounce(searchText);

  const getProjectTeamQuery = useGetSuggestedCandidatesQuery({
    variables: {
      taskIdFilter: taskIdList,
      searchString: debouncedSearch,
      projectId: projectId as string,
      limit: config.TABLE_LIMIT,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
    },
  });

  const projectTeamUsers =
    getProjectTeamQuery.data?.getSuggestedCandidates?.data;
  const totalPagesCount =
    getProjectTeamQuery.data?.getSuggestedCandidates?.total;

  const tableData = projectTeamUsers?.map((projectTeamUser) => {
    return {
      key: projectTeamUser.graphUser.id,
      ...projectTeamUser,
    };
  });

  const onPageChange = (page: number) => {
    addSearchParams({
      page: page.toString(),
    });
  };

  return (
    <Table
      loading={getProjectTeamQuery.loading}
      columns={columns}
      dataSource={tableData}
      pagination={{
        total: totalPagesCount,
        current: currentPage,
        onChange: onPageChange,
        showSizeChanger: false,
      }}
    />
  );
};

export default SuggestedCandidatesTable;
