import StatusCell from "@common/components/StatusCell";
import { ColumnsType } from "antd/lib/table/interface";
import config from "config";
import { format } from "date-fns";
import { Need, CertificateInfo } from "graphqlTypes";
import i18n from "providers/i18n";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.TrainingsTableOverdue",
);

const translatedNeed = {
  [Need.JobDepended]: i18n.t("jobDependent", {
    ns: "training.TrainingsTableOverdue",
  }),
  [Need.Mandatory]: i18n.t("mandatory", {
    ns: "training.TrainingsTableOverdue",
  }),
  [Need.Custom]: i18n.t("custom", {
    ns: "training.TrainingsTableOverdue",
  }),
};

export const getColumns = (): ColumnsType<CertificateInfo> => {
  return [
    {
      title: t("trainingName"),
      dataIndex: "trainingName",
      key: "trainingName",
      render: (_, certificate) => {
        return certificate.name;
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (_, certificate) => {
        return certificate.assignedAt
          ? format(new Date(certificate.assignedAt), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("expiry"),
      dataIndex: "expiry",
      key: "expiry",
      render: (_, certificate) => {
        return certificate.dueTo
          ? format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("need"),
      dataIndex: "need",
      key: "need",
      render: (_, certificate) => {
        return translatedNeed[certificate.need];
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (_, certificate) => {
        return <StatusCell status={certificate.status} />;
      },
    },
  ];
};
