export default {
  LeaderboardInfo: {
    searchPlaceholder: "Search by project manager, project title...",
  },
  LeadersTable: {
    rating: "Rating",
    projectTitle: "Project Title",
    businessUnit: "Business unit",
    projectManager: "Project manager",
    endDate: "End date",
    status: "Status",
    active: "Active",
    reactive: "Reactive",
    inactive: "Inactive",
  },
  LeaderboardStatusCell: {
    active: "Active",
    reactive: "Reactive",
    inactive: "Inactive",
  },
  ManageAwaitingCommencementBoard: {
    searchPlaceholder: "Search by project manager, project title...",
  },
  AwaitingCommencementTable: {
    index: "Index",
    projectTitle: "Project Title",
    businessUnit: "Business unit",
    projectManager: "Project manager",
    endDate: "End date",
    status: "Status",
    active: "Active",
    reactive: "Reactive",
    inactive: "Inactive",
  },
  ReactiveProjectInfoWithLink: {
    trainingsMissing: "Training missing ({{missingTrainingsCount}}):",
  },
  ReactiveProjectInfo: {
    trainingsMissing: "Training missing ({{missingTrainingsCount}}):",
  },
  ActiveProjectInfo: {
    trainingsDone: "Done trainings ({{doneTrainingsCount}}):",
  },
  ActiveProjectInfoWithLink: {
    trainingsDone: "Done trainings ({{doneTrainingsCount}}):",
  },
  ManageDashboard: {
    beingConstructed: "Being constructed",
    awaitingCommencement: "Awaiting commencement",
  },
};
