import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Spin } from "@common/components";
import { ScreenContext } from "@common/contexts/ScreenContext";
import { SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useModal } from "@common/hooks/useModal";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices";
import MyTrainingMatrixCardList from "@training/components/MyTrainingMatrixCardList";
import MyTrainingMatrixFilterForm from "@training/components/MyTrainingMatrixFilterForm";
import MyTrainingMatrixTable from "@training/components/MyTrainingMatrixTable";
import RequestTrainingForm from "@training/components/RequestTrainingForm";
import UploadCertificateForm from "@training/components/UploadCertificateForm";
import { MyTrainingMatrixFilterType } from "@training/types";
import { Col, Row } from "antd";
import config from "config";
import features from "config/features";
import {
  useGetProfileInfoQuery,
  useGetTrainingMatrixQuery,
} from "graphqlTypes";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const MyTrainingMatrixManagement: FC = () => {
  const { t } = useTranslation("training.MyTrainingMatrixManagement");
  const { setModalProps, hideModal } = useModal();
  const { getSearchParam, addSearchParams } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncesSearchText = useDebounce(searchText);
  const userProfileQuery = useGetProfileInfoQuery();
  const userData = userProfileQuery.data?.getProfileInfo;
  const { isPhoneScreenSize } = useContext(ScreenContext);
  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;
  const [filterParams, setFilterParams] = useState<MyTrainingMatrixFilterType>({
    needs: [],
    statuses: [],
  });
  const getTrainingMatrixQuery = useGetTrainingMatrixQuery({
    variables: {
      statusFilter: filterParams?.statuses,
      titleSort: filterParams?.trainingTitle,
      needFilter: filterParams.needs,
      search: debouncesSearchText || undefined,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
      limit: config.TABLE_LIMIT,
    },
  });
  const trainingMatrixInfoList =
    getTrainingMatrixQuery.data?.getTrainingMatrix?.data;
  const totalPageCount = getTrainingMatrixQuery.data?.getTrainingMatrix?.total;

  useEffect(() => {
    const filterProps = getSearchParam(SearchParamsEnum.FilterParams);
    if (filterProps) {
      setFilterParams(JSON.parse(filterProps));
    }
  }, []);

  useEffect(() => {
    addSearchParams({
      [SearchParamsEnum.FilterParams]: JSON.stringify(filterParams || {}),
    });
  }, [filterParams]);

  const onUploadCertificateClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: <UploadCertificateForm userData={userData || undefined} />,
      open: true,
    });
  };

  const onRequestCertificateClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: userData?.graphProfile && (
        <RequestTrainingForm userId={userData.user?.id} />
      ),
      open: true,
    });
  };

  const onFilterClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: (
        <MyTrainingMatrixFilterForm
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      ),
      open: true,
    });
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });
  };

  const onPageChange = (page: number) => {
    addSearchParams({ page: page.toString() });
  };

  const isLoading = loadingService.checkLoading([
    userProfileQuery.loading,
    getTrainingMatrixQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={23}>
          <Row>
            <Col flex="auto">
              <Input
                data-testid="my-training-search"
                value={searchText || ""}
                placeholder={t("searchPlaceholder")}
                onChange={onSearchChange}
              />
            </Col>
            {isPhoneScreenSize && (
              <Col>
                <Button
                  onClick={onFilterClick}
                  type="link"
                  icon={<FilterOutlined />}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col span={23}>
          <Row justify="space-between" align="middle">
            <Col>
              <Row>
                <Col>
                  <Button
                    onClick={onUploadCertificateClick}
                    type="link"
                    icon={<PlusOutlined />}
                  >
                    {t("upload")}
                  </Button>
                </Col>

                {features.request && (
                  <Col>
                    <Button
                      onClick={onRequestCertificateClick}
                      type="link"
                      icon={<PlusOutlined />}
                    >
                      {t("request")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          {isPhoneScreenSize ? (
            <MyTrainingMatrixCardList
              trainingMatrixInfoList={trainingMatrixInfoList || []}
              currentPage={currentPage}
              totalPageCount={totalPageCount}
              onPageChange={onPageChange}
            />
          ) : (
            <MyTrainingMatrixTable
              trainingMatrixInfoList={trainingMatrixInfoList || []}
              currentPage={currentPage}
              totalPageCount={totalPageCount}
              setFilterParams={setFilterParams}
              onPageChange={onPageChange}
              filterParams={filterParams}
              userData={userData || undefined}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default MyTrainingMatrixManagement;
