import { SeparatedUsersChartDataType } from "@project/types";
import {
  GapAnalysisUserInfo,
  AccountStatus,
  ProjectStatus,
  Status,
} from "graphqlTypes";

const negativeStatuses = [Status.Expired, Status.Required];

class ChartServices {
  getCertificatesCountByStatus = ({
    userData,
    fragmentStatus,
  }: {
    userData: GapAnalysisUserInfo;
    fragmentStatus: ProjectStatus.Active | ProjectStatus.Reactive;
  }): number => {
    return userData.taskTrainingInfoList.reduce(
      (certificatesCount, certificate) => {
        const isIncreasePositive =
          fragmentStatus === ProjectStatus.Active &&
          certificate.status === Status.Done;

        const isCertificateStatusNegative = negativeStatuses.some((status) => {
          return status === certificate.status;
        });

        const isIncreaseNegative =
          fragmentStatus === ProjectStatus.Reactive &&
          isCertificateStatusNegative;

        return isIncreasePositive || isIncreaseNegative
          ? certificatesCount + 1
          : certificatesCount;
      },
      0,
    );
  };

  separateUsersByStatus = (
    gapAnalysisUserInfoList: GapAnalysisUserInfo[],
  ): SeparatedUsersChartDataType => {
    return gapAnalysisUserInfoList.reduce(
      (
        gapAnalysisUserInfoByStatus: SeparatedUsersChartDataType,
        gapAnalysisUserInfo,
      ) => {
        if (gapAnalysisUserInfo.profileStatus === AccountStatus.Reactive) {
          return {
            ...gapAnalysisUserInfoByStatus,
            reactiveUsersData: [
              ...gapAnalysisUserInfoByStatus.reactiveUsersData,
              gapAnalysisUserInfo,
            ],
          };
        }

        if (gapAnalysisUserInfo.profileStatus === AccountStatus.Active) {
          return {
            ...gapAnalysisUserInfoByStatus,
            activeUsersData: [
              ...gapAnalysisUserInfoByStatus.activeUsersData,
              gapAnalysisUserInfo,
            ],
          };
        }

        return gapAnalysisUserInfoByStatus;
      },
      { activeUsersData: [], reactiveUsersData: [] },
    );
  };
}

const chartServices = new ChartServices();

export default chartServices;
