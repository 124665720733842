import modulesData from "modules";
import { FC } from "react";

const { contextProviders } = modulesData;

type PropTypes = {
  children: JSX.Element;
};

const ContextsProvider: FC<PropTypes> = ({ children }) => {
  const wrappedComponent = contextProviders.reduce(
    (wrappedComponent, Provider) => {
      return <Provider>{wrappedComponent}</Provider>;
    },
    children,
  );

  return wrappedComponent;
};

export default ContextsProvider;
