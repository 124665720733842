import { onError } from "@apollo/client/link/error";
import LogsServices from "@common/services/logsServices";
import { message } from "antd";
import { appInsights } from "providers/appInsight";

const logsServices = new LogsServices(
  "providers",
  "graphql",
  "link",
  "errorLink",
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const errorsMessages = graphQLErrors.map((error) => {
        return error.message;
      });

      message.error(errorsMessages);

      logsServices.logError({
        message: `operation name: ${
          operation.operationName
        } error: ${errorsMessages.join("; ")} variables: ${JSON.stringify(
          operation.variables,
        )}`,
        location: ["graphQLErrors"],
      });
      appInsights.flush();
      return forward(operation);
    }
    if (networkError) {
      logsServices.logError({
        message: `${networkError.message}`,
        location: ["networkErrors"],
      });
      appInsights.flush();
    }
  },
);

export default errorLink;
