export default {
  EditUser: {
    fullName: "Full Name",
    position: "Position",
    currentProjects: "Current projects",
    businessUnit: "Business unit",
    role: "Role",
    profileTrainingStatus: "Profile training status",
    save: "Save",
    userUpdated: "User updated",
    userDeactivated: "User was deactivated",
    cancel: "Cancel",
    noCurrentProjects: "This user doesn't have any current projects",
    deactivateUser: "Deactivate user",
    superAdmin: "Super Admin",
    admin: "Admin",
    management: "Management",
    projectTeam: "Project Team",
    selectRole: "Select Role",
  },
  FilterItemsForm: {
    roles: "Roles",
    supperAdmin: "Super Admin",
    admin: "Admin",
    management: "Management",
    projectTeam: "Project team",
  },
  ManageUsers: {
    searchPlaceholder: "Search by full name, position, projects...",
    managePermissions: "Manage permissions",
    editUser: "Edit user",
  },
  UsersTable: {
    fullName: "Full Name",
    role: "Role",
    position: "Position",
    currentProjects: "Current projects",
    businessUnit: "Business unit",
    profileTrainingStatus: "Profile training status",
    superAdmin: "Super Admin",
    admin: "Admin",
    management: "Management",
    projectTeam: "Project Team",
    active: "Active",
    proactive: "Proactive",
    reactive: "Reactive",
    inactive: "Inactive",
    deactivated: "Deactivated",
    userStatus: "User status",
  },
  ExpandedProjectsList: {
    current: "Current projects",
    all: "All projects",
    noCurrentProjects: "This user doesn't have any current projects",
  },
  UserProfileView: {
    education: "Education",
    addDiploma: "Add qualification",
    certificates: "Certificates",
    overdue: "Overdue",
    active: "Active",
  },
};
