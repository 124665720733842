import { Table } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices";
import sortConverterServices from "@common/services/sortConverterServices";
import { ManageAdminProjectsTab } from "@project/enum";
import { ProjectsTableFilterParametersType } from "@project/types";
import { TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import config from "config";
import {
  ProjectInfo,
  Role,
  useGetBusinessUnitListQuery,
  useGetProjectStageListQuery,
  useGetUserQuery,
} from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { getColumns } from "./columns";
import { getManagerColumns } from "./columnsManager";

const createColumnsByRole = {
  [Role.SuperAdmin]: getColumns,
  [Role.Admin]: getColumns,
  [Role.Manager]: getManagerColumns,
  [Role.User]: getColumns,
};

type PropTypes = {
  currentPage: number;
  setFilterParams: Dispatch<SetStateAction<ProjectsTableFilterParametersType>>;
  filterParams: ProjectsTableFilterParametersType;
  projects: ProjectInfo[];
  pageTotal?: number;
  isProjectListLoading: boolean;
};

const ProjectsTable: FC<PropTypes> = ({
  currentPage,
  setFilterParams,
  filterParams,
  projects,
  pageTotal,
  isProjectListLoading,
}) => {
  const { t } = useTranslation("project.ProjectsTable");
  const { getSearchParam, addSearchParams } = useSearchParams();

  const businessUnitsQuery = useGetBusinessUnitListQuery();
  const stagesListQuery = useGetProjectStageListQuery();
  const getUserQuery = useGetUserQuery();

  const userRole = getUserQuery.data?.getUser?.role;
  const businessUnits = businessUnitsQuery.data?.getBusinessUnitList;
  const stages = stagesListQuery.data?.getProjectStageList;

  const columns = createColumnsByRole[userRole || config.DEFAULT_ROLE]({
    t,
    businessUnits: businessUnits || [],
    setFilterParams,
    filterParams,
    stages: stages || [],
  });

  const onPageChange = (page: number) => {
    const tabName = getSearchParam(SearchParamsEnum.Tab);
    addSearchParams({
      [SearchParamsEnum.Page]: page.toString(),
      [SearchParamsEnum.Tab]: tabName || ManageAdminProjectsTab.MyProjects,
    });
  };

  const onTableChange: TableProps<ProjectInfo>["onChange"] = (
    _pagination,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<ProjectInfo>;

    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setFilterParams((filterProps) => {
      return {
        ...filterProps,
        businessUnits: (filters.businessUnit as string[]) || [],
        projectStages: (filters.projectStage as string[]) || [],
        ...sorterFields,
        trainingsCompleted: sorterFields?.percent,
      };
    });
  };

  const isLoading = loadingService.checkLoading([
    businessUnitsQuery.loading,
    stagesListQuery.loading,
    getUserQuery.loading,
    isProjectListLoading,
  ]);

  return (
    <Table
      data-testid="projects-table"
      onChange={onTableChange}
      loading={isLoading}
      columns={columns}
      dataSource={projects}
      pagination={{
        current: currentPage,
        onChange: onPageChange,
        total: pageTotal,
        showSizeChanger: false,
      }}
    />
  );
};

export default ProjectsTable;
