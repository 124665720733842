import { Layout } from "@common/enums";
import { AuthType, ModuleType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import MyTrainingMatrix from "./pages/MyTrainingMatrix";
import TrainingList from "./pages/TrainingList";
import UserCertificates from "./pages/UserCertificates";

const trainings: ModuleType = {
  routes: [
    {
      layout: Layout.MainLayout,
      path: RoutePaths.Training,
      Page: TrainingList,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager],
      type: AuthType.Auth,
    },
    {
      layout: Layout.MainLayout,
      path: RoutePaths.MyTrainingMatrix,
      Page: MyTrainingMatrix,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      type: AuthType.Auth,
    },
    {
      layout: Layout.MainLayout,
      path: RoutePaths.UserCertificates,
      Page: UserCertificates,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager],
      type: AuthType.Auth,
    },
  ],
  contextProviders: [],
};

export default trainings;
