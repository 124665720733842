import config from "config";
import { Role, Type } from "graphqlTypes";
import * as yup from "yup";

export type CreateTrainingType = {
  trainingName: string;
  nationalUnitOfCompetency?: string;
  mandatoryNeed?: Role[];
  trainingType?: Type;
  recurring?: number;
  isRecurring?: boolean;
  description?: string;
};

export const schema: yup.SchemaOf<CreateTrainingType> = yup.object({
  trainingName: yup.string().required(),
  nationalUnitOfCompetency: yup.string(),
  mandatoryNeed: yup.array(),
  trainingType: yup.mixed<Type>(),
  isRecurring: yup.boolean(),
  recurring: yup
    .number()
    .min(1)
    .when("isRecurring", {
      is: (isRecurring: boolean) => {
        return isRecurring;
      },
      then: (schema) => {
        return schema.required();
      },
    }),
  description: yup.string().max(config.LONG_DESCRIPTION_LENGTH),
});
