import fileServices from "@common/services/fileServices";
import { UploadedFileType } from "@common/types";
import { UploadFile } from "antd";
import config from "config";
import * as yup from "yup";

export type AddEducationFormType = {
  universityName: string;
  study: string;
  year: number;
  fileData: UploadedFileType;
};

export const schema: yup.SchemaOf<AddEducationFormType> = yup.object({
  universityName: yup.string().required(),
  study: yup.string().required(),
  year: yup.number().min(config.MIN_YEAR).max(config.MAX_YEAR).required(),
  fileData: yup
    .object({
      file: yup.mixed<UploadFile>(),
      fileList: yup.array<UploadFile>().max(1),
    })
    .test("fileError", "File Error", (attachment) => {
      if ((attachment.fileList?.length || 0) < 1) {
        return false;
      }

      const isError = fileServices.validateAttachmentFile(
        attachment,
        config.HELP_FILE_TYPES,
      );
      return !isError;
    })
    .required(),
});
