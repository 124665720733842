import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  ControlledDatePicker,
  ControlledUpload,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import fileServices from "@common/services/fileServices";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Form, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import config from "config";
import { format } from "date-fns";
import {
  CertificateInfo,
  Need,
  useAddAdditionalCertificateMutation,
  useAddTrainingCertificateMutation,
  useGetProjectNameByIdLazyQuery,
  UserInfo,
} from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddCertificateFormType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Training,
  "components",
  "UploadExistedCertificateForm",
);

type PropTypes = {
  certificate: CertificateInfo;
  userData: UserInfo | undefined;
};

const UploadExistedCertificateForm: FC<PropTypes> = ({
  certificate,
  userData,
}) => {
  const { hideModal } = useModal();
  const { t } = useTranslation("training.UploadExistedCertificateForm");
  const [projectName, setProjectName] = useState<string>();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm<AddCertificateFormType>({
    resolver: yupResolver(schema),
  });
  const [addCertificateMutation, addCertificateMutationStatus] =
    useAddTrainingCertificateMutation({
      client: apolloProvider.apolloUploadClient,
    });
  const [
    addAdditionalCertificateMutation,
    addAdditionalCertificateMutationStatus,
  ] = useAddAdditionalCertificateMutation({
    client: apolloProvider.apolloUploadClient,
  });
  const [projectNameQuery, projectNameQueryStatus] =
    useGetProjectNameByIdLazyQuery();

  useEffect(() => {
    if (certificate.projectId) {
      projectNameQuery({
        variables: {
          projectId: certificate.projectId,
        },
        onCompleted: (data) => {
          setProjectName(data.getProjectNameById || undefined);
        },
      });
    }
  }, []);

  const translatedNeed = {
    [Need.JobDepended]: t("jobDependent"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const files = getValues("fileData.fileList");

  const validateFileBeforeUpload = (file: UploadFile) => {
    return fileServices.validateFileBeforeUpload(
      file,
      config.CERTIFICATE_FILE_TYPES,
      {
        sizeErrorCallback: () => {
          message.error(
            t("fileSizeError", {
              fileSize: config.FILE_SIZE,
            }),
          );
        },
        typeErrorCallback: () => {
          message.error(
            t("fileTypeError", {
              fileTypes: config.CERTIFICATE_FILE_TYPES.join(", "),
            }),
          );
        },
      },
    );
  };

  const onSubmit = (data: AddCertificateFormType) => {
    const onQueryCompleted = () => {
      message.success(t("certificateUploaded"));
      reset();
      apolloProvider.apolloApiClient.refetchQueries({
        include: [
          "getProfileInfo",
          "getTrainingMatrix",
          "getUserInfo",
          "countUnreadNotifications",
          "getTrainingMatrix",
        ],
      });
      hideModal();
    };

    if (certificate.need === Need.Custom) {
      return addAdditionalCertificateMutation({
        variables: {
          userId: userData?.user?.id,
          trainingName: certificate.name,
          completed: format(data.completedDate, config.DATE_AND_TIME),
          dueTo: data?.expirationDate
            ? format(data.expirationDate, config.DATE_AND_TIME)
            : undefined,
          certificate: data.fileData.fileList?.[0]?.originFileObj,
        },
        onCompleted: (data) => {
          logsServices.logInformation({
            location: ["onSubmit", "onCompleted"],
            message: `Existed certificate (id=${data.addAdditionalCertificate?.id}) was uploaded.`,
          });
          onQueryCompleted();
        },
        onError: () => {
          logsServices.logError({
            location: [
              "addAdditionalCertificateMutation",
              "onSubmit",
              "onError",
            ],
            message: `Certificate uploading was failed.`,
          });
        },
      });
    }
    addCertificateMutation({
      variables: {
        userId: userData?.user?.id,
        trainingId: certificate.trainingId,
        completed: format(data.completedDate, config.DATE_AND_TIME),
        dueTo: data?.expirationDate
          ? format(data.expirationDate, config.DATE_AND_TIME)
          : undefined,
        certificate: data.fileData.fileList?.[0]?.originFileObj,
      },
      onCompleted: (data) => {
        logsServices.logInformation({
          location: ["onSubmit", "onCompleted"],
          message: `Existed certificate (id=${data.addTrainingCertificate?.id}) was uploaded.`,
        });
        onQueryCompleted();
      },
      onError: () => {
        logsServices.logError({
          location: ["addCertificateMutation", "onSubmit", "onError"],
          message: `Certificate uploading was failed.`,
        });
      },
    });
  };

  const isLoading = loadingService.checkLoading([
    addAdditionalCertificateMutationStatus.loading,
    addCertificateMutationStatus.loading,
    projectNameQueryStatus.loading,
  ]);

  return (
    <Form>
      <Row gutter={[0, 24]}>
        <Col span={24} className={styles.title}>
          {t("uploadCertificate")}
        </Col>
        <Col span={8}>{t("userName")}:</Col>
        <Col span={16}>{userData?.graphProfile?.displayName}</Col>
        {projectName && (
          <>
            <Col span={8}>{t("projectName")}:</Col>
            <Col span={16}>{projectName}</Col>
          </>
        )}
        <Col span={8}>{t("trainingName")}:</Col>
        <Col span={16}>{certificate.name}</Col>
        <Col span={8}>{t("need")}:</Col>
        <Col span={16}>{translatedNeed[certificate.need]}</Col>
        <Col span={8}>{t("completedDate")}:</Col>
        <Col span={16}>
          <ControlledDatePicker
            useControllerProps={{
              name: "completedDate",
              control,
            }}
            datePickerProps={{
              "data-testid": "complete-data",
              status: errors.completedDate && "error",
              placeholder: t("selectDate"),
            }}
          />
        </Col>
        <Col span={8}>{t("expirationDate")}:</Col>
        <Col span={16}>
          <ControlledDatePicker
            useControllerProps={{
              name: "expirationDate",
              control,
            }}
            datePickerProps={{
              "data-testid": "expiry-data",
              status: errors.expirationDate && "error",
              placeholder: t("selectDate"),
            }}
          />
        </Col>
        <Col span={8}>{t("certificate")}:</Col>
        <Col span={16}>
          <ControlledUpload
            useControllerProps={{ control, name: "fileData" }}
            uploadProps={{
              "data-testid": "upload",
              beforeUpload: validateFileBeforeUpload,
              maxCount: 1,
              fileList: files,
              children: (
                <Button danger={!!errors.fileData} icon={<UploadOutlined />}>
                  {t("upload")}
                </Button>
              ),
            }}
          />
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col>
              <Button
                data-testid="submit"
                type="primary"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UploadExistedCertificateForm;
