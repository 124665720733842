export default {
  ManegeTrainings: {
    searchPlaceholder: "Search by training title, position...",
    createNewTrainingButton: "Create a new training",
    exportExcel: "Export to Excel",
  },
  CreateTrainingForm: {
    createNewTraining: "Create a new training",
    trainingName: "Training title",
    nationalUnitOfCompetency: "National unit of competency",
    mandatoryNeed: "Mandatory need",
    mandatoryNeedPlaceholder: "Select position",
    trainingType: "Training type",
    date: "Date",
    optional: "Optional",
    description: "Description",
    submit: "Submit",
    close: "Close",
    recurring: "Recurring",
    enterFrequency: "Enter frequency",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    external: "External",
    inHouse: "In House",
    RTO: "RTO",
    trainingCreated: "Training created",
    month: "month(s)",
  },
  UpdateTrainingForm: {
    editTraining: "Edit training",
    trainingName: "Training title",
    nationalUnitOfCompetency: "National unit of competency",
    mandatoryNeed: "Mandatory need",
    mandatoryNeedPlaceholder: "Select position",
    trainingType: "Training type",
    date: "Date",
    optional: "Optional",
    description: "Description",
    submit: "Submit",
    close: "Close",
    recurring: "Recurring",
    enterFrequency: "Enter frequency",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    external: "External",
    inHouse: "In House",
    RTO: "RTO",
    trainingUpdated: "Changes were saved",
    month: "month(s)",
  },
  TrainingsTable: {
    months: "month(s)",
    trainingName: "Training title",
    need: "Need",
    project: "Project",
    positions: "Positions",
    creationDate: "Creation date",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    deleteWarning: "Are you sure you want to delete training?",
    yes: "Yes",
    no: "No",
    nationalUnitOfCompetency: "National unit of competency",
    trainingType: "Training type",
    recurring: "Recurring",
    description: "Description",
    external: "External",
    inHouse: "In House",
    RTO: "RTO",
    users: "Users",
    tasks: "Tasks",
  },
  EditTrainingsTableOverdue: {
    trainingName: "Training name",
    date: "Date",
    need: "Need",
    status: "Status",
    addRecords: "Add records",
    upload: "Upload",
    request: "Request",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    custom: "Custom",
    expiry: "Expiry",
    hidden: "Hidden",
    visible: "Visible",
  },
  TrainingsTableOverdue: {
    trainingName: "Training name",
    date: "Date",
    need: "Need",
    status: "Status",
    addRecords: "Add records",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    custom: "Custom",
    expiry: "Expiry",
  },
  TrainingsTableActive: {
    expiry: "Expiry",
    trainingName: "Training name",
    completed: "Completed",
    need: "Need",
    status: "Status",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    done: "Done",
    archived: "Archived",
    custom: "Custom",
  },
  TrainingsTableActiveWithFileLink: {
    expiry: "Expiry",
    trainingName: "Training name",
    completed: "Completed",
    need: "Need",
    status: "Status",
    viewRecords: "View Records",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    done: "Done",
    archived: "Archived",
    custom: "Custom",
    yes: "Yes",
    no: "No",
    deleteConfirmation: "Are you sure you want to delete certificate?",
    certificateDeleted: "Certificate was deleted",
  },
  UploadCertificateForm: {
    selectTrainingPlaceholder: "Select training name",
    trainingName: "Training name",
    selectNeed: "Select training need",
    upload: "Upload",
    save: "Save",
    need: "Need",
    completedDate: "Completed date",
    expirationDate: "Expiration date",
    selectDate: "Select the date",
    certificate: "Certificate",
    fileSizeError: "File size should be less than {{fileSize}}MB.",
    fileTypeError: "Available file types are {{fileTypes}}.",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    certificateUploaded: "Certificate uploaded",
    uploadCertificate: "Upload certificate",
    userName: "User name",
  },
  UploadExistedCertificateForm: {
    trainingName: "Training name",
    selectNeed: "Select training need",
    upload: "Upload",
    save: "Save",
    need: "Need",
    selectDate: "Select the date",
    mandatory: "Mandatory",
    jobDependent: "Job Dependent",
    completedDate: "Completed date",
    expirationDate: "Expiration date",
    certificate: "Certificate",
    fileSizeError: "File size should be less than {{fileSize}}MB.",
    fileTypeError: "Available file types are {{fileTypes}}.",
    certificateUploaded: "Certificate uploaded",
    custom: "Custom",
    uploadCertificate: "Upload certificate",
    userName: "User name",
    projectName: "Project name",
  },
  RequestTrainingForm: {
    trainingRequested: "Training requested",
    requestTraining: "Request a training",
    trainingName: "Training name",
    userName: "User name",
    position: "Position",
    descriptionPlaceholder: "Leave your preferences (date, time etc)",
    send: "Send",
  },
  RequestExistedCertificateForm: {
    requestTraining: "Request a training",
    userName: "User name",
    position: "Position",
    descriptionPlaceholder: "Leave your preferences (data, time, etc)",
    submit: "Submit",
    trainingRequested: "Training requested",
    trainingName: "Training name",
    projectName: "Project name",
  },
  TrainingsFilterRadioGroup: {
    all: "All",
    mandatory: "Mandatory",
    jobDependent: "Job dependent",
  },
  MyTrainingMatrixManagement: {
    searchPlaceholder: "Search by training title, project...",
    upload: "Upload",
    request: "Request",
  },
  TrainingCardActive: {
    mandatory: "Mandatory",
    training: "Training",
    completed: "Completed",
    expiry: "Expiry",
    need: "Need",
    status: "Status",
    custom: "Custom",
  },
  TrainingCardActiveWithFileLink: {
    done: "Done",
    expired: "Expired",
    required: "Required",
    mandatory: "Mandatory",
    training: "Training",
    completed: "Completed",
    expiry: "Expiry",
    need: "Need",
    status: "Status",
    viewRecords: "View records",
    archived: "Archived",
    custom: "Custom",
    yes: "Yes",
    no: "No",
    deleteConfirmation: "Are you sure you want to delete certificate?",
  },
  EditTrainingCardOverdue: {
    done: "Done",
    required: "Required",
    mandatory: "Mandatory",
    jobDependent: "Job dependent",
    training: "Training",
    date: "Date",
    expired: "Expired",
    need: "Need",
    status: "Status",
    addRecords: "Add records",
    upload: "Upload",
    archived: "Archived",
    custom: "Custom",
    expiry: "Expiry",
  },
  EditMyTrainingCardOverdue: {
    mandatory: "Mandatory",
    jobDependent: "Job dependent",
    training: "Training",
    need: "Need",
    status: "Status",
    addRecords: "Add records",
    upload: "Upload",
    custom: "Custom",
    viewRecord: "View record",
    project: "Project",
    yes: "Yes",
    no: "No",
    deleteConfirmation: "Are you sure you want to delete certificate?",
  },
  TrainingCardOverdue: {
    mandatory: "Mandatory",
    training: "Training",
    completed: "Completed",
    expiry: "Expiry",
    need: "Need",
    status: "Status",
    custom: "Custom",
  },
  MyTrainingMatrixTable: {
    done: "Done",
    expired: "Expired",
    required: "Required",
    jobDepended: "Job dependent",
    mandatory: "Mandatory",
    trainingTitle: "Training title",
    need: "Need",
    project: "Project",
    status: "Status",
    addRecords: "Add records",
    upload: "Upload",
    request: "Request",
    archived: "Archived",
    custom: "Custom",
    yes: "Yes",
    no: "No",
    deleteConfirmation: "Are you sure you want to delete certificate?",
  },
  UserCertificatesTable: {
    done: "Done",
    expired: "Expired",
    required: "Required",
    upload: "Upload",
    request: "Request",
    archived: "Archived",
    trainingName: "Training name",
    trainingNeed: "Training need",
    jobDepended: "Job dependent",
    mandatory: "Mandatory",
    custom: "Custom",
  },
  RequestTrainingCell: {
    request: "Request",
  },
  MyTrainingMatrixFilterForm: {
    needs: "Needs",
    statuses: "Statuses",
    filters: "Filters",
    jobDepended: "Job dependent",
    mandatory: "Mandatory",
    custom: "Custom",
    done: "Done",
    expired: "Expired",
    required: "Required",
    archived: "Archived",
    statusesSelectPlaceholder: "Select status",
    needsSelectPlaceholder: "Select need",
  },
  UploadTrainingCell: {
    upload: "Upload",
  },
  AssignedUsersTrainingInfo: {
    downloadExcel: "Export Excel",
    searchPlaceholder: "Search by name...",
  },
  AssignedUsersTrainingInfoTable: {
    fullName: "Full name",
    position: "Position",
    businessUnit: "Business unit",
    expiry: "Expiry",
    status: "Status",
    competed: "Completed",
    done: "Done",
    expired: "Expired",
    required: "Required",
    archived: "Archived",
  },
  ExpiryColumnFilter: {
    turnOffFilter: "Off/0",
    filterLabel30days: "30",
    filterLabel60days: "60",
    filterLabel90days: "90",
  },
};
