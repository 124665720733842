import { gql } from "@apollo/client";

export const EDUCATION_FRAGMENT = gql`
  fragment EducationNode on Education {
    id
    universityName
    fieldOfStudy
    year
    diplomaUrl
    userId
    format
    createdAt
    updatedAt
  }
`;
