import config from "config";
import msalInstance from "providers/msal";

class TokenServices {
  getToken = async (): Promise<string | null> => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const account = activeAccount || accounts[0];

    if (!account) {
      return sessionStorage.getItem("access_token");
    }

    const request = {
      scopes: config.AZURE_SCOPES,
      account,
    };
    const result = await msalInstance.acquireTokenSilent(request);

    return result.accessToken;
  };
}

const tokenServices = new TokenServices();

export default tokenServices;
