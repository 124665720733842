export default {
  Notification: {
    addedDiploma: "{{userName}} added a diploma.",
    requestTraining: "{{userName}} requested training {{training}}.",
    requestTrainings: "{{userName}} requested trainings {{trainings}}.",
    addNewCertificate: "{{userName}} added new certificate.",
    requiresTraining:
      "{{senderUserName}} requires training {{training}} for {{recipientUserName}}",
    certificateForAnother:
      "{{senderUserName}} uploaded a new certificate {{training}} for {{recipientUserName}}.",
    requestForAnother:
      "{{senderUserName}} requested training {{training}} for {{recipientUserName}}.",
  },
  NotificationIconWithCount: {
    addedDiploma: "{{userName}} added a diploma",
    requestTraining: "{{userName}} requested training",
    addNewCertificate: "{{userName}} added new certificate.",
    requiresTraining: "{{userName}} requires training",
    certificateForAnother: "{{userName}} got certificate",
    requestForAnother: "{{userName}} got request",
  },
};
