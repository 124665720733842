import { DeleteOutlined, FilterFilled } from "@ant-design/icons";
import { Link, Popconfirm } from "@common/components";
import StatusCell from "@common/components/StatusCell";
import sortConverterServices from "@common/services/sortConverterServices";
import RequestExistedCertificateForm from "@training/components/RequestExistedCertificateForm";
import RequestTrainingCell from "@training/components/RequestTrainingCell";
import UploadTrainingCell from "@training/components/UploadTrainingCell";
import { MyTrainingMatrixFilterType } from "@training/types";
import { ModalProps } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import features from "config/features";
import RoutePaths from "config/routePaths";
import {
  CertificateInfo,
  Need,
  Status,
  TrainingMatrixInfo,
  UserInfo,
} from "graphqlTypes";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

import styles from "./styles.module.scss";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.MyTrainingMatrixTable",
);

type PropTypes = {
  hideModal: () => void;
  setModalProps: Dispatch<SetStateAction<ModalProps>>;
  userData: UserInfo | undefined;
  deleteCertificate: (certificate: CertificateInfo) => void;
  filterParams: MyTrainingMatrixFilterType;
};

const positiveStatuses = [Status.Archived, Status.Done];

export const getColumns = ({
  setModalProps,
  hideModal,
  userData,
  deleteCertificate,
  filterParams,
}: PropTypes): ColumnsType<TrainingMatrixInfo> => {
  const statusFilterOptions = [
    { value: Status.Done, text: t("done") },
    { value: Status.Expired, text: t("expired") },
    { value: Status.Required, text: t("required") },
    { value: Status.Archived, text: t("archived") },
  ];

  const needFilterOptions = [
    { value: Need.JobDepended, text: t("jobDepended") },
    { value: Need.Mandatory, text: t("mandatory") },
    { value: Need.Custom, text: t("custom") },
  ];

  const translatedNeed = {
    [Need.JobDepended]: t("jobDepended"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  return [
    {
      title: (
        <span data-testid="my-training-table-title-column">
          {t("trainingTitle")}
        </span>
      ),
      key: "trainingTitle",
      dataIndex: "trainingTitle",
      sorter: true,
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams.trainingTitle,
      ),
      render: (_, trainingMatrixInfo) => {
        return (
          <span data-cy="my-training-table-title">
            {trainingMatrixInfo.certificate.name}
          </span>
        );
      },
    },
    {
      title: t("need"),
      key: "need",
      dataIndex: "need",
      filters: needFilterOptions,
      filteredValue: filterParams.needs,
      filterIcon: () => {
        return <FilterFilled data-testid="my-training-table-need-filter" />;
      },
      render: (_, trainingMatrixInfo) => {
        return (
          <span data-cy="my-training-table-need">
            {translatedNeed[trainingMatrixInfo.certificate.need]}
          </span>
        );
      },
    },
    {
      title: t("project"),
      key: "project",
      dataIndex: "project",
      render: (_, trainingMatrixInfo) => {
        return trainingMatrixInfo.project ? (
          <Link to={`${RoutePaths.Projects}/${trainingMatrixInfo.project.id}`}>
            {trainingMatrixInfo.project.name}
          </Link>
        ) : null;
      },
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "status",
      filters: statusFilterOptions,
      filteredValue: filterParams.statuses,
      filterIcon: () => {
        return <FilterFilled data-testid="my-training-table-status-filter" />;
      },
      render: (_, trainingMatrixInfo) => {
        return (
          <span data-cy="my-training-table-status">
            <StatusCell status={trainingMatrixInfo.certificate.status} />
          </span>
        );
      },
    },
    {
      title: t("addRecords"),
      key: "addRecords",
      dataIndex: "addRecords",
      render: (_, trainingMatrixInfo) => {
        return (
          <UploadTrainingCell
            userData={userData}
            certificate={trainingMatrixInfo.certificate}
          />
        );
      },
    },
    ...(features.request
      ? [
          {
            key: "requestTraining",
            dataIndex: "requestTraining",
            render: (_: undefined, trainingMatrixInfo: TrainingMatrixInfo) => {
              const isPositiveStatus = positiveStatuses.some((status) => {
                return status === trainingMatrixInfo.certificate.status;
              });

              const onRequestClick = () => {
                return setModalProps({
                  footer: false,
                  onCancel: hideModal,
                  children: (
                    <RequestExistedCertificateForm
                      key={trainingMatrixInfo.certificate.name}
                      userData={userData}
                      certificate={trainingMatrixInfo.certificate}
                    />
                  ),
                  open: true,
                });
              };

              const onDelete = () => {
                deleteCertificate(trainingMatrixInfo.certificate);
              };

              return isPositiveStatus ? (
                <Popconfirm
                  okText={t("yes")}
                  cancelText={t("no")}
                  onConfirm={onDelete}
                  title={t("deleteConfirmation")}
                >
                  <DeleteOutlined className={styles.deleteIcon} />
                </Popconfirm>
              ) : (
                <RequestTrainingCell
                  certificate={trainingMatrixInfo.certificate}
                  onRequestClick={onRequestClick}
                />
              );
            },
          },
        ]
      : []),
  ];
};
