import Checkbox from "@common/components/Checkbox";
import { Col, Row } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";
import { FC } from "react";

type PropTypes = {
  title: string;
  checkboxProps?: CheckboxProps;
};

const FilterItem: FC<PropTypes> = ({ title, checkboxProps }) => {
  return (
    <Row align="middle" wrap={false} gutter={[24, 0]}>
      <Col>
        <Checkbox {...checkboxProps} />
      </Col>
      <Col>{title}</Col>
    </Row>
  );
};

export default FilterItem;
