import { Link } from "@common/components";
import { Col, Row } from "antd";
import config from "config";
import { format } from "date-fns";
import { ProjectInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PropTypes = {
  projectData: ProjectInfo;
};

const ProjectCardManager: FC<PropTypes> = ({ projectData }) => {
  const { t } = useTranslation("project.ProjectCardManager");

  return (
    <Row>
      <Col span={8}>{t("project")}:</Col>
      <Col span={16}>
        <Link to={`${projectData.id}`}>{projectData.name}</Link>
      </Col>
      <Col span={8}>{t("businessUnit")}:</Col>
      <Col span={16}>{projectData.businessUnit}</Col>
      <Col span={8}>{t("projectStage")}:</Col>
      <Col span={16}>{projectData.stage}</Col>
      <Col span={8}>{t("startDate")}:</Col>
      <Col span={16}>
        {projectData.startDate
          ? format(new Date(projectData.startDate), config.DATE_WITH_DOTS)
          : null}
      </Col>
      <Col span={8}>{t("endDate")}:</Col>
      <Col span={16}>
        {projectData.startDate
          ? format(new Date(projectData.endDate), config.DATE_WITH_DOTS)
          : null}
      </Col>
    </Row>
  );
};

export default ProjectCardManager;
