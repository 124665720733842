import FilterItem from "@common/components/FilterItem";
import { FilterOptionType } from "@common/types";
import { Col, Form, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Dispatch, FC, SetStateAction } from "react";

type PropTypes = {
  items?: FilterOptionType[];
  setSelectedOptions: Dispatch<SetStateAction<string[]>>;
  selectedOptions: string[];
};

const FilterItemsForm: FC<PropTypes> = ({
  items,
  setSelectedOptions,
  selectedOptions,
}) => {
  const addSelectedOption = (id: string) => {
    setSelectedOptions((selectedOptions) => {
      return [...selectedOptions, id];
    });
  };

  const deleteSelectedOption = (id: string) => {
    setSelectedOptions((selectedOptions) => {
      return selectedOptions.filter((option) => {
        return option !== id;
      });
    });
  };

  return (
    <Form>
      <Row gutter={[0, 12]}>
        {items?.map((item) => {
          const onCheckboxChange = (event: CheckboxChangeEvent) => {
            event.target.checked
              ? addSelectedOption(item.id)
              : deleteSelectedOption(item.id);
          };
          const isChecked = selectedOptions.some((itemId) => {
            return itemId === item.id;
          });
          return (
            <Col span={23} key={item.id}>
              <FilterItem
                title={item.title}
                checkboxProps={{
                  onChange: onCheckboxChange,
                  checked: isChecked,
                }}
              />
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default FilterItemsForm;
