import { SliderRange } from "@common/components";
import { ExpiryDaysFilterEnum } from "@training/enum";
import { AssignedUsersTrainingInfoFilterType } from "@training/types";
import { SliderMarks } from "antd/lib/slider";
import { ExportExpireFilter } from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const expiryFilterOptionByNumber = {
  [ExpiryDaysFilterEnum.Days0]: ExportExpireFilter.Days0,
  [ExpiryDaysFilterEnum.Days30]: ExportExpireFilter.Days30,
  [ExpiryDaysFilterEnum.Days60]: ExportExpireFilter.Days60,
  [ExpiryDaysFilterEnum.Days90]: ExportExpireFilter.Days90,
};

type PropTypes = {
  setFilterParams: Dispatch<
    SetStateAction<AssignedUsersTrainingInfoFilterType | undefined>
  >;
};

const ExpiryColumnFilter: FC<PropTypes> = ({ setFilterParams }) => {
  const { t } = useTranslation("training.ExpiryColumnFilter");

  const sliderMarks: SliderMarks = {
    [ExpiryDaysFilterEnum.Days0]: {
      label: <span className={styles.grayFilter}>{t("turnOffFilter")}</span>,
    },
    [ExpiryDaysFilterEnum.Days30]: {
      label: <span className={styles.redFilter}>{t("filterLabel30days")}</span>,
    },
    [ExpiryDaysFilterEnum.Days60]: {
      label: (
        <span className={styles.orangeFilter}>{t("filterLabel60days")}</span>
      ),
    },
    [ExpiryDaysFilterEnum.Days90]: {
      label: (
        <span className={styles.greenFilter}>{t("filterLabel90days")}</span>
      ),
    },
  };

  const onSliderChange = (values: [number, number]) => {
    const expiryFilter = values.map((value) => {
      return expiryFilterOptionByNumber[
        value as keyof typeof expiryFilterOptionByNumber
      ];
    });
    setFilterParams((filterParams) => {
      return {
        ...(filterParams || {}),
        expiryFilter,
      };
    });
  };

  return (
    <div className={styles.sliderFilterDropdown}>
      <SliderRange
        onChange={onSliderChange}
        className={styles.slider}
        max={90}
        step={null}
        defaultValue={[0, 0]}
        marks={sliderMarks}
        range
      />
    </div>
  );
};

export default ExpiryColumnFilter;
