import { FileOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Need, Status, GapTrainingInfo } from "graphqlTypes";
import i18n from "providers/i18n";

import styles from "./styles.module.scss";

const positiveStatuses = [Status.Archived, Status.Done];

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.UserCertificatesTable",
);

export const getColumns = (): ColumnsType<GapTrainingInfo> => {
  const statusLocale = {
    [Status.Done]: t("done"),
    [Status.Expired]: t("expired"),
    [Status.Required]: t("required"),
    [Status.Archived]: t("archived"),
  };

  const needLocales = {
    [Need.JobDepended]: t("jobDepended"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const needFilterOptions = [
    { value: Need.JobDepended, text: t("jobDepended") },
    { value: Need.Mandatory, text: t("mandatory") },
  ];

  return [
    {
      key: "trainingName",
      title: t("trainingName"),
      sorter: ({ training: trainingA }, { training: trainingB }) => {
        const nameA = trainingA.name.toUpperCase();
        const nameB = trainingB.name.toUpperCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      },
      ellipsis: true,
      render: (_, { training }) => {
        return training?.name;
      },
    },
    {
      key: "trainingNeed",
      title: t("trainingNeed"),
      filters: needFilterOptions,
      onFilter(value, record) {
        return record.need === value;
      },
      render: (_, training) => {
        return training.need ? needLocales[training.need] : "-";
      },
    },
    {
      key: "uploadDownload",
      render: (_, certificate) => {
        const isPositiveStatus = positiveStatuses.some((status) => {
          return status === certificate.status;
        });

        return isPositiveStatus && certificate.certificate?.certificate ? (
          <a
            target="_blank"
            href={certificate.certificate?.certificate}
            rel="noreferrer"
          >
            <FileOutlined />
          </a>
        ) : (
          <span className={styles.overdueCertificate}>
            {statusLocale[certificate.status]}
          </span>
        );
      },
    },
  ];
};
