import { gql } from "@apollo/client";

export const PROJECT_FRAGMENT = gql`
  fragment ProjectNode on Project {
    id
    name
    businessUnit
    stage
    managerList {
      id
      lookup
      email
    }
    staffEmailList
    startDate
    endDate
  }
`;
