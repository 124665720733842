import Select from "@common/components/Select";
import { ElementMetadataType } from "@common/types";
import { SelectProps } from "antd";
import { useController, UseControllerProps } from "react-hook-form";

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  selectProps?: SelectProps & ElementMetadataType;
};

const ControlledSelect = <T extends object>({
  useControllerProps,
  selectProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, onBlur, value },
  } = useController(useControllerProps);

  return (
    <Select
      onChange={(value, option) => {
        onChange(value, option);
        selectProps?.onChange && selectProps?.onChange(value, option);
      }}
      onBlur={onBlur}
      value={value}
      {...selectProps}
    />
  );
};

export default ControlledSelect;
