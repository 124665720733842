import { Link } from "@common/components";
import { Col, Row, Spin } from "antd";
import RoutePaths from "config/routePaths";
import { useListFaqQuery } from "graphqlTypes";
import { FC } from "react";

import styles from "./styles.module.scss";

type PropTypes = {
  searchText: string;
};

const QuestionsList: FC<PropTypes> = ({ searchText }) => {
  const questionsListQuery = useListFaqQuery({
    variables: {
      searchString: searchText,
    },
  });

  const questionList = questionsListQuery.data?.listFAQ;

  return (
    <Spin spinning={questionsListQuery.loading}>
      <Row gutter={[0, 6]}>
        {questionList?.map((question) => {
          return (
            <Col span={24} key={question.id}>
              <Link
                to={`${RoutePaths.Help}/${question.id}`}
                className={styles.questionTitle}
              >
                {question.title}
              </Link>
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};

export default QuestionsList;
