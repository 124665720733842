import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  ControlledDatePicker,
  ControlledSelect,
  ControlledUpload,
  Spin,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import fileServices from "@common/services/fileServices";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Form, message } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { UploadFile } from "antd/lib/upload";
import config from "config";
import { format } from "date-fns";
import {
  EitherTrainingRequestOrTraining,
  useAddAdditionalCertificateMutation,
  useAddTrainingCertificateMutation,
  useListTrainingWithRequestQuery,
  UserInfo,
} from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddCertificateFormType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Training,
  "components",
  "UploadCertificateForm",
);

type PropTypes = {
  userData: UserInfo | undefined;
};

const UploadCertificateForm: FC<PropTypes> = ({ userData }) => {
  const { hideModal } = useModal();
  const { t } = useTranslation("training.UploadCertificateForm");
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm<AddCertificateFormType>({ resolver: yupResolver(schema) });
  const [addCertificateMutation, addCertificateMutationStatus] =
    useAddTrainingCertificateMutation({
      client: apolloProvider.apolloUploadClient,
    });

  const [
    addAdditionalCertificateMutation,
    addAdditionalCertificateMutationStatus,
  ] = useAddAdditionalCertificateMutation({
    client: apolloProvider.apolloUploadClient,
  });

  const trainingListQuery = useListTrainingWithRequestQuery();

  const trainingSelectOptions: DefaultOptionType[] | undefined =
    trainingListQuery.data?.listTrainingWithRequest?.map((training) => {
      return training.left
        ? {
            value: JSON.stringify(training),
            label: training.left.trainingName,
          }
        : {
            value: JSON.stringify(training),
            label: training.right?.name,
          };
    });

  const files = getValues("fileData.fileList");

  const validateFileBeforeUpload = (file: UploadFile) => {
    return fileServices.validateFileBeforeUpload(
      file,
      config.CERTIFICATE_FILE_TYPES,
      {
        sizeErrorCallback: () => {
          message.error(
            t("fileSizeError", {
              fileSize: config.FILE_SIZE,
            }),
          );
        },
        typeErrorCallback: () => {
          message.error(
            t("fileTypeError", {
              fileTypes: config.CERTIFICATE_FILE_TYPES.join(", "),
            }),
          );
        },
      },
    );
  };

  const onSubmit = (data: AddCertificateFormType) => {
    const onQueryCompleted = () => {
      message.success(t("certificateUploaded"));
      reset();
      apolloProvider.apolloApiClient.refetchQueries({
        include: ["getProfileInfo", "getTrainingMatrix", "getUserInfo"],
      });
      hideModal();
    };
    const training: EitherTrainingRequestOrTraining = JSON.parse(
      data.stringifiedTraining,
    );

    if (training.right) {
      return addCertificateMutation({
        variables: {
          userId: userData?.user?.id,
          trainingId: training.right.id,
          completed: format(data.completedDate, config.DATE_AND_TIME),
          dueTo: data?.expirationDate
            ? format(data.expirationDate, config.DATE_AND_TIME)
            : undefined,
          certificate: data.fileData.fileList?.[0]?.originFileObj,
        },
        onCompleted: (data) => {
          logsServices.logInformation({
            location: ["addCertificateMutation", "onSubmit", "onCompleted"],
            message: `Certificate (id=${data.addTrainingCertificate?.id}) was uploaded.`,
          });
          onQueryCompleted();
        },
        onError: () => {
          logsServices.logError({
            location: ["addCertificateMutation", "onSubmit", "onError"],
            message: `Certificate uploading was failed.`,
          });
        },
      });
    }
    if (training.left?.trainingName) {
      addAdditionalCertificateMutation({
        variables: {
          userId: userData?.user?.id,
          trainingName: training.left?.trainingName,
          completed: format(data.completedDate, config.DATE_AND_TIME),
          dueTo: data?.expirationDate
            ? format(data.expirationDate, config.DATE_AND_TIME)
            : undefined,
          certificate: data.fileData.fileList?.[0]?.originFileObj,
        },
        onCompleted: (data) => {
          logsServices.logInformation({
            location: [
              "addAdditionalCertificateMutation",
              "onSubmit",
              "onCompleted",
            ],
            message: `Certificate (id=${data.addAdditionalCertificate?.id}) was uploaded.`,
          });
          onQueryCompleted();
        },
        onError: () => {
          logsServices.logError({
            location: [
              "addAdditionalCertificateMutation",
              "onSubmit",
              "onError",
            ],
            message: `Certificate uploading was failed.`,
          });
        },
      });
    }
  };

  const isLoading = loadingService.checkLoading([
    addAdditionalCertificateMutationStatus.loading,
    addCertificateMutationStatus.loading,
    trainingListQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Form>
        <Row gutter={[12, 24]} align="middle">
          <Col span={24} className={styles.title}>
            {t("uploadCertificate")}
          </Col>
          <Col span={8}>{t("userName")}:</Col>
          <Col span={16}>{userData?.graphProfile?.displayName}</Col>
          <Col span={8}>{t("trainingName")}:</Col>
          <Col span={16}>
            <ControlledSelect
              useControllerProps={{
                name: "stringifiedTraining",
                control,
              }}
              selectProps={{
                "data-testid": "training-name",
                popupClassName: styles.selectPopup,
                showSearch: true,
                filterOption: (input, option) => {
                  return option?.label
                    ? option?.label
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : false;
                },
                placeholder: t("selectTrainingPlaceholder"),
                className: styles.selectContainer,
                options: trainingSelectOptions,
                status: errors.stringifiedTraining && "error",
              }}
            />
          </Col>
          <Col span={8}>{t("completedDate")}:</Col>
          <Col span={16}>
            <ControlledDatePicker
              useControllerProps={{
                name: "completedDate",
                control,
              }}
              datePickerProps={{
                "data-testid": "complete-data",
                status: errors.completedDate && "error",
                placeholder: t("selectDate"),
              }}
            />
          </Col>
          <Col span={8}>{t("expirationDate")}:</Col>
          <Col span={16}>
            <ControlledDatePicker
              useControllerProps={{
                name: "expirationDate",
                control,
              }}
              datePickerProps={{
                "data-testid": "expiry-data",
                status: errors.expirationDate && "error",
                placeholder: t("selectDate"),
              }}
            />
          </Col>
          <Col span={8}>{t("certificate")}:</Col>
          <Col span={16}>
            <ControlledUpload
              useControllerProps={{ control, name: "fileData" }}
              uploadProps={{
                "data-testid": "upload",
                beforeUpload: validateFileBeforeUpload,
                maxCount: 1,
                fileList: files,
                children: (
                  <Button
                    data-testid="upload-button"
                    danger={!!errors.fileData}
                    icon={<UploadOutlined />}
                  >
                    {t("upload")}
                  </Button>
                ),
              }}
            />
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Button data-testid="submit" onClick={handleSubmit(onSubmit)}>
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default UploadCertificateForm;
