import { RangePickerProps } from "@common/types";
import generateRangePicker from "antd/lib/date-picker/generatePicker/generateRangePicker";
import config from "config";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import { FC } from "react";

const RangePickerWithDateFns = generateRangePicker<Date>(dateFnsGenerateConfig);

const RangePicker: FC<RangePickerProps> = (props: RangePickerProps) => {
  const { format = config.DATE_WITH_DOTS, ...restProps } = props;
  return <RangePickerWithDateFns format={format} {...restProps} />;
};

export default RangePicker;
