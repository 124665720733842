import { Modal } from "@common/components";
import { ModalContextValueType } from "@common/types";
import { createContext, FC, useState } from "react";

const modalContextValue: ModalContextValueType = {
  setModalProps: () => {},
};

const ModalContext = createContext(modalContextValue);

type PropTypes = { children: JSX.Element | JSX.Element[] };

const ModalContextProvider: FC<PropTypes> = ({ children }) => {
  const [modalProps, setModalProps] = useState({});

  return (
    <ModalContext.Provider
      value={{
        setModalProps,
      }}
    >
      {children}
      <Modal {...modalProps} />
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalContextProvider };
