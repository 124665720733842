import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  ControlledInput,
  ControlledSelect,
  ControlledReactQuill,
  ControlledUpload,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import fileServices from "@common/services/fileServices";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, message, Row, UploadFile } from "antd";
import config from "config";
import { Role, useCreateFaqMutation } from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddQuestionFormType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Help,
  "components",
  "CreateQuestionForm",
);

const CreateQuestionForm: FC = () => {
  const { t } = useTranslation("help.CreateQuestionForm");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<AddQuestionFormType>({
    resolver: yupResolver(schema),
    defaultValues: { roles: [] },
  });
  const { hideModal } = useModal();
  const [createFAQ, createFAQMutationStatus] = useCreateFaqMutation({
    client: apolloProvider.apolloApiClient,
  });
  const [createFAQWithFiles, createFAQWithFilesMutationStatus] =
    useCreateFaqMutation({
      client: apolloProvider.apolloUploadClient,
    });

  const files = getValues("attachment.fileList");

  const roles = [
    { value: Role.Admin, label: t("admin") },
    { value: Role.Manager, label: t("management") },
    { value: Role.User, label: t("projectTeam") },
  ];

  const onSubmitClick = (data: AddQuestionFormType) => {
    if (data.attachment?.fileList?.length) {
      return createFAQWithFiles({
        variables: {
          title: data.questionTitle,
          roles: data.roles,
          description: data.description,
          attachments: data.attachment.fileList.map((file) => {
            return file.originFileObj;
          }),
        },
        onCompleted: (data) => {
          message.success(t("FAQcreated"));
          reset();
          logsServices.logInformation({
            message: `FAQ (id=${data.createFAQ?.id}) was created.`,
            location: ["onSubmitClick", "createFAQWithFile", "onCompleted"],
          });
          apolloProvider.apolloApiClient.refetchQueries({
            include: ["listFAQ"],
          });
          hideModal();
        },
      });
    }
    createFAQ({
      variables: {
        title: data.questionTitle,
        roles: data.roles,
        description: data.description,
        attachments: [],
      },
      onCompleted: (data) => {
        message.success(t("FAQcreated"));
        reset();
        logsServices.logInformation({
          message: `FAQ (id=${data.createFAQ?.id}) was created.`,
          location: ["onSubmitClick", "createFAQ", "onCompleted"],
        });
        apolloProvider.apolloApiClient.refetchQueries({
          include: ["listFAQ"],
        });
        hideModal();
      },
    });
  };

  const validateFileBeforeUpload = (file: UploadFile) => {
    return fileServices.validateFileBeforeUpload(file, config.HELP_FILE_TYPES, {
      sizeErrorCallback: () => {
        message.error(
          t("fileSizeError", {
            fileSize: config.FILE_SIZE,
          }),
        );
      },
      typeErrorCallback: () => {
        message.error(
          t("fileTypeError", {
            fileTypes: config.HELP_FILE_TYPES.join(", "),
          }),
        );
      },
    });
  };

  const isMutationLoading = loadingService.checkLoading([
    createFAQWithFilesMutationStatus.loading,
    createFAQMutationStatus.loading,
  ]);
  return (
    <Form>
      <Row align="middle" gutter={[0, 24]}>
        <Col span={24} className={styles.title}>
          {t("createQuestion")}
        </Col>
        <Col span={8}>{t("questionTitle")}:</Col>
        <Col span={16}>
          <ControlledInput
            useControllerProps={{
              name: "questionTitle",
              control,
            }}
            inputProps={{
              "data-testid": "title",
              status: errors.questionTitle && "error",
              placeholder: t("titlePlaceholder"),
            }}
          />
        </Col>
        <Col span={8}>{t("selectRole")}:</Col>
        <Col span={16}>
          <ControlledSelect
            useControllerProps={{
              name: "roles",
              control,
            }}
            selectProps={{
              options: roles,
              popupClassName: styles.selectPopup,
              status: errors.roles && "error",
              className: styles.roleSelect,
              mode: "multiple",
              placeholder: t("selectRole"),
            }}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col>{t("description")}:</Col>
            {errors.description && (
              <Col className={styles.error}>{errors.description.message}</Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <ControlledReactQuill
            useControllerProps={{
              name: "description",
              control,
            }}
            reactQuillProps={{
              theme: "snow",
              placeholder: t("descriptionPlaceholder"),
            }}
          />
        </Col>
        <Col span={24}>
          <Row align="middle" gutter={[0, 6]}>
            <Col span={8}>{t("uploadAttachment")}</Col>
            <Col span={16}>
              <ControlledUpload
                useControllerProps={{
                  name: "attachment",
                  control,
                }}
                uploadProps={{
                  "data-testid": "upload",
                  fileList: files,
                  beforeUpload: validateFileBeforeUpload,
                  children: (
                    <Button
                      type="link"
                      danger={!!errors.attachment}
                      icon={<PlusOutlined />}
                    >
                      {t("upload")}
                    </Button>
                  ),
                }}
              />
            </Col>
            <Col>
              {errors.attachment && (
                <span className={styles.fileError}>{t("fileError")}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button
                disabled={createFAQMutationStatus.loading}
                onClick={hideModal}
              >
                {t("close")}
              </Button>
            </Col>
            <Col>
              <Button
                data-testid="submit"
                loading={isMutationLoading}
                type="primary"
                onClick={handleSubmit(onSubmitClick)}
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateQuestionForm;
