import { FC } from "react";
import ReactQuillComponent, { ReactQuillProps } from "react-quill";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];

const ReactQuill: FC<ReactQuillProps> = (props: ReactQuillProps) => {
  return (
    <ReactQuillComponent modules={{ toolbar: toolbarOptions }} {...props} />
  );
};

export default ReactQuill;
