import { Link } from "@common/components";
import { EmployeesTasksTableColumnType } from "@project/types";
import { ColumnsType } from "antd/lib/table/interface";
import RoutePaths from "config/routePaths";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

import SelectTasksCell from "./SelectTasksCell";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "project.TaskAssignmentTable",
);

type PropTypes = {
  setUserList: Dispatch<
    SetStateAction<EmployeesTasksTableColumnType[] | undefined>
  >;
  isEditing: boolean;
};

export const getColumns = ({
  setUserList,
  isEditing,
}: PropTypes): ColumnsType<EmployeesTasksTableColumnType> => {
  return [
    {
      dataIndex: "name",
      title: t("name"),
      key: "name",
      render: (_, userData) => {
        return (
          <Link to={`${RoutePaths.Users}/${userData.graphProfile.id}`}>
            {userData.graphProfile.displayName}
          </Link>
        );
      },
    },
    {
      dataIndex: "position",
      title: t("position"),
      key: "position",
      render: (_, tableUser) => {
        return tableUser.graphProfile.jobTitle;
      },
    },
    {
      dataIndex: "tasks",
      title: t("tasks"),
      key: "tasks",
      width: "50%",
      render: (_, userData) => {
        const currentUserTaskNames = userData.tasks.map((task) => {
          return task.name;
        });

        return isEditing ? (
          <span data-testid="task-select">
            <SelectTasksCell userData={userData} setUserList={setUserList} />
          </span>
        ) : (
          currentUserTaskNames.join(", ")
        );
      },
    },
  ];
};
