import { Spin } from "@common/components";
import ManageAdminProjects from "@project/components/ManageAdminProjects";
import ManageMyProjects from "@project/components/ManageMyProjects";
import config from "config";
import { Role, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";

const manageProjectsByRole = {
  [Role.SuperAdmin]: ManageAdminProjects,
  [Role.Admin]: ManageAdminProjects,
  [Role.Manager]: ManageAdminProjects,
  [Role.User]: ManageMyProjects,
};

const Projects: FC = () => {
  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data?.getUser;

  const ManageProjectsByRole =
    manageProjectsByRole[user?.role || config.DEFAULT_ROLE];

  return (
    <Spin spinning={getUserQuery.loading}>
      <ManageProjectsByRole />
    </Spin>
  );
};

export default Projects;
