import { Card } from "@common/components";
import StatusCell from "@common/components/StatusCell";
import { Row, Col } from "antd";
import config from "config";
import { format } from "date-fns";
import { Need, CertificateInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  certificate: CertificateInfo;
};
const TrainingCardActive: FC<PropTypes> = ({ certificate }) => {
  const { t } = useTranslation("training.TrainingCardActive");

  const translatedNeed = {
    [Need.JobDepended]: t("done"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("training")}:</Col>
            <Col>{certificate.name}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("completed")}:</Col>
            <Col>
              {certificate.completedAt
                ? format(
                    new Date(certificate.completedAt),
                    config.DATE_WITH_DOTS,
                  )
                : null}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("expiry")}:</Col>
            <Col>
              {certificate.dueTo
                ? format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)
                : null}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("need")}:</Col>
            <Col>{translatedNeed[certificate.need]}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("status")}:</Col>
            <Col>
              <StatusCell status={certificate.status} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TrainingCardActive;
