import { FileExcelOutlined } from "@ant-design/icons";
import { Button, DatePicker, DownloadButton } from "@common/components";
import loadingService from "@common/services/loadingServices";
import GapAnalysisTable from "@project/components/GapAnalysisTable";
import { CONFIG } from "@project/config";
import { Row, Col, Spin, message } from "antd";
import config from "config";
import { format } from "date-fns";
import {
  useRequireAllMutation,
  useGetGapAnalysisByDateNewQuery,
  useListGapAnalysisDateQuery,
  useGetProjectInfoQuery,
  Role,
  useGetUserQuery,
} from "graphqlTypes";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

import styles from "./styles.module.scss";

const rolesWithoutRequireAll = [Role.User];

const GapAnalysis: FC = () => {
  const { t } = useTranslation("project.GapAnalysis");
  const { projectId } = useParams();
  const [isPrintMode, setIsPrintMode] = useState(false);
  const userQuery = useGetUserQuery();
  const [date, setDate] = useState<Date | null>();
  const [requireAllMutation, requireAllMutationStatus] =
    useRequireAllMutation();
  const trainingMatrixRef = useRef<HTMLDivElement>(null);
  const getGapAnalysisByDateQuery = useGetGapAnalysisByDateNewQuery({
    variables: {
      date: format(
        date || CONFIG.TABLE_GAP_ANALYSIS_DEFAULT_DATE,
        config.REVERSED_DATE_WITH_DASHES,
      ),
      projectId: projectId as string,
    },
    onCompleted: (data) => {
      if (data.getGapAnalysisByDateNew?.gapAnalysis?.date) {
        setDate(new Date(data.getGapAnalysisByDateNew.gapAnalysis.date));
      }
    },
  });
  const listGapAnalysisDateQuery = useListGapAnalysisDateQuery({
    variables: {
      projectId: projectId as string,
    },
  });
  const projectInfoQuery = useGetProjectInfoQuery({
    variables: {
      id: projectId as string,
    },
  });

  const isExcelExport =
    userQuery.data?.getUser?.role &&
    config.ROLES_WITH_EXCEL_EXPORT.includes(userQuery.data.getUser.role);

  const userRole = userQuery.data?.getUser?.role;
  const projectName = projectInfoQuery.data?.getProjectInfo?.name;
  const allowedDateList =
    listGapAnalysisDateQuery.data?.listGapAnalysisDate?.map((date) => {
      return new Date(date);
    });
  const gapAnalysisByDateData =
    getGapAnalysisByDateQuery.data?.getGapAnalysisByDateNew;

  const onDatePickerChange = (date: Date | null) => {
    setDate(date);
  };

  const onRequestAllClick = () => {
    requireAllMutation({
      variables: {
        projectId: projectId as string,
      },
      onCompleted: () => {
        message.success(t("trainingsRequested"));
      },
    });
  };

  const onBeforeGetPrintContent = () => {
    setIsPrintMode(true);
    // React to print is waiting until project name will be displayed.
    return Promise.resolve();
  };

  const onAfterPrint = () => {
    setIsPrintMode(false);
  };

  const isWithoutRequireAll = rolesWithoutRequireAll.some((role) => {
    return role !== userRole;
  });

  const isLoading = loadingService.checkLoading([
    listGapAnalysisDateQuery.loading,
    getGapAnalysisByDateQuery.loading,
    projectInfoQuery.loading,
    userQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <div ref={trainingMatrixRef} className={styles.gapAnalysisTableContainer}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col className={styles.title}>
                    <Row align="middle" gutter={[24, 0]}>
                      <Col>
                        <Row align="middle" gutter={[12, 0]}>
                          <Col>{t("gapAnalysis")}</Col>
                          <Col>
                            <ReactToPrint
                              trigger={DownloadButton}
                              onBeforeGetContent={onBeforeGetPrintContent}
                              onAfterPrint={onAfterPrint}
                              content={() => {
                                return trainingMatrixRef.current;
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <DatePicker
                          clearIcon={false}
                          disabledDate={(date) => {
                            return !allowedDateList?.some((allowedDate) => {
                              const formattedAllowedDate = format(
                                allowedDate,
                                config.REVERSED_DATE_WITH_DASHES,
                              );
                              const formattedDate = format(
                                date,
                                config.REVERSED_DATE_WITH_DASHES,
                              );
                              return formattedAllowedDate === formattedDate;
                            });
                          }}
                          value={date}
                          onChange={onDatePickerChange}
                        />
                      </Col>
                      <Col>
                        {isExcelExport && (
                          <Button
                            disabled={
                              !getGapAnalysisByDateQuery.data
                                ?.getGapAnalysisByDateNew?.gapAnalysis?.info
                                ?.length
                            }
                            href={gapAnalysisByDateData?.exportUrl}
                            rel="noreferrer"
                            target="_blank"
                            icon={<FileExcelOutlined />}
                            type="dashed"
                          >
                            {t("exportExcel")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {isWithoutRequireAll && (
                    <Col>
                      <Button
                        data-testid="require-all"
                        onClick={onRequestAllClick}
                        loading={requireAllMutationStatus.loading}
                        type="primary"
                      >
                        {t("requireAll")}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          {isPrintMode && (
            <Col span={24} className={styles.title}>
              {projectName}
            </Col>
          )}
          <Col span={24}>
            <GapAnalysisTable
              gapAnalysisUserInfoList={
                gapAnalysisByDateData?.gapAnalysis?.info || []
              }
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default GapAnalysis;
