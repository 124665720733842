import {
  Button,
  ControlledSelect,
  ControlledTextArea,
  Spin,
} from "@common/components";
import { useModal } from "@common/hooks/useModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row, Select } from "antd";
import config from "config";
import {
  useCreateProjectTrainingRequestMutation,
  useGetProfileInfoQuery,
  useListTaskInfoWithoutPaginationQuery,
} from "graphqlTypes";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequestTrainingByProjectFormType, schema } from "./schema";
import styles from "./styles.module.scss";

const { Option, OptGroup } = Select;

type PropTypes = {
  projectName: string;
  projectId: string;
};

const RequestTrainingByProjectForm: FC<PropTypes> = ({
  projectName,
  projectId,
}) => {
  const { t } = useTranslation("project.RequestTrainingByProjectForm");
  const { hideModal } = useModal();
  const profileInfoQuery = useGetProfileInfoQuery();
  const userData = profileInfoQuery.data?.getProfileInfo;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<RequestTrainingByProjectFormType>({
    resolver: yupResolver(schema),
  });
  const taskInfoListQuery = useListTaskInfoWithoutPaginationQuery({
    variables: {
      projectId,
    },
  });
  const [
    createProjectTrainingRequest,
    createProjectTrainingRequestMutationStatus,
  ] = useCreateProjectTrainingRequestMutation();

  const taskInfoList = taskInfoListQuery.data?.listTaskInfoWithoutPagination;

  const onFormSubmit = (data: RequestTrainingByProjectFormType) => {
    createProjectTrainingRequest({
      variables: {
        projectId: projectId as string,
        trainingIdList: data.trainingIds,
        comment: data.description,
      },
      onCompleted: () => {
        reset();
        hideModal();
      },
    });
  };

  return (
    <Spin spinning={profileInfoQuery.loading}>
      <Form>
        <Row align="middle" gutter={[0, 24]}>
          <Col span={24} className={styles.title}>
            {t("requestTraining")}
          </Col>
          <Col span={8}>{t("employee")}:</Col>
          <Col span={16}>{userData?.graphProfile?.displayName}</Col>
          <Col span={8}>{t("position")}:</Col>
          <Col span={16}>{userData?.graphProfile?.jobTitle}</Col>
          <Col span={8}>{t("project")}:</Col>
          <Col span={16}>{projectName}</Col>
          <Col span={8}>{t("trainingNeed")}:</Col>
          <Col span={16}>{t("jobDependent")}</Col>
          <Col span={8}>{t("selectTraining")}:</Col>
          <Col span={16}>
            <ControlledSelect
              useControllerProps={{
                name: "trainingIds",
                control,
              }}
              selectProps={{
                loading: taskInfoListQuery.loading,
                mode: "multiple",
                placeholder: t("selectTraining"),
                status: errors.trainingIds && "error",
                className: styles.selectTrainings,
                children: taskInfoList?.map((taskInfo) => {
                  return (
                    <OptGroup key={taskInfo.id} label={taskInfo.name}>
                      {taskInfo.trainings.map((training) => {
                        return (
                          <Option
                            value={training.id}
                            key={`${taskInfo.id}:${training.id}`}
                          >
                            {training.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                }),
              }}
            />
          </Col>
          <Col span={24}>
            <ControlledTextArea
              useControllerProps={{
                name: "description",
                control,
              }}
              textAreaProps={{
                status: errors.description && "error",
                placeholder: t("descriptionPlaceholder"),
                maxLength: config.LONG_DESCRIPTION_LENGTH,
              }}
            />
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  loading={createProjectTrainingRequestMutationStatus.loading}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default RequestTrainingByProjectForm;
