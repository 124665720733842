import { DeleteOutlined, FileOutlined, FilterFilled } from "@ant-design/icons";
import { Popconfirm } from "@common/components";
import StatusCell from "@common/components/StatusCell";
import { message } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import config from "config";
import { format } from "date-fns";
import { CertificateInfo, Need, Status } from "graphqlTypes";
import i18n from "providers/i18n";

import styles from "./styles.module.scss";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.TrainingsTableActiveWithFileLink",
);

type PropTypes = {
  deleteCertificate: (certificate: CertificateInfo) => void;
};

export const getColumns = ({
  deleteCertificate,
}: PropTypes): ColumnsType<CertificateInfo> => {
  const translatedNeed = {
    [Need.JobDepended]: t("jobDependent"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const statusFilterOptions = [
    { value: Status.Archived, text: t("archived") },
    { value: Status.Done, text: t("done") },
  ];

  return [
    {
      title: t("trainingName"),
      dataIndex: "trainingName",
      key: "trainingName",
      render: (_, certificate) => {
        return certificate.name;
      },
    },
    {
      title: t("completed"),
      dataIndex: "completed",
      key: "completed",
      render: (_, certificate) => {
        return certificate.completedAt
          ? format(new Date(certificate.completedAt), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("expiry"),
      dataIndex: "expiry",
      key: "expiry",
      render: (_, certificate) => {
        return certificate.dueTo
          ? format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("need"),
      dataIndex: "need",
      key: "need",
      render: (_, certificate) => {
        return translatedNeed[certificate.need];
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      filters: statusFilterOptions,
      onFilter: (filter, certificate) => {
        return filter === certificate.status;
      },
      filterIcon: () => {
        return <FilterFilled data-testid="certificate-status-filter" />;
      },
      render: (_, certificate) => {
        return (
          <span data-cy="active-table-status">
            <StatusCell status={certificate.status} />
          </span>
        );
      },
    },
    {
      title: t("viewRecords"),
      dataIndex: "records",
      key: "records",
      render: (_, certificate) => {
        return certificate.certificateLink ? (
          <a
            data-cy="certificate-download"
            target="_blank"
            href={certificate.certificateLink}
            rel="noreferrer"
          >
            <FileOutlined />
          </a>
        ) : null;
      },
    },
    {
      key: "delete",
      render: (_, certificate) => {
        const onDelete = () => {
          deleteCertificate(certificate);
          message.success(t("certificateDeleted"));
        };

        return (
          <Popconfirm
            okText={<span data-testid="yes">{t("yes")}</span>}
            cancelText={t("no")}
            onConfirm={onDelete}
            title={t("deleteConfirmation")}
          >
            <span data-cy="delete-certificate">
              <DeleteOutlined className={styles.deleteIcon} />
            </span>
          </Popconfirm>
        );
      },
    },
  ];
};
