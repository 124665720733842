import { Input } from "@common/components";
import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useSearchParams } from "@common/hooks/useSearchParams";
import LogsServices from "@common/services/logsServices";
import UsersTable from "@user/components/UsersTable";
import { Row, Col } from "antd";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

const logsServices = new LogsServices(
  ModuleNameEnum.User,
  "components",
  "ManageUsers",
);

const ManageUsers: FC = () => {
  const { t } = useTranslation("user.ManageUsers");
  const { getSearchParam, addSearchParams } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearchText = useDebounce(searchText);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;
    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });

    logsServices.logVerbose({
      message: `Users search: ${searchText}`,
      location: ["onSearchChange"],
    });
  };

  return (
    <Row gutter={[0, 24]} justify="center" align="middle">
      <Col span={23}>
        <Input
          data-testid="users-search"
          value={searchText || ""}
          onChange={onSearchChange}
          placeholder={t("searchPlaceholder")}
        />
      </Col>
      <Col span={23}>
        <UsersTable searchText={debouncedSearchText || ""} />
      </Col>
    </Row>
  );
};

export default ManageUsers;
