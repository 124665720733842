import { ImgResolutionType } from "@common/types";
import config from "config";
import microsoftGraphApi from "providers/axios";

class PhotoServices {
  getMyProfilePhoto = async (resolution?: ImgResolutionType) => {
    return microsoftGraphApi.get(
      `me/photos/${resolution || config.IMG_RESOLUTION_HIGHT}/$value`,
      {
        responseType: "arraybuffer",
      },
    );
  };

  getProfilePhoto = async (userId: string, resolution?: ImgResolutionType) => {
    return microsoftGraphApi.get(
      `users/${userId}/photos/${
        resolution || config.IMG_RESOLUTION_HIGHT
      }/$value`,
      {
        params: {
          id: userId,
        },
        responseType: "arraybuffer",
      },
    );
  };
}

const photoServices = new PhotoServices();

export default photoServices;
