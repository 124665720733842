import fileConverterServices from "@common/services/fileConverterServices";
import photoServices from "@common/services/photoServices";
import { createContext, FC, useEffect, useState } from "react";

const contextValue = {
  profileImage: "",
};

const ProfilePhotoContext = createContext(contextValue);

type PropTypes = { children: JSX.Element | JSX.Element[] };

const ProfilePhotoContextProvider: FC<PropTypes> = ({ children }) => {
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    (async () => {
      const imageData = await photoServices.getMyProfilePhoto();

      const image = fileConverterServices.convertToBase64String(imageData);

      setProfileImage(image);
    })();
  }, []);

  return (
    <ProfilePhotoContext.Provider value={{ profileImage }}>
      {children}
    </ProfilePhotoContext.Provider>
  );
};

export { ProfilePhotoContext, ProfilePhotoContextProvider };
