import { FilterFilled } from "@ant-design/icons";
import { Link, RangePicker, Tooltip } from "@common/components";
import sortConverterServices from "@common/services/sortConverterServices";
import { ProjectsTableFilterParametersType } from "@project/types";
import { ColumnsType } from "antd/lib/table/interface";
import config from "config";
import RoutePaths from "config/routePaths";
import { format } from "date-fns";
import { ProjectInfo } from "graphqlTypes";
import i18n from "providers/i18n";
import { RangeValue } from "rc-picker/lib/interface";
import { Dispatch, SetStateAction } from "react";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "project.ProjectsTable",
);

type PropTypes = {
  businessUnits: string[];
  setFilterParams: Dispatch<SetStateAction<ProjectsTableFilterParametersType>>;
  filterParams: ProjectsTableFilterParametersType;
  stages: string[];
};

export const getColumns = ({
  businessUnits,
  setFilterParams,
  filterParams,
  stages,
}: PropTypes): ColumnsType<ProjectInfo> => {
  const businessUnitFilterOptions = businessUnits.map((businessUnit) => {
    return { text: businessUnit, value: businessUnit };
  });

  const projectStageFilterOptions = stages.map((stage) => {
    return { text: stage, value: stage };
  });

  return [
    {
      ellipsis: true,
      dataIndex: "projectName",
      title: (
        <span data-testid="project-table-project-name-column-title">
          {t("projectName")}
        </span>
      ),
      key: "projectName",
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams.projectName,
      ),
      sorter: true,
      render: (_, project) => {
        return (
          <Tooltip title={project.name}>
            <Link
              data-cy="project-table-project-name"
              to={`${RoutePaths.Projects}/${project.id}`}
            >
              {project.name}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "percent",
      title: t("percentOfTrainingsComplete"),
      key: "percent",
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams.trainingsCompleted,
      ),
      sorter: true,
      ellipsis: true,
      render: (_, project) => {
        return `${project.percent}%`;
      },
    },
    {
      dataIndex: "projectManager",
      title: t("projectManager"),
      key: "projectManager",
      ellipsis: true,
      render: (_, project) => {
        const managers = project.managerList.map((manager) => {
          return manager.right?.displayName || manager.left;
        });
        return managers.join(", ");
      },
    },
    {
      dataIndex: "businessUnit",
      title: t("businessUnit"),
      key: "businessUnit",
      filteredValue: filterParams.businessUnits,
      filters: businessUnitFilterOptions,
      filterIcon: () => {
        return (
          <FilterFilled data-testid="project-table-business-unit-filter" />
        );
      },
      render: (_, project) => {
        return (
          <span data-cy="project-table-business-unit">
            {project.businessUnit}
          </span>
        );
      },
    },
    {
      dataIndex: "projectStage",
      title: t("projectStage"),
      key: "projectStage",
      filteredValue: filterParams.projectStages,
      filters: projectStageFilterOptions,
      filterIcon: () => {
        return (
          <FilterFilled data-testid="project-table-project-stage-filter" />
        );
      },
      render: (_, project) => {
        return (
          <span data-cy="project-table-project-stage">{project.stage}</span>
        );
      },
    },
    {
      dataIndex: "startDate",
      title: (
        <span data-testid="project-table-start-date-column-title">
          {t("startDate")}
        </span>
      ),
      key: "startDate",
      sorter: true,
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams.startDate,
      ),
      filteredValue: [],
      filtered: !!filterParams.startDateRange,
      filterIcon: () => {
        return <FilterFilled data-testid="project-table-start-date-filter" />;
      },
      filterDropdown: () => {
        const onRangeChange = (dateRange: RangeValue<Date>) => {
          setFilterParams((filterParams) => {
            return {
              ...filterParams,
              startDateRange:
                dateRange?.[0] && dateRange?.[1]
                  ? {
                      from: format(
                        dateRange[0],
                        config.REVERSED_DATE_WITH_DASHES,
                      ),
                      to: format(
                        dateRange[1],
                        config.REVERSED_DATE_WITH_DASHES,
                      ),
                    }
                  : undefined,
            };
          });
        };
        return (
          <RangePicker
            data-testid="project-table-range-picker"
            onChange={onRangeChange}
          />
        );
      },
      render: (_, project) => {
        return project.startDate ? (
          <span data-cy="project-table-start-date">
            {format(Date.parse(project.startDate), config.DATE_WITH_DOTS)}
          </span>
        ) : null;
      },
    },
    {
      dataIndex: "endDate",
      title: (
        <span data-testid="project-table-end-date-column-title">
          {t("endDate")}
        </span>
      ),
      key: "endDate",
      sorter: true,
      sortOrder: sortConverterServices.convertToAntdSortOrder(
        filterParams.endDate,
      ),
      filteredValue: [],
      filtered: !!filterParams.endDateRange,
      filterIcon: () => {
        return <FilterFilled data-testid="project-table-end-date-filter" />;
      },
      filterDropdown: () => {
        const onRangeChange = (dateRange: RangeValue<Date>) => {
          setFilterParams((filterParams) => {
            return {
              ...filterParams,
              endDateRange:
                dateRange?.[0] && dateRange?.[1]
                  ? {
                      from: format(
                        dateRange[0],
                        config.REVERSED_DATE_WITH_DASHES,
                      ),
                      to: format(
                        dateRange[1],
                        config.REVERSED_DATE_WITH_DASHES,
                      ),
                    }
                  : undefined,
            };
          });
        };
        return <RangePicker onChange={onRangeChange} />;
      },
      render: (_, project) => {
        return project.startDate ? (
          <span data-cy="project-table-end-date">
            {format(Date.parse(project.endDate), config.DATE_WITH_DOTS)}
          </span>
        ) : null;
      },
    },
  ];
};
