import { BackButton, Spin } from "@common/components";
import DisplayEditableQuestion from "@help/components/DisplayEditableQuestion";
import EditQuestionForm from "@help/components/EditQuestionForm";
import { Row, Col } from "antd";
import { useGetFaqQuery } from "graphqlTypes";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

const ManageEditableQuestion: FC = () => {
  const { questionId } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);

  const questionQuery = useGetFaqQuery({
    variables: { id: questionId },
  });

  const question = questionQuery.data?.getFAQ;

  return question ? (
    <Spin spinning={questionQuery.loading}>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={23}>
          <BackButton />
        </Col>
        <Col span={23}>
          {isEditMode ? (
            <EditQuestionForm
              question={question}
              setIsEditMode={setIsEditMode}
            />
          ) : (
            <DisplayEditableQuestion
              question={question}
              setIsEditMode={setIsEditMode}
            />
          )}
        </Col>
      </Row>
    </Spin>
  ) : null;
};

export default ManageEditableQuestion;
