import { ValidateEnvType } from "@common/types";
import * as yup from "yup";

class EnvironmentsServices {
  private checkEnvironments = (environmentsValidations: ValidateEnvType) => {
    Object.entries(environmentsValidations).forEach(
      ([environmentName, validateEnvironment]) => {
        try {
          const environmentValue = process.env[environmentName];
          const errorMessage: string | undefined =
            validateEnvironment(environmentValue);

          return {
            environmentName,
            errorMessage,
          };
        } catch (error) {
          throw Error(`Environment ${environmentName} is invalid, ${error}`);
        }
      },
    );
  };

  validateEnvironments = () => {
    return this.checkEnvironments({
      REACT_APP_AZURE_APPLICATION_ID: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_AZURE_DIRECTORY: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_GRAPHQL_API_URL: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_GRAPHQL_API_URL_UPLOAD: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_GRAPHQL_WS_URL: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_MICROSOFT_GRAPH_API_URL: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_MSAL_REDIRECT_URL: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_CODEGEN_TOKEN: (environment) => {
        return yup.string().validateSync(environment);
      },
      REACT_APP_APPLICATION_INSIGHT_CONNECTING_STRING: (environment) => {
        return yup.string().required().validateSync(environment);
      },
      REACT_APP_SECRET_VALUE: (environment) => {
        return yup.string().required().validateSync(environment);
      },
    });
  };
}

const environmentsServices = new EnvironmentsServices();

export default environmentsServices;
