import { List, ListItem } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import ProjectCard from "@project/components/ProjectCard";
import ProjectCardManager from "@project/components/ProjectCardManager";
import { ManageAdminProjectsTab } from "@project/enum";
import config from "config";
import { ProjectInfo, Role, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

const projectCardByRole = {
  [Role.SuperAdmin]: ProjectCard,
  [Role.Admin]: ProjectCard,
  [Role.Manager]: ProjectCardManager,
  [Role.User]: ProjectCard,
};

type PropTypes = {
  currentPage: number;
  projects: ProjectInfo[];
  pageTotal?: number;
};

const ProjectCardList: FC<PropTypes> = ({
  currentPage,
  projects,
  pageTotal,
}) => {
  const getUserQuery = useGetUserQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const userRole = getUserQuery.data?.getUser?.role;

  const onPageChange = (page: number) => {
    const tabName = searchParams.get(SearchParamsEnum.Tab);
    setSearchParams({
      [SearchParamsEnum.Page]: page.toString(),
      [SearchParamsEnum.Tab]: tabName || ManageAdminProjectsTab.MyProjects,
    });
  };

  const ProjectCard = projectCardByRole[userRole || config.DEFAULT_ROLE];

  return (
    <List
      bordered
      dataSource={projects || []}
      renderItem={(projectData) => {
        return (
          <ListItem key={projectData.id}>
            <ProjectCard projectData={projectData} />
          </ListItem>
        );
      }}
      pagination={{
        current: currentPage,
        onChange: onPageChange,
        total: pageTotal,
        showSizeChanger: false,
      }}
    />
  );
};

export default ProjectCardList;
