import { Table } from "@common/components";
import loadingService from "@common/services/loadingServices/index";
import sortConverterServices from "@common/services/sortConverterServices";
import { AssignedUsersTrainingInfoFilterType } from "@training/types";
import { TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import config from "config";
import {
  Status,
  useGetBusinessUnitListQuery,
  useGetPositionListQuery,
  UserExportCsvInfo,
} from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";

import { getColumns } from "./columns";

type PropTypes = {
  assignedUsersTrainingList: UserExportCsvInfo[] | undefined;
  currentPage: number;
  pageTotal: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<
    SetStateAction<AssignedUsersTrainingInfoFilterType | undefined>
  >;
  isAssignedUsersTrainingListLoading: boolean;
  filterParams: AssignedUsersTrainingInfoFilterType;
};

const AssignedUsersTrainingInfoTable: FC<PropTypes> = ({
  assignedUsersTrainingList,
  currentPage,
  pageTotal,
  setCurrentPage,
  setFilterParams,
  filterParams,
  isAssignedUsersTrainingListLoading,
}) => {
  const businessUnitsQuery = useGetBusinessUnitListQuery();
  const positionsQuery = useGetPositionListQuery();

  const assignedUsersTrainingListTableData = assignedUsersTrainingList?.map(
    (assignedUserTraining) => {
      return {
        ...assignedUserTraining,
        key: assignedUserTraining.fullName,
      };
    },
  );

  const columns = getColumns({
    businessUnits: businessUnitsQuery.data?.getBusinessUnitList || [],
    positions: positionsQuery.data?.getPositionList || [],
    setFilterParams,
    filterParams,
  });

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onTableChange: TableProps<UserExportCsvInfo>["onChange"] = (
    _pagination,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<UserExportCsvInfo>;
    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setFilterParams((filterParams) => {
      return {
        ...filterParams,
        ...sorterFields,
        statusFilter: filters.status as Status[],
        positionFilter: filters.position as string[],
        businessUnitFilter: filters.businessUnit as string[],
      };
    });
  };

  const isLoading = loadingService.checkLoading([
    isAssignedUsersTrainingListLoading,
    businessUnitsQuery.loading,
    positionsQuery.loading,
  ]);

  return (
    <Table
      data-testid="assigned-users-on-training-table"
      columns={columns}
      dataSource={assignedUsersTrainingListTableData}
      onChange={onTableChange}
      pagination={{
        current: currentPage,
        total: pageTotal,
        showSizeChanger: false,
        onChange: onPageChange,
        pageSize: config.TABLE_LIMIT,
      }}
      loading={isLoading}
    />
  );
};

export default AssignedUsersTrainingInfoTable;
