import { Table } from "@common/components";
import { CertificateInfo } from "graphqlTypes";
import { FC } from "react";

import { getColumns } from "./columns";

type PropTypes = {
  certificates: CertificateInfo[];
};

const TrainingsTableOverdue: FC<PropTypes> = ({ certificates }) => {
  const columns = getColumns();

  const tableData = certificates.map((certificate) => {
    return { key: certificate.trainingId, ...certificate };
  });

  return <Table columns={columns} dataSource={tableData} />;
};

export default TrainingsTableOverdue;
