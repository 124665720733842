export enum ProjectTab {
  ProjectTeam = "projectTeam",
  TaskAssignment = "taskAssignment",
  GapAnalytics = "gapAnalytics",
  SuggestedCandidates = "suggestedCandidates",
}

export enum ManageAdminProjectsTab {
  Projects = "projects",
  MyProjects = "myProjects",
}
