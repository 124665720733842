import { setContext } from "@apollo/client/link/context";
import tokenServices from "@auth/services/tokenServices";

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await tokenServices.getToken();

  return {
    headers: {
      ...headers,
      "x-auth": accessToken,
    },
  };
});

export default authLink;
