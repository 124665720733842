import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Button, Image } from "@common/components";
import loadingService from "@common/services/loadingServices";
import { Col, Row } from "antd";
import buildcorpLogoWhite from "assets/images/BuildcorpLogoWhite.svg";
import MicrosoftIcon from "assets/images/MicrosoftIcon.svg";
import { useTrySignInMutation } from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const SignIn: FC = () => {
  const { instance, inProgress } = useMsal();
  const { t } = useTranslation("auth.SignIn");

  const [trySignIn, trySignInStatus] = useTrySignInMutation();

  const onLogicClick = async () => {
    await instance.loginPopup();
    trySignIn({
      onCompleted: async () => {
        apolloProvider.apolloApiClient.refetchQueries({
          include: ["getUser"],
        });
      },
    });
  };

  const interactionStatuses = [
    InteractionStatus.Login,
    InteractionStatus.SsoSilent,
  ];

  const isMsalLoading = !!interactionStatuses.find(
    (status) => status === inProgress,
  );

  const isLoading = loadingService.checkLoading([
    trySignInStatus.loading,
    isMsalLoading,
  ]);

  return (
    <div className={styles.container}>
      <Image
        className={styles.logo}
        preview={false}
        alt="logo"
        src={buildcorpLogoWhite}
      />
      <Row
        justify="center"
        className={styles.signinFormContainer}
        gutter={[0, 12]}
      >
        <Col span={24}>
          <Row
            gutter={[12, 0]}
            wrap={false}
            className={styles.appName}
            align="bottom"
            justify="center"
          >
            <Col>{t("train")}</Col>
            <Col>{t("track")}</Col>
          </Row>
        </Col>
        <Col className={styles.title} span={24}>
          {t("signIn")}
        </Col>
        <Col span={24}>
          <Button
            loading={isLoading}
            icon={
              <Image
                className={styles.microsoftIcon}
                preview={false}
                src={MicrosoftIcon}
              />
            }
            onClick={onLogicClick}
            className={styles.signinButton}
          >
            {t("withMicrosoft")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;
