import config from "config";
import * as yup from "yup";

export type RequestTrainingByProjectFormType = {
  trainingIds: string[];
  description?: string;
};

export const schema: yup.SchemaOf<RequestTrainingByProjectFormType> =
  yup.object({
    trainingIds: yup.array().min(1).required(),
    description: yup.string().max(config.SHORT_DESCRIPTION_LENGTH),
  });
