import { gql } from "@apollo/client";

import { TRAINING_REQUEST_INFO_FRAGMENT } from "./trainingRequestInfoFragment";

export const CERTIFICATE_INFO_FRAGMENT = gql`
  ${TRAINING_REQUEST_INFO_FRAGMENT}
  fragment CertificateInfoNode on CertificateInfo {
    certificateId
    trainingId
    name
    need
    status
    projectId
    completedAt
    assignedAt
    dueTo
    certificateLink
    hidden
    request {
      ...TrainingRequestInfoNode
    }
  }
`;
