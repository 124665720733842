import { gql } from "@apollo/client";

export const GRAPH_USER_FRAGMENT = gql`
  fragment GraphUserNode on MicrosoftGraphUser {
    businessPhones
    displayName
    givenName
    jobTitle
    mail
    mobilePhone
    officeLocation
    preferredLanguage
    surname
    userPrincipalName
    id
  }
`;
