import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, ReactQuill } from "@common/components";
import AttachmentDownload from "@help/components/AttachmentDownload";
import { Row, Col } from "antd";
import RoutePaths from "config/routePaths";
import { Faq, useDeleteFaqMutation } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

type PropTypes = {
  question: Faq;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const DisplayEditableQuestion: FC<PropTypes> = ({
  setIsEditMode,
  question,
}) => {
  const { t } = useTranslation("help.DisplayEditableQuestion");
  const [deleteFAQ, deleteFAQMutationStatus] = useDeleteFaqMutation();
  const navigate = useNavigate();

  const onEditClick = () => {
    setIsEditMode(true);
  };

  const onDelete = () => {
    deleteFAQ({
      variables: {
        id: question.id,
      },
      onCompleted: () => {
        navigate(RoutePaths.Help);
      },
      refetchQueries: ["listFAQ"],
    });
  };

  return (
    <Spin spinning={deleteFAQMutationStatus.loading}>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={24}>
          <Row align="middle" gutter={24}>
            <Col className={styles.title}>{question.title}</Col>
            <Col>
              <Button
                data-testid="edit"
                icon={<EditOutlined />}
                onClick={onEditClick}
                type="link"
              >
                {t("edit")}
              </Button>
            </Col>
            <Col>
              <Popconfirm
                okText={<span data-testid="yes">{t("yes")}</span>}
                cancelText={t("no")}
                onConfirm={onDelete}
                title={t("deleteConfirmation")}
              >
                <Button
                  data-testid="delete"
                  icon={<DeleteOutlined />}
                  type="link"
                  danger
                >
                  {t("delete")}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.text}>
          <ReactQuill readOnly theme="bubble" value={question.description} />
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col>
              <Row gutter={[12, 0]}>
                <Col>{t("attachments")}:</Col>
                <Col>
                  <Row gutter={[24, 24]}>
                    {question.attachments.map((attachment) => {
                      return (
                        <Col data-cy="attachment" key={attachment.url}>
                          <AttachmentDownload attachment={attachment} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default DisplayEditableQuestion;
