import { CheckOutlined, FileOutlined } from "@ant-design/icons";
import { Row, Col, Spin } from "antd";
import { GapTrainingInfo, Role, Status, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const rolesWithoutDownloading = [Role.User];

type PropTypes = {
  userGapTraining: GapTrainingInfo;
};

const CertificateCell: FC<PropTypes> = ({ userGapTraining }) => {
  const { t } = useTranslation("project.CertificateCell");
  const userQuery = useGetUserQuery();

  const userRole = userQuery.data?.getUser?.role;

  const isWithoutDownloading = rolesWithoutDownloading.some((role) => {
    return userRole === role;
  });

  const certificateByStatus = {
    [Status.Done]: (
      <Spin spinning={userQuery.loading}>
        <Row justify="center" align="middle" wrap={false}>
          <Col>
            <CheckOutlined className={styles.checkIcon} />
          </Col>
          <Col>
            {isWithoutDownloading ? (
              <FileOutlined className={styles.fileIcon} />
            ) : (
              <a
                target="_blank"
                href={userGapTraining.certificate?.certificate}
                rel="noreferrer"
              >
                <FileOutlined className={styles.fileIcon} />
              </a>
            )}
          </Col>
        </Row>
      </Spin>
    ),
    [Status.Expired]: <div className={styles.overdue}>{t("expired")}</div>,
    [Status.Required]: <div className={styles.overdue}>{t("required")}</div>,
    [Status.Archived]: <div className={styles.archived}>{t("archived")}</div>,
  };

  return certificateByStatus[userGapTraining.status];
};

export default CertificateCell;
