import { DownloadOutlined } from "@ant-design/icons";
import { Attachment } from "graphqlTypes";
import { FC } from "react";

type PropTypes = {
  attachment: Attachment;
};

const AttachmentDownload: FC<PropTypes> = ({ attachment }) => {
  return (
    <a target="_blank" href={attachment.url} rel="noreferrer">
      <DownloadOutlined />
      <span>{attachment.name}</span>
    </a>
  );
};

export default AttachmentDownload;
