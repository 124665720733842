import { Input } from "@common/components";
import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useSearchParams } from "@common/hooks/useSearchParams";
import LogsServices from "@common/services/logsServices";
import AwaitingCommencementTable from "@dashboard/components/AwaitingCommencementTable";
import { Col, Row } from "antd";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

const logsServices = new LogsServices(
  ModuleNameEnum.Help,
  "components",
  "LeaderboardInfo",
);

const ManageAwaitingCommencementBoard: FC = () => {
  const { t } = useTranslation("dashboard.ManageAwaitingCommencementBoard");
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearchText = useDebounce(searchText);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });

    logsServices.logVerbose({
      location: ["onSearchChange"],
      message: `Dashboard search: ${searchText}`,
    });
  };

  return (
    <Row justify="center" gutter={[0, 24]}>
      <Col span={24}>
        <Input
          data-testid="awaiting-commencement-search"
          value={searchText || ""}
          onChange={onSearchChange}
          placeholder={t("searchPlaceholder")}
        />
      </Col>
      <Col span={24}>
        <AwaitingCommencementTable searchText={debouncedSearchText || ""} />
      </Col>
    </Row>
  );
};

export default ManageAwaitingCommencementBoard;
