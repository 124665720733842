import { Table } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices/index";
import { GET_PROFILE_INFO_QUERY } from "@profile/graphql/queries/getProfileInfoQuery";
import { GET_USER_INFO_QUERY } from "@profile/graphql/queries/getUserInfoQuery";
import config from "config";
import {
  CertificateInfo,
  Role,
  useGetUserQuery,
  useHideOverdueCertificateMutation,
  UserInfo,
  useShowOverdueCertificateMutation,
} from "graphqlTypes";
import { FC } from "react";

import { getColumns } from "./columns";
import { getColumnsAdmin } from "./columnsAdmin";
import styles from "./styles.module.scss";

const getColumnsByRole = {
  [Role.SuperAdmin]: getColumnsAdmin,
  [Role.Admin]: getColumnsAdmin,
  [Role.Manager]: getColumns,
  [Role.User]: getColumns,
};

type PropTypes = {
  certificates: CertificateInfo[];
  userData?: UserInfo;
};

const EditTrainingsTableOverdue: FC<PropTypes> = ({
  certificates,
  userData,
}) => {
  const { setModalProps, hideModal } = useModal();
  const [hideTrainingMutation, hideTrainingMutationStatus] =
    useHideOverdueCertificateMutation();
  const [showTrainingMutation, showTrainingMutationStatus] =
    useShowOverdueCertificateMutation();
  const currentUserDataQuery = useGetUserQuery();

  const currentUserId = currentUserDataQuery.data?.getUser?.id;
  const currentUserRole = currentUserDataQuery.data?.getUser?.role;
  const isCurrentUser = currentUserId === userData?.user?.id;

  const updatedCacheData = {
    query: isCurrentUser ? GET_PROFILE_INFO_QUERY : GET_USER_INFO_QUERY,
    updatedCacheFieldName: isCurrentUser ? "getProfileInfo" : "getUserInfo",
    variables: isCurrentUser ? {} : { id: userData?.graphProfile?.id },
  };

  const hideTraining = (trainingId: number) => {
    hideTrainingMutation({
      variables: {
        userId: userData?.user?.id,
        trainingId,
      },
      update: (cache, response) => {
        const userProfileData = response.data?.hideOverdueCertificate;

        cache.writeQuery({
          query: updatedCacheData.query,
          data: { [updatedCacheData.updatedCacheFieldName]: userProfileData },
          variables: updatedCacheData.variables,
        });
        if (isCurrentUser) {
          cache.writeQuery({
            query: GET_USER_INFO_QUERY,
            data: { getUserInfo: userProfileData },
            variables: { id: userData?.graphProfile?.id },
          });
        }
      },
    });
  };

  const showTraining = (trainingId: number) => {
    showTrainingMutation({
      variables: {
        userId: userData?.user?.id,
        trainingId,
      },
      update: (cache, response) => {
        const userProfileData = response.data?.showOverdueCertificate;

        cache.writeQuery({
          query: updatedCacheData.query,
          data: { [updatedCacheData.updatedCacheFieldName]: userProfileData },
          variables: updatedCacheData.variables,
        });
        if (isCurrentUser) {
          cache.writeQuery({
            query: GET_USER_INFO_QUERY,
            data: { getUserInfo: userProfileData },
            variables: { id: userData?.graphProfile?.id },
          });
        }
      },
    });
  };

  const columns = getColumnsByRole[currentUserRole || config.DEFAULT_ROLE]({
    setModalProps,
    hideModal,
    userData,
    hideTraining,
    showTraining,
  });

  const tableData = certificates.map((certificate) => {
    return { key: certificate.trainingId, ...certificate };
  });

  const isLoading = loadingService.checkLoading([
    showTrainingMutationStatus.loading,
    hideTrainingMutationStatus.loading,
    currentUserDataQuery.loading,
  ]);

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={tableData}
      rowClassName={(certificate) => {
        return certificate.hidden ? styles.hiddenRow : "";
      }}
    />
  );
};

export default EditTrainingsTableOverdue;
