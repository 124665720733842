import { List, ListItem } from "@common/components";
import EditMyTrainingCard from "@training/components/EditMyTrainingCard";
import { TrainingMatrixInfo } from "graphqlTypes";
import { FC } from "react";

type PropsTypes = {
  trainingMatrixInfoList: TrainingMatrixInfo[];
  totalPageCount?: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

const MyTrainingMatrixCardList: FC<PropsTypes> = ({
  trainingMatrixInfoList,
  totalPageCount,
  currentPage,
  onPageChange,
}: PropsTypes) => {
  return (
    <List
      pagination={{
        current: currentPage,
        total: totalPageCount,
        onChange: onPageChange,
        showSizeChanger: false,
      }}
    >
      {trainingMatrixInfoList.map((trainingMatrixInfo) => {
        return (
          <ListItem
            key={`${trainingMatrixInfo.certificate.certificateId}:${trainingMatrixInfo.certificate.trainingId}`}
          >
            <EditMyTrainingCard trainingMatrixInfo={trainingMatrixInfo} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default MyTrainingMatrixCardList;
