import environmentsServices from "@common/services/environmentsServices";
import "assets/styles";
import "config/antdThem";
import "providers/appInsight";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

environmentsServices.validateEnvironments();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
