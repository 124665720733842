import StatusCell from "@common/components/StatusCell";
import RequestExistedCertificateForm from "@training/components/RequestExistedCertificateForm";
import RequestTrainingCell from "@training/components/RequestTrainingCell";
import UploadTrainingCell from "@training/components/UploadTrainingCell";
import { ModalProps } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import config from "config";
import features from "config/features";
import { format } from "date-fns";
import { Need, CertificateInfo, UserInfo } from "graphqlTypes";
import i18n from "providers/i18n";
import { Dispatch, SetStateAction } from "react";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.EditTrainingsTableOverdue",
);

type PropTypes = {
  hideModal: () => void;
  setModalProps: Dispatch<SetStateAction<ModalProps>>;
  userData?: UserInfo;
};

export const getColumns = ({
  hideModal,
  setModalProps,
  userData,
}: PropTypes): ColumnsType<CertificateInfo> => {
  const translatedNeed = {
    [Need.JobDepended]: t("jobDependent"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  return [
    {
      title: t("trainingName"),
      dataIndex: "trainingName",
      key: "trainingName",
      render: (_, certificate) => {
        return certificate.name;
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (_, certificate) => {
        return certificate.assignedAt
          ? format(new Date(certificate.assignedAt), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("expiry"),
      dataIndex: "expiry",
      key: "expiry",
      render: (_, certificate) => {
        return certificate.dueTo
          ? format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("need"),
      dataIndex: "need",
      key: "need",
      render: (_, certificate) => {
        return translatedNeed[certificate.need];
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (_, certificate) => {
        return <StatusCell status={certificate.status} />;
      },
    },
    {
      title: t("addRecords"),
      dataIndex: "addRecords",
      key: "addRecords",
      render: (_, certificate) => {
        return (
          <UploadTrainingCell userData={userData} certificate={certificate} />
        );
      },
    },
    ...(features.request
      ? [
          {
            dataIndex: "request",
            key: "request",
            render: (_: undefined, certificate: CertificateInfo) => {
              const onRequestClick = () => {
                setModalProps({
                  footer: false,
                  onCancel: hideModal,
                  children: (
                    <RequestExistedCertificateForm
                      key={certificate.trainingId}
                      certificate={certificate}
                      userData={userData}
                    />
                  ),
                  open: true,
                });
              };
              return (
                <RequestTrainingCell
                  onRequestClick={onRequestClick}
                  certificate={certificate}
                />
              );
            },
          },
        ]
      : []),
  ];
};
