import { Link } from "@common/components";
import { Result, Spin } from "antd";
import RoutePaths from "config/routePaths";
import { useGetUserQuery } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const DoesNotExist: FC = () => {
  const { t } = useTranslation("common.DoesNotExist");
  const userQuery = useGetUserQuery();

  return (
    <Spin spinning={userQuery.loading}>
      <Result
        status="404"
        title="404"
        subTitle={t("hintForUser")}
        extra={
          userQuery.data?.getUser ? (
            <Link to={RoutePaths.Profile}>{t("toProfile")}</Link>
          ) : (
            <Link to={RoutePaths.SignIn}>{t("toSignIn")}</Link>
          )
        }
      />
    </Spin>
  );
};
export default DoesNotExist;
