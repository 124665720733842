import Checkbox from "@common/components/Checkbox";
import { ElementMetadataType } from "@common/types";
import { CheckboxProps } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useController, UseControllerProps } from "react-hook-form";

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  checkboxProps?: CheckboxProps & ElementMetadataType;
};

const ControlledCheckbox = <T extends object>({
  useControllerProps,
  checkboxProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, name, value },
  } = useController(useControllerProps);

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    onChange(event);
    checkboxProps?.onChange && checkboxProps.onChange(event);
  };

  return (
    <Checkbox
      checked={value}
      name={name}
      onChange={onCheckboxChange}
      {...checkboxProps}
    />
  );
};

export default ControlledCheckbox;
