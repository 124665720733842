import { FileExcelOutlined } from "@ant-design/icons";
import { Button, Input } from "@common/components";
import { useDebounce } from "@common/hooks/useDebounce";
import AssignedUsersTrainingInfoTable from "@training/components/AssignedUsersTrainingInfoTable";
import { AssignedUsersTrainingInfoFilterType } from "@training/types";
import { Col, Row } from "antd";
import config from "config";
import { Training, useGetAssignedUsersTrainingInfoQuery } from "graphqlTypes";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  training: Training;
};

const AssignedUsersTrainingInfo: FC<PropTypes> = ({ training }) => {
  const { t } = useTranslation("training.AssignedUsersTrainingInfo");
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] =
    useState<AssignedUsersTrainingInfoFilterType>();

  const assignedUsersTrainingQuery = useGetAssignedUsersTrainingInfoQuery({
    variables: {
      trainingId: training.id,
      search: debouncedSearchText,
      nameSorting: filterParams?.fullName,
      positionFilter: filterParams?.positionFilter,
      businessUnitFilter: filterParams?.businessUnitFilter,
      completeDateSorting: filterParams?.competed,
      expiryDateSorting: filterParams?.expiry,
      expiryFilter: filterParams?.expiryFilter,
      statusFilter: filterParams?.statusFilter,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
      limit: config.TABLE_LIMIT,
    },
  });

  const assignedUsersTrainingList =
    assignedUsersTrainingQuery.data?.getAssignedUsersTrainingInfo?.data;

  const exportUrl =
    assignedUsersTrainingQuery.data?.getAssignedUsersTrainingInfo?.exportUrl;

  const pageTotal =
    assignedUsersTrainingQuery.data?.getAssignedUsersTrainingInfo?.total;

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
    setCurrentPage(1);
  };

  return (
    <Row gutter={[0, 24]}>
      <Col className={styles.title}>{training.name}</Col>
      <Col span={24}>
        <Row gutter={[12, 0]}>
          <Col flex="auto">
            <Input
              data-testid="assigned-users-on-training-search-user"
              value={searchText}
              onChange={onSearchChange}
              placeholder={t("searchPlaceholder")}
              className={styles.search}
            />
          </Col>
          <Col>
            {exportUrl && (
              <Button
                type="dashed"
                data-testid="assigned-users-on-training-download-table-button"
                href={exportUrl}
                rel="noreferrer"
                target="_blank"
                icon={<FileExcelOutlined />}
              >
                {t("downloadExcel")}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AssignedUsersTrainingInfoTable
          setCurrentPage={setCurrentPage}
          pageTotal={pageTotal || 0}
          currentPage={currentPage}
          setFilterParams={setFilterParams}
          assignedUsersTrainingList={assignedUsersTrainingList}
          isAssignedUsersTrainingListLoading={
            assignedUsersTrainingQuery.loading
          }
          filterParams={filterParams || {}}
        />
      </Col>
    </Row>
  );
};

export default AssignedUsersTrainingInfo;
