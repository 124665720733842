import { Popconfirm as PopconfirmANTD, PopconfirmProps } from "antd";
import { FC } from "react";

import styles from "./styles.module.scss";

const Popconfirm: FC<PopconfirmProps> = (props: PopconfirmProps) => {
  return <PopconfirmANTD overlayClassName={styles.popup} {...props} />;
};

export default Popconfirm;
