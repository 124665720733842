import { Layout } from "@common/enums";
import { ModuleType, AuthType } from "@common/types";
import RoutePaths from "config/routePaths";

import SignIn from "./pages/SignIn";

const auth: ModuleType = {
  routes: [
    {
      layout: Layout.NoLayout,
      path: RoutePaths.SignIn,
      Page: SignIn,
      type: AuthType.NoAuth,
      roles: [],
    },
  ],
  contextProviders: [],
};

export default auth;
