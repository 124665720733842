import fileServices from "@common/services/fileServices";
import textServices from "@common/services/textServices";
import { UploadedFileType } from "@common/types";
import { UploadFile } from "antd/lib/upload/interface";
import config from "config";
import { Role } from "graphqlTypes";
import i18n from "providers/i18n";
import * as yup from "yup";

export type AddQuestionFormType = {
  questionTitle: string;
  roles: Role[];
  description: string;
  attachment?: UploadedFileType;
};

export const schema: yup.SchemaOf<AddQuestionFormType> = yup.object({
  questionTitle: yup.string().required(),
  description: yup
    .string()
    .test(
      "descriptionLength",
      i18n.t("descriptionMostThan", {
        ns: "help.CreateQuestionForm",
        maxLength: config.LONG_DESCRIPTION_LENGTH,
      }),
      (text) => {
        const clearText = textServices.clearHtmlText(text || "");

        return (clearText?.length || 0) < config.LONG_DESCRIPTION_LENGTH;
      },
    )
    .test(
      "empty",
      i18n.t("descriptionRequired", { ns: "help.CreateQuestionForm" }),
      (text) => {
        const clearText = textServices.clearHtmlText(text || "");
        return !!clearText?.length;
      },
    )
    .required(i18n.t("descriptionRequired", { ns: "help.CreateQuestionForm" })),
  roles: yup.array().required(),
  attachment: yup
    .object({
      file: yup.mixed<UploadFile>(),
      fileList: yup.array<UploadFile>(),
    })
    .test("fileError", "File Error", (attachment) => {
      const isError = fileServices.validateAttachmentFile(
        attachment,
        config.HELP_FILE_TYPES,
      );
      return !isError;
    }),
});
