import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserNode on User {
    active
    id
    graphId
    email
    role
    createdAt
    updatedAt
    inactiveTrainingStatus
    showedPrompts
  }
`;
