import { SelectProps, Select as SelectANTD } from "antd";
import { FC } from "react";

type PropTypes = SelectProps;

const Select: FC<PropTypes> = (props) => {
  return (
    <SelectANTD
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    />
  );
};

export default Select;
