import { Tabs } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useSearchParams } from "@common/hooks/useSearchParams";
import LeaderboardInfo from "@dashboard/components/LeaderboardInfo";
import ManageAwaitingCommencementBoard from "@dashboard/components/ManageAwaitingCommencementBoard";
import { DashboardTabEnum } from "@dashboard/enum";
import { Col, Row } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const ManageDashboard: FC = () => {
  const { t } = useTranslation("dashboard.ManageDashboard");
  const { getSearchParam, setSearchParams } = useSearchParams();

  const currentTab = getSearchParam(SearchParamsEnum.Tab);

  const tabItems = [
    {
      label: t("beingConstructed"),
      key: DashboardTabEnum.BeingConstructed,
      children: <LeaderboardInfo />,
    },
    {
      label: t("awaitingCommencement"),
      key: DashboardTabEnum.AwaitingCommencement,
      children: <ManageAwaitingCommencementBoard />,
    },
  ];

  const onTabChange = (tabName: string) => {
    setSearchParams({
      [SearchParamsEnum.Tab]: tabName,
    });
  };

  return (
    <Row justify="center">
      <Col span={23}>
        <Tabs
          activeKey={currentTab || DashboardTabEnum.BeingConstructed}
          onChange={onTabChange}
          items={tabItems}
        />
      </Col>
    </Row>
  );
};

export default ManageDashboard;
