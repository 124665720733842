import Upload from "@common/components/Upload";
import { ElementMetadataType } from "@common/types";
import { UploadProps } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useController, UseControllerProps } from "react-hook-form";

type PropTypes<T extends object> = {
  useControllerProps: UseControllerProps<T>;
  uploadProps?: UploadProps & ElementMetadataType;
};

const ControlledUpload = <T extends object>({
  useControllerProps,
  uploadProps,
}: PropTypes<T>): JSX.Element => {
  const {
    field: { onChange, name },
  } = useController(useControllerProps);

  const onUploadChange = (file: UploadChangeParam<UploadFile>) => {
    onChange(file);
    uploadProps?.onChange && uploadProps?.onChange(file);
  };

  return <Upload name={name} onChange={onUploadChange} {...uploadProps} />;
};

export default ControlledUpload;
