import HeaderUserData from "@profile/components/HeaderUserData";
import ProfileAvatar from "@profile/components/ProfileAvatar";
import { Col, Row } from "antd";
import { UserInfo } from "graphqlTypes";
import { FC } from "react";

type PropTypes = {
  profileData: UserInfo | null | undefined;
};

const ProfileHeader: FC<PropTypes> = ({ profileData }) => {
  return (
    <Row wrap={false} justify="start" gutter={[24, 24]}>
      <Col>
        <ProfileAvatar />
      </Col>
      <Col>
        <HeaderUserData profileData={profileData} />
      </Col>
    </Row>
  );
};

export default ProfileHeader;
