import { BackButton, Spin } from "@common/components";
import UserProfileHeader from "@profile/components/UserProfileHeader";
import TrainingCardActive from "@training/components/TrainingCardActive";
import TrainingCardOverdue from "@training/components/TrainingCardOverdue";
import TrainingsCardList from "@training/components/TrainingsCardList";
import TrainingsTableActive from "@training/components/TrainingsTableActive";
import TrainingsTableOverdue from "@training/components/TrainingsTableOverdue";
import { Col, Row } from "antd";
import config from "config";
import { Status, useGetUserInfoQuery } from "graphqlTypes";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";

const doneStatuses = [Status.Done, Status.Archived];
const pendingStatuses = [Status.Expired, Status.Required];

const UserProfileView: FC = () => {
  const { t } = useTranslation("user.UserProfileView");
  const { userId } = useParams();
  const [isPhoneScreenSize, setIsPhoneScreenSize] = useState<boolean>();
  const profileInfoQuery = useGetUserInfoQuery({
    variables: {
      id: Number(userId) || userId,
    },
  });

  const userData = profileInfoQuery.data?.getUserInfo;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsPhoneScreenSize(window.innerWidth <= config.PHONE_SCREEN_SIZE);
    });
    setIsPhoneScreenSize(window.innerWidth <= config.PHONE_SCREEN_SIZE);
  }, []);

  const doneTrainings = userData?.trainings?.filter((training) => {
    return doneStatuses.some((status) => {
      return status === training.status;
    });
  });

  const doneTrainingsWithCustom = [
    ...(doneTrainings || []),
    ...(userData?.customCertificates || []),
  ];

  const pendingTrainings = userData?.trainings?.filter((training) => {
    return pendingStatuses.some((status) => {
      return status === training.status;
    });
  });

  return (
    <Spin spinning={profileInfoQuery.loading}>
      <Row justify="center" gutter={[0, 16]}>
        <Col span={23}>
          <BackButton />
        </Col>
        <Col span={23}>
          <UserProfileHeader profileData={userData} />
        </Col>
        <Col className={styles.titleText} span={23}>
          {t("certificates")}:
        </Col>
        {pendingTrainings?.length ? (
          <>
            <Col span={23} className={styles.overdue}>
              {t("overdue")}:
            </Col>
            <Col span={23}>
              {isPhoneScreenSize ? (
                <TrainingsCardList
                  TrainingCard={TrainingCardOverdue}
                  certificates={pendingTrainings || []}
                  userData={userData || undefined}
                />
              ) : (
                <TrainingsTableOverdue certificates={pendingTrainings || []} />
              )}
            </Col>
          </>
        ) : null}
        <Col span={23} className={styles.done}>
          {t("active")}:
        </Col>
        <Col span={23}>
          {isPhoneScreenSize ? (
            <TrainingsCardList
              TrainingCard={TrainingCardActive}
              certificates={doneTrainingsWithCustom}
              userData={userData || undefined}
            />
          ) : (
            <TrainingsTableActive certificates={doneTrainingsWithCustom} />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default UserProfileView;
