import StatusCell from "@common/components/StatusCell";
import { ColumnsType } from "antd/lib/table/interface";
import config from "config";
import { format } from "date-fns";
import { CertificateInfo, Need, Status } from "graphqlTypes";
import i18n from "providers/i18n";

const t: (key: string) => string = i18n.getFixedT(
  null,
  "training.TrainingsTableActive",
);

export const getColumns = (): ColumnsType<CertificateInfo> => {
  const translatedNeed = {
    [Need.JobDepended]: t("jobDependent"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const statusFilterOptions = [
    { value: Status.Archived, text: t("archived") },
    { value: Status.Done, text: t("done") },
  ];

  return [
    {
      title: t("trainingName"),
      dataIndex: "trainingName",
      key: "trainingName",
      render: (_, certificate) => {
        return certificate.name;
      },
    },
    {
      title: t("completed"),
      dataIndex: "completed",
      key: "completed",
      render: (_, certificate) => {
        return certificate.completedAt
          ? format(new Date(certificate.completedAt), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("expiry"),
      dataIndex: "expiry",
      key: "expiry",
      render: (_, certificate) => {
        return certificate?.dueTo
          ? format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)
          : null;
      },
    },
    {
      title: t("need"),
      dataIndex: "need",
      key: "need",
      render: (_, certificate) => {
        return translatedNeed[certificate.need];
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      filters: statusFilterOptions,
      onFilter: (filter, certificate) => {
        return filter === certificate.status;
      },
      render: (_, certificate) => {
        return <StatusCell status={certificate.status} />;
      },
    },
  ];
};
