import { Spin } from "@common/components";
import UserProfileEdit from "@user/components/UserProfileEdit";
import UserProfileView from "@user/components/UserProfileView";
import config from "config";
import { Role, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";

const userProfileByRole = {
  [Role.SuperAdmin]: UserProfileEdit,
  [Role.Admin]: UserProfileEdit,
  [Role.Manager]: UserProfileEdit,
  [Role.User]: UserProfileView,
};

const UserProfile: FC = () => {
  const getUserQuery = useGetUserQuery();
  const userData = getUserQuery.data?.getUser;

  const UserProfile = userProfileByRole[userData?.role || config.DEFAULT_ROLE];

  return (
    <Spin spinning={getUserQuery.loading}>
      <UserProfile />
    </Spin>
  );
};

export default UserProfile;
