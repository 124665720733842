import { BackButton, Spin } from "@common/components";
import DisplayQuestion from "@help/components/DisplayQuestion";
import { Row, Col } from "antd";
import { useGetFaqQuery } from "graphqlTypes";
import { FC } from "react";
import { useParams } from "react-router-dom";

const ManageQuestionEdit: FC = () => {
  const { questionId } = useParams();

  const questionQuery = useGetFaqQuery({
    variables: { id: questionId },
  });

  const question = questionQuery.data?.getFAQ;

  return (
    <Spin spinning={questionQuery.loading}>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={23}>
          <BackButton />
        </Col>
        <Col span={23}>
          {question && <DisplayQuestion question={question} />}
        </Col>
      </Row>
    </Spin>
  );
};

export default ManageQuestionEdit;
