import { NotificationType, NotificationInfo } from "graphqlTypes";

class NotificationsServices {
  private notificationDataByType = {
    [NotificationType.Certificate]: (notification: NotificationInfo) => {
      return {
        localeName: "addNewCertificate",
        localeParams: {
          userName: notification.recipient.displayName,
        },
      };
    },
    [NotificationType.Diploma]: (notificationData: NotificationInfo) => {
      return {
        localeName: "addedDiploma",
        localeParams: {
          userName: notificationData.recipient.displayName,
        },
      };
    },
    [NotificationType.Request]: (notificationData: NotificationInfo) => {
      return {
        localeName: "requestTrainings",
        localeParams: {
          userName: notificationData.recipient.displayName,
          trainings: notificationData.notification.trainingName?.join(", "),
        },
      };
    },
    [NotificationType.Require]: (notificationData: NotificationInfo) => {
      return {
        localeName: "requiresTraining",
        localeParams: {
          senderUserName: notificationData.sender?.displayName,
          recipientUserName: notificationData.recipient.displayName,
          training: notificationData.notification.trainingName?.join(),
        },
      };
    },
    [NotificationType.RequestForAnother]: (
      notificationData: NotificationInfo,
    ) => {
      return {
        localeName: "requestForAnother",
        localeParams: {
          senderUserName: notificationData.sender?.displayName,
          recipientUserName: notificationData.recipient.displayName,
          training: notificationData.notification.trainingName?.join(),
        },
      };
    },
    [NotificationType.CertificateForAnother]: (
      notificationData: NotificationInfo,
    ) => {
      return {
        localeName: "certificateForAnother",
        localeParams: {
          senderUserName: notificationData.sender?.displayName,
          recipientUserName: notificationData.recipient.displayName,
          training: notificationData.notification.trainingName?.join(),
        },
      };
    },
  };

  getNotificationDataByType = (notificationData: NotificationInfo) => {
    const notificationType = notificationData.notification.type;
    return this.notificationDataByType[notificationType](notificationData);
  };
}

const notificationsServices = new NotificationsServices();

export default notificationsServices;
