import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Instant: any;
  LocalDate: any;
  LocalDateTime: any;
  Long: any;
  Unit: any;
  Upload: any;
  UserID: any;
};

export enum AccountStatus {
  Active = "Active",
  Inactive = "Inactive",
  Proactive = "Proactive",
  Reactive = "Reactive",
}

export type AdditionalCertificate = {
  __typename?: "AdditionalCertificate";
  archived: Scalars["Boolean"];
  certificate: Scalars["String"];
  completed: Scalars["LocalDateTime"];
  createdAt: Scalars["LocalDateTime"];
  dueTo?: Maybe<Scalars["LocalDateTime"]>;
  format: Scalars["String"];
  id?: Maybe<Scalars["Long"]>;
  trainingName: Scalars["String"];
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type Attachment = {
  __typename?: "Attachment";
  format: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type AttachmentInput = {
  format: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type CertificateInfo = {
  __typename?: "CertificateInfo";
  assignedAt?: Maybe<Scalars["LocalDateTime"]>;
  certificateId?: Maybe<Scalars["Long"]>;
  certificateLink?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["LocalDateTime"]>;
  dueTo?: Maybe<Scalars["LocalDateTime"]>;
  hidden: Scalars["Boolean"];
  name: Scalars["String"];
  need: Need;
  projectId?: Maybe<Scalars["String"]>;
  request?: Maybe<TrainingRequestInfo>;
  status: Status;
  trainingId?: Maybe<Scalars["Long"]>;
};

export type DateRangeInput = {
  from: Scalars["LocalDate"];
  to: Scalars["LocalDate"];
};

export type Education = {
  __typename?: "Education";
  createdAt: Scalars["LocalDateTime"];
  diplomaUrl: Scalars["String"];
  fieldOfStudy: Scalars["String"];
  format: Scalars["String"];
  id?: Maybe<Scalars["Long"]>;
  universityName: Scalars["String"];
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
  year: Scalars["Int"];
};

/** Either String or MicrosoftGraphUser */
export type EitherStringOrMicrosoftGraphUser = {
  __typename?: "EitherStringOrMicrosoftGraphUser";
  /** Left element of the Either */
  left?: Maybe<Scalars["String"]>;
  /** Right element of the Either */
  right?: Maybe<MicrosoftGraphUser>;
};

/** Either TrainingRequest or Training */
export type EitherTrainingRequestOrTraining = {
  __typename?: "EitherTrainingRequestOrTraining";
  /** Left element of the Either */
  left?: Maybe<TrainingRequest>;
  /** Right element of the Either */
  right?: Maybe<Training>;
};

export enum ExportExpireFilter {
  Days0 = "Days0",
  Days30 = "Days30",
  Days60 = "Days60",
  Days90 = "Days90",
}

export type Faq = {
  __typename?: "FAQ";
  attachments: Array<Attachment>;
  createdAt: Scalars["LocalDateTime"];
  description: Scalars["String"];
  id?: Maybe<Scalars["Long"]>;
  roles: Array<Role>;
  title: Scalars["String"];
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type GapAnalysis = {
  __typename?: "GapAnalysis";
  date: Scalars["LocalDate"];
  id?: Maybe<Scalars["Long"]>;
  info: Array<GapAnalysisUserInfo>;
  projectId: Scalars["String"];
};

export type GapAnalysisUserInfo = {
  __typename?: "GapAnalysisUserInfo";
  graphUser?: Maybe<MicrosoftGraphUser>;
  profileStatus: AccountStatus;
  taskTrainingInfoList: Array<GapTrainingInfo>;
  user?: Maybe<User>;
};

export type GapTrainingInfo = {
  __typename?: "GapTrainingInfo";
  certificate?: Maybe<TrainingCertificate>;
  need?: Maybe<Need>;
  status: Status;
  task?: Maybe<Task>;
  training: Training;
};

export type GetGapAnalysisByDateResult = {
  __typename?: "GetGapAnalysisByDateResult";
  exportUrl: Scalars["String"];
  gapAnalysis: GapAnalysis;
};

export type GetTaskInfo = {
  __typename?: "GetTaskInfo";
  exportUrl: Scalars["String"];
  taskInfo: PaginationTaskInfo;
};

export type LeaderboardAwaitingCommencementInfo = {
  __typename?: "LeaderboardAwaitingCommencementInfo";
  index: Scalars["Int"];
  projectInfo: ProjectInfo;
  projectTeamInfo: Array<LeaderboardProjectTeamInfo>;
  status: ProjectStatus;
};

export type LeaderboardInfo = {
  __typename?: "LeaderboardInfo";
  projectInfo: ProjectInfo;
  projectTeamInfo: Array<LeaderboardProjectTeamInfo>;
  rating: Scalars["Int"];
  status: ProjectStatus;
};

export type LeaderboardProjectTeamInfo = {
  __typename?: "LeaderboardProjectTeamInfo";
  certificatesValue: Scalars["Int"];
  graphUser?: Maybe<MicrosoftGraphUser>;
};

export type ListProjectInfo = {
  __typename?: "ListProjectInfo";
  exportUrl: Scalars["String"];
  projectInfo: PaginationProjectInfo;
};

export type MicrosoftGraphUser = {
  __typename?: "MicrosoftGraphUser";
  businessPhones: Array<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  jobTitle?: Maybe<Scalars["String"]>;
  mail?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  officeLocation?: Maybe<Scalars["String"]>;
  preferredLanguage?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<Scalars["String"]>;
};

export type Mutations = {
  __typename?: "Mutations";
  addAdditionalCertificate?: Maybe<AdditionalCertificate>;
  addEducationInfo?: Maybe<Education>;
  addTrainingCertificate?: Maybe<TrainingCertificate>;
  approveProjectTrainingRequest?: Maybe<Array<ProjectTrainingRequest>>;
  createFAQ?: Maybe<Faq>;
  createProjectTrainingRequest?: Maybe<Array<TrainingRequest>>;
  createTask?: Maybe<Task>;
  createTraining?: Maybe<Training>;
  createTrainingRequest?: Maybe<TrainingRequest>;
  declineProjectTrainingRequest?: Maybe<Array<ProjectTrainingRequest>>;
  deleteCustomCertificate?: Maybe<Scalars["Unit"]>;
  deleteDiploma?: Maybe<Scalars["Unit"]>;
  deleteFAQ?: Maybe<Scalars["Unit"]>;
  deleteTask?: Maybe<Scalars["Unit"]>;
  deleteTraining?: Maybe<Scalars["Unit"]>;
  deleteTrainingCertificate?: Maybe<Scalars["Unit"]>;
  getUnconfirmedProjectTrainingRequest?: Maybe<Array<ProjectTrainingRequest>>;
  hideOverdueCertificate?: Maybe<UserInfo>;
  readNotifications?: Maybe<Scalars["Unit"]>;
  removeSimulationData?: Maybe<Scalars["Unit"]>;
  requireAll?: Maybe<Scalars["Unit"]>;
  showOverdueCertificate?: Maybe<UserInfo>;
  testMutation?: Maybe<Scalars["String"]>;
  trySignIn?: Maybe<User>;
  updateFAQ?: Maybe<Faq>;
  updateGapAnalysisPreset?: Maybe<UpdateGapAnalysisPresetQuery>;
  updateTask?: Maybe<PaginationTaskInfo>;
  updateTraining?: Maybe<Training>;
  updateUser?: Maybe<User>;
};

export type MutationsAddAdditionalCertificateArgs = {
  certificate: Scalars["Upload"];
  completed: Scalars["LocalDateTime"];
  dueTo?: InputMaybe<Scalars["LocalDateTime"]>;
  trainingName: Scalars["String"];
  userId?: InputMaybe<Scalars["Long"]>;
};

export type MutationsAddEducationInfoArgs = {
  diploma: Scalars["Upload"];
  fieldOfStudy: Scalars["String"];
  universityName: Scalars["String"];
  userId?: InputMaybe<Scalars["Long"]>;
  year: Scalars["Int"];
};

export type MutationsAddTrainingCertificateArgs = {
  certificate: Scalars["Upload"];
  completed: Scalars["LocalDateTime"];
  dueTo?: InputMaybe<Scalars["LocalDateTime"]>;
  trainingId: Scalars["Long"];
  userId?: InputMaybe<Scalars["Long"]>;
};

export type MutationsApproveProjectTrainingRequestArgs = {
  requestIdList: Array<Scalars["Long"]>;
};

export type MutationsCreateFaqArgs = {
  attachments?: InputMaybe<Array<Scalars["Upload"]>>;
  description: Scalars["String"];
  roles: Array<Role>;
  title: Scalars["String"];
};

export type MutationsCreateProjectTrainingRequestArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["String"];
  trainingIdList: Array<InputMaybe<Scalars["Long"]>>;
};

export type MutationsCreateTaskArgs = {
  businessUnit?: InputMaybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
  trainingIds: Array<Scalars["Long"]>;
};

export type MutationsCreateTrainingArgs = {
  description?: InputMaybe<Scalars["String"]>;
  mandatoryPositions: Array<Scalars["String"]>;
  name: Scalars["String"];
  recurring?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Type>;
  unitOfCompetency?: InputMaybe<Scalars["String"]>;
};

export type MutationsCreateTrainingRequestArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  trainingId?: InputMaybe<Scalars["Long"]>;
  trainingName?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["Long"]>;
};

export type MutationsDeclineProjectTrainingRequestArgs = {
  requestIdList: Array<Scalars["Long"]>;
};

export type MutationsDeleteCustomCertificateArgs = {
  certificateId: Scalars["Long"];
  userId?: InputMaybe<Scalars["Long"]>;
};

export type MutationsDeleteDiplomaArgs = {
  id: Scalars["Long"];
};

export type MutationsDeleteFaqArgs = {
  id: Scalars["Long"];
};

export type MutationsDeleteTaskArgs = {
  id: Scalars["Long"];
};

export type MutationsDeleteTrainingArgs = {
  id: Scalars["Long"];
};

export type MutationsDeleteTrainingCertificateArgs = {
  certificateId: Scalars["Long"];
  userId?: InputMaybe<Scalars["Long"]>;
};

export type MutationsGetUnconfirmedProjectTrainingRequestArgs = {
  projectId: Scalars["String"];
};

export type MutationsHideOverdueCertificateArgs = {
  trainingId: Scalars["Long"];
  userId: Scalars["Long"];
};

export type MutationsReadNotificationsArgs = {
  notificationId: Array<Scalars["Long"]>;
};

export type MutationsRemoveSimulationDataArgs = {
  simulationName: Scalars["String"];
};

export type MutationsRequireAllArgs = {
  projectId: Scalars["String"];
};

export type MutationsShowOverdueCertificateArgs = {
  trainingId: Scalars["Long"];
  userId: Scalars["Long"];
};

export type MutationsUpdateFaqArgs = {
  description: Scalars["String"];
  id: Scalars["Long"];
  newAttachments?: InputMaybe<Array<Scalars["Upload"]>>;
  oldAttachments?: InputMaybe<Array<AttachmentInput>>;
  roles: Array<Role>;
  title: Scalars["String"];
};

export type MutationsUpdateGapAnalysisPresetArgs = {
  projectId: Scalars["String"];
  projectTeamInfo: Array<ProjectTeamGapAnalysisInfoInput>;
};

export type MutationsUpdateTaskArgs = {
  updateTasks: Array<UpdateTaskInput>;
};

export type MutationsUpdateTrainingArgs = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Long"];
  mandatoryPositions: Array<Scalars["String"]>;
  name: Scalars["String"];
  recurring?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Type>;
  unitOfCompetency?: InputMaybe<Scalars["String"]>;
};

export type MutationsUpdateUserArgs = {
  active: Scalars["Boolean"];
  id: Scalars["Long"];
  role: Role;
};

export enum Need {
  Custom = "Custom",
  JobDepended = "JobDepended",
  Mandatory = "Mandatory",
}

export type Notification = {
  __typename?: "Notification";
  createdAt: Scalars["Instant"];
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Long"]>;
  profileLink?: Maybe<Scalars["String"]>;
  recipientId: Scalars["ID"];
  senderId?: Maybe<Scalars["ID"]>;
  trainingName?: Maybe<Array<Scalars["String"]>>;
  type: NotificationType;
  updatedAt: Scalars["Instant"];
};

export type NotificationInfo = {
  __typename?: "NotificationInfo";
  notification: Notification;
  recipient: MicrosoftGraphUser;
  sender?: Maybe<MicrosoftGraphUser>;
  status: NotificationStatus;
};

export enum NotificationStatus {
  Archived = "Archived",
  Read = "Read",
  Unread = "Unread",
}

export enum NotificationType {
  Certificate = "Certificate",
  CertificateForAnother = "CertificateForAnother",
  Diploma = "Diploma",
  Request = "Request",
  RequestForAnother = "RequestForAnother",
  Require = "Require",
}

export type PaginationLeaderboardAwaitingCommencementInfo = {
  __typename?: "PaginationLeaderboardAwaitingCommencementInfo";
  data: Array<LeaderboardAwaitingCommencementInfo>;
  total: Scalars["Int"];
};

export type PaginationLeaderboardInfo = {
  __typename?: "PaginationLeaderboardInfo";
  data: Array<LeaderboardInfo>;
  total: Scalars["Int"];
};

export type PaginationNotificationInfo = {
  __typename?: "PaginationNotificationInfo";
  data: Array<NotificationInfo>;
  total: Scalars["Int"];
};

export type PaginationProjectInfo = {
  __typename?: "PaginationProjectInfo";
  data: Array<ProjectInfo>;
  total: Scalars["Int"];
};

export type PaginationProjectTeamInfo = {
  __typename?: "PaginationProjectTeamInfo";
  data: Array<ProjectTeamInfo>;
  total: Scalars["Int"];
};

export type PaginationSuggestedCandidateInfo = {
  __typename?: "PaginationSuggestedCandidateInfo";
  data: Array<SuggestedCandidateInfo>;
  total: Scalars["Int"];
};

export type PaginationTaskInfo = {
  __typename?: "PaginationTaskInfo";
  data: Array<TaskInfo>;
  total: Scalars["Int"];
};

export type PaginationTrainingMatrixInfo = {
  __typename?: "PaginationTrainingMatrixInfo";
  data: Array<TrainingMatrixInfo>;
  total: Scalars["Int"];
};

export type PaginationTrainingWithTasks = {
  __typename?: "PaginationTrainingWithTasks";
  data: Array<TrainingWithTasks>;
  total: Scalars["Int"];
};

export type PaginationUserInfo = {
  __typename?: "PaginationUserInfo";
  data: Array<UserInfo>;
  total: Scalars["Int"];
};

export type Project = {
  __typename?: "Project";
  businessUnit?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["LocalDateTime"]>;
  id: Scalars["String"];
  managerList?: Maybe<Array<ProjectUser>>;
  name?: Maybe<Scalars["String"]>;
  staffEmailList?: Maybe<Array<Scalars["String"]>>;
  stage?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["LocalDateTime"]>;
};

export type ProjectInfo = {
  __typename?: "ProjectInfo";
  businessUnit?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["LocalDateTime"]>;
  id: Scalars["String"];
  managerList: Array<EitherStringOrMicrosoftGraphUser>;
  name?: Maybe<Scalars["String"]>;
  percent: Scalars["Float"];
  staffEmailList?: Maybe<Array<Scalars["String"]>>;
  stage?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["LocalDateTime"]>;
};

export enum ProjectStatus {
  Active = "Active",
  Inactive = "Inactive",
  Reactive = "Reactive",
}

export type ProjectTeamGapAnalysisInfo = {
  __typename?: "ProjectTeamGapAnalysisInfo";
  taskIdList: Array<Scalars["Long"]>;
  userId: Scalars["Long"];
};

export type ProjectTeamGapAnalysisInfoInput = {
  taskIdList: Array<Scalars["Long"]>;
  userId: Scalars["Long"];
};

export type ProjectTeamInfo = {
  __typename?: "ProjectTeamInfo";
  graphUser: MicrosoftGraphUser;
  jobDependedTrainingStatus: AccountStatus;
  mandatoryTrainingStatus: AccountStatus;
  position: Scalars["String"];
  projectId: Scalars["String"];
  tasks: Array<Task>;
  user: User;
};

export type ProjectTrainingRequest = {
  __typename?: "ProjectTrainingRequest";
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["LocalDateTime"];
  id?: Maybe<Scalars["Long"]>;
  projectId: Scalars["String"];
  status: ProjectTrainingRequestStatus;
  taskId?: Maybe<Scalars["Long"]>;
  trainingId?: Maybe<Scalars["Long"]>;
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export enum ProjectTrainingRequestStatus {
  Approved = "Approved",
  Declined = "Declined",
  WaitingDecision = "WaitingDecision",
}

export type ProjectUser = {
  __typename?: "ProjectUser";
  email: Scalars["String"];
  id: Scalars["Int"];
  lookup: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  countAssignedUsersOnTraining?: Maybe<Scalars["Int"]>;
  countUnreadNotifications?: Maybe<Scalars["Int"]>;
  gapAnalysis?: Maybe<Array<GapAnalysisUserInfo>>;
  getAssignedUsersTrainingInfo?: Maybe<UserExportCsvInfoPagination>;
  getBusinessUnitList?: Maybe<Array<Scalars["String"]>>;
  getDepartmentList?: Maybe<Array<Scalars["String"]>>;
  getFAQ?: Maybe<Faq>;
  getGapAnalysisByDate?: Maybe<GapAnalysis>;
  getGapAnalysisByDateNew?: Maybe<GetGapAnalysisByDateResult>;
  getLeaderboardAwaitingCommencementInfo?: Maybe<PaginationLeaderboardAwaitingCommencementInfo>;
  getLeaderboardInfo?: Maybe<PaginationLeaderboardInfo>;
  getMyProjects?: Maybe<PaginationProjectInfo>;
  getPositionList?: Maybe<Array<Scalars["String"]>>;
  getProfileInfo?: Maybe<UserInfo>;
  getProjectInfo?: Maybe<ProjectInfo>;
  getProjectNameById?: Maybe<Scalars["String"]>;
  getProjectStageList?: Maybe<Array<Scalars["String"]>>;
  getProjectTeamInfo?: Maybe<PaginationProjectTeamInfo>;
  getSuggestedCandidates?: Maybe<PaginationSuggestedCandidateInfo>;
  getTrainingMatrix?: Maybe<PaginationTrainingMatrixInfo>;
  getUser?: Maybe<User>;
  getUserInfo?: Maybe<UserInfo>;
  listFAQ?: Maybe<Array<Faq>>;
  listGapAnalysisDate?: Maybe<Array<Scalars["LocalDate"]>>;
  listMyNotifications?: Maybe<PaginationNotificationInfo>;
  listMyRequests?: Maybe<Array<TrainingRequest>>;
  listProjects?: Maybe<ListProjectInfo>;
  listTask?: Maybe<GetTaskInfo>;
  listTaskInfoWithoutPagination?: Maybe<Array<TaskInfo>>;
  listTaskProjectUserAssign?: Maybe<Array<Task>>;
  listTaskWithoutPagination?: Maybe<Array<Task>>;
  listTraining?: Maybe<PaginationTrainingWithTasks>;
  listTrainingWithRequest?: Maybe<Array<EitherTrainingRequestOrTraining>>;
  listTrainingWithoutPagination?: Maybe<Array<Training>>;
  listUser?: Maybe<PaginationUserInfo>;
  test?: Maybe<Scalars["String"]>;
  testGatlingQuery?: Maybe<Scalars["String"]>;
  testQuery?: Maybe<Scalars["String"]>;
};

export type QueryCountAssignedUsersOnTrainingArgs = {
  id: Scalars["Long"];
};

export type QueryGapAnalysisArgs = {
  projectId: Scalars["String"];
};

export type QueryGetAssignedUsersTrainingInfoArgs = {
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]>>;
  completeDateSorting?: InputMaybe<Sort>;
  expiryDateSorting?: InputMaybe<Sort>;
  expiryFilter?: InputMaybe<Array<ExportExpireFilter>>;
  limit: Scalars["Int"];
  nameSorting?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  positionFilter?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  statusFilter?: InputMaybe<Array<Status>>;
  trainingId?: InputMaybe<Scalars["Long"]>;
};

export type QueryGetFaqArgs = {
  id: Scalars["Long"];
};

export type QueryGetGapAnalysisByDateArgs = {
  date: Scalars["LocalDate"];
  projectId: Scalars["String"];
};

export type QueryGetGapAnalysisByDateNewArgs = {
  date: Scalars["LocalDate"];
  projectId: Scalars["String"];
};

export type QueryGetLeaderboardAwaitingCommencementInfoArgs = {
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]>>;
  endDateSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  statusFilter?: InputMaybe<Array<ProjectStatus>>;
  titleSort?: InputMaybe<Sort>;
};

export type QueryGetLeaderboardInfoArgs = {
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]>>;
  endDateSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  ratingSort?: InputMaybe<Sort>;
  search?: InputMaybe<Scalars["String"]>;
  statusFilter?: InputMaybe<Array<ProjectStatus>>;
  titleSort?: InputMaybe<Sort>;
};

export type QueryGetMyProjectsArgs = {
  businessUnit?: InputMaybe<Array<Scalars["String"]>>;
  endDateRange?: InputMaybe<DateRangeInput>;
  endDateSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  projectNameSort?: InputMaybe<Sort>;
  projectStage?: InputMaybe<Array<Scalars["String"]>>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDateRange?: InputMaybe<DateRangeInput>;
  startDateSort?: InputMaybe<Sort>;
};

export type QueryGetProjectInfoArgs = {
  id: Scalars["String"];
};

export type QueryGetProjectNameByIdArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectTeamInfoArgs = {
  jobDependentTrainingSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  mandatoryTrainingSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  projectId: Scalars["String"];
  searchString?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSuggestedCandidatesArgs = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  projectId: Scalars["String"];
  searchString?: InputMaybe<Scalars["String"]>;
  taskIdFilter: Array<Scalars["Long"]>;
};

export type QueryGetTrainingMatrixArgs = {
  limit: Scalars["Int"];
  needFilter?: InputMaybe<Array<Need>>;
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  statusFilter?: InputMaybe<Array<Status>>;
  titleSort?: InputMaybe<Sort>;
};

export type QueryGetUserInfoArgs = {
  id: Scalars["UserID"];
};

export type QueryListFaqArgs = {
  searchString?: InputMaybe<Scalars["String"]>;
};

export type QueryListGapAnalysisDateArgs = {
  projectId: Scalars["String"];
};

export type QueryListMyNotificationsArgs = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type QueryListProjectsArgs = {
  businessUnit?: InputMaybe<Array<Scalars["String"]>>;
  endDateRange?: InputMaybe<DateRangeInput>;
  endDateSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  projectNameSort?: InputMaybe<Sort>;
  projectStage?: InputMaybe<Array<Scalars["String"]>>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDateRange?: InputMaybe<DateRangeInput>;
  startDateSort?: InputMaybe<Sort>;
};

export type QueryListTaskArgs = {
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]>>;
  creationDateSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  titleSort?: InputMaybe<Sort>;
};

export type QueryListTaskInfoWithoutPaginationArgs = {
  projectId: Scalars["String"];
};

export type QueryListTaskProjectUserAssignArgs = {
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryListTrainingArgs = {
  creationDateSort?: InputMaybe<Sort>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  titleSort?: InputMaybe<Sort>;
};

export type QueryListUserArgs = {
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]>>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  profileTrainingStatusFilter?: InputMaybe<Array<AccountStatus>>;
  roleFilter?: InputMaybe<Array<Role>>;
  search?: InputMaybe<Scalars["String"]>;
};

export enum Role {
  Admin = "Admin",
  Manager = "Manager",
  SuperAdmin = "SuperAdmin",
  User = "User",
}

export enum Sort {
  Asc = "Asc",
  Desc = "Desc",
}

export enum Status {
  Archived = "Archived",
  Done = "Done",
  Expired = "Expired",
  Required = "Required",
}

export type Subscriptions = {
  __typename?: "Subscriptions";
  countNotifications?: Maybe<Scalars["Int"]>;
};

export type SuggestedCandidateInfo = {
  __typename?: "SuggestedCandidateInfo";
  graphUser: MicrosoftGraphUser;
  jobDependedTrainingStatus: AccountStatus;
  mandatoryTrainingStatus: AccountStatus;
  position: Scalars["String"];
};

export type Task = {
  __typename?: "Task";
  businessUnit?: Maybe<Array<Scalars["String"]>>;
  createdAt: Scalars["LocalDateTime"];
  id?: Maybe<Scalars["Long"]>;
  name: Scalars["String"];
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type TaskInfo = {
  __typename?: "TaskInfo";
  businessUnit?: Maybe<Array<Scalars["String"]>>;
  createdAt: Scalars["LocalDateTime"];
  id?: Maybe<Scalars["Long"]>;
  name: Scalars["String"];
  trainings: Array<Training>;
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type Training = {
  __typename?: "Training";
  createdAt: Scalars["LocalDateTime"];
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Long"]>;
  mandatoryPositions: Array<Scalars["String"]>;
  name: Scalars["String"];
  recurring?: Maybe<Scalars["Int"]>;
  type?: Maybe<Type>;
  unitOfCompetency?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type TrainingCertificate = {
  __typename?: "TrainingCertificate";
  archived: Scalars["Boolean"];
  certificate: Scalars["String"];
  completed: Scalars["LocalDateTime"];
  createdAt: Scalars["LocalDateTime"];
  expiredAt?: Maybe<Scalars["LocalDateTime"]>;
  format: Scalars["String"];
  id?: Maybe<Scalars["Long"]>;
  trainingId?: Maybe<Scalars["Long"]>;
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type TrainingMatrixInfo = {
  __typename?: "TrainingMatrixInfo";
  certificate: CertificateInfo;
  project?: Maybe<Project>;
};

export type TrainingRequest = {
  __typename?: "TrainingRequest";
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["LocalDateTime"];
  createdById?: Maybe<Scalars["Long"]>;
  id?: Maybe<Scalars["Long"]>;
  trainingId?: Maybe<Scalars["Long"]>;
  trainingName?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["LocalDateTime"];
  userId?: Maybe<Scalars["Long"]>;
};

export type TrainingRequestInfo = {
  __typename?: "TrainingRequestInfo";
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["LocalDateTime"];
  createdBy?: Maybe<MicrosoftGraphUser>;
  id?: Maybe<Scalars["Long"]>;
  trainingId?: Maybe<Scalars["Long"]>;
  trainingName?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["LocalDateTime"];
  user?: Maybe<MicrosoftGraphUser>;
};

export type TrainingWithTasks = {
  __typename?: "TrainingWithTasks";
  tasks: Array<Task>;
  training: Training;
};

export enum Type {
  External = "External",
  InHouse = "InHouse",
  Rto = "RTO",
}

export type UpdateGapAnalysisPresetQuery = {
  __typename?: "UpdateGapAnalysisPresetQuery";
  projectId: Scalars["String"];
  projectTeamInfo: Array<ProjectTeamGapAnalysisInfo>;
};

export type UpdateTaskInput = {
  businessUnit?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["Long"];
  name: Scalars["String"];
  trainingIds: Array<Scalars["Long"]>;
};

export type User = {
  __typename?: "User";
  active: Scalars["Boolean"];
  createdAt: Scalars["LocalDateTime"];
  email: Scalars["String"];
  graphId: Scalars["ID"];
  id?: Maybe<Scalars["Long"]>;
  inactiveTrainingStatus: Scalars["Boolean"];
  role: Role;
  showedPrompts: Scalars["Boolean"];
  updatedAt: Scalars["LocalDateTime"];
};

export type UserExportCsvInfo = {
  __typename?: "UserExportCsvInfo";
  businessUnit?: Maybe<Scalars["String"]>;
  competed?: Maybe<Scalars["LocalDateTime"]>;
  expiry?: Maybe<Scalars["LocalDateTime"]>;
  fullName?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  status: Status;
};

export type UserExportCsvInfoPagination = {
  __typename?: "UserExportCsvInfoPagination";
  data: Array<UserExportCsvInfo>;
  exportUrl: Scalars["String"];
  total: Scalars["Int"];
};

export type UserInfo = {
  __typename?: "UserInfo";
  accountStatus?: Maybe<AccountStatus>;
  currentProjects?: Maybe<Array<Project>>;
  customCertificates?: Maybe<Array<CertificateInfo>>;
  education?: Maybe<Array<Education>>;
  graphProfile: MicrosoftGraphUser;
  manager?: Maybe<MicrosoftGraphUser>;
  projects?: Maybe<Array<Project>>;
  trainings?: Maybe<Array<CertificateInfo>>;
  user?: Maybe<User>;
};

export type TrySignInMutationVariables = Exact<{ [key: string]: never }>;

export type TrySignInMutation = {
  __typename?: "Mutations";
  trySignIn?: {
    __typename?: "User";
    id?: any | null;
    graphId: string;
    email: string;
    role: Role;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type GetBusinessUnitListQueryVariables = Exact<{ [key: string]: never }>;

export type GetBusinessUnitListQuery = {
  __typename?: "Query";
  getBusinessUnitList?: Array<string> | null;
};

export type GetLeaderboardAwaitingCommencementInfoQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusFilter?: InputMaybe<Array<ProjectStatus> | ProjectStatus>;
  titleSort?: InputMaybe<Sort>;
  endDateSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type GetLeaderboardAwaitingCommencementInfoQuery = {
  __typename?: "Query";
  getLeaderboardAwaitingCommencementInfo?: {
    __typename?: "PaginationLeaderboardAwaitingCommencementInfo";
    total: number;
    data: Array<{
      __typename?: "LeaderboardAwaitingCommencementInfo";
      index: number;
      status: ProjectStatus;
      projectInfo: {
        __typename?: "ProjectInfo";
        id: string;
        name?: string | null;
        businessUnit?: string | null;
        stage?: string | null;
        staffEmailList?: Array<string> | null;
        startDate?: any | null;
        endDate?: any | null;
        percent: number;
        managerList: Array<{
          __typename?: "EitherStringOrMicrosoftGraphUser";
          left?: string | null;
          right?: {
            __typename?: "MicrosoftGraphUser";
            businessPhones: Array<string>;
            displayName?: string | null;
            givenName?: string | null;
            jobTitle?: string | null;
            mail?: string | null;
            mobilePhone?: string | null;
            officeLocation?: string | null;
            preferredLanguage?: string | null;
            surname?: string | null;
            userPrincipalName?: string | null;
            id: string;
          } | null;
        }>;
      };
      projectTeamInfo: Array<{
        __typename?: "LeaderboardProjectTeamInfo";
        certificatesValue: number;
        graphUser?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      }>;
    }>;
  } | null;
};

export type GetLeaderboardInfoQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusFilter?: InputMaybe<Array<ProjectStatus> | ProjectStatus>;
  ratingSort?: InputMaybe<Sort>;
  titleSort?: InputMaybe<Sort>;
  endDateSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type GetLeaderboardInfoQuery = {
  __typename?: "Query";
  getLeaderboardInfo?: {
    __typename?: "PaginationLeaderboardInfo";
    total: number;
    data: Array<{
      __typename?: "LeaderboardInfo";
      rating: number;
      status: ProjectStatus;
      projectInfo: {
        __typename?: "ProjectInfo";
        id: string;
        name?: string | null;
        businessUnit?: string | null;
        stage?: string | null;
        staffEmailList?: Array<string> | null;
        startDate?: any | null;
        endDate?: any | null;
        percent: number;
        managerList: Array<{
          __typename?: "EitherStringOrMicrosoftGraphUser";
          left?: string | null;
          right?: {
            __typename?: "MicrosoftGraphUser";
            businessPhones: Array<string>;
            displayName?: string | null;
            givenName?: string | null;
            jobTitle?: string | null;
            mail?: string | null;
            mobilePhone?: string | null;
            officeLocation?: string | null;
            preferredLanguage?: string | null;
            surname?: string | null;
            userPrincipalName?: string | null;
            id: string;
          } | null;
        }>;
      };
      projectTeamInfo: Array<{
        __typename?: "LeaderboardProjectTeamInfo";
        certificatesValue: number;
        graphUser?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      }>;
    }>;
  } | null;
};

export type FaqNodeFragment = {
  __typename?: "FAQ";
  id?: any | null;
  title: string;
  roles: Array<Role>;
  description: string;
  userId?: any | null;
  createdAt: any;
  updatedAt: any;
  attachments: Array<{
    __typename?: "Attachment";
    name: string;
    format: string;
    url: string;
  }>;
};

export type CreateFaqMutationVariables = Exact<{
  title: Scalars["String"];
  roles: Array<Role> | Role;
  description: Scalars["String"];
  attachments: Array<Scalars["Upload"]> | Scalars["Upload"];
}>;

export type CreateFaqMutation = {
  __typename?: "Mutations";
  createFAQ?: {
    __typename?: "FAQ";
    id?: any | null;
    title: string;
    roles: Array<Role>;
    description: string;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    attachments: Array<{
      __typename?: "Attachment";
      name: string;
      format: string;
      url: string;
    }>;
  } | null;
};

export type DeleteFaqMutationVariables = Exact<{
  id: Scalars["Long"];
}>;

export type DeleteFaqMutation = {
  __typename?: "Mutations";
  deleteFAQ?: any | null;
};

export type UpdateFaqMutationVariables = Exact<{
  id: Scalars["Long"];
  title: Scalars["String"];
  roles: Array<Role> | Role;
  description: Scalars["String"];
  oldAttachments: Array<AttachmentInput> | AttachmentInput;
  newAttachments: Array<Scalars["Upload"]> | Scalars["Upload"];
}>;

export type UpdateFaqMutation = {
  __typename?: "Mutations";
  updateFAQ?: {
    __typename?: "FAQ";
    id?: any | null;
    title: string;
    roles: Array<Role>;
    description: string;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    attachments: Array<{
      __typename?: "Attachment";
      name: string;
      format: string;
      url: string;
    }>;
  } | null;
};

export type ListFaqQueryVariables = Exact<{
  searchString: Scalars["String"];
}>;

export type ListFaqQuery = {
  __typename?: "Query";
  listFAQ?: Array<{
    __typename?: "FAQ";
    id?: any | null;
    title: string;
    roles: Array<Role>;
    description: string;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    attachments: Array<{
      __typename?: "Attachment";
      name: string;
      format: string;
      url: string;
    }>;
  }> | null;
};

export type GetFaqQueryVariables = Exact<{
  id: Scalars["Long"];
}>;

export type GetFaqQuery = {
  __typename?: "Query";
  getFAQ?: {
    __typename?: "FAQ";
    id?: any | null;
    title: string;
    roles: Array<Role>;
    description: string;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    attachments: Array<{
      __typename?: "Attachment";
      name: string;
      format: string;
      url: string;
    }>;
  } | null;
};

export type NotificationNodeFragment = {
  __typename?: "Notification";
  id?: any | null;
  type: NotificationType;
  recipientId: string;
  senderId?: string | null;
  description?: string | null;
  profileLink?: string | null;
  trainingName?: Array<string> | null;
  createdAt: any;
  updatedAt: any;
};

export type ListMyNotificationsQueryVariables = Exact<{
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type ListMyNotificationsQuery = {
  __typename?: "Query";
  listMyNotifications?: {
    __typename?: "PaginationNotificationInfo";
    total: number;
    data: Array<{
      __typename?: "NotificationInfo";
      status: NotificationStatus;
      sender?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
      recipient: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      };
      notification: {
        __typename?: "Notification";
        id?: any | null;
        type: NotificationType;
        recipientId: string;
        senderId?: string | null;
        description?: string | null;
        profileLink?: string | null;
        trainingName?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
      };
    }>;
  } | null;
};

export type EducationNodeFragment = {
  __typename?: "Education";
  id?: any | null;
  universityName: string;
  fieldOfStudy: string;
  year: number;
  diplomaUrl: string;
  userId?: any | null;
  format: string;
  createdAt: any;
  updatedAt: any;
};

export type GraphUserNodeFragment = {
  __typename?: "MicrosoftGraphUser";
  businessPhones: Array<string>;
  displayName?: string | null;
  givenName?: string | null;
  jobTitle?: string | null;
  mail?: string | null;
  mobilePhone?: string | null;
  officeLocation?: string | null;
  preferredLanguage?: string | null;
  surname?: string | null;
  userPrincipalName?: string | null;
  id: string;
};

export type ManagerNodeFragment = {
  __typename?: "EitherStringOrMicrosoftGraphUser";
  left?: string | null;
  right?: {
    __typename?: "MicrosoftGraphUser";
    businessPhones: Array<string>;
    displayName?: string | null;
    givenName?: string | null;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
    id: string;
  } | null;
};

export type UserNodeFragment = {
  __typename?: "User";
  active: boolean;
  id?: any | null;
  graphId: string;
  email: string;
  role: Role;
  createdAt: any;
  updatedAt: any;
  inactiveTrainingStatus: boolean;
  showedPrompts: boolean;
};

export type AddEducationInfoMutationVariables = Exact<{
  universityName: Scalars["String"];
  fieldOfStudy: Scalars["String"];
  year: Scalars["Int"];
  diploma: Scalars["Upload"];
  userId?: InputMaybe<Scalars["Long"]>;
}>;

export type AddEducationInfoMutation = {
  __typename?: "Mutations";
  addEducationInfo?: {
    __typename?: "Education";
    id?: any | null;
    universityName: string;
    fieldOfStudy: string;
    year: number;
    diplomaUrl: string;
    userId?: any | null;
  } | null;
};

export type DeleteDiplomaMutationVariables = Exact<{
  id: Scalars["Long"];
}>;

export type DeleteDiplomaMutation = {
  __typename?: "Mutations";
  deleteDiploma?: any | null;
};

export type GetProfileInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileInfoQuery = {
  __typename?: "Query";
  getProfileInfo?: {
    __typename?: "UserInfo";
    accountStatus?: AccountStatus | null;
    user?: {
      __typename?: "User";
      active: boolean;
      id?: any | null;
      graphId: string;
      email: string;
      role: Role;
      createdAt: any;
      updatedAt: any;
      inactiveTrainingStatus: boolean;
      showedPrompts: boolean;
    } | null;
    graphProfile: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    };
    manager?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
    projects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    currentProjects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    education?: Array<{
      __typename?: "Education";
      id?: any | null;
      universityName: string;
      fieldOfStudy: string;
      year: number;
      diplomaUrl: string;
      userId?: any | null;
      format: string;
      createdAt: any;
      updatedAt: any;
    }> | null;
    trainings?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
    customCertificates?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars["UserID"];
}>;

export type GetUserInfoQuery = {
  __typename?: "Query";
  getUserInfo?: {
    __typename?: "UserInfo";
    accountStatus?: AccountStatus | null;
    user?: {
      __typename?: "User";
      active: boolean;
      id?: any | null;
      graphId: string;
      email: string;
      role: Role;
      createdAt: any;
      updatedAt: any;
      inactiveTrainingStatus: boolean;
      showedPrompts: boolean;
    } | null;
    graphProfile: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    };
    manager?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
    projects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    currentProjects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    education?: Array<{
      __typename?: "Education";
      id?: any | null;
      universityName: string;
      fieldOfStudy: string;
      year: number;
      diplomaUrl: string;
      userId?: any | null;
      format: string;
      createdAt: any;
      updatedAt: any;
    }> | null;
    trainings?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
    customCertificates?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "User";
    active: boolean;
    id?: any | null;
    graphId: string;
    email: string;
    role: Role;
    createdAt: any;
    updatedAt: any;
    inactiveTrainingStatus: boolean;
    showedPrompts: boolean;
  } | null;
};

export type ProjectNodeFragment = {
  __typename?: "Project";
  id: string;
  name?: string | null;
  businessUnit?: string | null;
  stage?: string | null;
  staffEmailList?: Array<string> | null;
  startDate?: any | null;
  endDate?: any | null;
  managerList?: Array<{
    __typename?: "ProjectUser";
    id: number;
    lookup: string;
    email: string;
  }> | null;
};

export type ProjectInfoNodeFragment = {
  __typename?: "ProjectInfo";
  id: string;
  name?: string | null;
  businessUnit?: string | null;
  stage?: string | null;
  staffEmailList?: Array<string> | null;
  startDate?: any | null;
  endDate?: any | null;
  percent: number;
  managerList: Array<{
    __typename?: "EitherStringOrMicrosoftGraphUser";
    left?: string | null;
    right?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
  }>;
};

export type ProjectTeamInfoNodeFragment = {
  __typename?: "ProjectTeamInfo";
  position: string;
  mandatoryTrainingStatus: AccountStatus;
  jobDependedTrainingStatus: AccountStatus;
  projectId: string;
  graphUser: {
    __typename?: "MicrosoftGraphUser";
    businessPhones: Array<string>;
    displayName?: string | null;
    givenName?: string | null;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
    id: string;
  };
  user: {
    __typename?: "User";
    active: boolean;
    id?: any | null;
    graphId: string;
    email: string;
    role: Role;
    createdAt: any;
    updatedAt: any;
    inactiveTrainingStatus: boolean;
    showedPrompts: boolean;
  };
  tasks: Array<{
    __typename?: "Task";
    id?: any | null;
    name: string;
    userId?: any | null;
    businessUnit?: Array<string> | null;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type RequireAllMutationVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type RequireAllMutation = {
  __typename?: "Mutations";
  requireAll?: any | null;
};

export type UpdateGapAnalysisPresetMutationVariables = Exact<{
  projectId: Scalars["String"];
  projectTeamInfo:
    | Array<ProjectTeamGapAnalysisInfoInput>
    | ProjectTeamGapAnalysisInfoInput;
}>;

export type UpdateGapAnalysisPresetMutation = {
  __typename?: "Mutations";
  updateGapAnalysisPreset?: {
    __typename?: "UpdateGapAnalysisPresetQuery";
    projectId: string;
    projectTeamInfo: Array<{
      __typename?: "ProjectTeamGapAnalysisInfo";
      userId: any;
      taskIdList: Array<any>;
    }>;
  } | null;
};

export type GetGapAnalysisByDateQueryVariables = Exact<{
  projectId: Scalars["String"];
  date: Scalars["LocalDate"];
}>;

export type GetGapAnalysisByDateQuery = {
  __typename?: "Query";
  getGapAnalysisByDate?: {
    __typename?: "GapAnalysis";
    id?: any | null;
    projectId: string;
    date: any;
    info: Array<{
      __typename?: "GapAnalysisUserInfo";
      profileStatus: AccountStatus;
      user?: {
        __typename?: "User";
        active: boolean;
        id?: any | null;
        graphId: string;
        email: string;
        role: Role;
        createdAt: any;
        updatedAt: any;
        inactiveTrainingStatus: boolean;
        showedPrompts: boolean;
      } | null;
      graphUser?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
      taskTrainingInfoList: Array<{
        __typename?: "GapTrainingInfo";
        need?: Need | null;
        status: Status;
        training: {
          __typename?: "Training";
          id?: any | null;
          name: string;
          unitOfCompetency?: string | null;
          mandatoryPositions: Array<string>;
          type?: Type | null;
          recurring?: number | null;
          description?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
        };
        task?: {
          __typename?: "Task";
          id?: any | null;
          name: string;
          userId?: any | null;
          businessUnit?: Array<string> | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        certificate?: {
          __typename?: "TrainingCertificate";
          id?: any | null;
          trainingId?: any | null;
          completed: any;
          expiredAt?: any | null;
          certificate: string;
          format: string;
          userId?: any | null;
          archived: boolean;
          createdAt: any;
          updatedAt: any;
        } | null;
      }>;
    }>;
  } | null;
};

export type GapAnalysisQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GapAnalysisQuery = {
  __typename?: "Query";
  gapAnalysis?: Array<{
    __typename?: "GapAnalysisUserInfo";
    profileStatus: AccountStatus;
    user?: {
      __typename?: "User";
      active: boolean;
      id?: any | null;
      graphId: string;
      email: string;
      role: Role;
      createdAt: any;
      updatedAt: any;
      inactiveTrainingStatus: boolean;
      showedPrompts: boolean;
    } | null;
    graphUser?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
    taskTrainingInfoList: Array<{
      __typename?: "GapTrainingInfo";
      status: Status;
      training: {
        __typename?: "Training";
        id?: any | null;
        name: string;
        unitOfCompetency?: string | null;
        mandatoryPositions: Array<string>;
        type?: Type | null;
        recurring?: number | null;
        description?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
      };
      certificate?: {
        __typename?: "TrainingCertificate";
        id?: any | null;
        trainingId?: any | null;
        completed: any;
        expiredAt?: any | null;
        certificate: string;
        format: string;
        userId?: any | null;
        archived: boolean;
        createdAt: any;
        updatedAt: any;
      } | null;
      task?: {
        __typename?: "Task";
        id?: any | null;
        name: string;
        userId?: any | null;
        businessUnit?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
      } | null;
    }>;
  }> | null;
};

export type GetGapAnalysisByDateNewQueryVariables = Exact<{
  projectId: Scalars["String"];
  date: Scalars["LocalDate"];
}>;

export type GetGapAnalysisByDateNewQuery = {
  __typename?: "Query";
  getGapAnalysisByDateNew?: {
    __typename?: "GetGapAnalysisByDateResult";
    exportUrl: string;
    gapAnalysis: {
      __typename?: "GapAnalysis";
      id?: any | null;
      projectId: string;
      date: any;
      info: Array<{
        __typename?: "GapAnalysisUserInfo";
        profileStatus: AccountStatus;
        user?: {
          __typename?: "User";
          active: boolean;
          id?: any | null;
          graphId: string;
          email: string;
          role: Role;
          createdAt: any;
          updatedAt: any;
          inactiveTrainingStatus: boolean;
          showedPrompts: boolean;
        } | null;
        graphUser?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        taskTrainingInfoList: Array<{
          __typename?: "GapTrainingInfo";
          status: Status;
          training: {
            __typename?: "Training";
            id?: any | null;
            name: string;
            unitOfCompetency?: string | null;
            mandatoryPositions: Array<string>;
            type?: Type | null;
            recurring?: number | null;
            description?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
          };
          certificate?: {
            __typename?: "TrainingCertificate";
            id?: any | null;
            trainingId?: any | null;
            completed: any;
            expiredAt?: any | null;
            certificate: string;
            format: string;
            userId?: any | null;
            archived: boolean;
            createdAt: any;
            updatedAt: any;
          } | null;
        }>;
      }>;
    };
  } | null;
};

export type GetMyProjectsQueryVariables = Exact<{
  searchString: Scalars["String"];
  businessUnit?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  projectStage?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  startDateSort?: InputMaybe<Sort>;
  endDateSort?: InputMaybe<Sort>;
  startDateRange?: InputMaybe<DateRangeInput>;
  endDateRange?: InputMaybe<DateRangeInput>;
  projectNameSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  trainingsCompleted?: InputMaybe<Sort>;
}>;

export type GetMyProjectsQuery = {
  __typename?: "Query";
  getMyProjects?: {
    __typename?: "PaginationProjectInfo";
    total: number;
    data: Array<{
      __typename?: "ProjectInfo";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      percent: number;
      managerList: Array<{
        __typename?: "EitherStringOrMicrosoftGraphUser";
        left?: string | null;
        right?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      }>;
    }>;
  } | null;
};

export type GetProjectInfoQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetProjectInfoQuery = {
  __typename?: "Query";
  getProjectInfo?: {
    __typename?: "ProjectInfo";
    id: string;
    name?: string | null;
    businessUnit?: string | null;
    stage?: string | null;
    staffEmailList?: Array<string> | null;
    startDate?: any | null;
    endDate?: any | null;
    percent: number;
    managerList: Array<{
      __typename?: "EitherStringOrMicrosoftGraphUser";
      left?: string | null;
      right?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
    }>;
  } | null;
};

export type GetProjectNameByIdQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectNameByIdQuery = {
  __typename?: "Query";
  getProjectNameById?: string | null;
};

export type GetProjectStageListQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectStageListQuery = {
  __typename?: "Query";
  getProjectStageList?: Array<string> | null;
};

export type GetProjectTeamInfoQueryVariables = Exact<{
  projectId: Scalars["String"];
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  searchString?: InputMaybe<Scalars["String"]>;
  mandatoryTrainingSort?: InputMaybe<Sort>;
  jobDependentTrainingSort?: InputMaybe<Sort>;
}>;

export type GetProjectTeamInfoQuery = {
  __typename?: "Query";
  getProjectTeamInfo?: {
    __typename?: "PaginationProjectTeamInfo";
    total: number;
    data: Array<{
      __typename?: "ProjectTeamInfo";
      position: string;
      mandatoryTrainingStatus: AccountStatus;
      jobDependedTrainingStatus: AccountStatus;
      projectId: string;
      graphUser: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      };
      user: {
        __typename?: "User";
        active: boolean;
        id?: any | null;
        graphId: string;
        email: string;
        role: Role;
        createdAt: any;
        updatedAt: any;
        inactiveTrainingStatus: boolean;
        showedPrompts: boolean;
      };
      tasks: Array<{
        __typename?: "Task";
        id?: any | null;
        name: string;
        userId?: any | null;
        businessUnit?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
      }>;
    }>;
  } | null;
};

export type GetSuggestedCandidatesQueryVariables = Exact<{
  projectId: Scalars["String"];
  searchString?: InputMaybe<Scalars["String"]>;
  taskIdFilter: Array<Scalars["Long"]> | Scalars["Long"];
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type GetSuggestedCandidatesQuery = {
  __typename?: "Query";
  getSuggestedCandidates?: {
    __typename?: "PaginationSuggestedCandidateInfo";
    total: number;
    data: Array<{
      __typename?: "SuggestedCandidateInfo";
      position: string;
      mandatoryTrainingStatus: AccountStatus;
      jobDependedTrainingStatus: AccountStatus;
      graphUser: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      };
    }>;
  } | null;
};

export type ListGapAnalysisDateQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type ListGapAnalysisDateQuery = {
  __typename?: "Query";
  listGapAnalysisDate?: Array<any> | null;
};

export type ListProjectsQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars["String"]>;
  businessUnit?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  projectStage?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  startDateSort?: InputMaybe<Sort>;
  endDateSort?: InputMaybe<Sort>;
  projectNameSort?: InputMaybe<Sort>;
  startDateRange?: InputMaybe<DateRangeInput>;
  endDateRange?: InputMaybe<DateRangeInput>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  trainingsCompleted?: InputMaybe<Sort>;
}>;

export type ListProjectsQuery = {
  __typename?: "Query";
  listProjects?: {
    __typename?: "ListProjectInfo";
    exportUrl: string;
    projectInfo: {
      __typename?: "PaginationProjectInfo";
      total: number;
      data: Array<{
        __typename?: "ProjectInfo";
        id: string;
        name?: string | null;
        businessUnit?: string | null;
        stage?: string | null;
        staffEmailList?: Array<string> | null;
        startDate?: any | null;
        endDate?: any | null;
        percent: number;
        managerList: Array<{
          __typename?: "EitherStringOrMicrosoftGraphUser";
          left?: string | null;
          right?: {
            __typename?: "MicrosoftGraphUser";
            businessPhones: Array<string>;
            displayName?: string | null;
            givenName?: string | null;
            jobTitle?: string | null;
            mail?: string | null;
            mobilePhone?: string | null;
            officeLocation?: string | null;
            preferredLanguage?: string | null;
            surname?: string | null;
            userPrincipalName?: string | null;
            id: string;
          } | null;
        }>;
      }>;
    };
  } | null;
};

export type TaskInfoNodeFragment = {
  __typename?: "TaskInfo";
  id?: any | null;
  name: string;
  userId?: any | null;
  businessUnit?: Array<string> | null;
  createdAt: any;
  updatedAt: any;
  trainings: Array<{
    __typename?: "Training";
    id?: any | null;
    name: string;
    unitOfCompetency?: string | null;
    mandatoryPositions: Array<string>;
    type?: Type | null;
    recurring?: number | null;
    description?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type TaskNodeFragment = {
  __typename?: "Task";
  id?: any | null;
  name: string;
  userId?: any | null;
  businessUnit?: Array<string> | null;
  createdAt: any;
  updatedAt: any;
};

export type CreateTaskMutationVariables = Exact<{
  name: Scalars["String"];
  trainingIds: Array<Scalars["Long"]> | Scalars["Long"];
  businessUnit?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CreateTaskMutation = {
  __typename?: "Mutations";
  createTask?: {
    __typename?: "Task";
    id?: any | null;
    name: string;
    businessUnit?: Array<string> | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars["Long"];
}>;

export type DeleteTaskMutation = {
  __typename?: "Mutations";
  deleteTask?: any | null;
};

export type UpdateTaskMutationVariables = Exact<{
  updateTasks: Array<UpdateTaskInput> | UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: "Mutations";
  updateTask?: {
    __typename?: "PaginationTaskInfo";
    total: number;
    data: Array<{
      __typename?: "TaskInfo";
      id?: any | null;
      name: string;
      userId?: any | null;
      businessUnit?: Array<string> | null;
      createdAt: any;
      updatedAt: any;
      trainings: Array<{
        __typename?: "Training";
        id?: any | null;
        name: string;
        unitOfCompetency?: string | null;
        mandatoryPositions: Array<string>;
        type?: Type | null;
        recurring?: number | null;
        description?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
      }>;
    }>;
  } | null;
};

export type ListTaskProjectUserAssignQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ListTaskProjectUserAssignQuery = {
  __typename?: "Query";
  listTaskProjectUserAssign?: Array<{
    __typename?: "Task";
    id?: any | null;
    name: string;
    userId?: any | null;
    businessUnit?: Array<string> | null;
    createdAt: any;
    updatedAt: any;
  }> | null;
};

export type ListTaskWithoutPaginationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListTaskWithoutPaginationQuery = {
  __typename?: "Query";
  listTaskWithoutPagination?: Array<{
    __typename?: "Task";
    id?: any | null;
    name: string;
    userId?: any | null;
    businessUnit?: Array<string> | null;
    createdAt: any;
    updatedAt: any;
  }> | null;
};

export type ListTaskInfoWithoutPaginationQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type ListTaskInfoWithoutPaginationQuery = {
  __typename?: "Query";
  listTaskInfoWithoutPagination?: Array<{
    __typename?: "TaskInfo";
    id?: any | null;
    name: string;
    userId?: any | null;
    businessUnit?: Array<string> | null;
    createdAt: any;
    updatedAt: any;
    trainings: Array<{
      __typename?: "Training";
      id?: any | null;
      name: string;
      unitOfCompetency?: string | null;
      mandatoryPositions: Array<string>;
      type?: Type | null;
      recurring?: number | null;
      description?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
    }>;
  }> | null;
};

export type ListTaskQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  titleSort?: InputMaybe<Sort>;
  creationDateSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ListTaskQuery = {
  __typename?: "Query";
  listTask?: {
    __typename?: "GetTaskInfo";
    exportUrl: string;
    taskInfo: {
      __typename?: "PaginationTaskInfo";
      total: number;
      data: Array<{
        __typename?: "TaskInfo";
        id?: any | null;
        name: string;
        userId?: any | null;
        businessUnit?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
        trainings: Array<{
          __typename?: "Training";
          id?: any | null;
          name: string;
          unitOfCompetency?: string | null;
          mandatoryPositions: Array<string>;
          type?: Type | null;
          recurring?: number | null;
          description?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
        }>;
      }>;
    };
  } | null;
};

export type CertificateNodeFragment = {
  __typename?: "TrainingCertificate";
  id?: any | null;
  trainingId?: any | null;
  completed: any;
  expiredAt?: any | null;
  certificate: string;
  format: string;
  userId?: any | null;
  archived: boolean;
  createdAt: any;
  updatedAt: any;
};

export type CertificateInfoNodeFragment = {
  __typename?: "CertificateInfo";
  certificateId?: any | null;
  trainingId?: any | null;
  name: string;
  need: Need;
  status: Status;
  projectId?: string | null;
  completedAt?: any | null;
  assignedAt?: any | null;
  dueTo?: any | null;
  certificateLink?: string | null;
  hidden: boolean;
  request?: {
    __typename?: "TrainingRequestInfo";
    id?: any | null;
    trainingName?: string | null;
    trainingId?: any | null;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
    user?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
    createdBy?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
  } | null;
};

export type TrainingNodeFragment = {
  __typename?: "Training";
  id?: any | null;
  name: string;
  unitOfCompetency?: string | null;
  mandatoryPositions: Array<string>;
  type?: Type | null;
  recurring?: number | null;
  description?: string | null;
  userId?: any | null;
  createdAt: any;
  updatedAt: any;
};

export type TrainingRequestNodeFragment = {
  __typename?: "TrainingRequest";
  id?: any | null;
  trainingName?: string | null;
  trainingId?: any | null;
  userId?: any | null;
  createdById?: any | null;
  comment?: string | null;
  createdAt: any;
  updatedAt: any;
};

export type TrainingRequestInfoNodeFragment = {
  __typename?: "TrainingRequestInfo";
  id?: any | null;
  trainingName?: string | null;
  trainingId?: any | null;
  comment?: string | null;
  createdAt: any;
  updatedAt: any;
  user?: {
    __typename?: "MicrosoftGraphUser";
    businessPhones: Array<string>;
    displayName?: string | null;
    givenName?: string | null;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
    id: string;
  } | null;
  createdBy?: {
    __typename?: "MicrosoftGraphUser";
    businessPhones: Array<string>;
    displayName?: string | null;
    givenName?: string | null;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
    id: string;
  } | null;
};

export type AddAdditionalCertificateMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["Long"]>;
  trainingName: Scalars["String"];
  completed: Scalars["LocalDateTime"];
  dueTo?: InputMaybe<Scalars["LocalDateTime"]>;
  certificate: Scalars["Upload"];
}>;

export type AddAdditionalCertificateMutation = {
  __typename?: "Mutations";
  addAdditionalCertificate?: {
    __typename?: "AdditionalCertificate";
    id?: any | null;
    trainingName: string;
    completed: any;
    dueTo?: any | null;
    certificate: string;
    format: string;
    archived: boolean;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type AddTrainingCertificateMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["Long"]>;
  trainingId: Scalars["Long"];
  completed: Scalars["LocalDateTime"];
  dueTo?: InputMaybe<Scalars["LocalDateTime"]>;
  certificate: Scalars["Upload"];
}>;

export type AddTrainingCertificateMutation = {
  __typename?: "Mutations";
  addTrainingCertificate?: {
    __typename?: "TrainingCertificate";
    id?: any | null;
    trainingId?: any | null;
    completed: any;
    expiredAt?: any | null;
    certificate: string;
    format: string;
    userId?: any | null;
    archived: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CreateProjectTrainingRequestMutationVariables = Exact<{
  projectId: Scalars["String"];
  trainingIdList:
    | Array<InputMaybe<Scalars["Long"]>>
    | InputMaybe<Scalars["Long"]>;
  comment?: InputMaybe<Scalars["String"]>;
}>;

export type CreateProjectTrainingRequestMutation = {
  __typename?: "Mutations";
  createProjectTrainingRequest?: Array<{
    __typename?: "TrainingRequest";
    id?: any | null;
    trainingName?: string | null;
    trainingId?: any | null;
    userId?: any | null;
    createdById?: any | null;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  }> | null;
};

export type CreateTrainingMutationVariables = Exact<{
  name: Scalars["String"];
  unitOfCompetency?: InputMaybe<Scalars["String"]>;
  mandatoryPositions: Array<Scalars["String"]> | Scalars["String"];
  type?: InputMaybe<Type>;
  recurring?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type CreateTrainingMutation = {
  __typename?: "Mutations";
  createTraining?: {
    __typename?: "Training";
    id?: any | null;
    name: string;
    unitOfCompetency?: string | null;
    mandatoryPositions: Array<string>;
    type?: Type | null;
    recurring?: number | null;
    description?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CreateTrainingRequestMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["Long"]>;
  trainingName?: InputMaybe<Scalars["String"]>;
  trainingId?: InputMaybe<Scalars["Long"]>;
  comment?: InputMaybe<Scalars["String"]>;
}>;

export type CreateTrainingRequestMutation = {
  __typename?: "Mutations";
  createTrainingRequest?: {
    __typename?: "TrainingRequest";
    id?: any | null;
    trainingName?: string | null;
    userId?: any | null;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type DeleteCustomCertificateMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["Long"]>;
  certificateId: Scalars["Long"];
}>;

export type DeleteCustomCertificateMutation = {
  __typename?: "Mutations";
  deleteCustomCertificate?: any | null;
};

export type DeleteTrainingCertificateMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["Long"]>;
  certificateId: Scalars["Long"];
}>;

export type DeleteTrainingCertificateMutation = {
  __typename?: "Mutations";
  deleteTrainingCertificate?: any | null;
};

export type DeleteTrainingMutationVariables = Exact<{
  id: Scalars["Long"];
}>;

export type DeleteTrainingMutation = {
  __typename?: "Mutations";
  deleteTraining?: any | null;
};

export type HideOverdueCertificateMutationVariables = Exact<{
  userId: Scalars["Long"];
  trainingId: Scalars["Long"];
}>;

export type HideOverdueCertificateMutation = {
  __typename?: "Mutations";
  hideOverdueCertificate?: {
    __typename?: "UserInfo";
    accountStatus?: AccountStatus | null;
    user?: {
      __typename?: "User";
      active: boolean;
      id?: any | null;
      graphId: string;
      email: string;
      role: Role;
      createdAt: any;
      updatedAt: any;
      inactiveTrainingStatus: boolean;
      showedPrompts: boolean;
    } | null;
    graphProfile: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    };
    manager?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
    projects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    currentProjects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    education?: Array<{
      __typename?: "Education";
      id?: any | null;
      universityName: string;
      fieldOfStudy: string;
      year: number;
      diplomaUrl: string;
      userId?: any | null;
      format: string;
      createdAt: any;
      updatedAt: any;
    }> | null;
    trainings?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
    customCertificates?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type ShowOverdueCertificateMutationVariables = Exact<{
  userId: Scalars["Long"];
  trainingId: Scalars["Long"];
}>;

export type ShowOverdueCertificateMutation = {
  __typename?: "Mutations";
  showOverdueCertificate?: {
    __typename?: "UserInfo";
    accountStatus?: AccountStatus | null;
    user?: {
      __typename?: "User";
      active: boolean;
      id?: any | null;
      graphId: string;
      email: string;
      role: Role;
      createdAt: any;
      updatedAt: any;
      inactiveTrainingStatus: boolean;
      showedPrompts: boolean;
    } | null;
    graphProfile: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    };
    manager?: {
      __typename?: "MicrosoftGraphUser";
      businessPhones: Array<string>;
      displayName?: string | null;
      givenName?: string | null;
      jobTitle?: string | null;
      mail?: string | null;
      mobilePhone?: string | null;
      officeLocation?: string | null;
      preferredLanguage?: string | null;
      surname?: string | null;
      userPrincipalName?: string | null;
      id: string;
    } | null;
    projects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    currentProjects?: Array<{
      __typename?: "Project";
      id: string;
      name?: string | null;
      businessUnit?: string | null;
      stage?: string | null;
      staffEmailList?: Array<string> | null;
      startDate?: any | null;
      endDate?: any | null;
      managerList?: Array<{
        __typename?: "ProjectUser";
        id: number;
        lookup: string;
        email: string;
      }> | null;
    }> | null;
    education?: Array<{
      __typename?: "Education";
      id?: any | null;
      universityName: string;
      fieldOfStudy: string;
      year: number;
      diplomaUrl: string;
      userId?: any | null;
      format: string;
      createdAt: any;
      updatedAt: any;
    }> | null;
    trainings?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
    customCertificates?: Array<{
      __typename?: "CertificateInfo";
      certificateId?: any | null;
      trainingId?: any | null;
      name: string;
      need: Need;
      status: Status;
      projectId?: string | null;
      completedAt?: any | null;
      assignedAt?: any | null;
      dueTo?: any | null;
      certificateLink?: string | null;
      hidden: boolean;
      request?: {
        __typename?: "TrainingRequestInfo";
        id?: any | null;
        trainingName?: string | null;
        trainingId?: any | null;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
        user?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
        createdBy?: {
          __typename?: "MicrosoftGraphUser";
          businessPhones: Array<string>;
          displayName?: string | null;
          givenName?: string | null;
          jobTitle?: string | null;
          mail?: string | null;
          mobilePhone?: string | null;
          officeLocation?: string | null;
          preferredLanguage?: string | null;
          surname?: string | null;
          userPrincipalName?: string | null;
          id: string;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdateTrainingMutationVariables = Exact<{
  id: Scalars["Long"];
  name: Scalars["String"];
  unitOfCompetency?: InputMaybe<Scalars["String"]>;
  mandatoryPositions: Array<Scalars["String"]> | Scalars["String"];
  type?: InputMaybe<Type>;
  recurring?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateTrainingMutation = {
  __typename?: "Mutations";
  updateTraining?: {
    __typename?: "Training";
    id?: any | null;
    name: string;
    unitOfCompetency?: string | null;
    mandatoryPositions: Array<string>;
    type?: Type | null;
    recurring?: number | null;
    description?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CountAssignedUsersOnTrainingQueryVariables = Exact<{
  id: Scalars["Long"];
}>;

export type CountAssignedUsersOnTrainingQuery = {
  __typename?: "Query";
  countAssignedUsersOnTraining?: number | null;
};

export type GetAssignedUsersTrainingInfoQueryVariables = Exact<{
  trainingId?: InputMaybe<Scalars["Long"]>;
  search?: InputMaybe<Scalars["String"]>;
  nameSorting?: InputMaybe<Sort>;
  positionFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  completeDateSorting?: InputMaybe<Sort>;
  expiryDateSorting?: InputMaybe<Sort>;
  expiryFilter?: InputMaybe<Array<ExportExpireFilter> | ExportExpireFilter>;
  statusFilter?: InputMaybe<Array<Status> | Status>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type GetAssignedUsersTrainingInfoQuery = {
  __typename?: "Query";
  getAssignedUsersTrainingInfo?: {
    __typename?: "UserExportCsvInfoPagination";
    total: number;
    exportUrl: string;
    data: Array<{
      __typename?: "UserExportCsvInfo";
      fullName?: string | null;
      position?: string | null;
      businessUnit?: string | null;
      competed?: any | null;
      expiry?: any | null;
      status: Status;
    }>;
  } | null;
};

export type GetPositionListQueryVariables = Exact<{ [key: string]: never }>;

export type GetPositionListQuery = {
  __typename?: "Query";
  getPositionList?: Array<string> | null;
};

export type GetTrainingMatrixQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  statusFilter?: InputMaybe<Array<Status> | Status>;
  needFilter?: InputMaybe<Array<Need> | Need>;
  titleSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type GetTrainingMatrixQuery = {
  __typename?: "Query";
  getTrainingMatrix?: {
    __typename?: "PaginationTrainingMatrixInfo";
    total: number;
    data: Array<{
      __typename?: "TrainingMatrixInfo";
      project?: {
        __typename?: "Project";
        id: string;
        name?: string | null;
        businessUnit?: string | null;
        stage?: string | null;
        staffEmailList?: Array<string> | null;
        startDate?: any | null;
        endDate?: any | null;
        managerList?: Array<{
          __typename?: "ProjectUser";
          id: number;
          lookup: string;
          email: string;
        }> | null;
      } | null;
      certificate: {
        __typename?: "CertificateInfo";
        certificateId?: any | null;
        trainingId?: any | null;
        name: string;
        need: Need;
        status: Status;
        projectId?: string | null;
        completedAt?: any | null;
        assignedAt?: any | null;
        dueTo?: any | null;
        certificateLink?: string | null;
        hidden: boolean;
        request?: {
          __typename?: "TrainingRequestInfo";
          id?: any | null;
          trainingName?: string | null;
          trainingId?: any | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          user?: {
            __typename?: "MicrosoftGraphUser";
            businessPhones: Array<string>;
            displayName?: string | null;
            givenName?: string | null;
            jobTitle?: string | null;
            mail?: string | null;
            mobilePhone?: string | null;
            officeLocation?: string | null;
            preferredLanguage?: string | null;
            surname?: string | null;
            userPrincipalName?: string | null;
            id: string;
          } | null;
          createdBy?: {
            __typename?: "MicrosoftGraphUser";
            businessPhones: Array<string>;
            displayName?: string | null;
            givenName?: string | null;
            jobTitle?: string | null;
            mail?: string | null;
            mobilePhone?: string | null;
            officeLocation?: string | null;
            preferredLanguage?: string | null;
            surname?: string | null;
            userPrincipalName?: string | null;
            id: string;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type ListTrainingQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  titleSort?: InputMaybe<Sort>;
  creationDateSort?: InputMaybe<Sort>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type ListTrainingQuery = {
  __typename?: "Query";
  listTraining?: {
    url: string;
    __typename?: "TrainingWithTasksExport";
    trainingWithTask: {
      total: number;
      __typename?: "PaginationTrainingWithTasks";
      data: Array<{
        __typename?: "TrainingWithTasks";
        training: {
          __typename?: "Training";
          id?: any | null;
          name: string;
          unitOfCompetency?: string | null;
          mandatoryPositions: Array<string>;
          type?: Type | null;
          recurring?: number | null;
          description?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
        };
        tasks: Array<{
          __typename?: "Task";
          id?: any | null;
          name: string;
          userId?: any | null;
          businessUnit?: Array<string> | null;
          createdAt: any;
          updatedAt: any;
        }>;
      }>;
    } | null;
  };
};

export type ListTrainingWithRequestQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListTrainingWithRequestQuery = {
  __typename?: "Query";
  listTrainingWithRequest?: Array<{
    __typename?: "EitherTrainingRequestOrTraining";
    left?: {
      __typename?: "TrainingRequest";
      id?: any | null;
      trainingName?: string | null;
      trainingId?: any | null;
      userId?: any | null;
      createdById?: any | null;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    right?: {
      __typename?: "Training";
      id?: any | null;
      name: string;
      unitOfCompetency?: string | null;
      mandatoryPositions: Array<string>;
      type?: Type | null;
      recurring?: number | null;
      description?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
    } | null;
  }> | null;
};

export type ListTrainingWithoutPaginationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListTrainingWithoutPaginationQuery = {
  __typename?: "Query";
  listTrainingWithoutPagination?: Array<{
    __typename?: "Training";
    id?: any | null;
    name: string;
    unitOfCompetency?: string | null;
    mandatoryPositions: Array<string>;
    type?: Type | null;
    recurring?: number | null;
    description?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
  }> | null;
};

export type UserInfoNodeFragment = {
  __typename?: "UserInfo";
  accountStatus?: AccountStatus | null;
  user?: {
    __typename?: "User";
    active: boolean;
    id?: any | null;
    graphId: string;
    email: string;
    role: Role;
    createdAt: any;
    updatedAt: any;
    inactiveTrainingStatus: boolean;
    showedPrompts: boolean;
  } | null;
  graphProfile: {
    __typename?: "MicrosoftGraphUser";
    businessPhones: Array<string>;
    displayName?: string | null;
    givenName?: string | null;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
    id: string;
  };
  manager?: {
    __typename?: "MicrosoftGraphUser";
    businessPhones: Array<string>;
    displayName?: string | null;
    givenName?: string | null;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
    id: string;
  } | null;
  projects?: Array<{
    __typename?: "Project";
    id: string;
    name?: string | null;
    businessUnit?: string | null;
    stage?: string | null;
    staffEmailList?: Array<string> | null;
    startDate?: any | null;
    endDate?: any | null;
    managerList?: Array<{
      __typename?: "ProjectUser";
      id: number;
      lookup: string;
      email: string;
    }> | null;
  }> | null;
  currentProjects?: Array<{
    __typename?: "Project";
    id: string;
    name?: string | null;
    businessUnit?: string | null;
    stage?: string | null;
    staffEmailList?: Array<string> | null;
    startDate?: any | null;
    endDate?: any | null;
    managerList?: Array<{
      __typename?: "ProjectUser";
      id: number;
      lookup: string;
      email: string;
    }> | null;
  }> | null;
  education?: Array<{
    __typename?: "Education";
    id?: any | null;
    universityName: string;
    fieldOfStudy: string;
    year: number;
    diplomaUrl: string;
    userId?: any | null;
    format: string;
    createdAt: any;
    updatedAt: any;
  }> | null;
  trainings?: Array<{
    __typename?: "CertificateInfo";
    certificateId?: any | null;
    trainingId?: any | null;
    name: string;
    need: Need;
    status: Status;
    projectId?: string | null;
    completedAt?: any | null;
    assignedAt?: any | null;
    dueTo?: any | null;
    certificateLink?: string | null;
    hidden: boolean;
    request?: {
      __typename?: "TrainingRequestInfo";
      id?: any | null;
      trainingName?: string | null;
      trainingId?: any | null;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
      user?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
      createdBy?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
    } | null;
  }> | null;
  customCertificates?: Array<{
    __typename?: "CertificateInfo";
    certificateId?: any | null;
    trainingId?: any | null;
    name: string;
    need: Need;
    status: Status;
    projectId?: string | null;
    completedAt?: any | null;
    assignedAt?: any | null;
    dueTo?: any | null;
    certificateLink?: string | null;
    hidden: boolean;
    request?: {
      __typename?: "TrainingRequestInfo";
      id?: any | null;
      trainingName?: string | null;
      trainingId?: any | null;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
      user?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
      createdBy?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
    } | null;
  }> | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["Long"];
  role: Role;
  active: Scalars["Boolean"];
}>;

export type UpdateUserMutation = {
  __typename?: "Mutations";
  updateUser?: {
    __typename?: "User";
    id?: any | null;
    graphId: string;
    email: string;
    role: Role;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type GetDepartmentListQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartmentListQuery = {
  __typename?: "Query";
  getDepartmentList?: Array<string> | null;
};

export type ListUserQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  roleFilter?: InputMaybe<Array<Role> | Role>;
  businessUnitFilter?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  profileTrainingStatusFilter?: InputMaybe<
    Array<AccountStatus> | AccountStatus
  >;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type ListUserQuery = {
  __typename?: "Query";
  listUser?: {
    __typename?: "PaginationUserInfo";
    total: number;
    data: Array<{
      __typename?: "UserInfo";
      accountStatus?: AccountStatus | null;
      user?: {
        __typename?: "User";
        active: boolean;
        id?: any | null;
        graphId: string;
        email: string;
        role: Role;
        createdAt: any;
        updatedAt: any;
        inactiveTrainingStatus: boolean;
        showedPrompts: boolean;
      } | null;
      graphProfile: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      };
      manager?: {
        __typename?: "MicrosoftGraphUser";
        businessPhones: Array<string>;
        displayName?: string | null;
        givenName?: string | null;
        jobTitle?: string | null;
        mail?: string | null;
        mobilePhone?: string | null;
        officeLocation?: string | null;
        preferredLanguage?: string | null;
        surname?: string | null;
        userPrincipalName?: string | null;
        id: string;
      } | null;
      projects?: Array<{
        __typename?: "Project";
        id: string;
        name?: string | null;
        businessUnit?: string | null;
        stage?: string | null;
        staffEmailList?: Array<string> | null;
        startDate?: any | null;
        endDate?: any | null;
        managerList?: Array<{
          __typename?: "ProjectUser";
          id: number;
          lookup: string;
          email: string;
        }> | null;
      }> | null;
      currentProjects?: Array<{
        __typename?: "Project";
        id: string;
        name?: string | null;
        businessUnit?: string | null;
        stage?: string | null;
        staffEmailList?: Array<string> | null;
        startDate?: any | null;
        endDate?: any | null;
        managerList?: Array<{
          __typename?: "ProjectUser";
          id: number;
          lookup: string;
          email: string;
        }> | null;
      }> | null;
    }>;
  } | null;
};

export const FaqNodeFragmentDoc = gql`
  fragment FAQNode on FAQ {
    id
    title
    roles
    description
    attachments {
      name
      format
      url
    }
    userId
    createdAt
    updatedAt
  }
`;
export const NotificationNodeFragmentDoc = gql`
  fragment NotificationNode on Notification {
    id
    type
    recipientId
    senderId
    description
    profileLink
    trainingName
    createdAt
    updatedAt
  }
`;
export const ManagerNodeFragmentDoc = gql`
  fragment ManagerNode on EitherStringOrMicrosoftGraphUser {
    left
    right {
      businessPhones
      displayName
      givenName
      jobTitle
      mail
      mobilePhone
      officeLocation
      preferredLanguage
      surname
      userPrincipalName
      id
    }
  }
`;
export const ProjectInfoNodeFragmentDoc = gql`
  fragment ProjectInfoNode on ProjectInfo {
    id
    name
    businessUnit
    stage
    managerList {
      left
      right {
        businessPhones
        displayName
        givenName
        jobTitle
        mail
        mobilePhone
        officeLocation
        preferredLanguage
        surname
        userPrincipalName
        id
      }
    }
    staffEmailList
    startDate
    endDate
    percent
  }
`;
export const GraphUserNodeFragmentDoc = gql`
  fragment GraphUserNode on MicrosoftGraphUser {
    businessPhones
    displayName
    givenName
    jobTitle
    mail
    mobilePhone
    officeLocation
    preferredLanguage
    surname
    userPrincipalName
    id
  }
`;
export const UserNodeFragmentDoc = gql`
  fragment UserNode on User {
    active
    id
    graphId
    email
    role
    createdAt
    updatedAt
    inactiveTrainingStatus
    showedPrompts
  }
`;
export const TaskNodeFragmentDoc = gql`
  fragment TaskNode on Task {
    id
    name
    userId
    businessUnit
    createdAt
    updatedAt
  }
`;
export const ProjectTeamInfoNodeFragmentDoc = gql`
  fragment ProjectTeamInfoNode on ProjectTeamInfo {
    graphUser {
      ...GraphUserNode
    }
    user {
      ...UserNode
    }
    position
    tasks {
      ...TaskNode
    }
    mandatoryTrainingStatus
    jobDependedTrainingStatus
    projectId
  }
  ${GraphUserNodeFragmentDoc}
  ${UserNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
`;
export const TrainingNodeFragmentDoc = gql`
  fragment TrainingNode on Training {
    id
    name
    unitOfCompetency
    mandatoryPositions
    type
    recurring
    description
    userId
    createdAt
    updatedAt
  }
`;
export const TaskInfoNodeFragmentDoc = gql`
  fragment TaskInfoNode on TaskInfo {
    id
    name
    userId
    businessUnit
    trainings {
      ...TrainingNode
    }
    createdAt
    updatedAt
  }
  ${TrainingNodeFragmentDoc}
`;
export const CertificateNodeFragmentDoc = gql`
  fragment CertificateNode on TrainingCertificate {
    id
    trainingId
    completed
    expiredAt
    certificate
    format
    userId
    archived
    createdAt
    updatedAt
  }
`;
export const TrainingRequestNodeFragmentDoc = gql`
  fragment TrainingRequestNode on TrainingRequest {
    id
    trainingName
    trainingId
    userId
    createdById
    comment
    createdAt
    updatedAt
  }
`;
export const ProjectNodeFragmentDoc = gql`
  fragment ProjectNode on Project {
    id
    name
    businessUnit
    stage
    managerList {
      id
      lookup
      email
    }
    staffEmailList
    startDate
    endDate
  }
`;
export const EducationNodeFragmentDoc = gql`
  fragment EducationNode on Education {
    id
    universityName
    fieldOfStudy
    year
    diplomaUrl
    userId
    format
    createdAt
    updatedAt
  }
`;
export const TrainingRequestInfoNodeFragmentDoc = gql`
  fragment TrainingRequestInfoNode on TrainingRequestInfo {
    id
    trainingName
    trainingId
    user {
      ...GraphUserNode
    }
    createdBy {
      ...GraphUserNode
    }
    comment
    createdAt
    updatedAt
  }
  ${GraphUserNodeFragmentDoc}
`;
export const CertificateInfoNodeFragmentDoc = gql`
  fragment CertificateInfoNode on CertificateInfo {
    certificateId
    trainingId
    name
    need
    status
    projectId
    completedAt
    assignedAt
    dueTo
    certificateLink
    hidden
    request {
      ...TrainingRequestInfoNode
    }
  }
  ${TrainingRequestInfoNodeFragmentDoc}
`;
export const UserInfoNodeFragmentDoc = gql`
  fragment UserInfoNode on UserInfo {
    user {
      ...UserNode
    }
    graphProfile {
      ...GraphUserNode
    }
    manager {
      ...GraphUserNode
    }
    projects {
      ...ProjectNode
    }
    currentProjects {
      ...ProjectNode
    }
    education {
      ...EducationNode
    }
    trainings {
      ...CertificateInfoNode
    }
    customCertificates {
      ...CertificateInfoNode
    }
    accountStatus
  }
  ${UserNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
  ${ProjectNodeFragmentDoc}
  ${EducationNodeFragmentDoc}
  ${CertificateInfoNodeFragmentDoc}
`;
export const TrySignInDocument = gql`
  mutation trySignIn {
    trySignIn {
      id
      graphId
      email
      role
      active
      createdAt
      updatedAt
    }
  }
`;
export type TrySignInMutationFn = Apollo.MutationFunction<
  TrySignInMutation,
  TrySignInMutationVariables
>;

/**
 * __useTrySignInMutation__
 *
 * To run a mutation, you first call `useTrySignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrySignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trySignInMutation, { data, loading, error }] = useTrySignInMutation({
 *   variables: {
 *   },
 * });
 */
export function useTrySignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrySignInMutation,
    TrySignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrySignInMutation, TrySignInMutationVariables>(
    TrySignInDocument,
    options
  );
}
export type TrySignInMutationHookResult = ReturnType<
  typeof useTrySignInMutation
>;
export type TrySignInMutationResult = Apollo.MutationResult<TrySignInMutation>;
export type TrySignInMutationOptions = Apollo.BaseMutationOptions<
  TrySignInMutation,
  TrySignInMutationVariables
>;
export const GetBusinessUnitListDocument = gql`
  query getBusinessUnitList {
    getBusinessUnitList
  }
`;

/**
 * __useGetBusinessUnitListQuery__
 *
 * To run a query within a React component, call `useGetBusinessUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUnitListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessUnitListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBusinessUnitListQuery,
    GetBusinessUnitListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBusinessUnitListQuery,
    GetBusinessUnitListQueryVariables
  >(GetBusinessUnitListDocument, options);
}
export function useGetBusinessUnitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessUnitListQuery,
    GetBusinessUnitListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBusinessUnitListQuery,
    GetBusinessUnitListQueryVariables
  >(GetBusinessUnitListDocument, options);
}
export type GetBusinessUnitListQueryHookResult = ReturnType<
  typeof useGetBusinessUnitListQuery
>;
export type GetBusinessUnitListLazyQueryHookResult = ReturnType<
  typeof useGetBusinessUnitListLazyQuery
>;
export type GetBusinessUnitListQueryResult = Apollo.QueryResult<
  GetBusinessUnitListQuery,
  GetBusinessUnitListQueryVariables
>;
export const GetLeaderboardAwaitingCommencementInfoDocument = gql`
  query getLeaderboardAwaitingCommencementInfo(
    $search: String
    $businessUnitFilter: [String!]
    $statusFilter: [ProjectStatus!]
    $titleSort: Sort
    $endDateSort: Sort
    $offset: Int!
    $limit: Int!
  ) {
    getLeaderboardAwaitingCommencementInfo(
      search: $search
      businessUnitFilter: $businessUnitFilter
      statusFilter: $statusFilter
      titleSort: $titleSort
      endDateSort: $endDateSort
      offset: $offset
      limit: $limit
    ) {
      data {
        index
        projectInfo {
          ...ProjectInfoNode
        }
        status
        projectTeamInfo {
          graphUser {
            ...GraphUserNode
          }
          certificatesValue
        }
      }
      total
    }
  }
  ${ProjectInfoNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
`;

/**
 * __useGetLeaderboardAwaitingCommencementInfoQuery__
 *
 * To run a query within a React component, call `useGetLeaderboardAwaitingCommencementInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaderboardAwaitingCommencementInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaderboardAwaitingCommencementInfoQuery({
 *   variables: {
 *      search: // value for 'search'
 *      businessUnitFilter: // value for 'businessUnitFilter'
 *      statusFilter: // value for 'statusFilter'
 *      titleSort: // value for 'titleSort'
 *      endDateSort: // value for 'endDateSort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLeaderboardAwaitingCommencementInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaderboardAwaitingCommencementInfoQuery,
    GetLeaderboardAwaitingCommencementInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaderboardAwaitingCommencementInfoQuery,
    GetLeaderboardAwaitingCommencementInfoQueryVariables
  >(GetLeaderboardAwaitingCommencementInfoDocument, options);
}
export function useGetLeaderboardAwaitingCommencementInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaderboardAwaitingCommencementInfoQuery,
    GetLeaderboardAwaitingCommencementInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaderboardAwaitingCommencementInfoQuery,
    GetLeaderboardAwaitingCommencementInfoQueryVariables
  >(GetLeaderboardAwaitingCommencementInfoDocument, options);
}
export type GetLeaderboardAwaitingCommencementInfoQueryHookResult = ReturnType<
  typeof useGetLeaderboardAwaitingCommencementInfoQuery
>;
export type GetLeaderboardAwaitingCommencementInfoLazyQueryHookResult =
  ReturnType<typeof useGetLeaderboardAwaitingCommencementInfoLazyQuery>;
export type GetLeaderboardAwaitingCommencementInfoQueryResult =
  Apollo.QueryResult<
    GetLeaderboardAwaitingCommencementInfoQuery,
    GetLeaderboardAwaitingCommencementInfoQueryVariables
  >;
export const GetLeaderboardInfoDocument = gql`
  query getLeaderboardInfo(
    $search: String
    $businessUnitFilter: [String!]
    $statusFilter: [ProjectStatus!]
    $ratingSort: Sort
    $titleSort: Sort
    $endDateSort: Sort
    $offset: Int!
    $limit: Int!
  ) {
    getLeaderboardInfo(
      search: $search
      businessUnitFilter: $businessUnitFilter
      statusFilter: $statusFilter
      ratingSort: $ratingSort
      titleSort: $titleSort
      endDateSort: $endDateSort
      offset: $offset
      limit: $limit
    ) {
      data {
        rating
        projectInfo {
          ...ProjectInfoNode
        }
        status
        projectTeamInfo {
          graphUser {
            ...GraphUserNode
          }
          certificatesValue
        }
      }
      total
    }
  }
  ${ProjectInfoNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
`;

/**
 * __useGetLeaderboardInfoQuery__
 *
 * To run a query within a React component, call `useGetLeaderboardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaderboardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaderboardInfoQuery({
 *   variables: {
 *      search: // value for 'search'
 *      businessUnitFilter: // value for 'businessUnitFilter'
 *      statusFilter: // value for 'statusFilter'
 *      ratingSort: // value for 'ratingSort'
 *      titleSort: // value for 'titleSort'
 *      endDateSort: // value for 'endDateSort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLeaderboardInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaderboardInfoQuery,
    GetLeaderboardInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaderboardInfoQuery,
    GetLeaderboardInfoQueryVariables
  >(GetLeaderboardInfoDocument, options);
}
export function useGetLeaderboardInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaderboardInfoQuery,
    GetLeaderboardInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaderboardInfoQuery,
    GetLeaderboardInfoQueryVariables
  >(GetLeaderboardInfoDocument, options);
}
export type GetLeaderboardInfoQueryHookResult = ReturnType<
  typeof useGetLeaderboardInfoQuery
>;
export type GetLeaderboardInfoLazyQueryHookResult = ReturnType<
  typeof useGetLeaderboardInfoLazyQuery
>;
export type GetLeaderboardInfoQueryResult = Apollo.QueryResult<
  GetLeaderboardInfoQuery,
  GetLeaderboardInfoQueryVariables
>;
export const CreateFaqDocument = gql`
  mutation createFAQ(
    $title: String!
    $roles: [Role!]!
    $description: String!
    $attachments: [Upload!]!
  ) {
    createFAQ(
      title: $title
      roles: $roles
      description: $description
      attachments: $attachments
    ) {
      ...FAQNode
    }
  }
  ${FaqNodeFragmentDoc}
`;
export type CreateFaqMutationFn = Apollo.MutationFunction<
  CreateFaqMutation,
  CreateFaqMutationVariables
>;

/**
 * __useCreateFaqMutation__
 *
 * To run a mutation, you first call `useCreateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaqMutation, { data, loading, error }] = useCreateFaqMutation({
 *   variables: {
 *      title: // value for 'title'
 *      roles: // value for 'roles'
 *      description: // value for 'description'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateFaqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFaqMutation,
    CreateFaqMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFaqMutation, CreateFaqMutationVariables>(
    CreateFaqDocument,
    options
  );
}
export type CreateFaqMutationHookResult = ReturnType<
  typeof useCreateFaqMutation
>;
export type CreateFaqMutationResult = Apollo.MutationResult<CreateFaqMutation>;
export type CreateFaqMutationOptions = Apollo.BaseMutationOptions<
  CreateFaqMutation,
  CreateFaqMutationVariables
>;
export const DeleteFaqDocument = gql`
  mutation deleteFAQ($id: Long!) {
    deleteFAQ(id: $id)
  }
`;
export type DeleteFaqMutationFn = Apollo.MutationFunction<
  DeleteFaqMutation,
  DeleteFaqMutationVariables
>;

/**
 * __useDeleteFaqMutation__
 *
 * To run a mutation, you first call `useDeleteFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqMutation, { data, loading, error }] = useDeleteFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFaqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFaqMutation,
    DeleteFaqMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFaqMutation, DeleteFaqMutationVariables>(
    DeleteFaqDocument,
    options
  );
}
export type DeleteFaqMutationHookResult = ReturnType<
  typeof useDeleteFaqMutation
>;
export type DeleteFaqMutationResult = Apollo.MutationResult<DeleteFaqMutation>;
export type DeleteFaqMutationOptions = Apollo.BaseMutationOptions<
  DeleteFaqMutation,
  DeleteFaqMutationVariables
>;
export const UpdateFaqDocument = gql`
  mutation updateFAQ(
    $id: Long!
    $title: String!
    $roles: [Role!]!
    $description: String!
    $oldAttachments: [AttachmentInput!]!
    $newAttachments: [Upload!]!
  ) {
    updateFAQ(
      id: $id
      title: $title
      roles: $roles
      description: $description
      oldAttachments: $oldAttachments
      newAttachments: $newAttachments
    ) {
      ...FAQNode
    }
  }
  ${FaqNodeFragmentDoc}
`;
export type UpdateFaqMutationFn = Apollo.MutationFunction<
  UpdateFaqMutation,
  UpdateFaqMutationVariables
>;

/**
 * __useUpdateFaqMutation__
 *
 * To run a mutation, you first call `useUpdateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqMutation, { data, loading, error }] = useUpdateFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      roles: // value for 'roles'
 *      description: // value for 'description'
 *      oldAttachments: // value for 'oldAttachments'
 *      newAttachments: // value for 'newAttachments'
 *   },
 * });
 */
export function useUpdateFaqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFaqMutation,
    UpdateFaqMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFaqMutation, UpdateFaqMutationVariables>(
    UpdateFaqDocument,
    options
  );
}
export type UpdateFaqMutationHookResult = ReturnType<
  typeof useUpdateFaqMutation
>;
export type UpdateFaqMutationResult = Apollo.MutationResult<UpdateFaqMutation>;
export type UpdateFaqMutationOptions = Apollo.BaseMutationOptions<
  UpdateFaqMutation,
  UpdateFaqMutationVariables
>;
export const ListFaqDocument = gql`
  query listFAQ($searchString: String!) {
    listFAQ(searchString: $searchString) {
      ...FAQNode
    }
  }
  ${FaqNodeFragmentDoc}
`;

/**
 * __useListFaqQuery__
 *
 * To run a query within a React component, call `useListFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFaqQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useListFaqQuery(
  baseOptions: Apollo.QueryHookOptions<ListFaqQuery, ListFaqQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFaqQuery, ListFaqQueryVariables>(
    ListFaqDocument,
    options
  );
}
export function useListFaqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListFaqQuery, ListFaqQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFaqQuery, ListFaqQueryVariables>(
    ListFaqDocument,
    options
  );
}
export type ListFaqQueryHookResult = ReturnType<typeof useListFaqQuery>;
export type ListFaqLazyQueryHookResult = ReturnType<typeof useListFaqLazyQuery>;
export type ListFaqQueryResult = Apollo.QueryResult<
  ListFaqQuery,
  ListFaqQueryVariables
>;
export const GetFaqDocument = gql`
  query getFAQ($id: Long!) {
    getFAQ(id: $id) {
      ...FAQNode
    }
  }
  ${FaqNodeFragmentDoc}
`;

/**
 * __useGetFaqQuery__
 *
 * To run a query within a React component, call `useGetFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFaqQuery(
  baseOptions: Apollo.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFaqQuery, GetFaqQueryVariables>(
    GetFaqDocument,
    options
  );
}
export function useGetFaqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(
    GetFaqDocument,
    options
  );
}
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>;
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>;
export type GetFaqQueryResult = Apollo.QueryResult<
  GetFaqQuery,
  GetFaqQueryVariables
>;
export const ListMyNotificationsDocument = gql`
  query listMyNotifications($offset: Int!, $limit: Int!) {
    listMyNotifications(offset: $offset, limit: $limit) {
      data {
        sender {
          ...GraphUserNode
        }
        recipient {
          ...GraphUserNode
        }
        notification {
          ...NotificationNode
        }
        status
      }
      total
    }
  }
  ${GraphUserNodeFragmentDoc}
  ${NotificationNodeFragmentDoc}
`;

/**
 * __useListMyNotificationsQuery__
 *
 * To run a query within a React component, call `useListMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyNotificationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListMyNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListMyNotificationsQuery,
    ListMyNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMyNotificationsQuery,
    ListMyNotificationsQueryVariables
  >(ListMyNotificationsDocument, options);
}
export function useListMyNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMyNotificationsQuery,
    ListMyNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMyNotificationsQuery,
    ListMyNotificationsQueryVariables
  >(ListMyNotificationsDocument, options);
}
export type ListMyNotificationsQueryHookResult = ReturnType<
  typeof useListMyNotificationsQuery
>;
export type ListMyNotificationsLazyQueryHookResult = ReturnType<
  typeof useListMyNotificationsLazyQuery
>;
export type ListMyNotificationsQueryResult = Apollo.QueryResult<
  ListMyNotificationsQuery,
  ListMyNotificationsQueryVariables
>;
export const AddEducationInfoDocument = gql`
  mutation addEducationInfo(
    $universityName: String!
    $fieldOfStudy: String!
    $year: Int!
    $diploma: Upload!
    $userId: Long
  ) {
    addEducationInfo(
      universityName: $universityName
      fieldOfStudy: $fieldOfStudy
      year: $year
      diploma: $diploma
      userId: $userId
    ) {
      id
      universityName
      fieldOfStudy
      year
      diplomaUrl
      userId
    }
  }
`;
export type AddEducationInfoMutationFn = Apollo.MutationFunction<
  AddEducationInfoMutation,
  AddEducationInfoMutationVariables
>;

/**
 * __useAddEducationInfoMutation__
 *
 * To run a mutation, you first call `useAddEducationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEducationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEducationInfoMutation, { data, loading, error }] = useAddEducationInfoMutation({
 *   variables: {
 *      universityName: // value for 'universityName'
 *      fieldOfStudy: // value for 'fieldOfStudy'
 *      year: // value for 'year'
 *      diploma: // value for 'diploma'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddEducationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEducationInfoMutation,
    AddEducationInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEducationInfoMutation,
    AddEducationInfoMutationVariables
  >(AddEducationInfoDocument, options);
}
export type AddEducationInfoMutationHookResult = ReturnType<
  typeof useAddEducationInfoMutation
>;
export type AddEducationInfoMutationResult =
  Apollo.MutationResult<AddEducationInfoMutation>;
export type AddEducationInfoMutationOptions = Apollo.BaseMutationOptions<
  AddEducationInfoMutation,
  AddEducationInfoMutationVariables
>;
export const DeleteDiplomaDocument = gql`
  mutation deleteDiploma($id: Long!) {
    deleteDiploma(id: $id)
  }
`;
export type DeleteDiplomaMutationFn = Apollo.MutationFunction<
  DeleteDiplomaMutation,
  DeleteDiplomaMutationVariables
>;

/**
 * __useDeleteDiplomaMutation__
 *
 * To run a mutation, you first call `useDeleteDiplomaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiplomaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiplomaMutation, { data, loading, error }] = useDeleteDiplomaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiplomaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDiplomaMutation,
    DeleteDiplomaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDiplomaMutation,
    DeleteDiplomaMutationVariables
  >(DeleteDiplomaDocument, options);
}
export type DeleteDiplomaMutationHookResult = ReturnType<
  typeof useDeleteDiplomaMutation
>;
export type DeleteDiplomaMutationResult =
  Apollo.MutationResult<DeleteDiplomaMutation>;
export type DeleteDiplomaMutationOptions = Apollo.BaseMutationOptions<
  DeleteDiplomaMutation,
  DeleteDiplomaMutationVariables
>;
export const GetProfileInfoDocument = gql`
  query getProfileInfo {
    getProfileInfo {
      ...UserInfoNode
    }
  }
  ${UserInfoNodeFragmentDoc}
`;

/**
 * __useGetProfileInfoQuery__
 *
 * To run a query within a React component, call `useGetProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfileInfoQuery,
    GetProfileInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(
    GetProfileInfoDocument,
    options
  );
}
export function useGetProfileInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileInfoQuery,
    GetProfileInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(
    GetProfileInfoDocument,
    options
  );
}
export type GetProfileInfoQueryHookResult = ReturnType<
  typeof useGetProfileInfoQuery
>;
export type GetProfileInfoLazyQueryHookResult = ReturnType<
  typeof useGetProfileInfoLazyQuery
>;
export type GetProfileInfoQueryResult = Apollo.QueryResult<
  GetProfileInfoQuery,
  GetProfileInfoQueryVariables
>;
export const GetUserInfoDocument = gql`
  query getUserInfo($id: UserID!) {
    getUserInfo(id: $id) {
      ...UserInfoNode
    }
  }
  ${UserInfoNodeFragmentDoc}
`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options
  );
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options
  );
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserInfoLazyQuery
>;
export type GetUserInfoQueryResult = Apollo.QueryResult<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
>;
export const GetUserDocument = gql`
  query getUser {
    getUser {
      ...UserNode
    }
  }
  ${UserNodeFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const RequireAllDocument = gql`
  mutation requireAll($projectId: String!) {
    requireAll(projectId: $projectId)
  }
`;
export type RequireAllMutationFn = Apollo.MutationFunction<
  RequireAllMutation,
  RequireAllMutationVariables
>;

/**
 * __useRequireAllMutation__
 *
 * To run a mutation, you first call `useRequireAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequireAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requireAllMutation, { data, loading, error }] = useRequireAllMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRequireAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequireAllMutation,
    RequireAllMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequireAllMutation, RequireAllMutationVariables>(
    RequireAllDocument,
    options
  );
}
export type RequireAllMutationHookResult = ReturnType<
  typeof useRequireAllMutation
>;
export type RequireAllMutationResult =
  Apollo.MutationResult<RequireAllMutation>;
export type RequireAllMutationOptions = Apollo.BaseMutationOptions<
  RequireAllMutation,
  RequireAllMutationVariables
>;
export const UpdateGapAnalysisPresetDocument = gql`
  mutation updateGapAnalysisPreset(
    $projectId: String!
    $projectTeamInfo: [ProjectTeamGapAnalysisInfoInput!]!
  ) {
    updateGapAnalysisPreset(
      projectId: $projectId
      projectTeamInfo: $projectTeamInfo
    ) {
      projectId
      projectTeamInfo {
        userId
        taskIdList
      }
    }
  }
`;
export type UpdateGapAnalysisPresetMutationFn = Apollo.MutationFunction<
  UpdateGapAnalysisPresetMutation,
  UpdateGapAnalysisPresetMutationVariables
>;

/**
 * __useUpdateGapAnalysisPresetMutation__
 *
 * To run a mutation, you first call `useUpdateGapAnalysisPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGapAnalysisPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGapAnalysisPresetMutation, { data, loading, error }] = useUpdateGapAnalysisPresetMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectTeamInfo: // value for 'projectTeamInfo'
 *   },
 * });
 */
export function useUpdateGapAnalysisPresetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGapAnalysisPresetMutation,
    UpdateGapAnalysisPresetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGapAnalysisPresetMutation,
    UpdateGapAnalysisPresetMutationVariables
  >(UpdateGapAnalysisPresetDocument, options);
}
export type UpdateGapAnalysisPresetMutationHookResult = ReturnType<
  typeof useUpdateGapAnalysisPresetMutation
>;
export type UpdateGapAnalysisPresetMutationResult =
  Apollo.MutationResult<UpdateGapAnalysisPresetMutation>;
export type UpdateGapAnalysisPresetMutationOptions = Apollo.BaseMutationOptions<
  UpdateGapAnalysisPresetMutation,
  UpdateGapAnalysisPresetMutationVariables
>;
export const GetGapAnalysisByDateDocument = gql`
  query getGapAnalysisByDate($projectId: String!, $date: LocalDate!) {
    getGapAnalysisByDate(projectId: $projectId, date: $date) {
      id
      projectId
      date
      info {
        user {
          ...UserNode
        }
        graphUser {
          ...GraphUserNode
        }
        profileStatus
        taskTrainingInfoList {
          need
          training {
            ...TrainingNode
          }
          task {
            ...TaskNode
          }
          status
          certificate {
            ...CertificateNode
          }
        }
      }
    }
  }
  ${UserNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
  ${TrainingNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
  ${CertificateNodeFragmentDoc}
`;

/**
 * __useGetGapAnalysisByDateQuery__
 *
 * To run a query within a React component, call `useGetGapAnalysisByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGapAnalysisByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGapAnalysisByDateQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetGapAnalysisByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGapAnalysisByDateQuery,
    GetGapAnalysisByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGapAnalysisByDateQuery,
    GetGapAnalysisByDateQueryVariables
  >(GetGapAnalysisByDateDocument, options);
}
export function useGetGapAnalysisByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGapAnalysisByDateQuery,
    GetGapAnalysisByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGapAnalysisByDateQuery,
    GetGapAnalysisByDateQueryVariables
  >(GetGapAnalysisByDateDocument, options);
}
export type GetGapAnalysisByDateQueryHookResult = ReturnType<
  typeof useGetGapAnalysisByDateQuery
>;
export type GetGapAnalysisByDateLazyQueryHookResult = ReturnType<
  typeof useGetGapAnalysisByDateLazyQuery
>;
export type GetGapAnalysisByDateQueryResult = Apollo.QueryResult<
  GetGapAnalysisByDateQuery,
  GetGapAnalysisByDateQueryVariables
>;
export const GapAnalysisDocument = gql`
  query gapAnalysis($projectId: String!) {
    gapAnalysis(projectId: $projectId) {
      user {
        ...UserNode
      }
      graphUser {
        ...GraphUserNode
      }
      profileStatus
      taskTrainingInfoList {
        training {
          ...TrainingNode
        }
        certificate {
          ...CertificateNode
        }
        task {
          ...TaskNode
        }
        status
      }
    }
  }
  ${UserNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
  ${TrainingNodeFragmentDoc}
  ${CertificateNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
`;

/**
 * __useGapAnalysisQuery__
 *
 * To run a query within a React component, call `useGapAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGapAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGapAnalysisQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGapAnalysisQuery(
  baseOptions: Apollo.QueryHookOptions<
    GapAnalysisQuery,
    GapAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GapAnalysisQuery, GapAnalysisQueryVariables>(
    GapAnalysisDocument,
    options
  );
}
export function useGapAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GapAnalysisQuery,
    GapAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GapAnalysisQuery, GapAnalysisQueryVariables>(
    GapAnalysisDocument,
    options
  );
}
export type GapAnalysisQueryHookResult = ReturnType<typeof useGapAnalysisQuery>;
export type GapAnalysisLazyQueryHookResult = ReturnType<
  typeof useGapAnalysisLazyQuery
>;
export type GapAnalysisQueryResult = Apollo.QueryResult<
  GapAnalysisQuery,
  GapAnalysisQueryVariables
>;
export const GetGapAnalysisByDateNewDocument = gql`
  query getGapAnalysisByDateNew($projectId: String!, $date: LocalDate!) {
    getGapAnalysisByDateNew(projectId: $projectId, date: $date) {
      gapAnalysis {
        id
        projectId
        date
        info {
          user {
            ...UserNode
          }
          graphUser {
            ...GraphUserNode
          }
          profileStatus
          taskTrainingInfoList {
            training {
              ...TrainingNode
            }
            status
            certificate {
              ...CertificateNode
            }
          }
        }
      }
      exportUrl
    }
  }
  ${UserNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
  ${TrainingNodeFragmentDoc}
  ${CertificateNodeFragmentDoc}
`;

/**
 * __useGetGapAnalysisByDateNewQuery__
 *
 * To run a query within a React component, call `useGetGapAnalysisByDateNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGapAnalysisByDateNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGapAnalysisByDateNewQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetGapAnalysisByDateNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGapAnalysisByDateNewQuery,
    GetGapAnalysisByDateNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGapAnalysisByDateNewQuery,
    GetGapAnalysisByDateNewQueryVariables
  >(GetGapAnalysisByDateNewDocument, options);
}
export function useGetGapAnalysisByDateNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGapAnalysisByDateNewQuery,
    GetGapAnalysisByDateNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGapAnalysisByDateNewQuery,
    GetGapAnalysisByDateNewQueryVariables
  >(GetGapAnalysisByDateNewDocument, options);
}
export type GetGapAnalysisByDateNewQueryHookResult = ReturnType<
  typeof useGetGapAnalysisByDateNewQuery
>;
export type GetGapAnalysisByDateNewLazyQueryHookResult = ReturnType<
  typeof useGetGapAnalysisByDateNewLazyQuery
>;
export type GetGapAnalysisByDateNewQueryResult = Apollo.QueryResult<
  GetGapAnalysisByDateNewQuery,
  GetGapAnalysisByDateNewQueryVariables
>;
export const GetMyProjectsDocument = gql`
  query getMyProjects(
    $searchString: String!
    $businessUnit: [String!]
    $projectStage: [String!]
    $startDateSort: Sort
    $endDateSort: Sort
    $startDateRange: DateRangeInput
    $endDateRange: DateRangeInput
    $projectNameSort: Sort
    $offset: Int!
    $limit: Int!
    $trainingsCompleted: Sort
  ) {
    getMyProjects(
      searchString: $searchString
      businessUnit: $businessUnit
      projectStage: $projectStage
      startDateSort: $startDateSort
      endDateSort: $endDateSort
      startDateRange: $startDateRange
      endDateRange: $endDateRange
      projectNameSort: $projectNameSort
      offset: $offset
      limit: $limit
      trainingsCompleted: $trainingsCompleted
    ) {
      data {
        ...ProjectInfoNode
      }
      total
    }
  }
  ${ProjectInfoNodeFragmentDoc}
`;

/**
 * __useGetMyProjectsQuery__
 *
 * To run a query within a React component, call `useGetMyProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProjectsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      businessUnit: // value for 'businessUnit'
 *      projectStage: // value for 'projectStage'
 *      startDateSort: // value for 'startDateSort'
 *      endDateSort: // value for 'endDateSort'
 *      startDateRange: // value for 'startDateRange'
 *      endDateRange: // value for 'endDateRange'
 *      projectNameSort: // value for 'projectNameSort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyProjectsQuery,
    GetMyProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyProjectsQuery, GetMyProjectsQueryVariables>(
    GetMyProjectsDocument,
    options
  );
}
export function useGetMyProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyProjectsQuery,
    GetMyProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyProjectsQuery, GetMyProjectsQueryVariables>(
    GetMyProjectsDocument,
    options
  );
}
export type GetMyProjectsQueryHookResult = ReturnType<
  typeof useGetMyProjectsQuery
>;
export type GetMyProjectsLazyQueryHookResult = ReturnType<
  typeof useGetMyProjectsLazyQuery
>;
export type GetMyProjectsQueryResult = Apollo.QueryResult<
  GetMyProjectsQuery,
  GetMyProjectsQueryVariables
>;
export const GetProjectInfoDocument = gql`
  query getProjectInfo($id: String!) {
    getProjectInfo(id: $id) {
      id
      name
      businessUnit
      stage
      managerList {
        left
        right {
          ...GraphUserNode
        }
      }
      staffEmailList
      startDate
      endDate
      percent
    }
  }
  ${GraphUserNodeFragmentDoc}
`;

/**
 * __useGetProjectInfoQuery__
 *
 * To run a query within a React component, call `useGetProjectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectInfoQuery,
    GetProjectInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectInfoQuery, GetProjectInfoQueryVariables>(
    GetProjectInfoDocument,
    options
  );
}
export function useGetProjectInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectInfoQuery,
    GetProjectInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectInfoQuery, GetProjectInfoQueryVariables>(
    GetProjectInfoDocument,
    options
  );
}
export type GetProjectInfoQueryHookResult = ReturnType<
  typeof useGetProjectInfoQuery
>;
export type GetProjectInfoLazyQueryHookResult = ReturnType<
  typeof useGetProjectInfoLazyQuery
>;
export type GetProjectInfoQueryResult = Apollo.QueryResult<
  GetProjectInfoQuery,
  GetProjectInfoQueryVariables
>;
export const GetProjectNameByIdDocument = gql`
  query getProjectNameById($projectId: String!) {
    getProjectNameById(projectId: $projectId)
  }
`;

/**
 * __useGetProjectNameByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectNameByIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectNameByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectNameByIdQuery,
    GetProjectNameByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectNameByIdQuery,
    GetProjectNameByIdQueryVariables
  >(GetProjectNameByIdDocument, options);
}
export function useGetProjectNameByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectNameByIdQuery,
    GetProjectNameByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectNameByIdQuery,
    GetProjectNameByIdQueryVariables
  >(GetProjectNameByIdDocument, options);
}
export type GetProjectNameByIdQueryHookResult = ReturnType<
  typeof useGetProjectNameByIdQuery
>;
export type GetProjectNameByIdLazyQueryHookResult = ReturnType<
  typeof useGetProjectNameByIdLazyQuery
>;
export type GetProjectNameByIdQueryResult = Apollo.QueryResult<
  GetProjectNameByIdQuery,
  GetProjectNameByIdQueryVariables
>;
export const GetProjectStageListDocument = gql`
  query getProjectStageList {
    getProjectStageList
  }
`;

/**
 * __useGetProjectStageListQuery__
 *
 * To run a query within a React component, call `useGetProjectStageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStageListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectStageListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectStageListQuery,
    GetProjectStageListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectStageListQuery,
    GetProjectStageListQueryVariables
  >(GetProjectStageListDocument, options);
}
export function useGetProjectStageListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectStageListQuery,
    GetProjectStageListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectStageListQuery,
    GetProjectStageListQueryVariables
  >(GetProjectStageListDocument, options);
}
export type GetProjectStageListQueryHookResult = ReturnType<
  typeof useGetProjectStageListQuery
>;
export type GetProjectStageListLazyQueryHookResult = ReturnType<
  typeof useGetProjectStageListLazyQuery
>;
export type GetProjectStageListQueryResult = Apollo.QueryResult<
  GetProjectStageListQuery,
  GetProjectStageListQueryVariables
>;
export const GetProjectTeamInfoDocument = gql`
  query getProjectTeamInfo(
    $projectId: String!
    $offset: Int!
    $limit: Int!
    $searchString: String
    $mandatoryTrainingSort: Sort
    $jobDependentTrainingSort: Sort
  ) {
    getProjectTeamInfo(
      projectId: $projectId
      offset: $offset
      limit: $limit
      searchString: $searchString
      mandatoryTrainingSort: $mandatoryTrainingSort
      jobDependentTrainingSort: $jobDependentTrainingSort
    ) {
      data {
        ...ProjectTeamInfoNode
      }
      total
    }
  }
  ${ProjectTeamInfoNodeFragmentDoc}
`;

/**
 * __useGetProjectTeamInfoQuery__
 *
 * To run a query within a React component, call `useGetProjectTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTeamInfoQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchString: // value for 'searchString'
 *      mandatoryTrainingSort: // value for 'mandatoryTrainingSort'
 *      jobDependentTrainingSort: // value for 'jobDependentTrainingSort'
 *   },
 * });
 */
export function useGetProjectTeamInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTeamInfoQuery,
    GetProjectTeamInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTeamInfoQuery,
    GetProjectTeamInfoQueryVariables
  >(GetProjectTeamInfoDocument, options);
}
export function useGetProjectTeamInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTeamInfoQuery,
    GetProjectTeamInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTeamInfoQuery,
    GetProjectTeamInfoQueryVariables
  >(GetProjectTeamInfoDocument, options);
}
export type GetProjectTeamInfoQueryHookResult = ReturnType<
  typeof useGetProjectTeamInfoQuery
>;
export type GetProjectTeamInfoLazyQueryHookResult = ReturnType<
  typeof useGetProjectTeamInfoLazyQuery
>;
export type GetProjectTeamInfoQueryResult = Apollo.QueryResult<
  GetProjectTeamInfoQuery,
  GetProjectTeamInfoQueryVariables
>;
export const GetSuggestedCandidatesDocument = gql`
  query getSuggestedCandidates(
    $projectId: String!
    $searchString: String
    $taskIdFilter: [Long!]!
    $offset: Int!
    $limit: Int!
  ) {
    getSuggestedCandidates(
      projectId: $projectId
      searchString: $searchString
      taskIdFilter: $taskIdFilter
      offset: $offset
      limit: $limit
    ) {
      data {
        graphUser {
          ...GraphUserNode
        }
        position
        mandatoryTrainingStatus
        jobDependedTrainingStatus
      }
      total
    }
  }
  ${GraphUserNodeFragmentDoc}
`;

/**
 * __useGetSuggestedCandidatesQuery__
 *
 * To run a query within a React component, call `useGetSuggestedCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedCandidatesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      searchString: // value for 'searchString'
 *      taskIdFilter: // value for 'taskIdFilter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSuggestedCandidatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSuggestedCandidatesQuery,
    GetSuggestedCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSuggestedCandidatesQuery,
    GetSuggestedCandidatesQueryVariables
  >(GetSuggestedCandidatesDocument, options);
}
export function useGetSuggestedCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSuggestedCandidatesQuery,
    GetSuggestedCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSuggestedCandidatesQuery,
    GetSuggestedCandidatesQueryVariables
  >(GetSuggestedCandidatesDocument, options);
}
export type GetSuggestedCandidatesQueryHookResult = ReturnType<
  typeof useGetSuggestedCandidatesQuery
>;
export type GetSuggestedCandidatesLazyQueryHookResult = ReturnType<
  typeof useGetSuggestedCandidatesLazyQuery
>;
export type GetSuggestedCandidatesQueryResult = Apollo.QueryResult<
  GetSuggestedCandidatesQuery,
  GetSuggestedCandidatesQueryVariables
>;
export const ListGapAnalysisDateDocument = gql`
  query listGapAnalysisDate($projectId: String!) {
    listGapAnalysisDate(projectId: $projectId)
  }
`;

/**
 * __useListGapAnalysisDateQuery__
 *
 * To run a query within a React component, call `useListGapAnalysisDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGapAnalysisDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGapAnalysisDateQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListGapAnalysisDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListGapAnalysisDateQuery,
    ListGapAnalysisDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListGapAnalysisDateQuery,
    ListGapAnalysisDateQueryVariables
  >(ListGapAnalysisDateDocument, options);
}
export function useListGapAnalysisDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGapAnalysisDateQuery,
    ListGapAnalysisDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListGapAnalysisDateQuery,
    ListGapAnalysisDateQueryVariables
  >(ListGapAnalysisDateDocument, options);
}
export type ListGapAnalysisDateQueryHookResult = ReturnType<
  typeof useListGapAnalysisDateQuery
>;
export type ListGapAnalysisDateLazyQueryHookResult = ReturnType<
  typeof useListGapAnalysisDateLazyQuery
>;
export type ListGapAnalysisDateQueryResult = Apollo.QueryResult<
  ListGapAnalysisDateQuery,
  ListGapAnalysisDateQueryVariables
>;
export const ListProjectsDocument = gql`
  query listProjects(
    $searchString: String
    $businessUnit: [String!]
    $projectStage: [String!]
    $startDateSort: Sort
    $endDateSort: Sort
    $projectNameSort: Sort
    $startDateRange: DateRangeInput
    $endDateRange: DateRangeInput
    $offset: Int!
    $limit: Int!
    $trainingsCompleted: Sort
  ) {
    listProjects(
      searchString: $searchString
      businessUnit: $businessUnit
      projectStage: $projectStage
      startDateSort: $startDateSort
      endDateSort: $endDateSort
      projectNameSort: $projectNameSort
      startDateRange: $startDateRange
      endDateRange: $endDateRange
      offset: $offset
      limit: $limit
      trainingsCompleted: $trainingsCompleted
    ) {
      projectInfo {
        data {
          ...ProjectInfoNode
        }
        total
      }
      exportUrl
    }
  }
  ${ProjectInfoNodeFragmentDoc}
`;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      businessUnit: // value for 'businessUnit'
 *      projectStage: // value for 'projectStage'
 *      startDateSort: // value for 'startDateSort'
 *      endDateSort: // value for 'endDateSort'
 *      projectNameSort: // value for 'projectNameSort'
 *      startDateRange: // value for 'startDateRange'
 *      endDateRange: // value for 'endDateRange'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListProjectsQuery,
    ListProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    options
  );
}
export function useListProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsQuery,
    ListProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    options
  );
}
export type ListProjectsQueryHookResult = ReturnType<
  typeof useListProjectsQuery
>;
export type ListProjectsLazyQueryHookResult = ReturnType<
  typeof useListProjectsLazyQuery
>;
export type ListProjectsQueryResult = Apollo.QueryResult<
  ListProjectsQuery,
  ListProjectsQueryVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask(
    $name: String!
    $trainingIds: [Long!]!
    $businessUnit: [String!]
  ) {
    createTask(
      name: $name
      trainingIds: $trainingIds
      businessUnit: $businessUnit
    ) {
      id
      name
      businessUnit
      userId
      createdAt
      updatedAt
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      name: // value for 'name'
 *      trainingIds: // value for 'trainingIds'
 *      businessUnit: // value for 'businessUnit'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  );
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>;
export type CreateTaskMutationResult =
  Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($id: Long!) {
    deleteTask(id: $id)
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options
  );
}
export type DeleteTaskMutationHookResult = ReturnType<
  typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
  Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($updateTasks: [UpdateTaskInput!]!) {
    updateTask(updateTasks: $updateTasks) {
      data {
        ...TaskInfoNode
      }
      total
    }
  }
  ${TaskInfoNodeFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      updateTasks: // value for 'updateTasks'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options
  );
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
  Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const ListTaskProjectUserAssignDocument = gql`
  query listTaskProjectUserAssign(
    $search: String
    $businessUnitFilter: [String!]
  ) {
    listTaskProjectUserAssign(
      search: $search
      businessUnitFilter: $businessUnitFilter
    ) {
      ...TaskNode
    }
  }
  ${TaskNodeFragmentDoc}
`;

/**
 * __useListTaskProjectUserAssignQuery__
 *
 * To run a query within a React component, call `useListTaskProjectUserAssignQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskProjectUserAssignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskProjectUserAssignQuery({
 *   variables: {
 *      search: // value for 'search'
 *      businessUnitFilter: // value for 'businessUnitFilter'
 *   },
 * });
 */
export function useListTaskProjectUserAssignQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTaskProjectUserAssignQuery,
    ListTaskProjectUserAssignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTaskProjectUserAssignQuery,
    ListTaskProjectUserAssignQueryVariables
  >(ListTaskProjectUserAssignDocument, options);
}
export function useListTaskProjectUserAssignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskProjectUserAssignQuery,
    ListTaskProjectUserAssignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTaskProjectUserAssignQuery,
    ListTaskProjectUserAssignQueryVariables
  >(ListTaskProjectUserAssignDocument, options);
}
export type ListTaskProjectUserAssignQueryHookResult = ReturnType<
  typeof useListTaskProjectUserAssignQuery
>;
export type ListTaskProjectUserAssignLazyQueryHookResult = ReturnType<
  typeof useListTaskProjectUserAssignLazyQuery
>;
export type ListTaskProjectUserAssignQueryResult = Apollo.QueryResult<
  ListTaskProjectUserAssignQuery,
  ListTaskProjectUserAssignQueryVariables
>;
export const ListTaskWithoutPaginationDocument = gql`
  query listTaskWithoutPagination {
    listTaskWithoutPagination {
      ...TaskNode
    }
  }
  ${TaskNodeFragmentDoc}
`;

/**
 * __useListTaskWithoutPaginationQuery__
 *
 * To run a query within a React component, call `useListTaskWithoutPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskWithoutPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskWithoutPaginationQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTaskWithoutPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTaskWithoutPaginationQuery,
    ListTaskWithoutPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTaskWithoutPaginationQuery,
    ListTaskWithoutPaginationQueryVariables
  >(ListTaskWithoutPaginationDocument, options);
}
export function useListTaskWithoutPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskWithoutPaginationQuery,
    ListTaskWithoutPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTaskWithoutPaginationQuery,
    ListTaskWithoutPaginationQueryVariables
  >(ListTaskWithoutPaginationDocument, options);
}
export type ListTaskWithoutPaginationQueryHookResult = ReturnType<
  typeof useListTaskWithoutPaginationQuery
>;
export type ListTaskWithoutPaginationLazyQueryHookResult = ReturnType<
  typeof useListTaskWithoutPaginationLazyQuery
>;
export type ListTaskWithoutPaginationQueryResult = Apollo.QueryResult<
  ListTaskWithoutPaginationQuery,
  ListTaskWithoutPaginationQueryVariables
>;
export const ListTaskInfoWithoutPaginationDocument = gql`
  query listTaskInfoWithoutPagination($projectId: String!) {
    listTaskInfoWithoutPagination(projectId: $projectId) {
      ...TaskInfoNode
    }
  }
  ${TaskInfoNodeFragmentDoc}
`;

/**
 * __useListTaskInfoWithoutPaginationQuery__
 *
 * To run a query within a React component, call `useListTaskInfoWithoutPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskInfoWithoutPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskInfoWithoutPaginationQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListTaskInfoWithoutPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListTaskInfoWithoutPaginationQuery,
    ListTaskInfoWithoutPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTaskInfoWithoutPaginationQuery,
    ListTaskInfoWithoutPaginationQueryVariables
  >(ListTaskInfoWithoutPaginationDocument, options);
}
export function useListTaskInfoWithoutPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskInfoWithoutPaginationQuery,
    ListTaskInfoWithoutPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTaskInfoWithoutPaginationQuery,
    ListTaskInfoWithoutPaginationQueryVariables
  >(ListTaskInfoWithoutPaginationDocument, options);
}
export type ListTaskInfoWithoutPaginationQueryHookResult = ReturnType<
  typeof useListTaskInfoWithoutPaginationQuery
>;
export type ListTaskInfoWithoutPaginationLazyQueryHookResult = ReturnType<
  typeof useListTaskInfoWithoutPaginationLazyQuery
>;
export type ListTaskInfoWithoutPaginationQueryResult = Apollo.QueryResult<
  ListTaskInfoWithoutPaginationQuery,
  ListTaskInfoWithoutPaginationQueryVariables
>;
export const ListTaskDocument = gql`
  query listTask(
    $search: String
    $titleSort: Sort
    $creationDateSort: Sort
    $offset: Int!
    $limit: Int!
    $businessUnitFilter: [String!]
  ) {
    listTask(
      search: $search
      titleSort: $titleSort
      creationDateSort: $creationDateSort
      offset: $offset
      limit: $limit
      businessUnitFilter: $businessUnitFilter
    ) {
      exportUrl
      taskInfo {
        data {
          ...TaskInfoNode
        }
        total
      }
    }
  }
  ${TaskInfoNodeFragmentDoc}
`;

/**
 * __useListTaskQuery__
 *
 * To run a query within a React component, call `useListTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskQuery({
 *   variables: {
 *      search: // value for 'search'
 *      titleSort: // value for 'titleSort'
 *      creationDateSort: // value for 'creationDateSort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      businessUnitFilter: // value for 'businessUnitFilter'
 *   },
 * });
 */
export function useListTaskQuery(
  baseOptions: Apollo.QueryHookOptions<ListTaskQuery, ListTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTaskQuery, ListTaskQueryVariables>(
    ListTaskDocument,
    options
  );
}
export function useListTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskQuery,
    ListTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTaskQuery, ListTaskQueryVariables>(
    ListTaskDocument,
    options
  );
}
export type ListTaskQueryHookResult = ReturnType<typeof useListTaskQuery>;
export type ListTaskLazyQueryHookResult = ReturnType<
  typeof useListTaskLazyQuery
>;
export type ListTaskQueryResult = Apollo.QueryResult<
  ListTaskQuery,
  ListTaskQueryVariables
>;
export const AddAdditionalCertificateDocument = gql`
  mutation addAdditionalCertificate(
    $userId: Long
    $trainingName: String!
    $completed: LocalDateTime!
    $dueTo: LocalDateTime
    $certificate: Upload!
  ) {
    addAdditionalCertificate(
      userId: $userId
      trainingName: $trainingName
      completed: $completed
      dueTo: $dueTo
      certificate: $certificate
    ) {
      id
      trainingName
      completed
      dueTo
      certificate
      format
      archived
      userId
      createdAt
      updatedAt
    }
  }
`;
export type AddAdditionalCertificateMutationFn = Apollo.MutationFunction<
  AddAdditionalCertificateMutation,
  AddAdditionalCertificateMutationVariables
>;

/**
 * __useAddAdditionalCertificateMutation__
 *
 * To run a mutation, you first call `useAddAdditionalCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalCertificateMutation, { data, loading, error }] = useAddAdditionalCertificateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trainingName: // value for 'trainingName'
 *      completed: // value for 'completed'
 *      dueTo: // value for 'dueTo'
 *      certificate: // value for 'certificate'
 *   },
 * });
 */
export function useAddAdditionalCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdditionalCertificateMutation,
    AddAdditionalCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAdditionalCertificateMutation,
    AddAdditionalCertificateMutationVariables
  >(AddAdditionalCertificateDocument, options);
}
export type AddAdditionalCertificateMutationHookResult = ReturnType<
  typeof useAddAdditionalCertificateMutation
>;
export type AddAdditionalCertificateMutationResult =
  Apollo.MutationResult<AddAdditionalCertificateMutation>;
export type AddAdditionalCertificateMutationOptions =
  Apollo.BaseMutationOptions<
    AddAdditionalCertificateMutation,
    AddAdditionalCertificateMutationVariables
  >;
export const AddTrainingCertificateDocument = gql`
  mutation addTrainingCertificate(
    $userId: Long
    $trainingId: Long!
    $completed: LocalDateTime!
    $dueTo: LocalDateTime
    $certificate: Upload!
  ) {
    addTrainingCertificate(
      userId: $userId
      trainingId: $trainingId
      completed: $completed
      dueTo: $dueTo
      certificate: $certificate
    ) {
      ...CertificateNode
    }
  }
  ${CertificateNodeFragmentDoc}
`;
export type AddTrainingCertificateMutationFn = Apollo.MutationFunction<
  AddTrainingCertificateMutation,
  AddTrainingCertificateMutationVariables
>;

/**
 * __useAddTrainingCertificateMutation__
 *
 * To run a mutation, you first call `useAddTrainingCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingCertificateMutation, { data, loading, error }] = useAddTrainingCertificateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trainingId: // value for 'trainingId'
 *      completed: // value for 'completed'
 *      dueTo: // value for 'dueTo'
 *      certificate: // value for 'certificate'
 *   },
 * });
 */
export function useAddTrainingCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTrainingCertificateMutation,
    AddTrainingCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTrainingCertificateMutation,
    AddTrainingCertificateMutationVariables
  >(AddTrainingCertificateDocument, options);
}
export type AddTrainingCertificateMutationHookResult = ReturnType<
  typeof useAddTrainingCertificateMutation
>;
export type AddTrainingCertificateMutationResult =
  Apollo.MutationResult<AddTrainingCertificateMutation>;
export type AddTrainingCertificateMutationOptions = Apollo.BaseMutationOptions<
  AddTrainingCertificateMutation,
  AddTrainingCertificateMutationVariables
>;
export const CreateProjectTrainingRequestDocument = gql`
  mutation createProjectTrainingRequest(
    $projectId: String!
    $trainingIdList: [Long]!
    $comment: String
  ) {
    createProjectTrainingRequest(
      projectId: $projectId
      trainingIdList: $trainingIdList
      comment: $comment
    ) {
      ...TrainingRequestNode
    }
  }
  ${TrainingRequestNodeFragmentDoc}
`;
export type CreateProjectTrainingRequestMutationFn = Apollo.MutationFunction<
  CreateProjectTrainingRequestMutation,
  CreateProjectTrainingRequestMutationVariables
>;

/**
 * __useCreateProjectTrainingRequestMutation__
 *
 * To run a mutation, you first call `useCreateProjectTrainingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTrainingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTrainingRequestMutation, { data, loading, error }] = useCreateProjectTrainingRequestMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      trainingIdList: // value for 'trainingIdList'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateProjectTrainingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTrainingRequestMutation,
    CreateProjectTrainingRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTrainingRequestMutation,
    CreateProjectTrainingRequestMutationVariables
  >(CreateProjectTrainingRequestDocument, options);
}
export type CreateProjectTrainingRequestMutationHookResult = ReturnType<
  typeof useCreateProjectTrainingRequestMutation
>;
export type CreateProjectTrainingRequestMutationResult =
  Apollo.MutationResult<CreateProjectTrainingRequestMutation>;
export type CreateProjectTrainingRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectTrainingRequestMutation,
    CreateProjectTrainingRequestMutationVariables
  >;
export const CreateTrainingDocument = gql`
  mutation createTraining(
    $name: String!
    $unitOfCompetency: String
    $mandatoryPositions: [String!]!
    $type: Type
    $recurring: Int
    $description: String
  ) {
    createTraining(
      name: $name
      unitOfCompetency: $unitOfCompetency
      mandatoryPositions: $mandatoryPositions
      type: $type
      recurring: $recurring
      description: $description
    ) {
      ...TrainingNode
    }
  }
  ${TrainingNodeFragmentDoc}
`;
export type CreateTrainingMutationFn = Apollo.MutationFunction<
  CreateTrainingMutation,
  CreateTrainingMutationVariables
>;

/**
 * __useCreateTrainingMutation__
 *
 * To run a mutation, you first call `useCreateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingMutation, { data, loading, error }] = useCreateTrainingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      unitOfCompetency: // value for 'unitOfCompetency'
 *      mandatoryPositions: // value for 'mandatoryPositions'
 *      type: // value for 'type'
 *      recurring: // value for 'recurring'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTrainingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrainingMutation,
    CreateTrainingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrainingMutation,
    CreateTrainingMutationVariables
  >(CreateTrainingDocument, options);
}
export type CreateTrainingMutationHookResult = ReturnType<
  typeof useCreateTrainingMutation
>;
export type CreateTrainingMutationResult =
  Apollo.MutationResult<CreateTrainingMutation>;
export type CreateTrainingMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainingMutation,
  CreateTrainingMutationVariables
>;
export const CreateTrainingRequestDocument = gql`
  mutation createTrainingRequest(
    $userId: Long
    $trainingName: String
    $trainingId: Long
    $comment: String
  ) {
    createTrainingRequest(
      userId: $userId
      trainingName: $trainingName
      trainingId: $trainingId
      comment: $comment
    ) {
      id
      trainingName
      userId
      comment
      createdAt
      updatedAt
    }
  }
`;
export type CreateTrainingRequestMutationFn = Apollo.MutationFunction<
  CreateTrainingRequestMutation,
  CreateTrainingRequestMutationVariables
>;

/**
 * __useCreateTrainingRequestMutation__
 *
 * To run a mutation, you first call `useCreateTrainingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingRequestMutation, { data, loading, error }] = useCreateTrainingRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trainingName: // value for 'trainingName'
 *      trainingId: // value for 'trainingId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateTrainingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrainingRequestMutation,
    CreateTrainingRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrainingRequestMutation,
    CreateTrainingRequestMutationVariables
  >(CreateTrainingRequestDocument, options);
}
export type CreateTrainingRequestMutationHookResult = ReturnType<
  typeof useCreateTrainingRequestMutation
>;
export type CreateTrainingRequestMutationResult =
  Apollo.MutationResult<CreateTrainingRequestMutation>;
export type CreateTrainingRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainingRequestMutation,
  CreateTrainingRequestMutationVariables
>;
export const DeleteCustomCertificateDocument = gql`
  mutation deleteCustomCertificate($userId: Long, $certificateId: Long!) {
    deleteCustomCertificate(userId: $userId, certificateId: $certificateId)
  }
`;
export type DeleteCustomCertificateMutationFn = Apollo.MutationFunction<
  DeleteCustomCertificateMutation,
  DeleteCustomCertificateMutationVariables
>;

/**
 * __useDeleteCustomCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCustomCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomCertificateMutation, { data, loading, error }] = useDeleteCustomCertificateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useDeleteCustomCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomCertificateMutation,
    DeleteCustomCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomCertificateMutation,
    DeleteCustomCertificateMutationVariables
  >(DeleteCustomCertificateDocument, options);
}
export type DeleteCustomCertificateMutationHookResult = ReturnType<
  typeof useDeleteCustomCertificateMutation
>;
export type DeleteCustomCertificateMutationResult =
  Apollo.MutationResult<DeleteCustomCertificateMutation>;
export type DeleteCustomCertificateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomCertificateMutation,
  DeleteCustomCertificateMutationVariables
>;
export const DeleteTrainingCertificateDocument = gql`
  mutation deleteTrainingCertificate($userId: Long, $certificateId: Long!) {
    deleteTrainingCertificate(userId: $userId, certificateId: $certificateId)
  }
`;
export type DeleteTrainingCertificateMutationFn = Apollo.MutationFunction<
  DeleteTrainingCertificateMutation,
  DeleteTrainingCertificateMutationVariables
>;

/**
 * __useDeleteTrainingCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingCertificateMutation, { data, loading, error }] = useDeleteTrainingCertificateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useDeleteTrainingCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrainingCertificateMutation,
    DeleteTrainingCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrainingCertificateMutation,
    DeleteTrainingCertificateMutationVariables
  >(DeleteTrainingCertificateDocument, options);
}
export type DeleteTrainingCertificateMutationHookResult = ReturnType<
  typeof useDeleteTrainingCertificateMutation
>;
export type DeleteTrainingCertificateMutationResult =
  Apollo.MutationResult<DeleteTrainingCertificateMutation>;
export type DeleteTrainingCertificateMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTrainingCertificateMutation,
    DeleteTrainingCertificateMutationVariables
  >;
export const DeleteTrainingDocument = gql`
  mutation deleteTraining($id: Long!) {
    deleteTraining(id: $id)
  }
`;
export type DeleteTrainingMutationFn = Apollo.MutationFunction<
  DeleteTrainingMutation,
  DeleteTrainingMutationVariables
>;

/**
 * __useDeleteTrainingMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingMutation, { data, loading, error }] = useDeleteTrainingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrainingMutation,
    DeleteTrainingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrainingMutation,
    DeleteTrainingMutationVariables
  >(DeleteTrainingDocument, options);
}
export type DeleteTrainingMutationHookResult = ReturnType<
  typeof useDeleteTrainingMutation
>;
export type DeleteTrainingMutationResult =
  Apollo.MutationResult<DeleteTrainingMutation>;
export type DeleteTrainingMutationOptions = Apollo.BaseMutationOptions<
  DeleteTrainingMutation,
  DeleteTrainingMutationVariables
>;
export const HideOverdueCertificateDocument = gql`
  mutation hideOverdueCertificate($userId: Long!, $trainingId: Long!) {
    hideOverdueCertificate(userId: $userId, trainingId: $trainingId) {
      ...UserInfoNode
    }
  }
  ${UserInfoNodeFragmentDoc}
`;
export type HideOverdueCertificateMutationFn = Apollo.MutationFunction<
  HideOverdueCertificateMutation,
  HideOverdueCertificateMutationVariables
>;

/**
 * __useHideOverdueCertificateMutation__
 *
 * To run a mutation, you first call `useHideOverdueCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideOverdueCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideOverdueCertificateMutation, { data, loading, error }] = useHideOverdueCertificateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useHideOverdueCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HideOverdueCertificateMutation,
    HideOverdueCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HideOverdueCertificateMutation,
    HideOverdueCertificateMutationVariables
  >(HideOverdueCertificateDocument, options);
}
export type HideOverdueCertificateMutationHookResult = ReturnType<
  typeof useHideOverdueCertificateMutation
>;
export type HideOverdueCertificateMutationResult =
  Apollo.MutationResult<HideOverdueCertificateMutation>;
export type HideOverdueCertificateMutationOptions = Apollo.BaseMutationOptions<
  HideOverdueCertificateMutation,
  HideOverdueCertificateMutationVariables
>;
export const ShowOverdueCertificateDocument = gql`
  mutation showOverdueCertificate($userId: Long!, $trainingId: Long!) {
    showOverdueCertificate(userId: $userId, trainingId: $trainingId) {
      ...UserInfoNode
    }
  }
  ${UserInfoNodeFragmentDoc}
`;
export type ShowOverdueCertificateMutationFn = Apollo.MutationFunction<
  ShowOverdueCertificateMutation,
  ShowOverdueCertificateMutationVariables
>;

/**
 * __useShowOverdueCertificateMutation__
 *
 * To run a mutation, you first call `useShowOverdueCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShowOverdueCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showOverdueCertificateMutation, { data, loading, error }] = useShowOverdueCertificateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useShowOverdueCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShowOverdueCertificateMutation,
    ShowOverdueCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShowOverdueCertificateMutation,
    ShowOverdueCertificateMutationVariables
  >(ShowOverdueCertificateDocument, options);
}
export type ShowOverdueCertificateMutationHookResult = ReturnType<
  typeof useShowOverdueCertificateMutation
>;
export type ShowOverdueCertificateMutationResult =
  Apollo.MutationResult<ShowOverdueCertificateMutation>;
export type ShowOverdueCertificateMutationOptions = Apollo.BaseMutationOptions<
  ShowOverdueCertificateMutation,
  ShowOverdueCertificateMutationVariables
>;
export const UpdateTrainingDocument = gql`
  mutation updateTraining(
    $id: Long!
    $name: String!
    $unitOfCompetency: String
    $mandatoryPositions: [String!]!
    $type: Type
    $recurring: Int
    $description: String
  ) {
    updateTraining(
      id: $id
      name: $name
      unitOfCompetency: $unitOfCompetency
      mandatoryPositions: $mandatoryPositions
      type: $type
      recurring: $recurring
      description: $description
    ) {
      ...TrainingNode
    }
  }
  ${TrainingNodeFragmentDoc}
`;
export type UpdateTrainingMutationFn = Apollo.MutationFunction<
  UpdateTrainingMutation,
  UpdateTrainingMutationVariables
>;

/**
 * __useUpdateTrainingMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingMutation, { data, loading, error }] = useUpdateTrainingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      unitOfCompetency: // value for 'unitOfCompetency'
 *      mandatoryPositions: // value for 'mandatoryPositions'
 *      type: // value for 'type'
 *      recurring: // value for 'recurring'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTrainingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTrainingMutation,
    UpdateTrainingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTrainingMutation,
    UpdateTrainingMutationVariables
  >(UpdateTrainingDocument, options);
}
export type UpdateTrainingMutationHookResult = ReturnType<
  typeof useUpdateTrainingMutation
>;
export type UpdateTrainingMutationResult =
  Apollo.MutationResult<UpdateTrainingMutation>;
export type UpdateTrainingMutationOptions = Apollo.BaseMutationOptions<
  UpdateTrainingMutation,
  UpdateTrainingMutationVariables
>;
export const CountAssignedUsersOnTrainingDocument = gql`
  query countAssignedUsersOnTraining($id: Long!) {
    countAssignedUsersOnTraining(id: $id)
  }
`;

/**
 * __useCountAssignedUsersOnTrainingQuery__
 *
 * To run a query within a React component, call `useCountAssignedUsersOnTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAssignedUsersOnTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAssignedUsersOnTrainingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCountAssignedUsersOnTrainingQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountAssignedUsersOnTrainingQuery,
    CountAssignedUsersOnTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountAssignedUsersOnTrainingQuery,
    CountAssignedUsersOnTrainingQueryVariables
  >(CountAssignedUsersOnTrainingDocument, options);
}
export function useCountAssignedUsersOnTrainingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountAssignedUsersOnTrainingQuery,
    CountAssignedUsersOnTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountAssignedUsersOnTrainingQuery,
    CountAssignedUsersOnTrainingQueryVariables
  >(CountAssignedUsersOnTrainingDocument, options);
}
export type CountAssignedUsersOnTrainingQueryHookResult = ReturnType<
  typeof useCountAssignedUsersOnTrainingQuery
>;
export type CountAssignedUsersOnTrainingLazyQueryHookResult = ReturnType<
  typeof useCountAssignedUsersOnTrainingLazyQuery
>;
export type CountAssignedUsersOnTrainingQueryResult = Apollo.QueryResult<
  CountAssignedUsersOnTrainingQuery,
  CountAssignedUsersOnTrainingQueryVariables
>;
export const GetAssignedUsersTrainingInfoDocument = gql`
  query getAssignedUsersTrainingInfo(
    $trainingId: Long
    $search: String
    $nameSorting: Sort
    $positionFilter: [String!]
    $businessUnitFilter: [String!]
    $completeDateSorting: Sort
    $expiryDateSorting: Sort
    $expiryFilter: [ExportExpireFilter!]
    $statusFilter: [Status!]
    $offset: Int!
    $limit: Int!
  ) {
    getAssignedUsersTrainingInfo(
      trainingId: $trainingId
      search: $search
      nameSorting: $nameSorting
      positionFilter: $positionFilter
      businessUnitFilter: $businessUnitFilter
      completeDateSorting: $completeDateSorting
      expiryDateSorting: $expiryDateSorting
      expiryFilter: $expiryFilter
      statusFilter: $statusFilter
      offset: $offset
      limit: $limit
    ) {
      data {
        fullName
        position
        businessUnit
        competed
        expiry
        status
      }
      total
      exportUrl
    }
  }
`;

/**
 * __useGetAssignedUsersTrainingInfoQuery__
 *
 * To run a query within a React component, call `useGetAssignedUsersTrainingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedUsersTrainingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedUsersTrainingInfoQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      search: // value for 'search'
 *      nameSorting: // value for 'nameSorting'
 *      positionFilter: // value for 'positionFilter'
 *      businessUnitFilter: // value for 'businessUnitFilter'
 *      completeDateSorting: // value for 'completeDateSorting'
 *      expiryDateSorting: // value for 'expiryDateSorting'
 *      expiryFilter: // value for 'expiryFilter'
 *      statusFilter: // value for 'statusFilter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAssignedUsersTrainingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignedUsersTrainingInfoQuery,
    GetAssignedUsersTrainingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignedUsersTrainingInfoQuery,
    GetAssignedUsersTrainingInfoQueryVariables
  >(GetAssignedUsersTrainingInfoDocument, options);
}
export function useGetAssignedUsersTrainingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignedUsersTrainingInfoQuery,
    GetAssignedUsersTrainingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignedUsersTrainingInfoQuery,
    GetAssignedUsersTrainingInfoQueryVariables
  >(GetAssignedUsersTrainingInfoDocument, options);
}
export type GetAssignedUsersTrainingInfoQueryHookResult = ReturnType<
  typeof useGetAssignedUsersTrainingInfoQuery
>;
export type GetAssignedUsersTrainingInfoLazyQueryHookResult = ReturnType<
  typeof useGetAssignedUsersTrainingInfoLazyQuery
>;
export type GetAssignedUsersTrainingInfoQueryResult = Apollo.QueryResult<
  GetAssignedUsersTrainingInfoQuery,
  GetAssignedUsersTrainingInfoQueryVariables
>;
export const GetPositionListDocument = gql`
  query getPositionList {
    getPositionList
  }
`;

/**
 * __useGetPositionListQuery__
 *
 * To run a query within a React component, call `useGetPositionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPositionListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionListQuery,
    GetPositionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPositionListQuery, GetPositionListQueryVariables>(
    GetPositionListDocument,
    options
  );
}
export function useGetPositionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionListQuery,
    GetPositionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPositionListQuery,
    GetPositionListQueryVariables
  >(GetPositionListDocument, options);
}
export type GetPositionListQueryHookResult = ReturnType<
  typeof useGetPositionListQuery
>;
export type GetPositionListLazyQueryHookResult = ReturnType<
  typeof useGetPositionListLazyQuery
>;
export type GetPositionListQueryResult = Apollo.QueryResult<
  GetPositionListQuery,
  GetPositionListQueryVariables
>;
export const GetTrainingMatrixDocument = gql`
  query getTrainingMatrix(
    $search: String
    $statusFilter: [Status!]
    $needFilter: [Need!]
    $titleSort: Sort
    $offset: Int!
    $limit: Int!
  ) {
    getTrainingMatrix(
      search: $search
      statusFilter: $statusFilter
      titleSort: $titleSort
      needFilter: $needFilter
      offset: $offset
      limit: $limit
    ) {
      data {
        project {
          ...ProjectNode
        }
        certificate {
          ...CertificateInfoNode
        }
      }
      total
    }
  }
  ${ProjectNodeFragmentDoc}
  ${CertificateInfoNodeFragmentDoc}
`;

/**
 * __useGetTrainingMatrixQuery__
 *
 * To run a query within a React component, call `useGetTrainingMatrixQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingMatrixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingMatrixQuery({
 *   variables: {
 *      search: // value for 'search'
 *      statusFilter: // value for 'statusFilter'
 *      needFilter: // value for 'needFilter'
 *      titleSort: // value for 'titleSort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTrainingMatrixQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTrainingMatrixQuery,
    GetTrainingMatrixQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTrainingMatrixQuery,
    GetTrainingMatrixQueryVariables
  >(GetTrainingMatrixDocument, options);
}
export function useGetTrainingMatrixLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrainingMatrixQuery,
    GetTrainingMatrixQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTrainingMatrixQuery,
    GetTrainingMatrixQueryVariables
  >(GetTrainingMatrixDocument, options);
}
export type GetTrainingMatrixQueryHookResult = ReturnType<
  typeof useGetTrainingMatrixQuery
>;
export type GetTrainingMatrixLazyQueryHookResult = ReturnType<
  typeof useGetTrainingMatrixLazyQuery
>;
export type GetTrainingMatrixQueryResult = Apollo.QueryResult<
  GetTrainingMatrixQuery,
  GetTrainingMatrixQueryVariables
>;
export const ListTrainingDocument = gql`
  query listTraining(
    $search: String
    $titleSort: Sort
    $creationDateSort: Sort
    $offset: Int!
    $limit: Int!
  ) {
    listTraining(
      search: $search
      titleSort: $titleSort
      creationDateSort: $creationDateSort
      offset: $offset
      limit: $limit
    ) {
      url
      trainingWithTask {
        data {
          training {
            ...TrainingNode
          }
          tasks {
            ...TaskNode
          }
        }
        total
      }
    }
  }
  ${TrainingNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
`;

/**
 * __useListTrainingQuery__
 *
 * To run a query within a React component, call `useListTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrainingQuery({
 *   variables: {
 *      search: // value for 'search'
 *      titleSort: // value for 'titleSort'
 *      creationDateSort: // value for 'creationDateSort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListTrainingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListTrainingQuery,
    ListTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTrainingQuery, ListTrainingQueryVariables>(
    ListTrainingDocument,
    options
  );
}
export function useListTrainingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTrainingQuery,
    ListTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTrainingQuery, ListTrainingQueryVariables>(
    ListTrainingDocument,
    options
  );
}
export type ListTrainingQueryHookResult = ReturnType<
  typeof useListTrainingQuery
>;
export type ListTrainingLazyQueryHookResult = ReturnType<
  typeof useListTrainingLazyQuery
>;
export type ListTrainingQueryResult = Apollo.QueryResult<
  ListTrainingQuery,
  ListTrainingQueryVariables
>;
export const ListTrainingWithRequestDocument = gql`
  query listTrainingWithRequest {
    listTrainingWithRequest {
      left {
        ...TrainingRequestNode
      }
      right {
        ...TrainingNode
      }
    }
  }
  ${TrainingRequestNodeFragmentDoc}
  ${TrainingNodeFragmentDoc}
`;

/**
 * __useListTrainingWithRequestQuery__
 *
 * To run a query within a React component, call `useListTrainingWithRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrainingWithRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrainingWithRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTrainingWithRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTrainingWithRequestQuery,
    ListTrainingWithRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTrainingWithRequestQuery,
    ListTrainingWithRequestQueryVariables
  >(ListTrainingWithRequestDocument, options);
}
export function useListTrainingWithRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTrainingWithRequestQuery,
    ListTrainingWithRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTrainingWithRequestQuery,
    ListTrainingWithRequestQueryVariables
  >(ListTrainingWithRequestDocument, options);
}
export type ListTrainingWithRequestQueryHookResult = ReturnType<
  typeof useListTrainingWithRequestQuery
>;
export type ListTrainingWithRequestLazyQueryHookResult = ReturnType<
  typeof useListTrainingWithRequestLazyQuery
>;
export type ListTrainingWithRequestQueryResult = Apollo.QueryResult<
  ListTrainingWithRequestQuery,
  ListTrainingWithRequestQueryVariables
>;
export const ListTrainingWithoutPaginationDocument = gql`
  query listTrainingWithoutPagination {
    listTrainingWithoutPagination {
      id
      name
      unitOfCompetency
      mandatoryPositions
      type
      recurring
      description
      userId
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useListTrainingWithoutPaginationQuery__
 *
 * To run a query within a React component, call `useListTrainingWithoutPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrainingWithoutPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrainingWithoutPaginationQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTrainingWithoutPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTrainingWithoutPaginationQuery,
    ListTrainingWithoutPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTrainingWithoutPaginationQuery,
    ListTrainingWithoutPaginationQueryVariables
  >(ListTrainingWithoutPaginationDocument, options);
}
export function useListTrainingWithoutPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTrainingWithoutPaginationQuery,
    ListTrainingWithoutPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTrainingWithoutPaginationQuery,
    ListTrainingWithoutPaginationQueryVariables
  >(ListTrainingWithoutPaginationDocument, options);
}
export type ListTrainingWithoutPaginationQueryHookResult = ReturnType<
  typeof useListTrainingWithoutPaginationQuery
>;
export type ListTrainingWithoutPaginationLazyQueryHookResult = ReturnType<
  typeof useListTrainingWithoutPaginationLazyQuery
>;
export type ListTrainingWithoutPaginationQueryResult = Apollo.QueryResult<
  ListTrainingWithoutPaginationQuery,
  ListTrainingWithoutPaginationQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($id: Long!, $role: Role!, $active: Boolean!) {
    updateUser(id: $id, role: $role, active: $active) {
      id
      graphId
      email
      role
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const GetDepartmentListDocument = gql`
  query getDepartmentList {
    getDepartmentList
  }
`;

/**
 * __useGetDepartmentListQuery__
 *
 * To run a query within a React component, call `useGetDepartmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDepartmentListQuery,
    GetDepartmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDepartmentListQuery,
    GetDepartmentListQueryVariables
  >(GetDepartmentListDocument, options);
}
export function useGetDepartmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepartmentListQuery,
    GetDepartmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDepartmentListQuery,
    GetDepartmentListQueryVariables
  >(GetDepartmentListDocument, options);
}
export type GetDepartmentListQueryHookResult = ReturnType<
  typeof useGetDepartmentListQuery
>;
export type GetDepartmentListLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentListLazyQuery
>;
export type GetDepartmentListQueryResult = Apollo.QueryResult<
  GetDepartmentListQuery,
  GetDepartmentListQueryVariables
>;
export const ListUserDocument = gql`
  query listUser(
    $search: String
    $roleFilter: [Role!]
    $businessUnitFilter: [String!]
    $profileTrainingStatusFilter: [AccountStatus!]
    $offset: Int!
    $limit: Int!
  ) {
    listUser(
      search: $search
      roleFilter: $roleFilter
      businessUnitFilter: $businessUnitFilter
      profileTrainingStatusFilter: $profileTrainingStatusFilter
      offset: $offset
      limit: $limit
    ) {
      data {
        accountStatus
        user {
          ...UserNode
        }
        graphProfile {
          ...GraphUserNode
        }
        manager {
          ...GraphUserNode
        }
        projects {
          ...ProjectNode
        }
        currentProjects {
          ...ProjectNode
        }
      }
      total
    }
  }
  ${UserNodeFragmentDoc}
  ${GraphUserNodeFragmentDoc}
  ${ProjectNodeFragmentDoc}
`;

/**
 * __useListUserQuery__
 *
 * To run a query within a React component, call `useListUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserQuery({
 *   variables: {
 *      search: // value for 'search'
 *      roleFilter: // value for 'roleFilter'
 *      businessUnitFilter: // value for 'businessUnitFilter'
 *      profileTrainingStatusFilter: // value for 'profileTrainingStatusFilter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListUserQuery(
  baseOptions: Apollo.QueryHookOptions<ListUserQuery, ListUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUserQuery, ListUserQueryVariables>(
    ListUserDocument,
    options
  );
}
export function useListUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserQuery,
    ListUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUserQuery, ListUserQueryVariables>(
    ListUserDocument,
    options
  );
}
export type ListUserQueryHookResult = ReturnType<typeof useListUserQuery>;
export type ListUserLazyQueryHookResult = ReturnType<
  typeof useListUserLazyQuery
>;
export type ListUserQueryResult = Apollo.QueryResult<
  ListUserQuery,
  ListUserQueryVariables
>;
