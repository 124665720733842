import { ReactQuill } from "@common/components";
import AttachmentDownload from "@help/components/AttachmentDownload";
import { Row, Col } from "antd";
import { Faq } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  question: Faq;
};

const DisplayQuestion: FC<PropTypes> = ({ question }) => {
  const { t } = useTranslation("help.DisplayQuestion");

  return (
    <Row justify="center" gutter={[0, 24]}>
      <Col span={24} className={styles.title}>
        {question.title}
      </Col>
      <Col span={24} className={styles.text}>
        <ReactQuill readOnly theme="bubble" value={question.description} />
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col>
            <Row gutter={[12, 0]}>
              <Col>{t("attachments")}:</Col>
              <Col>
                <Row gutter={[12, 24]}>
                  {question.attachments.map((attachment) => {
                    return (
                      <Col key={attachment.url}>
                        <AttachmentDownload attachment={attachment} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DisplayQuestion;
