import { Spin, Button, Tooltip, Card } from "@common/components";
import { useDebounce } from "@common/hooks/useDebounce";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices";
import { EmployeesTasksTableColumnType } from "@project/types";
import { Card as CardANTD, Col, Row } from "antd";
import classNames from "classnames";
import {
  Task,
  useGetProjectInfoQuery,
  useListTaskProjectUserAssignQuery,
} from "graphqlTypes";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchWithFilter from "./SearchWithFilter";
import SelectedTasksList from "./SelectedTasksList";
import styles from "./styles.module.scss";

type PropTypes = {
  setUserList: Dispatch<
    SetStateAction<EmployeesTasksTableColumnType[] | undefined>
  >;
  currentUserData: EmployeesTasksTableColumnType;
  projectId: string;
};

const TasksFilterForm: FC<PropTypes> = ({
  setUserList,
  currentUserData,
  projectId,
}) => {
  const { t } = useTranslation("task.TasksFilterForm");
  const [businessUnitFilter, setBusinessUnitFilter] = useState<string[]>();
  const [taskSearch, setTaskSearch] = useState<string>();
  const { hideModal } = useModal();
  const debouncedTaskSearch = useDebounce(taskSearch);
  const debouncedBusinessUnitsFilter = useDebounce(businessUnitFilter);
  const tasksQuery = useListTaskProjectUserAssignQuery({
    variables: {
      search: debouncedTaskSearch,
      businessUnitFilter: debouncedBusinessUnitsFilter,
    },
  });
  const projectInfoQuery = useGetProjectInfoQuery({
    variables: {
      id: projectId as string,
    },
    onCompleted: (data) => {
      const businessUnit = data.getProjectInfo?.businessUnit;
      if (businessUnit) {
        setBusinessUnitFilter([businessUnit]);
      }
    },
  });
  const [selectedTasks, setSelectedTasks] = useState(currentUserData.tasks);

  const removeTaskFromSelectList = (taskId: string) => {
    const filteredTasks = selectedTasks.filter((selectedTask) => {
      return selectedTask.id !== taskId;
    });

    setSelectedTasks(filteredTasks);
  };

  const onTaskClick = (task: Task) => {
    const isTaskSelected = selectedTasks.some((selectedTask) => {
      return selectedTask.id === task.id;
    });

    if (isTaskSelected) {
      return removeTaskFromSelectList(task.id);
    }
    setSelectedTasks((tasks) => {
      return [...tasks, task];
    });
  };

  const taskCards = tasksQuery.data?.listTaskProjectUserAssign?.map((task) => {
    const isChecked = selectedTasks.some((assignedTask) => {
      return assignedTask.id === task.id;
    });

    const className = classNames(styles.grid, {
      [styles.selectedGrid]: isChecked,
    });

    return (
      <CardANTD.Grid
        onClick={() => {
          onTaskClick(task);
        }}
        key={task.id}
        className={className}
      >
        <Tooltip title={task.name} className={styles.taskNameTooltip}>
          {task.name}
        </Tooltip>
      </CardANTD.Grid>
    );
  });

  const onSubmitClick = () => {
    setUserList((users) => {
      const editedUsers = users?.map((userData) => {
        if (userData.user.id === currentUserData.user.id) {
          return { ...currentUserData, tasks: selectedTasks };
        }
        return userData;
      });
      return [...(editedUsers || [])];
    });
    hideModal();
  };

  const onCleanTasksList = () => {
    setSelectedTasks([]);
  };

  const isLoading = loadingService.checkLoading([
    tasksQuery.loading,
    projectInfoQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Row
        className={styles.filterForm}
        justify="space-between"
        gutter={[0, 24]}
      >
        <Col span={24}>
          <SearchWithFilter
            setTaskSearch={setTaskSearch}
            businessUnitFilter={businessUnitFilter}
            setBusinessUnitFilter={setBusinessUnitFilter}
          />
        </Col>
        <Col span={24}>
          <Card className={styles.tasksList}>{taskCards}</Card>
        </Col>
        <Col span={24}>
          <SelectedTasksList
            selectedTasks={selectedTasks}
            removeTaskFromSelectList={removeTaskFromSelectList}
          />
        </Col>
        <Col>
          <Button onClick={onCleanTasksList}>{t("cleanAll")}</Button>
        </Col>
        <Col>
          <Button onClick={onSubmitClick} type="primary">
            {t("submit")}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default TasksFilterForm;
