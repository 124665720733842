import { Layout } from "@common/enums";
import { AuthType, ModuleType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import Dashboard from "./pages/Dashboard";

const dashboard: ModuleType = {
  routes: [
    {
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      path: RoutePaths.Dashboard,
      Page: Dashboard,
      type: AuthType.Auth,
      layout: Layout.MainLayout,
    },
  ],
  contextProviders: [],
};

export default dashboard;
