import { DeleteOutlined, FileOutlined } from "@ant-design/icons";
import { Popconfirm, Spin } from "@common/components";
import { Row, Col, message } from "antd";
import { Education, useDeleteDiplomaMutation } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  education: Education[];
};

const DiplomaListEdit: FC<PropTypes> = ({ education }) => {
  const { t } = useTranslation("profile.DiplomaListEdit");
  const [deleteDiploma, deleteDiplomaMutationStatus] =
    useDeleteDiplomaMutation();

  return (
    <Spin spinning={deleteDiplomaMutationStatus.loading}>
      <Row gutter={[0, 12]}>
        {education?.map((education) => {
          const onDeleteClick = () => {
            deleteDiploma({
              variables: { id: education.id },
              refetchQueries: ["getProfileInfo", "getUserInfo"],
              onCompleted: () => {
                message.success(t("diplomaDeleted"));
              },
            });
          };

          return (
            <Col span={24} key={education.id}>
              <Row className={styles.container} align="middle" gutter={[6, 0]}>
                <Col>{t("university")}:</Col>
                <Col>{education.universityName},</Col>
                <Col>{education.fieldOfStudy},</Col>
                <Col>{education.year}</Col>
                <Col>
                  <a
                    target="_blank"
                    href={education.diplomaUrl}
                    rel="noreferrer"
                    data-cy="download-diploma-button"
                  >
                    <FileOutlined className={styles.downloadIcon} />
                  </a>
                </Col>
                <Col>
                  <Popconfirm
                    okText={<span data-testid="yes">{t("yes")}</span>}
                    cancelText={t("no")}
                    title={t("deleteWarning")}
                    onConfirm={onDeleteClick}
                  >
                    <span data-cy="delete-diploma">
                      <DeleteOutlined className={styles.deleteIcon} />
                    </span>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};

export default DiplomaListEdit;
