import { UserOutlined } from "@ant-design/icons";
import { Image } from "@common/components";
import { ProfilePhotoContext } from "@common/contexts/ProfileImageContext";
import { ScreenContext } from "@common/contexts/ScreenContext";
import { FC, useContext } from "react";

import styles from "./styles.module.scss";

const ProfileAvatar: FC = () => {
  const { profileImage } = useContext(ProfilePhotoContext);
  const { isPhoneScreenSize } = useContext(ScreenContext);

  return profileImage ? (
    <Image
      className={isPhoneScreenSize ? styles.mobileAvatar : styles.avatar}
      preview={false}
      alt="avatar"
      src={profileImage}
    />
  ) : (
    <div
      className={
        isPhoneScreenSize
          ? styles.defaultMobileUserImageContainer
          : styles.defaultUserImageContainer
      }
    >
      <UserOutlined className={styles.defaultUserIcon} />
    </div>
  );
};
export default ProfileAvatar;
