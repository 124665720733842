import { Spin } from "@common/components";
import { RouteProps, Route } from "@common/types";
import RoutePaths from "config/routePaths";
import { useGetUserQuery } from "graphqlTypes";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const entranceUrl = `${window.location.pathname}${window.location.search}`;

type PropTypes = {
  RouteByAuthTypeComponent: FC<RouteProps>;
  route: Route;
};

const UserProvider: FC<PropTypes> = ({ RouteByAuthTypeComponent, route }) => {
  const userQuery = useGetUserQuery();
  const navigate = useNavigate();

  const user = userQuery.data?.getUser;

  useEffect(() => {
    if (
      user &&
      entranceUrl &&
      entranceUrl.split("?")[0] !== RoutePaths.SignIn
    ) {
      navigate(entranceUrl);
    }
  }, []);

  return userQuery.loading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <RouteByAuthTypeComponent
      pageProps={route.props}
      Page={route.Page}
      roles={route.roles}
      user={user}
    />
  );
};

export default UserProvider;
