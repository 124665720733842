import { Select, Spin } from "@common/components";
import loadingService from "@common/services/loadingServices";
import { ProjectsTableFilterParametersType } from "@project/types";
import { Col, Row } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import {
  useGetBusinessUnitListQuery,
  useGetProjectStageListQuery,
} from "graphqlTypes";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  setFilterParams: Dispatch<SetStateAction<ProjectsTableFilterParametersType>>;
  filterParams: ProjectsTableFilterParametersType;
};

const ProjectsFilterForm: FC<PropTypes> = ({
  setFilterParams,
  filterParams,
}) => {
  const { t } = useTranslation("project.ProjectsFilterForm");
  const businessUnitsQuery = useGetBusinessUnitListQuery();
  const stagesListQuery = useGetProjectStageListQuery();

  const stagesOptions: DefaultOptionType[] | undefined =
    stagesListQuery.data?.getProjectStageList?.map((stage) => {
      return { value: stage, label: stage };
    });
  const businessUnitsOptions: DefaultOptionType[] | undefined =
    businessUnitsQuery.data?.getBusinessUnitList?.map((businessUnit) => {
      return { value: businessUnit, label: businessUnit };
    });

  const filters = [
    {
      nameLocale: "businessUnits",
      options: businessUnitsOptions,
      filterName: "businessUnits",
      defaultValues: filterParams.businessUnits,
      placeholderLocale: "businessUnitsSelectPlaceholder",
    },
    {
      nameLocale: "stages",
      options: stagesOptions,
      filterName: "projectStages",
      defaultValues: filterParams.projectStages,
      placeholderLocale: "stagesSelectPlaceholder",
    },
  ];

  const isLoading = loadingService.checkLoading([
    businessUnitsQuery.loading,
    stagesListQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[0, 12]}>
        <Col className={styles.title} span={24}>
          {t("filters")}:
        </Col>
        {filters.map((filter) => {
          const onChange = (value: string) => {
            setFilterParams((filterParams) => {
              return { ...filterParams, [filter.filterName]: value };
            });
          };

          return (
            <Col key={filter.filterName} span={24}>
              <Row>
                <Col span={24}>{t(filter.nameLocale)}</Col>
                <Col span={24}>
                  <Select
                    className={styles.select}
                    onChange={onChange}
                    placeholder={t(filter.placeholderLocale)}
                    defaultValue={filter.defaultValues}
                    options={filter.options}
                    mode="multiple"
                  />
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};

export default ProjectsFilterForm;
