import { Layout } from "@common/enums";
import { AuthType, ModuleType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import Notifications from "./pages/Notifications";

const notifications: ModuleType = {
  routes: [
    {
      path: RoutePaths.Notifications,
      Page: Notifications,
      type: AuthType.Auth,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      layout: Layout.MainLayout,
    },
  ],
  contextProviders: [],
};

export default notifications;
