import { Tabs } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useSearchParams } from "@common/hooks/useSearchParams";
import ManageMyProjects from "@project/components/ManageMyProjects";
import ManageProjects from "@project/components/ManageProjects";
import { ManageAdminProjectsTab } from "@project/enum";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const ManageAdminProjects: FC = () => {
  const { t } = useTranslation("project.ManageAdminProjects");
  const { setSearchParams, getSearchParam } = useSearchParams();

  const paramsTabName = getSearchParam(SearchParamsEnum.Tab);

  const items = [
    {
      label: t("myProjects"),
      key: ManageAdminProjectsTab.MyProjects,
      children: <ManageMyProjects span={24} />,
    },
    {
      label: t("projects"),
      key: ManageAdminProjectsTab.Projects,
      children: <ManageProjects />,
    },
  ];

  const onTabChange = (tabName: string) => {
    setSearchParams({
      [SearchParamsEnum.Tab]: tabName || ManageAdminProjectsTab.MyProjects,
    });
  };

  return (
    <Tabs
      onChange={onTabChange}
      className={styles.tabName}
      activeKey={paramsTabName || ManageAdminProjectsTab.MyProjects}
      items={items}
    />
  );
};

export default ManageAdminProjects;
