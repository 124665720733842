import { ApolloProvider } from "@apollo/client";
import { MsalProvider } from "@azure/msal-react";
import { ErrorBoundary } from "@common/components";
import modulesData from "modules";
import apolloProvider from "providers/apolloProvider";
import AuthProvider from "providers/AuthProvider";
import ContextsProvider from "providers/ContextsProvider";
import { I18nextProvider } from "react-i18next";

import i18n from "./providers/i18n";
import msalInstance from "./providers/msal";

const { routes } = modulesData;

const App = () => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloProvider.apolloApiClient}>
        <I18nextProvider i18n={i18n}>
          <ContextsProvider>
            <MsalProvider instance={msalInstance}>
              <AuthProvider routes={routes} />
            </MsalProvider>
          </ContextsProvider>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
