import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import SideBar from "@common/components/MainLayout/SideBar";
import Tooltip from "@common/components/Tooltip";
import { Layout, Col, Row } from "antd";
import classNames from "classnames";
import config from "config";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

const { Sider, Content, Header } = Layout;

const collapseClassName = classNames("trigger", styles.collapseIcon);
const headerClassName = classNames("site-layout-background", styles.header);

const MainLayout: FC = () => {
  const { t } = useTranslation("common.MainLayout");
  const [collapsed, setCollapsed] = useState<boolean>();
  const location = useLocation();

  const pathname = location.pathname.split("/")[1];

  const pageNameByRole: { [key in string]: string } = {
    "": t("profile"),
    users: t("users"),
    projects: t("projects"),
    myProjects: t("myProjects"),
    training: t("training"),
    tasks: t("tasks"),
    myTrainingMatrix: t("myTrainingMatrix"),
    help: t("help"),
    dashboard: t("dashboard"),
    notifications: t("notifications"),
  };

  const pageName = pageNameByRole[pathname];

  const onCollapsedClick = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setCollapsed(window.innerWidth <= config.PHONE_SCREEN_SIZE);
    });
    setCollapsed(window.innerWidth <= config.PHONE_SCREEN_SIZE);
  }, []);

  const contentClassName = classNames(styles.content, {
    [styles.contentWithSider]: !collapsed,
  });

  return (
    <Layout hasSider>
      <Sider
        className={styles.sider}
        collapsed={collapsed}
        collapsedWidth="0"
        width="300px"
      >
        <div className={styles.sidebarMenuContainer}>
          <SideBar />
        </div>
      </Sider>
      <Layout className={contentClassName}>
        <Header className={headerClassName}>
          <Row wrap={false} align="middle">
            <Col className={collapseClassName} onClick={onCollapsedClick}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Col>
            <Col className={styles.headerName}>
              <Tooltip title={pageName}>{pageName}</Tooltip>
            </Col>
          </Row>
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
