import { PlusOutlined } from "@ant-design/icons";
import { BackButton, Button, Spin } from "@common/components";
import { ScreenContext } from "@common/contexts/ScreenContext";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices";
import DiplomaListEdit from "@profile/components/DiplomaListEdit";
import UploadEducationForm from "@profile/components/UploadEducationForm";
import UserProfileHeader from "@profile/components/UserProfileHeader";
import EditTrainingCardOverdue from "@training/components/EditTrainingCardOverdue";
import EditTrainingsTableOverdue from "@training/components/EditTrainingsTableOverdue";
import RequestTrainingForm from "@training/components/RequestTrainingForm";
import TrainingCardActiveWithFileLink from "@training/components/TrainingCardActiveWithFileLink";
import TrainingsCardList from "@training/components/TrainingsCardList";
import TrainingsTableActiveWithFileLink from "@training/components/TrainingsTableActiveWithFileLink";
import UploadCertificateForm from "@training/components/UploadCertificateForm";
import { Col, Row } from "antd";
import features from "config/features";
import {
  Role,
  Status,
  useGetUserInfoQuery,
  useGetUserQuery,
} from "graphqlTypes";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";

const doneStatuses = [Status.Done, Status.Archived];
const pendingStatuses = [Status.Expired, Status.Required];

const UserProfileEdit: FC = () => {
  const { setModalProps, hideModal } = useModal();
  const { t } = useTranslation("profile.UserProfileEdit");
  const currenUserQuery = useGetUserQuery();
  const { userId } = useParams();
  const profileInfoQuery = useGetUserInfoQuery({
    variables: {
      id: Number(userId) || userId,
    },
  });
  const { isPhoneScreenSize } = useContext(ScreenContext);

  const currentUserData = currenUserQuery.data?.getUser;
  const userData = profileInfoQuery.data?.getUserInfo;

  const doneTrainings = userData?.trainings?.filter((training) => {
    return doneStatuses.some((status) => {
      return status === training.status;
    });
  });

  const doneTrainingsWithCustom = [
    ...(doneTrainings || []),
    ...(userData?.customCertificates || []),
  ];

  const pendingTrainings = userData?.trainings?.filter((training) => {
    return pendingStatuses.some((status) => {
      return status === training.status;
    });
  });

  const onRequestCertificateClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: userData?.graphProfile && (
        <RequestTrainingForm userId={userData.user?.id} />
      ),
      open: true,
    });
  };

  const onUploadCertificateClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: <UploadCertificateForm userData={userData || undefined} />,
      open: true,
    });
  };

  const onAddEducationClick = () => {
    setModalProps({
      footer: false,
      onCancel: hideModal,
      children: <UploadEducationForm userData={userData || undefined} />,
      open: true,
    });
  };

  const isLoading = loadingService.checkLoading([
    currenUserQuery.loading,
    profileInfoQuery.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Row justify="center" gutter={[0, 16]}>
        <Col span={23}>
          <BackButton />
        </Col>
        <Col className="userDataStep" span={23}>
          <UserProfileHeader profileData={userData} />
        </Col>
        {features.otherUserEducation &&
          currentUserData?.role === Role.SuperAdmin && (
            <>
              <Col span={23}>
                <Row align="middle">
                  <Col className={styles.titleText}>{t("education")}:</Col>
                  <Col>
                    <Button
                      data-testid="add-diploma"
                      className="educationStep"
                      onClick={onAddEducationClick}
                      type="link"
                      icon={<PlusOutlined />}
                    >
                      {t("addDiploma")}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={23}>
                <DiplomaListEdit education={userData?.education || []} />
              </Col>
            </>
          )}
        <Col span={23}>
          <Row>
            <Col className={styles.titleText}>{t("listOfTrainings")}:</Col>
            {features.request && !isPhoneScreenSize && (
              <Col>
                <Button
                  data-testid="request-training"
                  onClick={onRequestCertificateClick}
                  type="link"
                  icon={<PlusOutlined />}
                  className="requestCertificateStep"
                >
                  {t("request")}
                </Button>
              </Col>
            )}
            <Col>
              <Button
                data-testid="upload-training"
                className="uploadCertificateStep"
                onClick={onUploadCertificateClick}
                type="link"
                icon={<PlusOutlined />}
              >
                {t("upload")}
              </Button>
            </Col>
          </Row>
        </Col>
        {pendingTrainings?.length ? (
          <>
            <Col span={23} className={styles.overdue}>
              {t("overdue")}:
            </Col>
            <Col span={23}>
              {isPhoneScreenSize ? (
                <TrainingsCardList
                  TrainingCard={EditTrainingCardOverdue}
                  certificates={pendingTrainings || []}
                  userData={userData || undefined}
                />
              ) : (
                <EditTrainingsTableOverdue
                  userData={userData || undefined}
                  certificates={pendingTrainings || []}
                />
              )}
            </Col>
          </>
        ) : null}
        <Col span={23} className={styles.done}>
          {t("active")}:
        </Col>
        <Col span={23}>
          {isPhoneScreenSize ? (
            <TrainingsCardList
              TrainingCard={TrainingCardActiveWithFileLink}
              certificates={doneTrainingsWithCustom}
              userData={userData || undefined}
            />
          ) : (
            <TrainingsTableActiveWithFileLink
              userId={userData?.user?.id}
              certificates={doneTrainingsWithCustom}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default UserProfileEdit;
