import { SortOrder } from "antd/lib/table/interface";
import { Sort } from "graphqlTypes";

class SortOrderConverterServices {
  convertToGraphqlSortOrder = (sort: false | SortOrder | undefined) => {
    const sortOrder = { descend: Sort.Desc, ascend: Sort.Asc };
    return sort ? sortOrder[sort] : undefined;
  };

  convertToAntdSortOrder = (sort: Sort | undefined) => {
    const sortOrder: { [key in Sort]: SortOrder } = {
      [Sort.Desc]: "descend",
      [Sort.Asc]: "ascend",
    };
    return sort ? sortOrder[sort] : undefined;
  };
}

const sortConverterServices = new SortOrderConverterServices();

export default sortConverterServices;
