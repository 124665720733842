import { Layout } from "@common/enums";
import { AuthType, ModuleType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import { HistoryContextProvider } from "./contexts/HistoryContext";
import { ModalContextProvider } from "./contexts/ModalContext";
import { ProfilePhotoContextProvider } from "./contexts/ProfileImageContext";
import { ScreenContextProvider } from "./contexts/ScreenContext";
import DoesNotExist from "./pages/DoesNotExist";

const common: ModuleType = {
  routes: [
    {
      layout: Layout.NoLayout,
      Page: DoesNotExist,
      roles: [Role.Admin, Role.Manager, Role.User, Role.SuperAdmin],
      path: RoutePaths.UnknownPath,
      type: AuthType.Common,
    },
  ],
  contextProviders: [
    HistoryContextProvider,
    ModalContextProvider,
    ProfilePhotoContextProvider,
    ScreenContextProvider,
  ],
};

export default common;
