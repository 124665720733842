import { RouteProps } from "@common/types";
import RoutePaths from "config/routePaths";
import { FC } from "react";
import { Navigate } from "react-router-dom";

const AuthRoute: FC<RouteProps> = ({ Page, pageProps, roles, user }) => {
  if (!user) {
    return <Navigate to={RoutePaths.SignIn} />;
  }

  const isAllowedRole = roles.includes(user.role);

  return isAllowedRole ? (
    <Page {...pageProps} />
  ) : (
    <Navigate to={RoutePaths.Profile} />
  );
};

export default AuthRoute;
