import { FileExcelOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Input } from "@common/components";
import { ScreenContext } from "@common/contexts/ScreenContext";
import { ModuleNameEnum, SearchParamsEnum } from "@common/enums";
import { useDebounce } from "@common/hooks/useDebounce";
import { useModal } from "@common/hooks/useModal";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices";
import LogsServices from "@common/services/logsServices";
import ProjectCardList from "@project/components/ProjectCardList";
import ProjectsFilterForm from "@project/components/ProjectsFilterForm";
import ProjectsTable from "@project/components/ProjectsTable";
import { ProjectsTableFilterParametersType } from "@project/types";
import { Col, Row, Spin } from "antd";
import config from "config";
import { useGetMyProjectsQuery, useGetUserQuery } from "graphqlTypes";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const logsServices = new LogsServices(
  ModuleNameEnum.Project,
  "components",
  "ManageMyProjects",
);

type PropTypes = {
  span?: number;
};

const ManageMyProjects: FC<PropTypes> = ({ span = 23 }) => {
  const { t } = useTranslation("project.ManageMyProjects");
  const { addSearchParams, getSearchParam } = useSearchParams();
  const searchText = getSearchParam(SearchParamsEnum.Search);
  const debouncedSearch = useDebounce(searchText);
  const { isPhoneScreenSize } = useContext(ScreenContext);
  const { setModalProps, hideModal } = useModal();
  const [filterParams, setFilterParams] =
    useState<ProjectsTableFilterParametersType>({
      businessUnits: [],
      projectStages: config.DEFAULT_STAGE_FILTERS,
    });
  const user = useGetUserQuery();

  const isExcelExport = false;
  // user.data?.getUser?.role &&
  // config.ROLES_WITH_EXCEL_EXPORT.includes(user.data.getUser.role);

  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;

  const listProjectsQuery = useGetMyProjectsQuery({
    variables: {
      searchString: debouncedSearch || "",
      businessUnit: filterParams.businessUnits,
      projectStage: filterParams.projectStages,
      endDateSort: filterParams.endDate,
      startDateSort: filterParams.startDate,
      projectNameSort: filterParams.projectName,
      startDateRange: filterParams.startDateRange,
      endDateRange: filterParams.endDateRange,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
      limit: config.TABLE_LIMIT,
      trainingsCompleted: filterParams.trainingsCompleted,
    },
  });

  const projectsList = listProjectsQuery.data?.getMyProjects?.data;
  const pageTotal = listProjectsQuery.data?.getMyProjects?.total;
  const isProjectListLoading = listProjectsQuery.loading;
  const projects = projectsList?.map((project) => {
    return {
      key: project.id,
      ...project,
    };
  });

  useEffect(() => {
    const filterProps = getSearchParam(SearchParamsEnum.FilterParams);
    if (filterProps) {
      setFilterParams(JSON.parse(filterProps));
    }
  }, []);

  useEffect(() => {
    addSearchParams({
      [SearchParamsEnum.FilterParams]: JSON.stringify(filterParams || {}),
    });
  }, [filterParams]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    addSearchParams({
      [SearchParamsEnum.Search]: searchText,
      [SearchParamsEnum.Page]: "1",
    });

    logsServices.logVerbose({
      location: ["onSearchChange"],
      message: `My project search: ${searchText}`,
    });
  };

  const onFilterClick = () => {
    setModalProps({
      onCancel: hideModal,
      children: (
        <ProjectsFilterForm
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      ),
      footer: false,
      open: true,
      closable: true,
    });
  };

  const isLoading = loadingService.checkLoading([listProjectsQuery.loading]);

  return (
    <Spin spinning={isLoading}>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={span}>
          <Row gutter={[24, 0]} align="middle">
            <Col flex="auto">
              <Input
                data-testid="projects-search"
                value={searchText || ""}
                onChange={onSearchChange}
                placeholder={t("searchPlaceholder")}
              />
            </Col>
            {isExcelExport && (
              <Col>
                <Button
                  href=""
                  rel="noreferrer"
                  target="_blank"
                  icon={<FileExcelOutlined />}
                  type="dashed"
                >
                  {t("exportExcel")}
                </Button>
              </Col>
            )}
            {isPhoneScreenSize && (
              <Col>
                <Button
                  type="link"
                  onClick={onFilterClick}
                  icon={<FilterOutlined />}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col span={span}>
          {isPhoneScreenSize ? (
            <ProjectCardList
              currentPage={currentPage}
              projects={projects || []}
              pageTotal={pageTotal}
            />
          ) : (
            <ProjectsTable
              currentPage={currentPage}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              projects={projects || []}
              pageTotal={pageTotal}
              isProjectListLoading={isProjectListLoading}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default ManageMyProjects;
