import { Button, Card } from "@common/components";
import { Col, Row } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactJoyride, { Props, TooltipRenderProps } from "react-joyride";

const JoyrideTooltip: FC<TooltipRenderProps> = ({
  tooltipProps,
  step,
  index,
  backProps,
  primaryProps,
  skipProps,
  continuous,
}: TooltipRenderProps) => {
  const { t } = useTranslation("common.JoyrideTooltip");

  return (
    <Card>
      <Row {...tooltipProps} gutter={[0, 24]}>
        {step.title && <Col span={24}>{step.title}</Col>}
        <Col span={24}>{step.content}</Col>
        <Col span={24}>
          <Row>
            <Col flex="auto">
              <Button danger {...skipProps}>
                <span id="skip">{t("skip")}</span>
              </Button>
            </Col>
            <Col>
              <Row justify="end" gutter={[12, 0]}>
                {index > 0 && (
                  <Col>
                    <Button {...backProps}>
                      <span id="back">{t("back")}</span>
                    </Button>
                  </Col>
                )}
                {continuous && (
                  <Col>
                    <Button type="primary" {...primaryProps}>
                      <span id="next">{t("next")}</span>
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

const Joyride: FC<Props> = (props: Props) => {
  return (
    <ReactJoyride
      {...props}
      disableOverlayClose
      spotlightClicks
      tooltipComponent={JoyrideTooltip}
    />
  );
};

export default Joyride;
