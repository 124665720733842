import * as yup from "yup";

export type CreateTaskFormType = {
  taskName: string;
  trainings: string[];
  businessUnits: string[];
};

export const schema: yup.SchemaOf<CreateTaskFormType> = yup.object({
  taskName: yup.string().required(),
  trainings: yup.array().min(1).required(),
  businessUnits: yup.array(),
});
