import { Spin } from "@common/components";
import ManageProject from "@project/components/ManageProject";
import ManageProjectUser from "@project/components/ManageProjectUser";
import config from "config";
import { Role, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";

const manageProjectByRole = {
  [Role.SuperAdmin]: ManageProject,
  [Role.Admin]: ManageProject,
  [Role.Manager]: ManageProject,
  [Role.User]: ManageProjectUser,
};

const Project: FC = () => {
  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data?.getUser;

  const ManageProjectByRole =
    manageProjectByRole[user?.role || config.DEFAULT_ROLE];

  return (
    <Spin spinning={getUserQuery.loading}>
      <ManageProjectByRole />
    </Spin>
  );
};

export default Project;
