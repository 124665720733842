import SideBarMenuItem from "@common/components/MainLayout/SideBarMenuItem";
import sidebarServices from "@common/services/sidebarServices";
import { MenuItemType } from "@common/types";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Role } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

type PropTypes = {
  userRole: Role;
};

const getMenuComponent = (
  menu: MenuItemType[],
  pathname: string,
): JSX.Element => {
  return (
    <Row>
      {menu.map((menuItem) => {
        const isSelected = menuItem.path.split("/")[1] === pathname;
        const className = classNames({
          [styles.item]: true,
          [styles.selectedItem]: isSelected,
        });
        return (
          <Col span={24} key={menuItem.path} className={className}>
            <SideBarMenuItem
              icon={menuItem.icon}
              title={menuItem.title}
              path={menuItem.path}
              isSelected={isSelected}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const SideBarMenu: FC<PropTypes> = ({ userRole }) => {
  const { t } = useTranslation("common.SideBarMenu");
  const location = useLocation();

  const menu = sidebarServices.getMenuByRole(userRole);
  const pathname = location.pathname.split("/")[1];

  const translatedRole = {
    [Role.SuperAdmin]: t("supperAdmin"),
    [Role.Admin]: t("admin"),
    [Role.Manager]: t("management"),
    [Role.User]: t("projectTeam"),
  };

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>{getMenuComponent(menu.commonMenu, pathname)}</Col>
      {!!menu.specialMenu.length && (
        <Col span={24}>
          <Row gutter={[0, 12]}>
            <Col span={24} className={styles.menuName}>
              {t("roleMenu", { role: translatedRole[userRole] })}
            </Col>
            <Col span={24}>{getMenuComponent(menu.specialMenu, pathname)}</Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
export default SideBarMenu;
