import { from } from "@apollo/client";

import authLink from "./authLink";
import errorLink from "./errorLink";
import httpLink from "./httpLink";
import uploadLink from "./uploadLink";

export default {
  apiLink: from([authLink, errorLink, httpLink]),
  uploadLink: from([authLink, errorLink, uploadLink]),
};
