import { Table } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import { useSearchParams } from "@common/hooks/useSearchParams";
import loadingService from "@common/services/loadingServices";
import sortConverterServices from "@common/services/sortConverterServices";
import { LeaderboardFilterOptionsType } from "@dashboard/types";
import { TableProps } from "antd";
import { ColumnFilterItem, SorterResult } from "antd/lib/table/interface";
import config from "config";
import {
  useGetLeaderboardInfoQuery,
  useGetBusinessUnitListQuery,
  ProjectStatus,
  LeaderboardInfo,
} from "graphqlTypes";
import { FC, useEffect, useState } from "react";

import { getColumns } from "./columns";

type PropTypes = {
  searchText: string;
};

const LeadersTable: FC<PropTypes> = ({ searchText }) => {
  const { getSearchParam, addSearchParams } = useSearchParams();
  const currentPage = Number(getSearchParam(SearchParamsEnum.Page)) || 1;
  const [filterParams, setFilterParams] =
    useState<LeaderboardFilterOptionsType>();
  const leadersListQuery = useGetLeaderboardInfoQuery({
    variables: {
      search: searchText,
      statusFilter: filterParams?.statusFilter,
      businessUnitFilter: filterParams?.businessUnitFilter,
      ratingSort: filterParams?.rating,
      titleSort: filterParams?.projectTitle,
      endDateSort: filterParams?.endDate,
      offset: (currentPage - 1) * config.TABLE_LIMIT,
      limit: config.TABLE_LIMIT,
    },
  });
  const businessUnitListQuery = useGetBusinessUnitListQuery();
  const businessUnitsFilterOptions: ColumnFilterItem[] | undefined =
    businessUnitListQuery.data?.getBusinessUnitList?.map((businessUnit) => {
      return { text: businessUnit, value: businessUnit };
    });

  const leadersList = leadersListQuery.data?.getLeaderboardInfo?.data?.map(
    (leader) => {
      return { key: leader.projectInfo.id, ...leader };
    },
  );
  const totalPageCount = leadersListQuery.data?.getLeaderboardInfo?.total;

  const columns = getColumns({
    businessUnitsFilterOptions: businessUnitsFilterOptions || [],
    filterParams,
  });

  useEffect(() => {
    const filterProps = getSearchParam(SearchParamsEnum.FilterParams);
    if (filterProps) {
      setFilterParams(JSON.parse(filterProps));
    }
  }, []);

  useEffect(() => {
    addSearchParams({
      [SearchParamsEnum.FilterParams]: JSON.stringify(filterParams || {}),
    });
  }, [filterParams]);

  const onPageChange = (page: number) => {
    addSearchParams({
      [SearchParamsEnum.Page]: page.toString(),
    });
  };

  const onTableChange: TableProps<LeaderboardInfo>["onChange"] = (
    _page,
    filters,
    sorter,
  ) => {
    const currentSorter = sorter as SorterResult<LeaderboardInfo>;

    const sorterFieldName = currentSorter.field;

    const sorterFields = sorterFieldName
      ? {
          [sorterFieldName as string]:
            sortConverterServices.convertToGraphqlSortOrder(
              currentSorter.order,
            ),
        }
      : {};

    setFilterParams({
      ...sorterFields,
      businessUnitFilter: (filters.businessUnit as string[]) || undefined,
      statusFilter: (filters.status as ProjectStatus[]) || undefined,
    });
  };

  const isLoading = loadingService.checkLoading([
    leadersListQuery.loading,
    businessUnitListQuery.loading,
  ]);

  return (
    <Table
      data-testid="leaderboard-table"
      loading={isLoading}
      columns={columns}
      dataSource={leadersList}
      onChange={onTableChange}
      pagination={{
        current: currentPage,
        total: totalPageCount,
        onChange: onPageChange,
        showSizeChanger: false,
      }}
    />
  );
};

export default LeadersTable;
