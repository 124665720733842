import { List, ListItem } from "@common/components";
import { SearchParamsEnum } from "@common/enums";
import Notification from "@notification/components/Notification";
import wsNotificationsServices from "@notification/services/wsNotificationsServices";
import { Row, Col } from "antd";
import config from "config";
import { useListMyNotificationsQuery } from "graphqlTypes";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ManageNotifications: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get(SearchParamsEnum.Page)) || 1;

  const myNotificationListQuery = useListMyNotificationsQuery({
    variables: {
      offset: (currentPage - 1) * config.TABLE_LIMIT,
      limit: config.TABLE_LIMIT,
    },
  });

  const notifications = myNotificationListQuery.data?.listMyNotifications?.data;
  const total = myNotificationListQuery.data?.listMyNotifications?.total;

  const refetchNotifications = () => {
    myNotificationListQuery.refetch();
  };

  useEffect(() => {
    wsNotificationsServices.subscribeOnNotifications(refetchNotifications);
    return () => {
      wsNotificationsServices.unsubscribeOnNotifications(refetchNotifications);
    };
  }, []);

  const onPageChange = (page: number) => {
    setSearchParams({ page: page.toString() });
  };

  return (
    <Row justify="center" gutter={[0, 24]}>
      <Col span={23}>
        <List
          split={false}
          loading={myNotificationListQuery.loading}
          dataSource={notifications}
          pagination={{
            total,
            onChange: onPageChange,
            showSizeChanger: false,
            current: currentPage,
          }}
          renderItem={(notification) => {
            return (
              <ListItem key={notification.notification.id}>
                <Notification notificationData={notification} />
              </ListItem>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default ManageNotifications;
