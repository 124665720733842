import { Link, Popover, Spin } from "@common/components";
import DashboardPopup from "@dashboard/components/DashboardPopup";
import { ProjectTab } from "@project/enum";
import config from "config";
import RoutePaths from "config/routePaths";
import {
  LeaderboardAwaitingCommencementInfo,
  LeaderboardInfo,
  ProjectStatus,
  Role,
  useGetUserQuery,
} from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  leaderData: LeaderboardInfo | LeaderboardAwaitingCommencementInfo;
};

const colorClassNameByStatus = {
  [ProjectStatus.Active]: styles.activeStatus,
  [ProjectStatus.Inactive]: styles.inactiveStatus,
  [ProjectStatus.Reactive]: styles.reactiveStatus,
};

const LeaderboardStatusCell: FC<PropTypes> = ({ leaderData }) => {
  const { t } = useTranslation("dashboard.LeaderboardStatusCell");
  const userQuery = useGetUserQuery();

  const userRole = userQuery.data?.getUser?.role || config.DEFAULT_ROLE;

  const statusLocaleByStatus = {
    [ProjectStatus.Active]: t("active"),
    [ProjectStatus.Reactive]: t("reactive"),
    [ProjectStatus.Inactive]: t("inactive"),
  };
  const statusByRole = {
    [Role.SuperAdmin]: (
      <Link
        className={colorClassNameByStatus[leaderData.status]}
        to={`${RoutePaths.Projects}/${leaderData.projectInfo.id}?tab=${ProjectTab.GapAnalytics}`}
      >
        {statusLocaleByStatus[leaderData.status]}
      </Link>
    ),
    [Role.Admin]: (
      <Link
        className={colorClassNameByStatus[leaderData.status]}
        to={`${RoutePaths.Projects}/${leaderData.projectInfo.id}?tab=${ProjectTab.GapAnalytics}`}
      >
        {statusLocaleByStatus[leaderData.status]}
      </Link>
    ),
    [Role.Manager]: (
      <Link
        className={colorClassNameByStatus[leaderData.status]}
        to={`${RoutePaths.Projects}/${leaderData.projectInfo.id}?tab=${ProjectTab.GapAnalytics}`}
      >
        {statusLocaleByStatus[leaderData.status]}
      </Link>
    ),
    [Role.User]: (
      <div className={colorClassNameByStatus[leaderData.status]}>
        {statusLocaleByStatus[leaderData.status]}
      </div>
    ),
  };

  return (
    <Spin spinning={userQuery.loading}>
      {leaderData.status === ProjectStatus.Inactive ? (
        statusByRole[userRole]
      ) : (
        <Popover
          content={
            <DashboardPopup userRole={userRole} leaderData={leaderData} />
          }
        >
          {statusByRole[userRole]}
        </Popover>
      )}
    </Spin>
  );
};

export default LeaderboardStatusCell;
