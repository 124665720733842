import { Spin } from "@common/components";
import ManageEditableQuestion from "@help/components/ManageEditableQuestion";
import ManageQuestion from "@help/components/ManageQuestion";
import config from "config";
import { Role, useGetUserQuery } from "graphqlTypes";
import { FC } from "react";

const manageQuestionByRole = {
  [Role.SuperAdmin]: ManageEditableQuestion,
  [Role.Admin]: ManageEditableQuestion,
  [Role.Manager]: ManageQuestion,
  [Role.User]: ManageQuestion,
};

const Question: FC = () => {
  const userQuery = useGetUserQuery();
  const role = userQuery.data?.getUser?.role;

  const ManageQuestion = manageQuestionByRole[role || config.DEFAULT_ROLE];

  return (
    <Spin spinning={userQuery.loading}>
      <ManageQuestion />
    </Spin>
  );
};

export default Question;
