import { Layout } from "@common/enums";
import { AuthType, ModuleType } from "@common/types";
import RoutePaths from "config/routePaths";
import { Role } from "graphqlTypes";

import Project from "./pages/Project";
import Projects from "./pages/Projects";

const projects: ModuleType = {
  routes: [
    {
      path: RoutePaths.Projects,
      Page: Projects,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      type: AuthType.Auth,
      layout: Layout.MainLayout,
    },
    {
      path: RoutePaths.Project,
      Page: Project,
      roles: [Role.SuperAdmin, Role.Admin, Role.Manager, Role.User],
      type: AuthType.Auth,
      layout: Layout.MainLayout,
    },
  ],
  contextProviders: [],
};

export default projects;
