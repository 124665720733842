import { ControlledTextArea, Spin } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import loadingService from "@common/services/loadingServices/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, message, Row } from "antd";
import config from "config";
import {
  CertificateInfo,
  useCreateTrainingRequestMutation,
  useGetProjectNameByIdLazyQuery,
  UserInfo,
} from "graphqlTypes";
import apolloProvider from "providers/apolloProvider";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequestExistedCertificateFormType, schema } from "./schema";
import styles from "./styles.module.scss";

type PropTypes = {
  userData: UserInfo | undefined;
  certificate: CertificateInfo;
};

const RequestExistedCertificateForm: FC<PropTypes> = ({
  certificate,
  userData,
}) => {
  const { t } = useTranslation("training.RequestExistedCertificateForm");
  const [projectName, setProjectName] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RequestExistedCertificateFormType>({
    resolver: yupResolver(schema),
  });
  const { hideModal } = useModal();
  const [requestTrainingMutation, requestTrainingMutationStatus] =
    useCreateTrainingRequestMutation();
  const [projectNameQuery, projectNameQueryStatus] =
    useGetProjectNameByIdLazyQuery();

  useEffect(() => {
    if (certificate.projectId) {
      projectNameQuery({
        variables: {
          projectId: certificate.projectId,
        },
        onCompleted: (data) => {
          setProjectName(data.getProjectNameById || undefined);
        },
      });
    }
  }, []);

  const onRequestSubmit = (data: RequestExistedCertificateFormType) => {
    requestTrainingMutation({
      variables: {
        userId: userData?.user?.id,
        trainingId: certificate.trainingId,
        comment: data.description,
      },
      onCompleted: () => {
        message.success(t("trainingRequested"));
        reset();
        apolloProvider.apolloApiClient.refetchQueries({
          include: ["getProfileInfo", "getTrainingMatrix", "getUserInfo"],
        });
        hideModal();
      },
    });
  };

  const isLoading = loadingService.checkLoading([
    projectNameQueryStatus.loading,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[24, 24]}>
        <Col span={24} className={styles.title}>
          {t("requestTraining")}
        </Col>
        <Col span={8}>{t("userName")}:</Col>
        <Col span={16}>{userData?.graphProfile?.displayName}</Col>
        {projectName && (
          <>
            <Col span={8}>{t("projectName")}:</Col>
            <Col span={16}>{projectName}</Col>
          </>
        )}
        <Col span={8}>{t("trainingName")}:</Col>
        <Col span={16}>{certificate.name}</Col>
        <Col span={8}>{t("position")}:</Col>
        <Col span={16}>{userData?.graphProfile?.jobTitle}</Col>
        <Col span={24}>
          <ControlledTextArea
            textAreaProps={{
              "data-testid": "description",
              status: errors.description && "error",
              placeholder: t("descriptionPlaceholder"),
              maxLength: config.LONG_DESCRIPTION_LENGTH,
            }}
            useControllerProps={{
              name: "description",
              control,
            }}
          />
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col>
              <Button
                data-testid="submit"
                type="primary"
                loading={requestTrainingMutationStatus.loading}
                onClick={handleSubmit(onRequestSubmit)}
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default RequestExistedCertificateForm;
