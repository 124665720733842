import { CloseOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { Task } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  selectedTasks: Task[];
  removeTaskFromSelectList: (taskId: string) => void;
};

const SelectedTasksList: FC<PropTypes> = ({
  selectedTasks,
  removeTaskFromSelectList,
}) => {
  const { t } = useTranslation("task.SelectedTasksList");

  const selectedTasksList = selectedTasks.map((selectedTask) => {
    return (
      <Col key={selectedTask.id}>
        <Row className={styles.selectedItemInList} gutter={[6, 0]}>
          <Col>{selectedTask.name}</Col>
          <Col>
            <CloseOutlined
              className={styles.removeTaskIcon}
              onClick={() => {
                removeTaskFromSelectList(selectedTask.id);
              }}
            />
          </Col>
        </Row>
      </Col>
    );
  });

  return (
    <Row gutter={[12, 12]}>
      <Col>{t("selectedTasks")}:</Col>
      {selectedTasksList}
    </Row>
  );
};

export default SelectedTasksList;
