import { Table } from "@common/components";
import loadingService from "@common/services/loadingServices";
import {
  CertificateInfo,
  Need,
  useDeleteCustomCertificateMutation,
  useDeleteTrainingCertificateMutation,
} from "graphqlTypes";
import { FC } from "react";

import { getColumns } from "./columns";

type PropTypes = {
  certificates: CertificateInfo[];
  userId: number;
};

const TrainingsTableActiveWithFileLink: FC<PropTypes> = ({
  certificates,
  userId,
}) => {
  const [deleteTrainingCertificate, deleteCertificateMutationStatus] =
    useDeleteTrainingCertificateMutation();
  const [deleteCustomCertificate, deleteCustomCertificateMutationStatus] =
    useDeleteCustomCertificateMutation();

  const deleteCertificate = (certificate: CertificateInfo) => {
    const variables = {
      userId,
      certificateId: certificate.certificateId,
    };
    const refetchQueries = [
      "getProfileInfo",
      "getTrainingMatrix",
      "getUserInfo",
    ];

    if (certificate.need === Need.Custom) {
      return deleteCustomCertificate({
        variables,
        refetchQueries,
      });
    }
    deleteTrainingCertificate({
      variables,
      refetchQueries,
    });
  };

  const columns = getColumns({ deleteCertificate });

  const tableData = certificates.map((certificate) => {
    return {
      key: certificate.certificateId,
      ...certificate,
    };
  });

  const isLoading = loadingService.checkLoading([
    deleteCertificateMutationStatus.loading,
    deleteCustomCertificateMutationStatus.loading,
  ]);

  return (
    <Table
      data-testid="active-certificates-table"
      loading={isLoading}
      columns={columns}
      dataSource={tableData}
    />
  );
};

export default TrainingsTableActiveWithFileLink;
